// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IndicatorCell--6uIuc1lX-U{width:100%;height:100%;display:flex}", ""]);
// Exports
exports.locals = {
	"IndicatorCell": "IndicatorCell--6uIuc1lX-U"
};
module.exports = exports;
