import {
  GetMaintenanceBoardMilkSugarErrorDocument,
  GetMaintenanceBoardMilkSugarErrorQuery,
  GetMaintenanceBoardMilkSugarErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getMilkAndSugar({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardMilkSugarErrorQuery,
    GetMaintenanceBoardMilkSugarErrorQueryVariables
  >(GetMaintenanceBoardMilkSugarErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
