import * as React from "react";

export function LocationColumnSpacer({ children }: { children: React.ReactNode; }) {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flexBasis: "70px", flexShrink: 9999 }}></div>
      <div style={{ flexBasis: "700px", minWidth: "0px" }}>{children}</div>
    </div>
  );
}
