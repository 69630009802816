import * as React from "react";
import { useState } from "react";
import {
  Route as RouteDom,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Route from "common/components/route/Route";
import Typography from "common/components/typography/Typography";
import ViewNav from "common/components/viewnav/ViewNav";
import TextButton from "common/components/textbutton/TextButton";
import { EllipsisDropdown } from "common/components/EllipsisDropdown";
import { UpdateLimitModal, DownloadReportModal } from "./components";
import { BatchSwapOrderModal } from "machines/components/batchswapordermodal/BatchSwapOrderModal";
import { ChangeZoneModal } from "./components/ChangeZoneModal/ChangeZoneModal";
import { ChangeServiceZoneModal } from "./components/ChangeServiceZoneModal/ChangeServiceZoneModal";
import { DownloadHealthModal } from "./components/DownloadHealthModal/DownloadHealthModal";
import Map from "containers/machines/views/map/Map";
import Cash from "./views/cash/Cash";
import StandbyMachine from "containers/machines/views/standbyMachine/StandbyMachine";
import {
  getMachineLimitsXLSX,
  getSelectedMachineReportCSV,
} from "machines/service";
import { Monitor } from "./views/monitor/Monitor";
import { useGetMonitorMachines } from "machines/hooks/useGetMonitorMachines";
import { downloadCSVFromJSON } from "utils/csv";
import { useTranslation } from "react-i18next";
import {
  GetMachinesDetailsForCsvQuery,
  GetMachinesDetailsForCsvQueryVariables,
  GetMachinesDetailsForCsvDocument,
  ReportType,
  useGenerateReportMutation,
} from "gql/generated";
import { fetcher } from "gql/fetcher";
import { toasti18n } from "utils/toast";
import * as moment from "moment";
import * as styles from "./Machines.module.scss";
import { useIsAdmin, useIsAdminContract, useIsOperator } from "utils/user-role";
import { OnBoardMachineModal } from "./components/OnBoardMachineModal/OnBoardMachineModal";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";

function Machines(): JSX.Element {
  const { enable: machineOverviewV2Enable } = useMachinesStore();
  const canViewButtons = useIsAdmin() || useIsAdminContract() || useIsOperator();
  const isAdminOrOperator = useIsAdmin() || useIsAdminContract() || useIsOperator();

  const { data: machines } = useGetMonitorMachines();
  const { t } = useTranslation();
  const [selectedMachine, setSelectedMachine] = useState<Array<string>>([]);
  const {
    isLoading: isDownloadMachineCSVLoading,
    download: downloadMachineCSV,
  } = useDownloadMachineCSV();
  const { mutate: downloadAllReport, isLoading: isDownloadAllReport} = useGenerateReportMutation({
    onSuccess: (data) => {
      window.open(`/download-report/${data.generateReport}`);
    },
  });
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isUploadLimitModalOpen, setIsUploadLimitModalOpen] = useState(false);
  const [batchSwapOrderModalOpen, setBatchSwapOrderModalOpen] = useState(false);
  const [isDownloadReportModalOpen, setIsDownloadReportModalOpen] =
    useState(false);
  const [isChangeRefillZoneOpen, setIsChangeRefillZoneOpen] = useState(false);
  const [isChangeServiceZoneOpen, setIsChangeServiceZoneOpen] = useState(false);

  const [isDownloadHelthModalOpen, setIsDownloadHelthModalOpen] =
    useState(false);
  async function downloadLimitXLSX() {
    const file = await getMachineLimitsXLSX();

    const XLSXFile = new File([file], "limits.xlsx", {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(XLSXFile);
    anchorElement.download = "limits.xlsx";
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  }
  function downloadLastSellCSV() {
    function generateRelativeTimeString(timeStamp: number): string {
      const differenceInSeconds = Math.floor(
        (moment().valueOf() - timeStamp) / 1000
      );
      const differenceInMins = Math.floor(differenceInSeconds / 60);
      const differenceInHours = Math.floor(differenceInMins / 60);
      const differenceInDays = Math.floor(differenceInHours / 24);

      if (differenceInDays >= 1) {
        return `${differenceInDays} ${t("label_day")}`;
      }
      if (differenceInHours >= 1) {
        return `${differenceInHours} ${t("label_hour_short")}`;
      }

      if (differenceInMins >= 1) {
        return `${differenceInMins} ${t("label_minute_short")}`;
      }

      return `${differenceInSeconds} ${t("label_second_short")}`;
    }
    const records = (machines || [])
      .sort((a, b) => {
        if (a.lastSell === 0 || a.lastSell === undefined) return 1;
        else if (b.lastSell === 0 || b.lastSell === undefined) return -1;
        else return a.lastSell - b.lastSell;
      })
      .map((machine) => ({
        "Machine ID": machine.machineId,
        "Machine Name": machine.machineName,
        "Organisation Name": machine.organisationName,
        "Last sell (Relative)": machine.lastSell
          ? generateRelativeTimeString(machine.lastSell)
          : undefined,
        "Last Sell (Date/Time)": machine.lastSell
          ? moment(Number(machine.lastSell)).format("DD/MM/YYYY, HH:mm:ss z")
          : undefined,
      }));
    downloadCSVFromJSON(
      records,
      `Last sell report ${moment().format("DD-MM-YYYY--HH-mm-ss z")}`
    );
  }
  
  async function downloadSelectedMachineReportCSV(machineParam: string) {
    const file = await getSelectedMachineReportCSV(machineParam);

    const CSVFile = new File([file], "report.csv", { type: "text/csv" });

    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = "report.csv";
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  }
  function getMachineParams(machines: string[]) {
    const URLparam = new URLSearchParams();
    machines.forEach((each) => URLparam.append("machines", each));
    return URLparam.toString();
  }
  const { pathname } = useLocation();
  function useDownloadMachineCSV() {
    const [isLoading, setIsLoading] = React.useState(false);

    async function downloadMachinesLocationCSV() {
      const machinesLocationQuery = await fetcher<
        GetMachinesDetailsForCsvQuery,
        GetMachinesDetailsForCsvQueryVariables
      >(GetMachinesDetailsForCsvDocument, { machinesId: selectedMachine })();
      const machinesData = machinesLocationQuery.machines;

      downloadCSVFromJSON(
        machinesData
          .sort((a, b) => a.id.localeCompare(b.id))
          .map((machine) => ({
            "Machine ID": machine.id,
            "Location name": machine.location.locationName,
            "Location type": machine.location.locationType,
            Province: machine.location.province,
            Organisation: machine.organisation.name,
          })),
        `Machine Information ${moment().format("DD-MM-YYYY")}`
      );
    }

    async function download() {
      setIsLoading(true);
      await toasti18n.promise(downloadMachinesLocationCSV);
      setIsLoading(false);
    }

    return { isLoading, download };
  }

  return (
    <>
      {isDownloadReportModalOpen && (
        <DownloadReportModal
          open
          onClose={() => setIsDownloadReportModalOpen(false)}
          selectedMachine={selectedMachine}
        />
      )}
      {isChangeRefillZoneOpen && (
        <ChangeZoneModal
          selectedMachine={selectedMachine}
          open={isChangeRefillZoneOpen}
          onClose={() => setIsChangeRefillZoneOpen(false)}
        />
      )}
      {isChangeServiceZoneOpen && (
        <ChangeServiceZoneModal
          selectedMachine={selectedMachine}
          open={isChangeServiceZoneOpen}
          onClose={() => setIsChangeServiceZoneOpen(false)}
        />
      )}
      {isDownloadHelthModalOpen && (
        <DownloadHealthModal
          open
          selectedMachine={selectedMachine}
          onClose={() => setIsDownloadHelthModalOpen(false)}
        />
      )}
      {isUploadLimitModalOpen && (
        <UpdateLimitModal onClose={() => setIsUploadLimitModalOpen(false)} />
      )}
      {batchSwapOrderModalOpen && (
        <BatchSwapOrderModal
          onClose={() => setBatchSwapOrderModalOpen(false)}
          selectedMachines={selectedMachine}
        />
      )}
      <div className={styles.Machines}>
        <div className={styles.header}>
          <div>
            <Typography translationKey="workspace_machines" type="headline-5" />
          </div>
          <div className={styles.Buttons}>
            <div className={styles.left}>
              {pathname === "/machines/cash" ? (
                <div style={{ marginLeft: "30px" }}>
                  <OnBoardMachineModal
                    renderProp={(openModal) => (
                      <TextButton
                        translationKey="action_onboard_machine"
                        onClick={openModal}
                        icon="MachineRegistration"
                      />
                    )}
                  />
                </div>
              ) : null}
              {pathname !== "/machines/cash" && (
                <>
                  <div className={styles.actionAndDropDownContainer}>
                    {canViewButtons && (
                      <>
                        <TextButton
                          translationKey="action_download_report"
                          onClick={async () => {
                            try {
                              document.body.classList.add(styles.loadingCursor);
                              const machineParams =
                                getMachineParams(selectedMachine);
                              await downloadSelectedMachineReportCSV(
                                machineParams
                              );
                            } catch (e) {
                              // Error toast handled in interceptor
                            } finally {
                              document.body.classList.remove(
                                styles.loadingCursor
                              );
                            }
                          }}
                          icon="VendingMachine"
                          className={styles.action}
                          disabled={selectedMachine.length === 0}
                        />
                        <TextButton
                          disabled={isDownloadAllReport}
                          translationKey="action_download_all_report"
                          onClick={async () => {
                            downloadAllReport({
                              params: {
                                reportType: ReportType.MachineInventory
                              }
                            });
                          }}
                          icon="VendingMachine"
                          className={styles.action}
                        />
                        <TextButton
                          translationKey="action_download_machine_health"
                          onClick={() =>
                            setIsDownloadHelthModalOpen((prev) => !prev)
                          }
                          icon="VendingMachine"
                          className={styles.action}
                          disabled={selectedMachine.length === 0}
                        />
                        <TextButton
                          translationKey="label_header_swap_inventory"
                          // disabled={selectedMachine.length === 0}
                          disabled={true}
                          onClick={() => setBatchSwapOrderModalOpen(true)}
                          icon="Swap"
                          className={styles.action}
                        />

                        <EllipsisDropdown className={styles.ellipsisDropDown}>
                          <EllipsisDropdown.EllipsisIcon
                            onClick={() => setIsDropDownOpen(!isDropDownOpen)}
                          />
                          <EllipsisDropdown.OptionGroup isOpen={isDropDownOpen}>
                            {isAdminOrOperator && (
                              <EllipsisDropdown.Option
                                onClick={async () => {
                                  try {
                                    setIsDropDownOpen(false);
                                    document.body.classList.add(
                                      styles.loadingCursor
                                    );
                                    await downloadLimitXLSX();
                                  } catch (e) {
                                    // Error toast handled in interceptor
                                  } finally {
                                    document.body.classList.remove(
                                      styles.loadingCursor
                                    );
                                  }
                                }}
                                icon="DownloadTray"
                                translationKey="action_download_limit"
                              />
                            )}

                            {selectedMachine.length !== 0 && (
                              <>
                                <EllipsisDropdown.Option
                                  onClick={() =>
                                    setIsChangeRefillZoneOpen((prev) => !prev)
                                  }
                                  icon="ChangeZone"
                                  translationKey="action_change_refill_zone"
                                />

                                <EllipsisDropdown.Option
                                  onClick={() =>
                                    setIsChangeServiceZoneOpen((prev) => !prev)
                                  }
                                  icon="ChangeZone"
                                  translationKey="action_change_service_zone"
                                />
                              </>
                            )}
                            {machines && machines.length > 0 && (
                              <EllipsisDropdown.Option
                                onClick={downloadLastSellCSV}
                                icon="DownloadTray"
                                translationKey="action_download_last_sell_report"
                              />
                            )}
                            {selectedMachine.length !== 0 && (
                              <EllipsisDropdown.Option
                                disabled={isDownloadMachineCSVLoading}
                                onClick={() => {
                                  downloadMachineCSV();
                                }}
                                icon="DownloadTray"
                                translationKey="label_download_machine_detail_csv"
                              />
                            )}
                            {isAdminOrOperator && (
                              <EllipsisDropdown.Option
                                onClick={() => {
                                  setIsUploadLimitModalOpen(true);
                                  setIsDropDownOpen(false);
                                }}
                                icon="Upload"
                                translationKey="action_upload_limit"
                              />
                            )}
                            {selectedMachine.length !== 0 && (
                              <EllipsisDropdown.Option
                                onClick={() => {
                                  setIsDownloadReportModalOpen(true);
                                }}
                                icon="InventoryReport"
                                translationKey="label_inventory_usage_report"
                              />
                            )}

                            <OnBoardMachineModal
                              renderProp={(openModal) => (
                                <EllipsisDropdown.Option
                                  onClick={openModal}
                                  icon="MachineRegistration"
                                  translationKey="action_onboard_machine"
                                />
                              )}
                            />
                          </EllipsisDropdown.OptionGroup>
                        </EllipsisDropdown>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>

            <ViewNav
              options={[
                {
                  icon: "Map",
                  to: "/machines/map",
                },
                {
                  icon: "MachineCash",
                  to: "/machines/cash",
                },
                {
                  icon: "Speedometer",
                  to: machineOverviewV2Enable ? "/machines-v2/monitor": "/machines/monitor",
                },
                {
                  icon: "StandbyMachine",
                  to: "/machines/standbymachine",
                },
              ]}
            />
          </div>
        </div>

        <Switch>
          <Route exact path="/machines/map" component={Map} />
          <Route exact path="/machines/cash" component={Cash} />
          <RouteDom path="/machines/monitor">
            <Monitor
              setSelectedMachine={setSelectedMachine}
              selectedMachine={selectedMachine}
            />
          </RouteDom>
          <RouteDom exact path="/machines/standbymachine">
            <StandbyMachine />
          </RouteDom>
          <Redirect to="/machines/monitor" />
        </Switch>
      </div>
    </>
  );
}

export default Machines;
