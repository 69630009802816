import { Tooltip } from "@mui/material";
import Typography from "components/Typography";
import * as React from "react";
import * as styles from "./InventoryBar.module.scss";

interface ValueBarCSSProperties extends React.CSSProperties {
  "--valuePercentage": number;
  "--offsetNum": number;
}
interface ForecastedBarCSSProperties extends React.CSSProperties {
  "--forecastedPercentage": number;
}
interface LevelBarCSSProperties extends React.CSSProperties {
  "--levelPercentage": number;
}
interface AmountWithinRecommendedCSSProperties extends React.CSSProperties {
  "--amountWithinRecommendedPercentage": number;
  "--forecastedPercentage": number;
}
interface AdditionalBarCSSProperties extends React.CSSProperties {
  "--amountWithinRecommendedPercentage": number;
  "--forecastedPercentage": number;
  "--additionalAmountPercentage": number;
}

function convertToStringPercentage(number: number) {
  return Math.floor(number * 100) + "%";
}

interface InventoryBarProps {
  selected: boolean;
  inventory: Inventory;
  recommendation?: Recommendation;
  amountTaken?: number;
}

interface Recommendation {
  recommendedAmount: number;
  baseline: number;
  travel: number;
  total: number;
}

interface Inventory {
  swapType?: "REPLACE" | "REFILL_ON_TOP";
  pendingSwap: boolean;
  autoRefill: boolean;
  currentLevel: number;
  lowLevel: number;
  highLevel: number;
  forecastedLevel?: number;
  capacity: number;
  uom?: string;
  isForecastingDisabled?: boolean;
}

export function InventoryBarWithSelection({
  inventory,
  selected,
  amountTaken,
  recommendation,
}: InventoryBarProps): JSX.Element {
  const {
    highLevel,
    lowLevel,
    capacity,
    autoRefill,
    pendingSwap,
    currentLevel,
    forecastedLevel,
    isForecastingDisabled,
    uom,
  } = inventory;
  const valuePercentage = currentLevel / capacity;
  const lowLevelPercentage = lowLevel / capacity;
  const highLevelPercentage = highLevel / capacity;

  const currentTooltip = (
    <div>
      <div>
        <Typography type="caption" translate color="background">
          {inventory.swapType === "REPLACE"
            ? "label_current_level_to_be_removed"
            : "label_current_quantity"}
          {": "}
          {`${currentLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_low
          {": "}
          {`${lowLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_high
          {": "}
          {`${highLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_capacity
          {": "}
          {`${capacity.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
    </div>
  );

  const { recommendedAmount } = recommendation;
  const forecastPercentage = forecastedLevel / capacity;
  const amountWithinRecommended = Math.min(amountTaken, recommendedAmount);
  const amountWithinRecommenedPercentage = amountWithinRecommended / capacity;
  const isRecommendedAmountWithinCapacity =
    recommendedAmount + forecastedLevel < capacity;

  const noRecommendedAmount = recommendedAmount === 0;
  const additionalAmount = Math.max(amountTaken - recommendedAmount, 0);
  const additionalAmountPercentage = additionalAmount / capacity;

  const overflow = amountTaken + forecastedLevel > capacity;
  const currentValueOverflow = currentLevel > capacity;

  const recommendedAmountTooltip = recommendation ? (
    <div>
      {inventory.swapType !== "REPLACE" ? (
        <>
          <div>
            <Typography type="caption" translate color="background">
              label_baseline
              {": "}
              {`${recommendation.baseline.toFixed(2)} `}
              {inventory.uom ?? undefined}
            </Typography>
          </div>
          {isForecastingDisabled ? null : (
            <div>
              <Typography type="caption" translate color="background">
                label_travel
                {": "}
                {`${recommendation.travel.toFixed(2)} `}
                {inventory.uom ?? undefined}
              </Typography>
            </div>
          )}
          <div>
            <Typography type="caption" translate color="background">
              label_total
              {": "}
              {`${recommendation.total.toFixed(2)} `}
              {inventory.uom ?? undefined}
            </Typography>
          </div>{" "}
        </>
      ) : null}

      <div>
        <Typography type="caption" translate color="background">
          label_forecast
          {": "}
          {`${recommendation.recommendedAmount.toFixed(2)} `}
          {inventory.uom ?? undefined}
        </Typography>
      </div>
    </div>
  ) : null;

  const additionalTooltip = additionalAmount ? (
    <div>
      <Typography type="caption" translate color="background">
        label_additional
        {": "}
        {`${additionalAmount.toFixed(2)} `}
        {inventory.uom ?? undefined}
      </Typography>
    </div>
  ) : null;

  const forecastedLevelTooltip =
    forecastedLevel !== undefined ? (
      <div>
        <Typography type="caption" translate color="background">
          label_forecasted_quantity
          {": "}
          {`${forecastedLevel.toFixed(2)} `}
          {inventory.uom ?? undefined}
        </Typography>
      </div>
    ) : null;

  return (
    <div className={styles.InventoryWrapper}>
      <div className={styles.InventoryContainer}>
        <div className={styles.TextContainer}>
          <div>
            <Typography
              translate
              type="caption"
              color={autoRefill ? "onSurfaceDisabled" : "error"}
            >
              {[
                autoRefill ? "label_auto_refill_on" : "label_auto_refill_off",
                pendingSwap ? "label_pending_swap" : null,
                !inventory.swapType
                  ? null
                  : inventory.swapType === "REFILL_ON_TOP"
                    ? "label_replace_method_refill_on_top"
                    : "label_replace_method_remove_old",
              ]
                .filter(Boolean)
                .join("#, #")
                .split("#")}
            </Typography>
          </div>
          <div>
            <Typography translate type="caption" color="onSurfaceDisabled">
              {convertToStringPercentage(valuePercentage)}
            </Typography>
          </div>
        </div>

        <div className={styles.InventoryBarContainer}>
          <Tooltip followCursor={true} title={currentTooltip}>
            <div className={styles.InventoryBarGreyOverlay}></div>
          </Tooltip>

          {selected && amountWithinRecommended !== 0 ? (
            <Tooltip followCursor={true} title={recommendedAmountTooltip}>
              <div
                className={styles.AmountWithinRecommendedBar}
                style={
                  {
                    "--amountWithinRecommendedPercentage":
                      amountWithinRecommenedPercentage,
                    "--forecastedPercentage": forecastPercentage,
                  } as AmountWithinRecommendedCSSProperties
                }
                data-within-capacity={isRecommendedAmountWithinCapacity}
              ></div>
            </Tooltip>
          ) : null}

          {selected && additionalAmount !== 0 && !currentValueOverflow ? (
            <div
              className={
                currentValueOverflow
                  ? ""
                  : styles.AdditionalBarOverflowHiddenContainer
              }
            >
              <Tooltip followCursor={true} title={additionalTooltip}>
                <div
                  data-no-recommended-amount={noRecommendedAmount}
                  className={styles.AdditionalBar}
                  style={
                    {
                      "--amountWithinRecommendedPercentage":
                        amountWithinRecommenedPercentage,
                      "--forecastedPercentage": forecastPercentage,
                      "--additionalAmountPercentage":
                        additionalAmountPercentage,
                    } as AdditionalBarCSSProperties
                  }
                  data-within-capacity={isRecommendedAmountWithinCapacity}
                ></div>
              </Tooltip>
            </div>
          ) : null}

          <div className={styles.InventoryBarBorder}></div>
          {lowLevelPercentage !== 0 && lowLevelPercentage < 1 ? (
            <div
              className={styles.LevelBar}
              style={
                {
                  "--levelPercentage": lowLevelPercentage,
                } as LevelBarCSSProperties
              }
            ></div>
          ) : null}
          {highLevelPercentage !== 0 && highLevelPercentage < 1 ? (
            <div
              className={styles.LevelBar}
              style={
                {
                  "--levelPercentage": highLevelPercentage,
                } as LevelBarCSSProperties
              }
            ></div>
          ) : null}

          {currentLevel !== 0 && inventory.swapType !== "REPLACE" ? (
            <Tooltip followCursor={true} title={currentTooltip}>
              <div className={styles.CurrentValueOverflowHiddenContainer}>
                <div
                  className={styles.CurrentValueBar}
                  style={Object.assign(
                    {} as ValueBarCSSProperties,
                    valuePercentage < 1
                      ? {
                          borderTopLeftRadius: "9999px",
                          borderBottomLeftRadius: "9999px",
                          "--valuePercentage": valuePercentage,
                          "--offsetNum": 1,
                        }
                      : {
                          borderRadius: "9999px",
                          "--valuePercentage": valuePercentage,
                          "--offsetNum": 2,
                        },
                    {
                      backgroundColor: selected
                        ? "rgba(39, 182, 186, 0.6)"
                        : "rgba(0, 0, 0, 0.12)",
                    }
                  )}
                ></div>
              </div>
            </Tooltip>
          ) : null}

          {currentValueOverflow && inventory.swapType !== "REPLACE" ? (
            <Tooltip followCursor={true} title={currentTooltip}>
              <div
                className={styles.OverflowIndicatorCurrentLevel}
                style={{
                  backgroundColor: selected
                    ? undefined
                    : "rgba(209, 209, 209, 1)",
                }}
              ></div>
            </Tooltip>
          ) : null}

          {overflow && selected && amountTaken > 0 ? (
            <Tooltip followCursor={true} title={additionalTooltip}>
              <div className={styles.OverflowIndicatorAdditional}></div>
            </Tooltip>
          ) : null}

          {selected &&
          forecastedLevel < capacity &&
          !isForecastingDisabled &&
          inventory.swapType !== "REPLACE" ? (
            <Tooltip followCursor={true} title={forecastedLevelTooltip}>
              <div
                className={styles.ForecastRedDot}
                style={
                  {
                    "--forecastedPercentage": forecastPercentage,
                  } as ForecastedBarCSSProperties
                }
              ></div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export function InventoryBar({
  inventory,
}: Pick<InventoryBarProps, "inventory">): JSX.Element {
  const {
    highLevel,
    lowLevel,
    capacity,
    autoRefill,
    pendingSwap,
    currentLevel,
    uom,
  } = inventory;
  const valuePercentage = currentLevel / capacity;
  const lowLevelPercentage = lowLevel / capacity;
  const highLevelPercentage = highLevel / capacity;
  const currentValueOverflow = currentLevel > capacity;

  const currentTooltip = (
    <div>
      <div>
        <Typography type="caption" translate color="background">
          label_current_quantity
          {": "}
          {`${currentLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_low
          {": "}
          {`${lowLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_high
          {": "}
          {`${highLevel.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
      <div>
        <Typography type="caption" translate color="background">
          label_capacity
          {": "}
          {`${capacity.toFixed(2)} `}
          {uom ?? undefined}
        </Typography>
      </div>
    </div>
  );

  return (
    <div className={styles.InventoryWrapper}>
      <div className={styles.InventoryContainer}>
        <div className={styles.TextContainer}>
          <div>
            <Typography
              translate
              type="caption"
              color={autoRefill ? "onSurfaceDisabled" : "error"}
            >
              {autoRefill ? "label_auto_refill_on" : "label_auto_refill_off"}
              {pendingSwap ? ", " : null}
              {pendingSwap ? "label_pending_swap" : null}
            </Typography>
          </div>
          <div>
            <Typography translate type="caption" color="onSurfaceDisabled">
              {convertToStringPercentage(valuePercentage)}
            </Typography>
          </div>
        </div>
        <div className={styles.InventoryBarContainer}>
          <Tooltip followCursor={true} title={currentTooltip}>
            <div className={styles.InventoryBarGreyOverlay}></div>
          </Tooltip>

          <div className={styles.InventoryBarBorder}></div>

          {lowLevelPercentage !== 0 && lowLevelPercentage < 1 ? (
            <div
              className={styles.LevelBar}
              style={
                {
                  "--levelPercentage": lowLevelPercentage,
                } as LevelBarCSSProperties
              }
            ></div>
          ) : null}
          {highLevelPercentage !== 0 && highLevelPercentage < 1 ? (
            <div
              className={styles.LevelBar}
              style={
                {
                  "--levelPercentage": highLevelPercentage,
                } as LevelBarCSSProperties
              }
            ></div>
          ) : null}

          {currentLevel !== 0 ? (
            <Tooltip followCursor={true} title={currentTooltip}>
              <div className={styles.CurrentValueOverflowHiddenContainer}>
                <div
                  className={styles.CurrentValueBar}
                  style={Object.assign(
                    {} as ValueBarCSSProperties,
                    valuePercentage < 1
                      ? {
                          borderTopLeftRadius: "9999px",
                          borderBottomLeftRadius: "9999px",
                          "--valuePercentage": valuePercentage,
                          "--offsetNum": 1,
                        }
                      : {
                          borderRadius: "9999px",
                          "--valuePercentage": valuePercentage,
                          "--offsetNum": 2,
                        },
                    { backgroundColor: "rgba(39, 182, 186, 0.6)" }
                  )}
                ></div>
              </div>
            </Tooltip>
          ) : null}
          {currentValueOverflow ? (
            <Tooltip followCursor={true} title={currentTooltip}>
              <div className={styles.OverflowIndicatorCurrentLevel}></div>
            </Tooltip>
          ) : null}
        </div>
      </div>
    </div>
  );
}
