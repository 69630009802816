import * as React from "react";
import Typography from "common/components/typography/Typography";
import { CellProps } from "react-table";
import { CellAlignmentProps } from "../types";

import * as SCSScolors from "common/styles/colors.scss";
import * as styles from "./StringFillBackgroundCell.module.scss";

interface StringFillBackgroundCellProps extends CellAlignmentProps {
  fillFullCell?: boolean;
}

export interface StringFillBackgroundCellValues {
  translationKey?: string;
  textColor?: string | undefined;
  backgroundColor?: string | undefined;
}

export default function StringFillBackgroundCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
  fillFullCell = false,
}: StringFillBackgroundCellProps = {}) {
  return function Component({
    value,
  }: CellProps<D, StringFillBackgroundCellValues>): JSX.Element {
    const translationKey = value.translationKey;
    const textColor = value.textColor;
    const backgroundColor = value.backgroundColor;

    return (
      <div
        className={`${styles.StringFillBackgroundCell} ${
          fillFullCell ? styles.FillFullCell : ""
        }`}
        style={{
          justifyContent: xAlign,
          alignItems: yAlign,
          backgroundColor: SCSScolors[backgroundColor],
          color: SCSScolors[textColor],
        }}
      >
        <Typography
          className={styles.Typography}
          type="body-2"
          translationKey={translationKey}
        />
      </div>
    );
  };
}
