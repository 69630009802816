// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--2KDKkHKbZJ{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--2KDKkHKbZJ .ButtonsContainer--13MKcBlU_a{width:100%;display:flex;gap:25px}.TableHeader--2KDKkHKbZJ .ButtonsContainer--13MKcBlU_a>*:last-child{margin-left:auto}@media screen and (max-width: 750px){.TableHeader--2KDKkHKbZJ .ButtonsContainer--13MKcBlU_a{flex-direction:column}.TableHeader--2KDKkHKbZJ .ButtonsContainer--13MKcBlU_a>*:last-child{margin-left:revert}}.TableContainer--2Dc4ioKi_M{overflow:auto}.TableContainer--2Dc4ioKi_M>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--2KDKkHKbZJ",
	"ButtonsContainer": "ButtonsContainer--13MKcBlU_a",
	"TableContainer": "TableContainer--2Dc4ioKi_M"
};
module.exports = exports;
