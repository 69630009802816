import * as React from "react";
import * as styles from "./IndexTransaction.module.scss";

import { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { getDefaultQueryParamsForGroupBy } from "transactions/utils/getDefaultQueryParamsForGroupBy";
import { UNIXTimeStampTodayInMS } from "common/utils/momentUtils";
import { toast } from "react-toastify";
import i18n from "common/i18n";

// component
import {
  StringCell,
  DateCell,
  InlineStatusCell,
  CapitalCell,
  CurrencyCell,
  ErrorCell,
} from "common/components/table/cells/Cells";
import { LinkCell } from "common/components/table/cells/LinkCell/LinkCell";

import Table, { IColumn } from "common/components/table/Table";
import PureDateRangePicker from "common/components/daterangepicker/PureDateRangePicker";
import Select from "common/components/select/Select";
import Typography from "common/components/typography/Typography";
import Icon from "common/components/icon/Icon";

// query
import { useInfiniteQuery, InfiniteData, useQueryClient } from "react-query";

import {
  getTransactionSales,
  IGroupByNone,
  IGroupSalesResult,
  ISalesQuery,
} from "services/transactions/getTransactions";
import { AxiosError } from "axios";

// transaction import
import { getAllTransactionsServerTimestampCSV } from "machines/service";
import { formatMomentDateFullYear } from "common/utils/format";
import * as moment from "moment";
import TextButton from "common/components/textbutton/TextButton";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import {
  useIsRouteman,
  useIsSuperViewer,
  useIsTechnician,
  useIsViewer,
  useIsAdmin,
  useIsOperator,
  useIsAdminContract,
} from "utils/user-role";
import {
  PaginatorNavigationContainer,
  PaginatorDoubleLeftChevron,
  PaginatorLeftChevron,
  PaginatorRightChevron,
  PaginatorPage,
} from "containers/new-operations/archive/tabs/Complete/Paginator";
import { DownloadTransactionReportModal } from "./DownloadTransactionReportModal";

const IndexTransaction = () => {
  const cannotViewContent =
    useIsViewer() || useIsTechnician() || useIsRouteman();
  const canDownload =
    useIsAdmin() ||
    useIsAdminContract() ||
    useIsOperator() ||
    useIsSuperViewer();

  if (cannotViewContent) {
    return <NoPermissionComponent />;
  }
  const itemsPerPage = 20;
  const storeData = 100;
  const [dateTo, setDateTo] = useState(
    UNIXTimeStampTodayInMS({ timeOfDay: "dayEnd" })
  );
  const [dateFrom, setDateFrom] = useState(
    UNIXTimeStampTodayInMS({ timeOfDay: "dayStart" })
  );
  const fetcher = (
    query: ISalesQuery = {
      groupBy: "none",
      from: `${dateFrom}`,
      to: `${dateTo}`,
      limit: String(storeData),
    }
  ) => getTransactionSales(query);

  const {
    data: allData,
    fetchNextPage: fetchNextFivePage,
    isFetching,
  } = useInfiniteQuery<IGroupSalesResult, AxiosError>(
    [
      "InfiniteTransactionSales",
      { dateFrom: String(dateFrom), dateTo: String(dateTo) },
    ],
    ({ pageParam }: { pageParam?: IGroupSalesResult["meta"] }) =>
      pageParam
        ? fetcher({
            groupBy: "none",
            from: `${dateFrom}`,
            to: `${dateTo}`,
            limit: String(storeData),
            action: "forward",
            offsetTimestamp: String(pageParam.pointer.nextTimestamp),
            transId: String(pageParam.pointer.nextTransId),
          })
        : fetcher(),
    {
      getNextPageParam: (lastPage) => lastPage.meta,
      refetchOnWindowFocus: false,
      onError: () => {
        toast.error(i18n.t("toast_request_failed"));
      },
    }
  );

  const history = useHistory();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const totalItemsCount = allData ? calcAllItems(allData) : 0;

  const pageAvaiable: number = calcAllPages(totalItemsCount, itemsPerPage);

  const pageShown: number[] = calcPageShown(currentPage, pageAvaiable);

  const pageData = calcPageDataAndFetchData(
    allData as InfiniteData<IGroupSalesResult>,
    currentPage,
    pageAvaiable
  );
  function calcPageDataAndFetchData(
    allData: InfiniteData<IGroupSalesResult>,
    currentPage: number,
    pageAvaiable: number
  ): IGroupByNone[] {
    if (pageAvaiable === 0) {
      return [];
    }
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = currentPage * itemsPerPage;
    const perInfiniteQueryPageData = storeData;
    const startPage = Math.floor(endIndex / perInfiniteQueryPageData);

    // check if the last chunk (of 100) if it is dont fetch
    const latestChunk = allData.pages[allData.pages.length - 1].results.length;
    if (latestChunk < 100) {
      if (endIndex % storeData === 0) {
        const result = allData.pages[startPage - 1].results.slice(
          startIndex - perInfiniteQueryPageData * startPage
        );
        return result;
      }
      const result = allData.pages[startPage].results.slice(
        startIndex - perInfiniteQueryPageData * startPage,
        endIndex - perInfiniteQueryPageData * startPage
      );

      return result;
    }

    // if not the last chunk see if page avaiable and fetch new one
    const isPageAvaiable = currentPage + 1 < pageAvaiable;

    if (isPageAvaiable) {
      if (endIndex % storeData === 0) {
        const result = allData.pages[startPage - 1].results.slice(
          startIndex - perInfiniteQueryPageData * startPage
        );
        return result;
      }

      const result = allData.pages[startPage].results.slice(
        startIndex - perInfiniteQueryPageData * startPage,
        endIndex - perInfiniteQueryPageData * startPage
      );

      return result;
    }

    if (!isFetching) fetchNextFivePage();
    return null;
  }

  function calcPageShown(currentPage: number, totalPage: number): number[] {
    let pageCenter = currentPage;
    if (currentPage <= 2) {
      pageCenter = 3;
    } else if (totalPage - currentPage < 2) {
      pageCenter = totalPage - 2;
    }
    return [
      pageCenter - 2,
      pageCenter - 1,
      pageCenter,
      pageCenter + 1,
      pageCenter + 2,
    ].filter((page) => page >= 1 && page <= totalPage);
  }

  function calcAllItems(data: InfiniteData<IGroupSalesResult>) {
    let items = 0;
    data.pages.forEach((dataSet) => {
      items = items + dataSet.results.length;
    });
    return items;
  }
  function calcAllPages(dataCount: number, itemsPerPage: number): number {
    const totalPageAvaiable = Math.ceil(
      Number(dataCount) / Number(itemsPerPage)
    );

    return totalPageAvaiable;
  }

  function onDatePickerBlur({ startDate, endDate }) {
    if (startDate >= endDate) {
      return;
    }
    setDateFrom(startDate);
    setDateTo(endDate);
    setCurrentPage(1);
  }

  function onGroupBySelect(selectedGroupBy) {
    if (selectedGroupBy === "allTransactionNone") {
      return;
    }

    const newURLSearchParams = new URLSearchParams(
      getDefaultQueryParamsForGroupBy(selectedGroupBy)
    ).toString();

    history.push(`transaction?${newURLSearchParams}`);
  }

  const queryClient = useQueryClient();

  function onRefresh() {
    setCurrentPage(1);
    queryClient.resetQueries([
      "InfiniteTransactionSales",
      { dateFrom: String(dateFrom), dateTo: String(dateTo) },
    ]);
  }

  /// Download CSV from API
  const CSVDownloading = useRef(false);

  async function handleDownloadAccountingReport() {
    // If downloading, do not attempt to download again
    if (CSVDownloading.current === true) {
      return;
    }
    CSVDownloading.current = true;
    const queryObjectNone = {
      groupBy: "none",
      orderBy: "time",
      sort: "DESC",
      page: "1",
      from: dateFrom,
      to: dateTo,
    };

    let rawCSV: string;
    const loadingToast = toast.loading(i18n.t("label_loading"));
    try {
      rawCSV = await getAllTransactionsServerTimestampCSV(queryObjectNone);
    } catch (responseError) {
      toast.dismiss(loadingToast);
      return;
    }

    const CSVFile = new File(["\ufeff" + rawCSV], "accounting-report.csv", {
      type: "text/csv",
    });

    // Create link to file and simulate click
    const anchorElement = document.createElement("a");
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = `accounting-report-${formatMomentDateFullYear(
      moment(Number(queryObjectNone.from))
    )}-${formatMomentDateFullYear(moment(Number(queryObjectNone.to)))}.csv`;
    anchorElement.click();

    URL.revokeObjectURL(anchorElement.href);

    CSVDownloading.current = false;
    toast.dismiss(loadingToast);
  }
  return (
    <div className={styles.Transaction}>
      <div className={styles.header}>
        <div className={styles.left}>
          <div className={styles.title}>
            <Typography
              type="headline-5"
              translationKey="workspace_transactions"
            />
            <div className={styles.refresh} onClick={() => onRefresh()}>
              <Icon name="Refresh" color="primary" />
            </div>
            {canDownload && (
              <>
                <DownloadTransactionReportModal />
                <TextButton
                  translationKey="label_download_accounting_report"
                  onClick={handleDownloadAccountingReport}
                  icon="Download"
                  className={styles.action}
                />
              </>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <Select
            className={styles.groupby}
            label="label_group_by"
            value="allTransactionNone"
            options={[
              { label: "label_none", value: "allTransactionNone" },
              { label: "label_drink_name", value: "productName" },
              { label: "label_machine", value: "machineId" },
              {
                label: "label_daily_machine_sales",
                value: "dailyMachineSales",
              },
              {
                label: "label_weekly_machine_sales",
                value: "weeklyMachineSales",
              },
              { label: "label_user", value: "telephoneNumber" },
              { label: "label_status", value: "status" },
              { label: "label_code", value: "codeId" },
              { label: "label_code_type", value: "codeType" },
              { label: "label_location", value: "locationId" },
            ]}
            onSelect={onGroupBySelect}
          />
          <PureDateRangePicker
            defaultStartDate={Number(dateFrom)}
            defaultEndDate={Number(dateTo)}
            className={styles.action}
            onBlur={onDatePickerBlur}
          />
        </div>
      </div>

      <div className={styles.TableContainer}>
        <Table
          loading={isFetching}
          className={styles.table}
          data={isFetching ? [] : pageData}
          columns={tableColumnsConfigGroupByNone}
        />
      </div>
      <div className={styles.Footer}>
        <div className={styles.Paginator}>
          <PaginatorNavigationContainer>
            <PaginatorDoubleLeftChevron onClick={() => setCurrentPage(1)} />
            <PaginatorLeftChevron
              onClick={() =>
                currentPage === 1
                  ? undefined
                  : setCurrentPage((current) => current - 1)
              }
            />
            {pageShown.map((eachPage) => {
              return (
                <PaginatorPage
                  key={eachPage}
                  pageNumber={eachPage}
                  active={eachPage === currentPage}
                  onClick={() => setCurrentPage(eachPage)}
                />
              );
            })}
            {pageShown[pageShown.length - 1] === currentPage ? null : (
              <PaginatorRightChevron
                onClick={() => setCurrentPage((current) => current + 1)}
              />
            )}
          </PaginatorNavigationContainer>
        </div>
      </div>
    </div>
  );
};

export default IndexTransaction;

// TABLE LOGIC
const tableColumnsConfigGroupByNone: IColumn[] = [
  {
    dataKey: "machineId",
    headerLabel: "label_machine_id",
    cellRenderer: (machineId) => LinkCell({ to: `/machines/detail/${machineId}/transaction/group-by-none`, translationKey: machineId as string}),
    align: "flex-start",
    columnWidth: 85,
    minWidth: 85,
    showMobile: true,
  },
  {
    dataKey: "machineName",
    headerLabel: "label_machine_name",
    cellRenderer: StringCell,
    align: "flex-start",
    columnFlex: 1,
    columnWidth: 200,
    minWidth: 200,
    showMobile: true,
  },
  {
    dataKey: "time",
    headerLabel: "label_time",
    cellRenderer: DateCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  },
  {
    dataKey: "productCode",
    headerLabel: "label_drink_code",
    cellRenderer: StringCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 110,
    minWidth: 110,
  },
  {
    dataKey: "productName",
    headerLabel: "label_drink_name",
    cellRenderer: StringCell,
    align: "flex-start",
    columnFlex: 1,
    showMobile: true,
    columnWidth: 120,
    minWidth: 120,
  },
  {
    headerLabel: "label_status",
    cellRenderer: InlineStatusCell({
      idKey: "status",
    }),
    align: "flex-start",
    showMobile: true,
    columnWidth: 74,
    minWidth: 74,
  },
  {
    dataKey: "errorcode",
    headerLabel: "label_error_code",
    cellRenderer: ErrorCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 74,
    minWidth: 74,
  },
  {
    dataKey: "price",
    headerLabel: "label_paid",
    cellRenderer: CurrencyCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 65,
    minWidth: 65,
  },
  {
    dataKey: "promotionDiscount",
    headerLabel: "label_promotion",
    cellRenderer: CurrencyCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 101,
    minWidth: 101,
  },
  {
    dataKey: "paymentType",
    headerLabel: "label_payment",
    cellRenderer: CapitalCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  },
  {
    dataKey: "telephoneNumber",
    headerLabel: "label_user",
    cellRenderer: StringCell,
    align: "flex-start",
    showMobile: true,
    columnWidth: 90,
    minWidth: 90,
  },
  {
    dataKey: "locationType",
    headerLabel: "label_location_type",
    cellRenderer: StringCell,
    align: "flex-start",
    columnFlex: 1,
    columnWidth: 90,
    minWidth: 90,
    showMobile: true,
  },
];
