import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Switch, Redirect, useLocation } from 'react-router-dom';

import { removeToast } from 'common/store/actions/uiActions';
import { selectToasts } from 'common/store/selectors/uiSelectors';

import Route from 'common/components/route/Route';
import Toast, { IToast } from 'common/components/toast/Toast';

import Splash from 'containers/splash/Splash';
import Login from 'containers/login/Login';
import SignUp from 'containers/signup/Signup';
import Portal from 'containers/portal/Portal';

import { refreshToken } from 'userandauth/utilsAuth';
import { useAuthStore } from 'userandauth/useAuthStore';

import 'pdf/constants';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import * as styles from './App.module.scss';
import './reset.css';
import { ErrorBoundary } from '@sentry/react';
import { Fallback } from 'components/Fallback';

function App(): JSX.Element {
  const dispatch = useDispatch();
  const toasts: IToast[] = useSelector(selectToasts);

  const [appInitialized, setAppInitialized] = useState<boolean>(false);

  async function refreshTokenOnStartUp() {
    await refreshToken();
    setAppInitialized(true);
  }

  useEffect(() => {
    refreshTokenOnStartUp();
  }, []);

  function handleRemoveToast(id: string): void {
    dispatch(removeToast(id));
  }

  if (!appInitialized) {
    return <Splash />;
  }

  return (
    <ErrorBoundary fallback={FallBackWrapper}>
      <div className={styles.App}>
        <React.Suspense fallback={<Splash />}>
          {toasts?.map((toast, index) => (
            <Toast
              key={index}
              text={toast.text}
              raw={toast.raw}
              open={!!toasts?.length}
              type={toast.type}
              position={index}
              onClose={() => handleRemoveToast(toast.id)}
            />
          ))}
          <BrowserRouter>
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={SignUp} />
              <PrivateRoute>
                <Route path="/" component={Portal} />
              </PrivateRoute>
            </Switch>
          </BrowserRouter>
        </React.Suspense>
      </div>
      <ToastContainer
        className={styles.ToastContainer}
        position="top-center"
        theme="colored"
        pauseOnFocusLoss={false}
      />
    </ErrorBoundary>
  );
}

export default App;

interface IPrivateRoute {
  children: JSX.Element;
}

function PrivateRoute({ children }: IPrivateRoute): JSX.Element {
  const loggedIn: boolean = useAuthStore((state) => state.isLoggedIn);
  const location = useLocation();
  if (!loggedIn) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />;
  }

  return <>{children}</>;
}

export function FallBackWrapper({ error }: { error: Error }) {
  return (
    <div
      style={{
        position: 'fixed',
        width: '100%',
        height: '100%',
      }}
    >
      <Fallback error={error} />
    </div>
  );
}
