import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';
import { PieChart } from '../atoms/charts/PieChart';

import * as styles from './PieChartCard.module.scss';
import { PieChartKey } from '../molecules/PieChartKey';
export interface PieChartCardProps {
  title: string;
  icon: string;
  className?: string;
  data: PieChartSegment[];
}

interface PieChartSegment {
  label: string;
  color: string;
  amount: number;
}

function PieChartCard ({
  title,
  icon,
  data,
  className = ''
}: PieChartCardProps): JSX.Element {
  return (
    <Card className={[ styles.PieChartCard, className ].join(' ')}>
      <CardTitle icon={icon} title={title} />
      <div className={styles.ChartContainer}>
        <PieChart data={data} />
        <div className={styles.Legend}>
          {data.map(segment => (
            <PieChartKey
              key={segment.label}
              value={(
                (segment.amount * 100) /
                  data.reduce((acc, segment) => acc + segment.amount, 0)
              ).toFixed(1)}
              label={segment.label}
              color={segment.color}
            />
          ))}
        </div>
      </div>
    </Card>
  );
}

export { PieChartCard };
