import { IAction } from 'common/interfaces';

import { IToast } from 'common/components/toast/Toast';

interface IUiState {
  toasts: IToast[]
}

const initialState: IUiState = {
  toasts: []
};

function uiReducer (
  state: IUiState = initialState,
  action: IAction
): IUiState {
  switch (action.type) {
    case 'UI/TOAST/ADD':
      return {
        ...state,
        toasts: [
          ...state.toasts,
          action.payload
        ]
      };
    case 'UI/TOAST/REMOVE':
      return {
        ...state,
        toasts: state.toasts.filter(i => i.id !== action.payload) || []
      };
    default:
      return state;
  }
}

export default uiReducer;
