import * as React from "react";
import { HeaderProps } from "react-table";
import Typography from "common/components/typography/Typography";
import Icon from "common/components/icon/Icon";

import * as styles from "./HeaderCell.module.scss";
import analytics from "utils/analytics";

interface HeaderCellProps {
  label: string;
}

export default function HeaderCell<D extends object>({
  label,
}: HeaderCellProps) {
  return function Component({ column }: HeaderProps<D>): JSX.Element {
    return (
      <div
        className={styles.HeaderCell}
        onClick={() => {
          analytics.track({
            name: "sorting applied",
            properties: {
              column_name: label,
              can_sort: column?.canSort,
              ascend_or_descend: column?.isSorted ? (column?.isSortedDesc ? "ASC" : "NONE"): "ASC" // Determine whether sorting is ascending, descending, or none based on the current state of the column but will use next state as a message for tracking
            }
          });
        }}
      >
        <Typography
          type="body-2"
          translationKey={label}
          className={`${styles.HeaderLabel} ${
            column?.isSorted ? styles.Sorted : ""
          }`}
        />
        <SortChevron
          canSort={column?.canSort}
          isSorted={column?.isSorted}
          isSortedDesc={column?.isSortedDesc}
        />
      </div>
    );
  };
}

interface SortChevronProps {
  canSort: boolean;
  isSorted: boolean;
  isSortedDesc: boolean;
}

function SortChevron({
  canSort,
  isSorted,
  isSortedDesc,
}: SortChevronProps): JSX.Element {
  return (
    <>
      {canSort ? (
        isSorted ? (
          isSortedDesc ? (
            <Icon
              name="Chevron"
              color="onSurfaceHigh"
              className={`${styles.Chevron} ${styles.Up}`}
            />
          ) : (
            <Icon
              name="Chevron"
              color="onSurfaceHigh"
              className={`${styles.Chevron} ${styles.Down}`}
            />
          )
        ) : (
          <Icon name="SortChevrons" color="onSurfaceDisabled" />
        )
      ) : (
        ""
      )}
    </>
  );
}
