import * as React from "react";
import {
  Route,
  Switch,
  Redirect,
  useRouteMatch,
  useHistory,
  useLocation,
} from "react-router-dom";

import * as styles from "./Monitor.module.scss";

import Select from "components/Select";
import Overview from "./pages/overview/Overview";
import SpecificError from "./pages/specificerror/SpecificError";
import Snapshot from "./pages/snapshot/Snapshot";
import AllError from "./pages/allerror/AllError";
import NoPermissionComponent from "containers/noPermissionPage/NoPermission";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
  useIsRouteman,
  useIsSuperViewer,
  useIsTechnician,
  useIsViewer,
} from "utils/user-role";
import Typography from "components/Typography/Typography";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "react-use";
import { useMachinesStore } from "containers/machinesV2/useMachinesStore";
import analytics from "utils/analytics";

interface MontiorProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<string[]>>;
  selectedMachine: Array<string>;
}

export function Monitor({
  setSelectedMachine,
  selectedMachine,
}: MontiorProps): JSX.Element {
  const { setEnable } = useMachinesStore();
  const [, setDisableMachineOverviewV2] = useLocalStorage(
    "disable-machine-overview-v2",
    false
  );
  const { t } = useTranslation("machine");
  const canViewContent =
    useIsAdmin() ||
    useIsAdminContract() ||
    useIsOperator() ||
    useIsSuperViewer() ||
    useIsRouteman() ||
    useIsTechnician() ||
    useIsClerk() ||
    useIsViewer();
  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  const history = useHistory();
  const location = useLocation();
  const { path } = useRouteMatch();

  const errorSelectedFromURL = getErrorNameFromURL(location.pathname);

  function getErrorNameFromURL(pathname: string): IErrorSelectedType {
    if (pathname.includes("error")) {
      return pathname.replace("/machines/monitor/error/", "");
    } else if (pathname.includes("all")) {
      return "all";
    } else {
      return pathname.slice(-8);
    }
  }

  function onChangeErrorType(type: IErrorSelectedType) {
    if (type === "overview") {
      history.push(`${path}/overview`);
    } else if (type === "snapshot") {
      history.push(`${path}/snapshot`);
    } else if (type === "all") {
      history.push(`${path}/all`);
    } else {
      history.push(`${path}/error/${type}`);
    }
  }

  const options = [
    {
      label: "label_overview",
      value: "overview",
    },
    {
      label: "label_snapshot",
      value: "snapshot",
    },
    {
      label: "label_all",
      value: "all",
    },
  ];

  const addedOptions: IOption[] = options.concat(
    errorTypeArray.map((each) => {
      return { label: each, value: each };
    })
  );

  const isViewingOverview = useRouteMatch(`${path}/overview`);

  return (
    <>
      <div className={styles.selectContainer}>
        {isViewingOverview ? (
          <div>
            <Typography type="body-2" translate>
              {selectedMachine.length}
              {` `}
              {selectedMachine.length === 1
                ? "label_machine"
                : "label_machines"}
              {` `}
              label_selected
            </Typography>
          </div>
        ) : null}
        <div className="flex items-center gap-2">
          <Select
            className={styles.select}
            value={errorSelectedFromURL}
            onChange={(e) =>
              onChangeErrorType(e.target.value as IErrorSelectedType)
            }
            id="error"
            label="label_error"
            options={addedOptions}
            required
          />
          {location.pathname === "/machines/monitor/overview" && (
            <div className="flex items-center gap-2">
              <p className="text-navigation-variant font-kanit gap-2">
                {t("try_new_experience")}
              </p>
              <ToggleSwitch
                isOn={false}
                onClick={() => {
                  setEnable(true);
                  history.replace("/machines-v2/monitor/overview");
                  setDisableMachineOverviewV2(false);
                  analytics.track({
                    name: "click try new experience",
                    properties: { location: "header", enable: true },
                  });
                }}
              />
            </div>
          )}
        </div>
      </div>
      <Switch>
        <Route exact path={`${path}/overview`}>
          <div className={styles.Container}>
            <Overview setSelectedMachine={setSelectedMachine} />
          </div>
        </Route>
        <Route exact path={`${path}/snapshot`}>
          <Snapshot setSelectedMachine={setSelectedMachine} />
        </Route>
        <Route exact path={`${path}/all`}>
          <div className={styles.Container}>
            <AllError setSelectedMachine={setSelectedMachine} />
          </div>
        </Route>
        <Route exact path={`${path}/error/:errortype`}>
          <div className={styles.Container}>
            <SpecificError setSelectedMachine={setSelectedMachine} />
          </div>
        </Route>
        <Redirect to={`${path}/overview`} />
      </Switch>
    </>
  );
}

type IErrorSelectedType = "overview" | "snapshot" | IErrorTypeFromAPI | "all";

export const errorTypeArray = [];

export type IErrorTypeFromAPI = (typeof errorTypeArray)[number];

interface IOption {
  label: string;
  value: string;
}
