/* eslint-disable react/display-name */
import * as React from "react";

import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import TextButton from "common/components/textbutton/TextButton";
import CreatePlanModal from "./CreatePlanModal/CreatePlanModal";
import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";

import {
  GetRefillOrderCandidatesQuery,
  MachineKind,
  useBatchDeleteRefillOrderCandidateMutation,
  useGetRefillOrderCandidatesQuery,
} from "gql/generated";
import { useQueryClient } from "react-query";

import {
  PriorityCell,
  Container,
  Header,
  isValidRefillPriority,
  OperationTable,
  RefillPriorityString,
  refillPriorityValueMap,
  TextButtonsContainer,
} from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";

import { useIdSelection } from "hooks/useIdSelection";
import { useSortState } from "hooks/useSortState";
import { timestampFormatter } from "../utils";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { toasti18n } from "utils/toast";
import { formatCurrency } from "utils/currency";
import { IconButton } from "@mui/material";
import * as styles from "./Plan.module.scss";
import { RemoveCandidateConfirmationModal } from "./RemoveCandidateConfirmationModal";
import { useTranslation } from "react-i18next";

function Plan() {
  const { t } = useTranslation();
  const { data: refillCandidatesQuery, isLoading } =
    useGetRefillOrderCandidatesQuery();
  const refillCandidates = React.useMemo(
    () => (isLoading ? undefined : mapDataToTable(refillCandidatesQuery)),
    [refillCandidatesQuery]
  );

  function findCandidateById(candidateId: string) {
    return refillCandidates.find(
      (candidate) => candidate.candidateId === candidateId
    );
  }
  function findCandidatesInSameRoute(candidateId: string) {
    const candidateRefillZoneId = findCandidateById(candidateId).refillZoneId;
    const candidatesInSameZone = refillCandidates.filter(
      (candidate) => candidate.refillZoneId === candidateRefillZoneId
    );
    return candidatesInSameZone;
  }

  function handleRemoveSingleCandidateButtonClick(candidateId: string): void {
    setCandidateToRemove(candidateId);
  }

  const allCandidateIds = React.useMemo(
    () => refillCandidates?.map((candidate) => candidate.candidateId) ?? [],
    [refillCandidates]
  );
  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredCandidates = React.useMemo(
    () =>
      appliedFilter
        ? refillCandidates?.filter((candidate) => {
            let value: string;
            switch (appliedFilter.field) {
              case columns.locationName:
                value = candidate.machine.location.locationName;
                break;
              case columns.machineId:
                value = candidate.machineId;
                break;
              case columns.routeName:
                value = candidate.refillZone.friendlyId;
                break;
              case columns.warehouseName:
                value = candidate.machine.warehouseName;
            }
            return value
              .toLowerCase()
              .includes(appliedFilter.value.toLowerCase());
          })
        : refillCandidates,
    [refillCandidates, appliedFilter]
  );
  const filteredCandidateIds = React.useMemo(
    () => filteredCandidates?.map((candidate) => candidate.candidateId),
    [filteredCandidates]
  );

  const disabledCandidateIds = React.useMemo(
    () =>
      refillCandidates
        ?.filter((candidate) => !candidate.machine.enableWorkOrderCreation)
        .map((candidate) => candidate.candidateId),
    [refillCandidates]
  );

  const selectableIds = React.useMemo(
    () =>
      filteredCandidateIds?.filter(
        (filteredCandidateId) =>
          !disabledCandidateIds.includes(filteredCandidateId)
      ),
    [filteredCandidateIds]
  );

  const {
    selectionStatus,
    isIdSelected,
    selectedIds,
    toggleId,
    clearSelection,
    selectIds,
    deselectIds,
    clearSelectionInView,
    selectAllInView,
  } = useIdSelection(allCandidateIds, selectableIds);
  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({ columnId: columns.routeName, direction: "ASC" });

  const sortedCandidates = React.useMemo(
    () =>
      sortState
        ? filteredCandidates
            ?.map((elem) => elem)
            ?.sort((planA, planB) => {
              return (
                sortFnMap[sortState.columnId](planA, planB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredCandidates,
    [filteredCandidates, sortState]
  );

  const selectedCandidates = React.useMemo(
    () =>
      selectedIds.map((id) => findCandidateById(id)).sort(sortRefillCandidates),
    [selectedIds]
  );

  const [candidateToRemove, setCandidateToRemove] =
    React.useState<string>(null);
  const [isCreatePlanModalOpen, setIsCreatePlanModalOpen] =
    React.useState<boolean>(false);
  const queryClient = useQueryClient();

  const { mutate: removeCandidates, isLoading: isRemovingCandidates } =
    useBatchDeleteRefillOrderCandidateMutation({
      onError: (err: Error) => {
        toasti18n.error(err);
      },
      onSuccess: () => {
        toasti18n.success("toast_remove_candidate_machine_successful");
        clearSelection();
        queryClient.invalidateQueries(["GetOperationStatusCounts"]);
        queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
      },
    });

  function handleCheckboxClick(candidateId: string) {
    const selectedMachineData = filteredCandidates.find(row => row.candidateId === candidateId);
    if(!selectedMachineData) return;
    const isCandidateIdSelected = isIdSelected(candidateId);
    toggleId(candidateId);

    // not help user toggle related candidates when unselect
    if(isCandidateIdSelected) return;

    if(selectedMachineData.kind === MachineKind.BeverageVending) {
      // get children machine with uncheck state
      const childrenMachine = filteredCandidates.filter(row => row.parentId === selectedMachineData.machineId && !isIdSelected(row.candidateId));
      childrenMachine?.forEach(machine => toggleId(machine.candidateId));
    }

    if(selectedMachineData.kind === MachineKind.SpiralVending) {
      const parentMachine = filteredCandidates.find(row => row.machineId === selectedMachineData.parentId);
      // we not toggle on uncheck
      !isIdSelected(parentMachine.candidateId) && toggleId(parentMachine.candidateId);
    }
  }

  return (
    <Container>
      {candidateToRemove && (
        <RemoveCandidateConfirmationModal
          candidateIdToRemove={candidateToRemove}
          onRemoved={() => setCandidateToRemove(null)}
          onClose={() => setCandidateToRemove(null)}
        />
      )}

      {isCreatePlanModalOpen && (
        <CreatePlanModal
          onClose={() => setIsCreatePlanModalOpen(false)}
          onCreatePlanSuccess={() => {
            setIsCreatePlanModalOpen(false);
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
            queryClient.invalidateQueries(["GetCreatedPlans"]);
            clearSelection();
          }}
          selectedRefillCandidates={selectedCandidates.map((candidate) => ({
            candidateId: candidate.candidateId,
            lat: convertToNumber(
              candidate.machine.location.coordinates.latitude,
              null
            ),
            lng: convertToNumber(
              candidate.machine.location.coordinates.longitude,
              null
            ),
            locationId: candidate.location.id,
            locationGrade: candidate.location.locationGrade,
            locationType: candidate.location.type,
            locationName: candidate.machine.location.locationName,
            machineId: candidate.machineId,
            kind: candidate.kind,
            parentId: candidate?.parentId,
            warehouseId: candidate.machine.warehouseId,
            warehouse: candidate.machine.warehouseName,
            lastRefilled: candidate.machine.lastRefilled,
            orderMetaData: {
              locationNote: candidate.note,
              criticalNote: candidate.criticalNote,
              critical: Boolean(candidate.critical),
              priority: isValidRefillPriority(candidate.machine.refillPriority)
                ? candidate.machine.refillPriority
                : null,
            },
            revenue: candidate.machine.revenue,
            cashValue: candidate.machine.cashValue,
            zone: candidate.refillZone.name,
          }))}
        />
      )}
      <Header>
        <TextButtonsContainer>
          <TextButton
            icon="Plus"
            translationKey="label_create_plan"
            onClick={() => setIsCreatePlanModalOpen(true)}
            disabled={selectedIds.length === 0 || isRemovingCandidates}
          />
          <TextButton
            icon="Trash"
            translationKey="label_remove_candidate"
            onClick={() =>
              removeCandidates({
                batchDeleteRefillOrderCandidateId: selectedCandidates.map(
                  (candidate) => candidate.candidateId
                ),
              })
            }
            disabled={selectedIds.length === 0 || isRemovingCandidates}
          />
        </TextButtonsContainer>

        <Typography type="body-2" translate>
          {selectedIds.length}
          {` `}
          {selectedIds.length === 1 ? "label_machine" : "label_machines"}
          {` `}
          label_selected
        </Typography>
      </Header>
      <PureFilterBar
        placeholder="label_search"
        filterOptions={[
          {
            field: columns.routeName,
            queryType: "contains",
            translationKey: "label_route",
          },
          {
            field: columns.machineId,
            queryType: "contains",
            translationKey: "label_machine_id",
          },
          {
            field: columns.locationName,
            queryType: "contains",
            translationKey: "label_location_name",
          },
          {
            field: columns.warehouseName,
            queryType: "contains",
            translationKey: "label_warehouse",
          },
        ]}
        onChange={setAppliedFilter}
        value={appliedFilter}
      />

      <OperationTable
        data={sortedCandidates}
        columns={[
          {
            cell: (candidate) => (
              <Checkbox
                disabled={!candidate.machine.enableWorkOrderCreation}
                checked={isIdSelected(candidate.candidateId)}
                onClick={() => {
                  handleCheckboxClick(candidate.candidateId);
                }}
                onDoubleClick={() => {
                  if (!isIdSelected(candidate.candidateId)) {
                    selectIds(
                      findCandidatesInSameRoute(candidate.candidateId)
                        .filter(
                          (candidate) =>
                            filteredCandidateIds.includes(
                              candidate.candidateId
                            ) && candidate.machine.enableWorkOrderCreation
                        )
                        .map((candidate) => candidate.candidateId)
                    );
                  } else {
                    deselectIds(
                      findCandidatesInSameRoute(candidate.candidateId)
                        .filter((candidate) =>
                          filteredCandidateIds.includes(candidate.candidateId)
                        )
                        .map((candidate) => candidate.candidateId)
                    );
                  }
                }}
              />
            ),
            headerCell: () => (
              <Checkbox
                onClick={() => {
                  if (selectionStatus === "ALL") {
                    clearSelectionInView();
                  } else {
                    selectAllInView();
                  }
                }}
                checked={selectionStatus === "ALL"}
                indeterminate={selectionStatus === "PARTIAL"}
              />
            ),
            id: columns.checkbox,
            style: {
              flex: "0 0 42px",
              minWidth: "42px",
              paddingRight: "var(--table-h-padding)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            },
          },
          {
            id: columns.routeName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_route")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceHigh">
                    {candidate.refillZone.friendlyId}
                  </Typography>
                </div>
                <div style={{ flexShrink: 0, marginRight: "22px" }}>
                  {candidate.critical ? <Icon name="PriorityMajor" /> : null}
                </div>
              </div>
            ),
            style: {
              flex: "0 0 65px",
              minWidth: "65px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.routePosition,
            headerCell: () => (
              <Typography
                type="body-2"
                translate
                color="brainStormingBlackTint300"
              >
                label_route_position
              </Typography>
            ),
            cell: (candidate) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {candidate.routePosition}
              </Typography>
            ),
            style: {
              flex: "0 0 70px",
              minWidth: "70px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.machineId,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_machine_id")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <>
                {candidate.kind === MachineKind.SpiralVending ? (
                  <div className="flex gap-2 items-center justify-between flex-1 pr-3">
                    <div>
                      <Typography
                        type="body-2"
                        translate
                        color="onSurfaceHigh"
                        className="leading-5"
                      >
                        {candidate.machineId}
                      </Typography>
                      <p className="font-kanit text-caption leading-4 text-on-surface-disabled">
                        {candidate?.parentId}
                      </p>
                    </div>
                    <Icon name="Snack" color="primary" />
                  </div>
                ) : (
                  <Typography type="body-2" color="onSurfaceHigh">
                    {candidate.machineId}
                  </Typography>
                )}
              </>
            ),
            style: {
              flex: "0 0 120px",
              minWidth: "120px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            },
          },
          {
            id: columns.priority,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_priority")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <PriorityCell
                priority={
                  isValidRefillPriority(candidate.machine.refillPriority)
                    ? candidate.machine.refillPriority
                    : null
                }
              />
            ),
            style: {
              flex: "0 0 105px",
              minWidth: "105px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.locationName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_location_name")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <>
                <Typography type="body-2" color="onSurfaceHigh">
                  {candidate.machine.location.locationName}
                </Typography>
                {!candidate.machine.enableWorkOrderCreation ? (
                  <div
                    style={{
                      margin: "auto",
                      marginRight: "8px",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      translate
                      type="emphasized"
                      color="onSurfaceHigh"
                    >
                      label_disabled_for_work_order_creation
                    </Typography>
                  </div>
                ) : null}
              </>
            ),
            style: {
              flex: "1 0 373px",
              minWidth: "373px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.warehouseName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_warehouse")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {candidate.machine.warehouseName}
              </Typography>
            ),
            style: {
              flex: "1 0 100px",
              minWidth: "100px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.lastRefill,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_last_refill")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {timestampFormatter(candidate.machine.lastRefilled)}
              </Typography>
            ),
            style: {
              flex: "0 0 92px",
              minWidth: "92px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.revenue,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_revenue")}
              </TableHeader>
            ),
            cell: (candidate) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {formatCurrency({
                  input: candidate.machine.revenue,
                  minimumFractionDigits: 2,
                })}
              </Typography>
            ),
            style: {
              flex: "0 0 96px",
              minWidth: "96px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.type,
            headerCell: () => (
              <Typography
                type="body-2"
                translate
                color="brainStormingBlackTint300"
              >
                label_type
              </Typography>
            ),
            cell: (candidate) => (
              <Typography type="body-2" color="onSurfaceHigh">
                Refill
                {candidate.swapItemIds.length > 0
                  ? ` & Swap (${candidate.swapItemIds.length})`
                  : null}
              </Typography>
            ),
            style: {
              flex: "0 0 68px",
              minWidth: "68px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.note,
            headerCell: () => (
              <Typography
                type="body-2"
                translate
                color="brainStormingBlackTint300"
              >
                label_note
              </Typography>
            ),
            cell: (candidate) => (
              <div>
                <div>
                  {candidate.criticalNote ? (
                    <Typography type="body-2" color="error">
                      {candidate.criticalNote}{" "}
                    </Typography>
                  ) : null}
                  {candidate.note ? (
                    <Typography type="body-2" color="onSurfaceHigh">
                      {(candidate.criticalNote ? ", " : "") +
                        (candidate.note ?? "")}
                    </Typography>
                  ) : null}
                </div>
                <div className={styles.ActionButtonContainer}>
                  <div className={styles.ActionButton}>
                    <IconButton
                      aria-label="remove candidate"
                      onClick={() =>
                        handleRemoveSingleCandidateButtonClick(
                          candidate.candidateId
                        )
                      }
                    >
                      <Icon name="Trash" color="primary" />
                    </IconButton>
                  </div>
                </div>
              </div>
            ),
            style: {
              flex: "0 0 194px",
              minWidth: "194px",
              paddingRight: "var(--table-padding)",
              display: "flex",
              alignItems: "center",
              position: "relative",
            },
          },
        ]}
        isLoading={isLoading}
        getKey={(candidate) => candidate.candidateId}
        isSelected={(candidate) => isIdSelected(candidate.candidateId)}
        tableDataRowClassName={styles.TableRow}
        tableDataRowSelectedClassName={styles.TableRowSelected}
      />
    </Container>
  );
}

export default Plan;

function sortRefillCandidates(
  refillCandidateA: TableRow,
  refillCandidateB: TableRow
) {
  const alphaNumericRouteComparison = alphanumeric(
    refillCandidateA.refillZone.friendlyId,
    refillCandidateB.refillZone.friendlyId
  );
  if (alphaNumericRouteComparison !== 0) return alphaNumericRouteComparison;
  return refillCandidateA.routePosition - refillCandidateB.routePosition;
}

function convertToNumber<T>(valueToConvert: any, fallbackValue: T): T | number {
  if (
    valueToConvert === undefined ||
    valueToConvert === null ||
    (typeof valueToConvert === "string" && valueToConvert.trim() === "")
  ) {
    return fallbackValue;
  }

  const number = Number(valueToConvert);
  return !isNaN(number) ? number : fallbackValue;
}

export interface TableRow {
  candidateId: string;
  critical: boolean;
  recommended: boolean;
  routePosition: number;
  criticalNote: string;
  refillZoneId: string;
  note: string;
  swapItemIds: Array<string>;
  machineId: string;
  parentId?: string;
  kind: MachineKind;
  location: {
    locationGrade: string;
    id: string;
    name: string;
    type: string;
  };
  refillZone: {
    friendlyId: string;
    id: string;
    name: string;
  };
  machine: {
    revenue: number;
    cashValue: number;
    refillPriority: RefillPriorityString | null;
    enableWorkOrderCreation: boolean;
    lastRefilled: string;
    warehouseId: string;
    warehouseName: string;
    location: {
      coordinates: {
        latitude: string;
        longitude: string;
      };
      locationName: string;
    };
  };
}

function mapDataToTable(
  refillCandidatesQuery: GetRefillOrderCandidatesQuery
): TableRow[] {
  const refillCandidates = refillCandidatesQuery.refillOrderCandidates;

  const formattedData =  refillCandidates
    .map((rc) => {
      const location = rc.machine.location;
      if (
        location.__typename === "Location" ||
        location.__typename === "Store"
      ) {
        const tableRowData: TableRow = {
          candidateId: rc.id,
          critical: rc.isCritical,
          criticalNote: rc.criticalNote,
          machineId: rc.machineId,
          parentId: rc.machine?.parentId,
          kind: rc.machine.kind,
          note: rc.note,
          location: {
            id: location.id,
            locationGrade: location.locationGrade,
            type: location.locationType,
            name: location.locationName,
          },
          machine: {
            warehouseId: location.warehouse.warehouseId,
            warehouseName: location.warehouse.name,
            location: {
              locationName: location.locationName,
              coordinates: {
                latitude: location.latitude,
                longitude: location.longitude,
              },
            },
            lastRefilled: rc.machine.lastRefilled,
            refillPriority: isValidRefillPriority(location.refillPriority)
              ? location.refillPriority
              : null,
            revenue: location.revenueSinceLastRefilled,
            enableWorkOrderCreation: rc.machine.enableWorkOrderCreation,
            cashValue: rc.machine.cashValue,
          },
          recommended: rc.isRecommended,
          swapItemIds: rc.swapOrders
            .map((swapOrder) =>
              swapOrder.swapItems.map((swapItem) => swapItem.id)
            )
            .flat(1),
          routePosition: location.positionInRoute,
          refillZoneId: location.refillZoneId,
          refillZone: {
            friendlyId: location.refillZone.friendlyId,
            id: location.refillZone.id,
            name: location.refillZone.name,
          },
        };
        return tableRowData;
      }

      return null;
    })
    .filter(Boolean);
  return formattedData;
}

const columns = {
  checkbox: "checkbox",
  routeName: "routeName",
  routePosition: "routePosition",
  machineId: "machineId",
  priority: "priority",
  locationName: "locationName",
  warehouseName: "warehouseName",
  lastRefill: "lastRefill",
  revenue: "revenue",
  type: "type",
  note: "note",
} as const;
type Columns = keyof typeof columns;

const sortFnMap: Record<
  Columns,
  (refillCandidateA: TableRow, refillCandidateB: TableRow) => number
> = {
  routeName: (refillcandidateA, refillCandidateB) => {
    const comparisonValue = alphanumeric(
      refillcandidateA.refillZone.friendlyId,
      refillCandidateB.refillZone.friendlyId
    );
    if (comparisonValue !== 0) return comparisonValue;
    return refillcandidateA.routePosition - refillCandidateB.routePosition;
  },
  machineId: (refillCandidateA, refillCandidateB) => {
    return refillCandidateA.machineId.localeCompare(refillCandidateB.machineId);
  },
  priority: (refillCandidateA, refillCandidateB) => {
    const comparisonValue =
      (refillPriorityValueMap[refillCandidateA.machine.refillPriority] ??
        -100) -
      (refillPriorityValueMap[refillCandidateB.machine.refillPriority] ?? -100);

    if (comparisonValue !== 0) return comparisonValue;
    return sortFnMap.routeName(refillCandidateA, refillCandidateB);
  },
  locationName: (refillCandidateA, refillCandidateB) => {
    return refillCandidateA.machine.location.locationName.localeCompare(
      refillCandidateB.machine.location.locationName
    );
  },
  warehouseName: (refillCandidateA, refillCandidateB) => {
    return refillCandidateA.machine.warehouseName.localeCompare(
      refillCandidateB.machine.warehouseName
    );
  },
  lastRefill: (refillCandidateA, refillCandidateB) => {
    if (!refillCandidateA.machine.lastRefilled) return 1;
    if (!refillCandidateB.machine.lastRefilled) return -1;
    return refillCandidateA.machine.lastRefilled.localeCompare(
      refillCandidateB.machine.lastRefilled
    );
  },
  revenue: (refillCandidateA, refillCandidateB) => {
    if (
      refillCandidateA.machine.revenue === null ||
      refillCandidateA.machine.revenue === undefined
    )
      return 1;
    if (
      refillCandidateB.machine.revenue === null ||
      refillCandidateB.machine.revenue === undefined
    )
      return 1;
    return refillCandidateA.machine.revenue - refillCandidateB.machine.revenue;
  },
  note: () => {
    throw new Error("Note column not sortable");
  },
  type: () => {
    throw new Error("Type column not sortable");
  },
  routePosition: () => {
    throw new Error("Route position column not sortable");
  },
  checkbox: () => {
    throw new Error("Check box column not sortable");
  },
};
