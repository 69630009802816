import * as React from 'react';
import { useState } from 'react';

import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import Button from 'common/components/button/Button';
import Icon from 'common/components/icon/Icon';

import { usePatchUserDetails } from 'userandauth/hooks/usePatchUserDetails';

import { useDispatch } from 'react-redux';
import { addToast } from 'common/store/actions/uiActions';

import * as styles from './UploadImageModal.module.scss';

function UploadImageModal({
  onClose,
  isOpen = true,
  userId,
  uploadAvatarFor,
}: IUploadImageModalProps): JSX.Element {
  const dispatch = useDispatch();
  const [uploadImages, setUploadImages] = useState(null);
  const { mutate: patchUser } = usePatchUserDetails();

  const onUpload = () => {
    if (uploadAvatarFor === 'organisation') {
      patchUser({
        userId: userId,
        newUserData: {
          organisationAvatar: uploadImages,
        },
      });
    } else if (uploadAvatarFor === 'user') {
      patchUser({
        userId: userId,
        newUserData: {
          userAvatar: uploadImages,
        },
      });
    }
    onClose();
  };

  function handleImage(e: React.ChangeEvent<HTMLInputElement>): void {
    if (e.target.files.length !== 0) {
      const productImage = new FileReader();
      productImage.readAsDataURL(e.target.files[0]);
      productImage.onload = async () => {
        if (e.target.files[0].size > 4096000) {
          dispatch(
            addToast({
              type: 'error',
              text: 'toast_max_image_upload_size',
              raw: `${e.target.files[0].name} is more than 4MB`,
            })
          );
        } else {
          setUploadImages(productImage.result.toString());
        }
      };
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.UploadImageModal}>
        <div className={styles.header}>
          <Typography
            type='headline-6'
            translationKey='action_upload_picture'
          />
        </div>
        <div className={styles.uploadInput}>
          {uploadImages === null ? (
            <div className={styles.inputUserInterface}>
              <Typography
                type='subtitle-1'
                translationKey='action_select_file'
              />
              <Icon name='Upload' color='primary' />
              <input
                type='file'
                accept='image/png, image/jpeg'
                onChange={(e) => handleImage(e)}
                className={styles.input}
              />
            </div>
          ) : (
            <div>
              <div>
                <Typography type='headline-6' translationKey='label_preview' />
              </div>

              <img className={styles.preview} src={uploadImages} alt='' />
            </div>
          )}
        </div>
        <div className={styles.buttonGroup}>
          <Button
            translationKey='action_cancel'
            onClick={onClose}
            type='secondary'
          />
          <Button
            translationKey='action_update'
            onClick={() => onUpload()}
            type='primary'
            disabled={uploadImages === null}
          />
        </div>
      </div>
    </Modal>
  );
}

export default UploadImageModal;

export interface IUploadImageModalProps {
  onClose: () => void;
  isOpen?: boolean;
  userId: string;
  uploadAvatarFor: 'organisation' | 'user';
}
