import { SortByFn } from "react-table";
import * as _ from "lodash";

const ERR_BG_MAP = {
  success: 1,
  warning: 2,
  error: 3,
  undefined: 4,
};

export default function errorCompareFn<D extends object>(path: string) {
  const compare: SortByFn<D> = (rowA, rowB, columnId) => {
    const bgColorA = path.length
      ? _.get(rowA.original[String(columnId)], path)
      : rowA.original[String(columnId)];
    const bgColorB = path.length
      ? _.get(rowB.original[String(columnId)], path)
      : rowB.original[String(columnId)];

    return ERR_BG_MAP[bgColorA] > ERR_BG_MAP[bgColorB] ? 1 : -1;
  };

  return compare;
}
