import { httpClient } from 'common/services/transportService';
import { useAuthStore } from './useAuthStore';
import { userKeys } from './hooks/userKeys';
import { queryClient } from 'index';
import { IAuthEndPoint } from './interfacesAuth';
import {
  IPostLogInWithGoogle,
  IPostLogInWithPassword,
  IPostSignUpWithGoogle,
  IPostSignUpWithPassword,
  postLoginWithGoogle,
  postLoginWithPassword,
  postLogOut,
  postRefreshToken,
  postSignUpWithGoogle,
  postSignUpWithPassword
} from './servicesAuth';
import { unstable_batchedUpdates } from 'react-dom';
import { updateDefaultTimezone } from 'utils/date';
import { prefetchCanAccessContractFeaturePermission } from 'containers/contract/hooks/useCanAccessContractFeature';
import { userTracking } from 'utils/userTracking';
import analytics from 'utils/analytics';
import { setSentryUser } from 'utils/sentryUserTracking';

export let accessTokenexpirationTimestamp = 0;

/**
 * Note: Will not throw
 */
export async function refreshToken () {
  try {
    const { accessToken, userInfo, expiresIn } = await postRefreshToken();

    setHTTPClientAccessToken(accessToken);
    await prefetchCanAccessContractFeaturePermission(userInfo.userId);
    queryClient.setQueryData(userKeys.user(userInfo.userId), userInfo);
    useAuthStore.getState().logIn(userInfo.userId);
    updateDefaultTimezone(userInfo.timezone);
    userTracking(userInfo);
    setSentryUser(userInfo);
    accessTokenexpirationTimestamp = Date.now() + expiresIn * 1000;
  } catch {
  }
}

export async function login (loginParams: ILoginWithGoogle | ILoginWithPassword) {
  let authData: IAuthEndPoint | undefined;
  if (loginParams.loginWith === 'google') {
    authData = await postLoginWithGoogle(loginParams.postBody);
  } else if (loginParams.loginWith === 'password') {
    authData = await postLoginWithPassword(loginParams.postBody);
  }
  const { accessToken, expiresIn, userInfo } = authData;

  setHTTPClientAccessToken(accessToken);
  await prefetchCanAccessContractFeaturePermission(userInfo.userId);
  queryClient.setQueryData(userKeys.user(userInfo.userId), userInfo);
  useAuthStore.getState().logIn(userInfo.userId);
  updateDefaultTimezone(userInfo.timezone);
  accessTokenexpirationTimestamp = Date.now() + expiresIn * 1000;
  return { accessToken, expiresIn, userInfo };
}

interface ILoginWithGoogle {
  loginWith: 'google';
  postBody: IPostLogInWithGoogle;
}

interface ILoginWithPassword {
  loginWith: 'password';
  postBody: IPostLogInWithPassword;
}

interface ISignupWithGoogle {
  loginWith: 'google';
  postBody: IPostSignUpWithGoogle;
}

interface ISignupWithPassword {
  loginWith: 'password';
  postBody: IPostSignUpWithPassword;
}

function setHTTPClientAccessToken(accessToken: string) {
  httpClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

export async function signup (signupParams: ISignupWithGoogle | ISignupWithPassword) {
  if (signupParams.loginWith === 'google') {
    await postSignUpWithGoogle(signupParams.postBody);
  } else if (signupParams.loginWith === 'password') {
    await postSignUpWithPassword(signupParams.postBody);
  }
}

/**
 * Clears
 * 1. Auth store - isLoggedIn false and sets userId to null
 * 2. Http client - Remove Authorization Beaerer <token>
 * 3. React query store - Removes all cache data
 *
 * Note : Can throw
 */
export async function logout () {
  await postLogOut();
  unstable_batchedUpdates(() => {
    useAuthStore.getState().logOut(); // Batch update otherwise TopNav useGetCurrentUser rerender first
  });
  delete httpClient.defaults.headers.common['Authorization'];
  queryClient.removeQueries();
  accessTokenexpirationTimestamp = 0;
  analytics.reset(); // Reset the Amplitude user ID and set a new user ID again once users log in.
}
