import * as React from "react";
import Drawer from "@mui/material/Drawer";
import Button from "components/Button";
import Icon from "common/components/icon/Icon";
import { useTranslation } from "react-i18next";
import { Table } from "@tanstack/table-core";
import { useLocalStorage } from "react-use";
import { ToggleSwitch } from "common/components/ToggleSwitch";
import { isNullish } from "utils/isNullish";

type SettingProps<T> = {
  table: Table<T>;
}

export function generateTableColumnFilterSettingKey(tableName: string) {
  return `table-v2-column-filter-setting-${tableName}`;
};

export function Setting<T>({ table }: SettingProps<T>) {
  const tableState = table.getState();
  const [setting, setSetting, removeSetting] = useLocalStorage(generateTableColumnFilterSettingKey(tableState.tableName));
  const { t } = useTranslation("translation", {
    keyPrefix: "table_v2.setting",
  });
  const [open, setOpen] = React.useState(false);
  

  return <>
    <Button type="secondary" size="small" iconName="Settings" className="!min-w-0 !w-10" onClick={() => setOpen(true)} />
    <Drawer
        anchor="right"
        open={open}
        sx={{
          flexShrink: 0,
        }}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          id="more-filter"
          className="w-[440px] max-w-full min-h-full box-border flex flex-col rounded-md shadow-sm max-h-[calc(100vh-6rem)] bg-white"
        >
          <div className="p-4 flex items-center gap-1 text-on-surface-high !border-b border-solid border-0 border-outline pb-2">
            <div className="flex items-baseline flex-1 gap-1">
              <h3 className="text-h6 font-medium">{t("label_title")}</h3>
            </div>
            <div className="cursor-pointer" onClick={() => setOpen(false)}>
              <Icon name="Close" color="onSurfaceHigh" />
            </div>
          </div>
          <div className="flex flex-col gap-3 flex-1 overflow-auto p-4">
            <div className="flex gap-2 items-center">
              <p className="text-on-surface-high font-kanit gap-2">{t("save_filter.label")}</p>
              <ToggleSwitch isOn={!isNullish(setting)} onClick={() => {
                if (isNullish(setting)) {
                  setSetting([...tableState.columnFilters]);
                } else {
                  removeSetting();
                }
              }} />
            </div>
          </div>
        </div>
      </Drawer>
  </>;
}
