import * as React from "react";

import Typography from "components/Typography";
import { Dialog, Transition } from "@headlessui/react";
import { ErrorBoundary } from "@sentry/react";
import { NoData } from "./components/NoData";
import Icon from "common/components/icon/Icon";
import { MaintenanceTable } from "./components/MaintenanceTable";
import { Skeleton } from "@mui/material";

import { useServiceZoneQueryParam } from "./hooks/useServiceZoneQueryParam";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useErrorFrequencyModalSearchParams } from "./hooks/useErrorFrequencyModalSearchParams";

import { clsx } from "clsx";
import * as moment from "moment";

import * as errorBoardService from "./services";

export function FrequentErrorModal() {
  const { t } = useTranslation("errorBoard");
  const [isOpen, setIsOpen] = useErrorFrequencyModalSearchParams();

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog onClose={() => setIsOpen(false)}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/30" />
        </Transition.Child>
        <div className="fixed top-0 h-[100dvh] z-10 w-screen overflow-y-hidden flex items-end justify-center box-border sm:items-center sm:p-0 ">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
            enterTo="translate-y-[0%] opacity-100 scale-100"
            leave="ease-in duration-150"
            leaveFrom="opacity-100 scale-100 translate-y-[0%]"
            leaveTo="translate-y-[100px] opacity-0 sm:translate-y-0 sm:scale-95"
          >
            <Dialog.Panel className="h-[calc(100%-170px)] sm:h-[calc(100%-80px)] sm:max-h-[800px] sm:relative rounded-lg rounded-b-none sm:rounded-b-lg bg-white overflow-hidden shadow-xl w-full sm:max-w-xl flex flex-col">
              <div className="bg-white p-6 pb-0">
                <div className="flex items-center justify-between">
                  <Dialog.Title>
                    <Typography type="headline-6" color="onSurfaceHigh">
                      {t("frequentErrors")}
                    </Typography>
                  </Dialog.Title>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="bg-transparent outline-none p-0 flex items-center justify-center cursor-pointer"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                        fill="black"
                        fillOpacity="0.87"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <ModalBody />
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

function ModalBody() {
  const { data, isLoading } = useGetContextualFrequentErrors();

  return (
    <>
      <div className="px-6 flex items-center">
        {isLoading ? (
          <Typography type="body-2" color="onSurfaceMedium">
            <Skeleton width={130} />
          </Typography>
        ) : null}
        {data ? (
          <Typography
            type="body-2"
            color="onSurfaceMedium"
            className="animate-fade-in"
          >
            {moment(data.date).format("MMM DD, YYYY HH:mm")}
          </Typography>
        ) : null}
      </div>
      <div className="flex-[1_1_0] min-h-0">
        <ErrorBoundary fallback={({ error }) => <Fallback error={error} />}>
          <ModalTables />
        </ErrorBoundary>
      </div>
    </>
  );
}
function ModalTables() {
  const { t } = useTranslation("errorBoard");
  const { isError, data, error, isLoading } = useGetContextualFrequentErrors();
  if (isError) throw error;
  return (
    <>
      {data?.errors.length === 0 ? (
        <NoData
          className="h-full w-full pb-24 box-border"
          text={t("noFrequentErrorsNow")}
        />
      ) : null}

      <div className="h-full w-full box-border p-6 overflow-y-auto [scrollbar-gutter:stable] pt-0 overscroll-contain snap-y snap-mandatory">
        {isLoading
          ? new Array(5)
              .fill(null)
              .map((_, index) => <ErrorDropdown key={index} isLoading />)
          : data.errors.map((error, index) => (
              <ErrorDropdown key={index} error={error} />
            ))}
      </div>
    </>
  );
}

function ErrorDropdown({
  isLoading = false,
  error,
}: {
  isLoading?: boolean;
  error?: FrequentErrors["errors"][number];
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation("errorBoard");
  return (
    <div className="border-b-[rgba(21,21,21,0.08)] border-b-[1px] border-solid snap-start ">
      <div className="flex items-center justify-between p-4 pb-3">
        {isLoading ? (
          <Typography type="subtitle-2" color="onSurfaceHigh">
            <Skeleton width={130} />
          </Typography>
        ) : (
          <Typography
            type="subtitle-2"
            color="onSurfaceHigh"
            className="animate-fade-in"
          >
            {`${t("error")} ${error.detail} (${error.totalNumberOfErrors})`}
          </Typography>
        )}

        {isLoading ? (
          <Skeleton width={24} height={24} />
        ) : (
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="border-none bg-transparent cursor-pointer p-0 animate-fade-in"
          >
            <Icon
              name="DropDownChevron"
              color="primary500"
              style={{
                transition: "transform 0.2s ease-in-out",
                transform: isOpen ? "rotate(180deg)" : null,
                transformOrigin: "center",
              }}
            />
          </button>
        )}
      </div>
      {isLoading ? null : (
        <div
          className={clsx(
            "grid overflow-hidden transition-all",
            isOpen ? "[grid-template-rows:1fr]" : "[grid-template-rows:0fr]"
          )}
        >
          <div
            className={clsx(
              "min-h-0 transition-all w-full min-w-0",
              isOpen ? "visible" : "invisible"
            )}
          >
            <div className="pb-2 px-2">
              <FrequentErrorTable events={error.events} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export function Fallback({ error }: { error: Error }) {
  const { t } = useTranslation();
  return (
    <div className="h-full flex justify-center items-center overflow-hidden rounded-lg animate-fade-in">
      <div className={"text-center max-w-[80%] pb-20"}>
        <Typography color="onSurfaceMedium" translate type="headline-6">
          {t("label_an_error_has_occured")}
        </Typography>

        <div>
          <Typography color="onSurfaceMedium" translate type="caption">
            {error.message}
          </Typography>
        </div>
      </div>
    </div>
  );
}

function FrequentErrorTable({
  events,
}: {
  events: FrequentErrors["errors"][number]["events"];
}) {
  const { t } = useTranslation("errorBoard");

  return (
    <MaintenanceTable
      data={events}
      containerStyles={{
        boxSizing: "border-box",
        scrollPaddingLeft: "80px",
        maxHeight: "18rem",
        borderRadius: "8px",
        border: "1px solid rgba(21,21,21,0.08)",
        scrollbarGutter: "unset",
      }}
      getKey={(dataPoint) => dataPoint.machineId}
      columns={[
        {
          id: "MACHINE_ID",
          commonStyle: {
            minWidth: "80px",
            flex: "0 0 80px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
            maxWidth: "80",
          },
          headerStyle: {
            position: "sticky",
            top: "0",
            left: "0",
            backgroundColor: "#F5F5F5",
          },
          headerCell: () => (
            <Typography type="caption" color="onSurfaceHigh">
              {t("machineId")}
            </Typography>
          ),
          rowStyle: {
            display: "flex",
            alignItems: "center",
            position: "sticky",
            left: "0",
            backgroundColor: "white",
          },
          cell: (event) => (
            <Typography type="subtitle-2" color="onSurfaceHigh">
              {event.machineId}
            </Typography>
          ),
          loadingRowStyle: {
            position: "sticky",
            left: "0",
            backgroundColor: "white",
            zIndex: 1,
          },
        },

        {
          id: "FREQUENCY",
          commonStyle: {
            minWidth: "80px",
            flex: "0 0 80px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "24px",
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("frequency")}
              </Typography>
            );
          },
          rowStyle: {
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="subtitle-2" color="onSurfaceHigh">
                {event.frequency}
              </Typography>
            );
          },
        },

        {
          id: "SERVICE_ZONE",
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.serviceZone}
              </Typography>
            );
          },
          commonStyle: {
            minWidth: "148px",
            flex: "0 0 148px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("serviceZone")}
              </Typography>
            );
          },
        },
        {
          id: "REFILL_ZONE",
          commonStyle: {
            minWidth: "148px",
            flex: "1 0 148px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.refillZone}
              </Typography>
            );
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("refillZone")}
              </Typography>
            );
          },
        },
      ]}
    />
  );
}

type FrequentErrors = {
  errors: Array<{
    detail: string;
    totalNumberOfErrors: number;
    events: Array<{
      machineId: string;
      frequency: number;
      serviceZone: string;
      refillZone: string;
    }>;
  }>;
  date: Date | undefined;
};

function useGetFrequentErrorsByServiceZone(serviceZoneId: string) {
  return useQuery<FrequentErrors>({
    queryFn: async () => {
      const response = await errorBoardService.getFrequentErrors({
        serviceZoneId: serviceZoneId,
      });
      return {
        errors: response.maintenanceFrequentErrors.map((error) => {
          const events = error.machines?.map((event) => ({
            machineId: event.machineId,
            frequency: event.count,
            serviceZone:
              event.machine.location.__typename === "Factory"
                ? undefined
                : event.machine?.location.serviceZone?.friendlyId,
            refillZone:
              event.machine.location.__typename === "Factory"
                ? undefined
                : event.machine?.location.refillZone?.friendlyId,
          }));
          const totalNumberOfErrors = events.reduce(
            (acc, curr) => acc + curr.frequency,
            0
          );

          return {
            detail: error.errorCode,
            totalNumberOfErrors: totalNumberOfErrors,
            events: events,
          };
        }),

        date: new Date(),
      };
    },
    queryKey: ["frequentErrors", serviceZoneId],
    useErrorBoundary: false,
    cacheTime: 0,
    refetchOnWindowFocus: false,
  });
}

function useGetContextualFrequentErrors() {
  const [serviceZoneId] = useServiceZoneQueryParam();
  return useGetFrequentErrorsByServiceZone(serviceZoneId);
}
