import {
  GetMaintenanceBoardSpiralErrorDocument,
  GetMaintenanceBoardSpiralErrorQuery,
  GetMaintenanceBoardSpiralErrorQueryVariables,
} from 'gql/generated';
import { fetcher } from 'gql/fetcher';

export async function getSpiralUnsellableError({
  serviceZoneId,
  cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return await fetcher<
    GetMaintenanceBoardSpiralErrorQuery,
    GetMaintenanceBoardSpiralErrorQueryVariables
  >(GetMaintenanceBoardSpiralErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
