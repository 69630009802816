import * as React from "react";
import { CellProps } from "react-table";
import Typography from "common/components/typography/Typography";
import { CellAlignmentProps } from "../types";

import * as styles from "./StringCell.module.scss";

export type StringCellValues = string;

export default function StringCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
}: CellAlignmentProps = {}) {
  return function Component({
    value,
  }: CellProps<D, StringCellValues>): JSX.Element {
    return (
      <div
        className={styles.StringCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        <Typography translationKey={value} type="body-2" />
      </div>
    );
  };
}
