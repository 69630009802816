import {
  GetMaintenanceBoardIceErrorDocument,
  GetMaintenanceBoardIceErrorQuery,
  GetMaintenanceBoardIceErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getIceError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardIceErrorQuery,
    GetMaintenanceBoardIceErrorQueryVariables
  >(GetMaintenanceBoardIceErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
