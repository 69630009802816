// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StringFillBackgroundCell--1D_Li7hssT{width:100%;height:100%;display:flex}.StringFillBackgroundCell--1D_Li7hssT.FillFullCell--XMo4TT6i-u{width:calc(100% + 10px);position:absolute;left:0}.StringFillBackgroundCell--1D_Li7hssT .Typography--2uPNDiMeFO{margin-left:8px}", ""]);
// Exports
exports.locals = {
	"StringFillBackgroundCell": "StringFillBackgroundCell--1D_Li7hssT",
	"FillFullCell": "FillFullCell--XMo4TT6i-u",
	"Typography": "Typography--2uPNDiMeFO"
};
module.exports = exports;
