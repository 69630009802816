import i18n from "common/i18n";
import { toast, UpdateOptions } from "react-toastify";
import * as React from "react";

function promise<T>(fn: Promise<T> | (() => Promise<T>)) {
  return toast.promise(fn, {
    pending: i18n.t("label_loading"),
    error: i18n.t("toast_request_failed"),
    success: i18n.t("toast_request_successful"),
  });
}

function success(translationKeys?: string | string[]) {
  let textToDisplay: string;
  if (!translationKeys) {
    textToDisplay = i18n.t("toast_request_successful");
  } else if (Array.isArray(translationKeys)) {
    textToDisplay = translationKeys
      .map((string) => (string.includes("_") ? i18n.t(string) : string))
      .join("");
  } else {
    textToDisplay = i18n.t(translationKeys);
  }
  return toast.success(textToDisplay);
}

function error(err: { message: string, excludeDefaultTitle?: boolean }) {
  const displayMessage = err.excludeDefaultTitle 
    ? err.message 
    : `${i18n.t("toast_request_failed")}: ${err.message}`;
  return toast.error(displayMessage);
}

function loading() {
  return toast.loading(i18n.t("label_loading"));
}

function updateLoadingToastStatus(toastId: React.ReactText, status: "success" | "error") {
  const commonConfig: UpdateOptions = {
    isLoading: false,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };

  const toastConfig = Object.assign(
    commonConfig,
    status === "success"
      ? {
          type: "success",
          render: i18n.t("toast_request_successful"),
        }
      : {},
    status === "error"
      ? {
          type: "error",
          render: i18n.t("toast_request_failed"),
        }
      : {}
  );

  toast.update(toastId, toastConfig);
}

const toasti18n = {
  promise,
  success,
  error,
  loading,
  updateLoadingToastStatus,
};

export { toasti18n };
