import analytics from "utils/analytics";
import * as moment from "moment";
import { RefillInventoryInput } from "gql/generated";
import { KittingModalState } from "./useKittingModalState";

type TrackMachineKittingPlanGeneratedArgs = {
  planId: string;
  variables: {
    planId: string;
    refillInventories: RefillInventoryInput | RefillInventoryInput[];
  };
  data: KittingModalState;
};

export function trackMachineKittingPlanGenerated({
  planId,
  data,
  variables,
}: TrackMachineKittingPlanGeneratedArgs) {
  for (const [, machine] of Object.entries(data.machines)) {
    const refillInventories = Array.isArray(variables.refillInventories)
      ? variables.refillInventories
      : [variables.refillInventories];
    const inventoryArray = Object.values(machine.inventory);

    const recommendedInventories = inventoryArray.filter(
      (inventory) => inventory.recommendedAction != null
    );

    const recommendedInventoriesIds = recommendedInventories.map(
      (ri) => ri.inventoryId
    );

    const inventorySelectedIds = refillInventories
      .find((ri) => ri.machineId === machine.machineId)
      .kittedItems.map((ki) => ki.machineInventoryId);

    const locationData = {
      no_of_cupC1_since_last_refill:
        inventoryArray.find((i) => i.slot === "C1")?.inventoryLevel?.current ||
        null,
      no_of_cupC2_since_last_refill:
        inventoryArray.find((i) => i.slot === "C2")?.inventoryLevel?.current ||
        null,
      no_of_cupC3_since_last_refill:
        inventoryArray.find((i) => i.slot === "C3")?.inventoryLevel?.current ||
        null,
      no_of_not_recommended_slot_selected: inventorySelectedIds.filter(
        (is) => !recommendedInventoriesIds.includes(is)
      ).length,
      no_of_recommended_slot: recommendedInventories.length,
      no_of_recommended_slot_selected: recommendedInventories.filter((ri) =>
        inventorySelectedIds.includes(ri.inventoryId)
      ).length,
      day_since_last_refill: Number(machine.lastRefilled)
        ? moment().diff(moment(Number(machine.lastRefilled)), "days")
        : "-",
      location_grade: machine.location.grade,
      location_id: machine.location.id,
      location_name: machine.location.name,
      location_type: machine.location.type,
      machine_id: machine.machineId,
      plan_id: planId,
      warehouseId: data.plan.warehouseId,
      revenue_since_last_refill: machine.location.rawRevenue,
      total_cash: machine.cashValue,
    };

    analytics.track({
      name: "machine kitting plan generated",
      properties: locationData,
    });
  }
}

export function trackPlanMoveToPrekits(data: KittingModalState) {
  analytics.track({
    name: "plan moved to prekits",
    properties: {
      plan_id: data?.plan?.planId,
      plan_name: data?.plan?.planName,
      warehouse_id: data?.plan?.warehouseId,
      no_of_machine_in_plan: Object.keys(data?.machines)?.length,
    },
  });
}
