// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@font-face{font-family:\"font-main-light\";src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"font-main-regular\";src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"font-main-medium\";src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"font-alt-light\";src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"font-alt-regular\";src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"font-alt-medium\";src:url(\"/fonts/Roboto-Medium.ttf\")}@font-face{font-family:\"Kanit\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Light.ttf\")}@font-face{font-family:\"Kanit\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Regular.ttf\")}@font-face{font-family:\"Kanit\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Kanit-Medium.ttf\")}@font-face{font-family:\"Roboto\";font-weight:300;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Light.ttf\")}@font-face{font-family:\"Roboto\";font-weight:400;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Regular.ttf\")}@font-face{font-family:\"Roboto\";font-weight:500;font-style:normal;font-display:swap;src:url(\"/fonts/Roboto-Medium.ttf\")}.DropdownCell--j8ZKpLFbyx{min-width:130px}.nonDropdown--1fyc1N-J2V{position:relative;display:flex;flex-direction:column;min-width:130px;background:#fff}.nonDropdown--1fyc1N-J2V .input--3MQary9wjG{padding:8px 15px;position:relative;background-color:rgba(0,0,0,0);display:flex;flex-direction:row;align-items:center;justify-content:space-between;box-sizing:border-box;min-height:58px}.nonDropdown--1fyc1N-J2V .input--3MQary9wjG .label--34i2Q1GnVH{position:absolute;padding:2px 3px;left:12px;opacity:1;font-size:16.5px !important}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"DropdownCell": "DropdownCell--j8ZKpLFbyx",
	"nonDropdown": "nonDropdown--1fyc1N-J2V",
	"input": "input--3MQary9wjG",
	"label": "label--34i2Q1GnVH"
};
module.exports = exports;
