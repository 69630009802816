// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--x93E8RiUTu{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--x93E8RiUTu .ButtonsContainer--bZek1apUAZ{width:100%;display:flex;gap:25px}.TableHeader--x93E8RiUTu .ButtonsContainer--bZek1apUAZ>*:last-child{margin-left:auto}@media screen and (max-width: 750px){.TableHeader--x93E8RiUTu .ButtonsContainer--bZek1apUAZ{flex-direction:column}.TableHeader--x93E8RiUTu .ButtonsContainer--bZek1apUAZ>*:last-child{margin-left:revert}}.TableContainer--2rXRCeQUEH{overflow:auto}.TableContainer--2rXRCeQUEH>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--x93E8RiUTu",
	"ButtonsContainer": "ButtonsContainer--bZek1apUAZ",
	"TableContainer": "TableContainer--2rXRCeQUEH"
};
module.exports = exports;
