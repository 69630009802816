/* eslint-disable react/display-name */
import * as React from "react";

import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import Typography from "components/Typography";

import { Container, OperationTable } from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";

import { useQueryClient } from "react-query";
import { RouteModal } from "./RouteModal/RouteModal";
import { MachineKind, useGetRoutesQuery } from "gql/generated";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { useSortState } from "hooks/useSortState";
import { useTranslation } from "react-i18next";

export default function RefillRoute() {
  const { t } = useTranslation();
  const { isLoading, data: routesQuery } = useGetRoutesQuery();

  const routes: Array<TableRow> = routesQuery?.refillZones
    .map((route) => {
      const operableLocations = route.locations.filter((location) => {
        if (location.__typename === "Location") {
          return Boolean(location.machine);
        } else if (location.__typename === "Store") {
          return Boolean(location.machines.length > 0);
        } else {
          return false;
        }
      });

      const taobinMachinesInRoute = operableLocations?.reduce(
        (prev, curr) => {
          if(curr.__typename === "Store") {
            return prev + curr.machines.filter(machine => machine.kind === MachineKind.BeverageVending).length;
          }
          return curr?.machine?.kind === MachineKind.BeverageVending ? prev + 1: prev;
        },
        0
      );

      const spiralMachinesInRoute = operableLocations?.reduce(
        (prev, curr) => {
          if(curr.__typename === "Store") {
            return prev + curr.machines.filter(machine => machine.kind === MachineKind.SpiralVending).length;
          }
          return curr?.machine?.kind === MachineKind.SpiralVending ? prev + 1: prev;
        },
        0
      );

      return {
        routeId: route.id,
        routeName: route.friendlyId,
        warehouseName: Array.from(
          new Set(route.locations.map((location) => location.warehouse.name))
        ).join(", "),
        taobinMachinesInRoute: taobinMachinesInRoute,
        spiralMachinesInRoute: spiralMachinesInRoute,
        activeRefill: route.refillOrders.length,
        recommended: route.refillOrderRecommendations.length,
        swapSheets: route.orphanSwapOrders.length,
      };
    })
    .filter((tableRow) => tableRow.taobinMachinesInRoute !== 0);

  const [routeId, setRouteId] = React.useState<null | string>(null);

  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredRoutes = React.useMemo(
    () =>
      appliedFilter
        ? routes?.filter((route) => {
            let value: string;
            switch (appliedFilter.field) {
              case columns.routeName:
                value = route.routeName;
                break;
              case columns.warehouseName:
                value = route.warehouseName;
                break;
              default:
                value = route.routeName;
                break;
            }
            return value
              .toLowerCase()
              .includes(appliedFilter.value.toLowerCase());
          })
        : routes,
    [routes, appliedFilter]
  );

  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({ columnId: columns.routeName, direction: "ASC" });
  const sortedRoutes = React.useMemo(
    () =>
      sortState
        ? filteredRoutes
            ?.map((elem) => elem)
            ?.sort((routeA, routeB) => {
              return (
                sortFnMap[sortState.columnId](routeA, routeB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredRoutes,
    [filteredRoutes, sortState]
  );

  const queryClient = useQueryClient();
  return (
    <Container>
      {routeId !== null ? (
        <RouteModal
          onClose={() => setRouteId(null)}
          onRouteSuccess={() => {
            setRouteId(null);
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            queryClient.invalidateQueries(["GetRoutes"]);
            queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
          }}
          routeId={routeId}
        />
      ) : null}
      <PureFilterBar
        filterOptions={[
          {
            field: columns.routeName,
            queryType: "contains",
            translationKey: "label_route",
          },
          {
            field: columns.warehouseName,
            queryType: "contains",
            translationKey: "label_warehouse",
          },
        ]}
        onChange={setAppliedFilter}
        placeholder="label_search"
        value={appliedFilter}
      />
      <OperationTable
        data={sortedRoutes}
        columns={[
          {
            id: columns.routeName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_route")}
              </TableHeader>
            ),
            cell: (route) => (
              <div
                style={{
                  fontFamily: "Kanit",
                  fontSize: "14px",
                  fontWeight: 700,
                  textDecoration: "underline",
                  color: "#4B4D9B",
                  cursor: "pointer",
                }}
                onClick={() => setRouteId(route.routeId)}
              >
                {route.routeName}
              </div>
            ),
            style: {
              flex: "1 0 100px",
              minWidth: "100px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.warehouseName,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_warehouse")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.warehouseName}
              </Typography>
            ),
            style: {
              flex: "1 0 177px",
              minWidth: "177px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.taobinMachinesInRoute,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_taobin_machines_in_route")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.taobinMachinesInRoute}
              </Typography>
            ),
            style: {
              flex: "1 0 80px",
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
            },
          },
          {
            id: columns.spiralMachinesInRoute,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_spiral_machines_in_route")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.spiralMachinesInRoute}
              </Typography>
            ),
            style: {
              flex: "1 0 80px",
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.activeRefill,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_active_refill")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.activeRefill}
              </Typography>
            ),
            style: {
              flex: "1 0 80px",
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.recommended,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_recommended")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.recommended}
              </Typography>
            ),
            style: {
              flex: "1 0 80px",
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
            },
          },

          {
            id: columns.swapSheets,
            headerCell: (columnId) => (
              <TableHeader
                columnId={columnId}
                sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                onClick={() => toggleSortColumnByColumnId(columnId)}
              >
                {t("label_swap_sheet")}
              </TableHeader>
            ),
            cell: (route) => (
              <Typography type="body-2" color="onSurfaceHigh">
                {route.swapSheets}
              </Typography>
            ),
            style: {
              flex: "1 0 80px",
              minWidth: "80px",
              display: "flex",
              alignItems: "center",
            },
          },
        ]}
        isLoading={isLoading}
        getKey={(route) => route.routeId}
      />
    </Container>
  );
}

interface TableRow {
  routeId: string; // 'A123-456-789'
  routeName: string; // 'A1'
  warehouseName: string;
  taobinMachinesInRoute: number;
  spiralMachinesInRoute: number;
  activeRefill: number;
  recommended: number;
  swapSheets: number;
}

const columns = {
  activeRefill: "activeRefill",
  taobinMachinesInRoute: "taobinMachinesInRoute",
  spiralMachinesInRoute: "spiralMachinesInRoute",
  warehouseName: "warehouseName",
  recommended: "recommended",
  routeName: "routeName",
  swapSheets: "swapSheets",
} as const;
type Columns = keyof typeof columns;

const sortFnMap: Record<
  Columns,
  (routeA: TableRow, routeB: TableRow) => number
> = {
  activeRefill: (routeA, routeB) => {
    let comparisonValue = routeA.activeRefill - routeB.activeRefill;
    comparisonValue =
      comparisonValue === 0
        ? alphanumeric(routeA.routeName, routeB.routeName)
        : comparisonValue;
    return comparisonValue;
  },
  taobinMachinesInRoute: (routeA, routeB) => {
    let comparisonValue = routeA.taobinMachinesInRoute - routeB.taobinMachinesInRoute;
    comparisonValue =
      comparisonValue === 0
        ? alphanumeric(routeA.routeName, routeB.routeName)
        : comparisonValue;
    return comparisonValue;
  },
  spiralMachinesInRoute: (routeA, routeB) => {
    let comparisonValue = routeA.spiralMachinesInRoute - routeB.spiralMachinesInRoute;
    comparisonValue =
      comparisonValue === 0
        ? alphanumeric(routeA.routeName, routeB.routeName)
        : comparisonValue;
    return comparisonValue;
  },
  recommended: (routeA, routeB) => {
    let comparisonValue = routeA.recommended - routeB.recommended;
    comparisonValue =
      comparisonValue === 0
        ? alphanumeric(routeA.routeName, routeB.routeName)
        : comparisonValue;
    return comparisonValue;
  },
  routeName: (routeA, routeB) => {
    return alphanumeric(routeA.routeName, routeB.routeName);
  },
  warehouseName: (routeA, routeB) => {
    return routeA.warehouseName.localeCompare(routeB.warehouseName);
  },
  swapSheets: (routeA, routeB) => {
    let comparisonValue = routeA.swapSheets - routeB.swapSheets;
    comparisonValue =
      comparisonValue === 0
        ? alphanumeric(routeA.routeName, routeB.routeName)
        : comparisonValue;
    return comparisonValue;
  },
};
