import { GetMachineMonitorsQuery, MachineInventoryStatusEnum, MachineStatusEnum } from "gql/generated";

export type CoordinatesType = {
  latitude: string;
  longitude: string;
}

export type MachineOverviewsTableDataRow = {
  id: string;
  status: MachineStatusEnum;
  machineId: string;
  locationName: string;
  coordinates: CoordinatesType,
  locationId: string;
  organizationName: string;
  serviceZoneName: string;
  refillZoneName: string;
  error: string;
  lastSold: string;
  todaySale: number;
  expectedSale: number;
  ice: MachineInventoryStatusEnum;
  soda: MachineInventoryStatusEnum;
  coffee: {
      B1: MachineInventoryStatusEnum;
      B2: MachineInventoryStatusEnum;
      B3: MachineInventoryStatusEnum;
  };
  milk: MachineInventoryStatusEnum;
  sugar: MachineInventoryStatusEnum;
  cups: {
    C1: MachineInventoryStatusEnum;
    C2: MachineInventoryStatusEnum;
    C3: MachineInventoryStatusEnum;
    C4: MachineInventoryStatusEnum;
    C5: MachineInventoryStatusEnum;
  },
  lid: {
    L1: MachineInventoryStatusEnum;
    L2: MachineInventoryStatusEnum;
  };
  cash: {
    BANK: MachineInventoryStatusEnum;
    COIN: MachineInventoryStatusEnum;
  },
  todayCupSold: number;
  disabledSlot: number;
  lastRefillDate: string;
  revenueSinceLastRefill: number;
  sevenDaysAgoSale: number;
};



export function mapDataToTable (data: GetMachineMonitorsQuery): MachineOverviewsTableDataRow[] {
  return data?.machineMonitors?.edges?.map((edge) => {
    const node = edge?.node;
    return {
      id: node?.machineId,
      status: node?.status,
      machineId: node?.machineId,
      locationName: node?.locationName,
      coordinates: {
        latitude: node?.coordinates.latitude,
        longitude: node?.coordinates.longitude
      },
      locationId: node?.locationId,
      organizationName: node?.organizationName,
      serviceZoneName: node?.serviceZoneName,
      refillZoneName: node?.refillZoneName,
      error: node?.error?.code,
      lastSold: node?.lastSold,
      todaySale: node?.todaySale,
      expectedSale: node?.expectedSale,
      ice: node?.ice,
      soda: node?.soda,
      coffee: {
        B1: node?.coffee?.B1,
        B2: node?.coffee?.B2,
        B3: node?.coffee?.B3,
      },
      milk: node?.milk,
      sugar: node?.sugar,
      cups: {
        C1: node?.cup.C1,
        C2: node?.cup.C2,
        C3: node?.cup.C3,
        C4: node?.cup.C4,
        C5: node?.cup.C5,
      },
      lid: {
        L1: node?.lid.L1,
        L2: node?.lid.L2,
      },
      cash: {
        BANK: node?.cash?.BANK,
        COIN: node?.cash?.COIN,
      },
      disabledSlot: node?.disabledSlot,
      lastRefillDate: node?.lastRefillDate,
      revenueSinceLastRefill: node?.revenueSinceLastRefill,
      sevenDaysAgoSale: node?.sevenDaysAgoSale,
      todayCupSold: node?. todaySaleCount,
    };
  });
};