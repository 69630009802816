import * as React from "react";
import Typography from "common/components/typography/Typography";
import { CellProps } from "react-table";
import { CellAlignmentProps } from "../types";

import * as SCSScolors from "common/styles/colors.scss";
import * as styles from "./BadgeCell.module.scss";

export interface BadgeCellValues {
  translationKey: string;
  backgroundColor: string;
  textColor: string;
}

export default function BadgeCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
}: CellAlignmentProps = {}) {
  return function Component({
    value,
  }: CellProps<D, BadgeCellValues>): JSX.Element {
    const translationKey = value.translationKey;
    const backgroundColor = value.backgroundColor;
    const textColor = value.textColor;

    return (
      <div
        className={styles.BadgeCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        <div
          className={styles.Badge}
          style={{
            backgroundColor: SCSScolors[backgroundColor],
            color: SCSScolors[textColor],
          }}
        >
          <Typography
            translationKey={translationKey}
            className={styles.Text}
            type="caption"
          />
        </div>
      </div>
    );
  };
}
