import { SortByFn } from "react-table";
import * as _ from "lodash";

export default function timestampCompareFn<D extends object>(path: string) {
  const compare: SortByFn<D> = (rowA, rowB, columnId) => {
    const tsA = path.length
      ? _.get(rowA.original[String(columnId)], path)
      : rowA.original[String(columnId)];
    const tsB = path.length
      ? _.get(rowB.original[String(columnId)], path)
      : rowB.original[String(columnId)];

    return (tsA === undefined && tsB !== undefined) || tsA > tsB ? 1 : -1;
  };

  return compare;
}
