// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".StringCell--1D164U3dqN{width:100%;height:100%;display:flex}", ""]);
// Exports
exports.locals = {
	"StringCell": "StringCell--1D164U3dqN"
};
module.exports = exports;
