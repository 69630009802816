import * as React from "react";

import Button from "common/components/button/Button";
import Modal from "common/components/modal/Modal";
import Typography from "common/components/typography/Typography";

import {
  usePostBatchSwapOrder,
  useValidateBatchSwapOrder
} from "reactquery/swaporders";
import { PostBatchSwapOrderBody } from "services/swaporders";

import { ISwapInfo } from "./BatchSwapOrderModal";

import * as styles from "./SwapConfirmationModal.module.scss";

interface ISwapConfirmationModal {
  formValues: ISwapInfo;
  onBack: () => void;
  onClose: () => void;
}
export function SwapConfirmationModal({
  formValues,
  onBack,
  onClose
}: ISwapConfirmationModal): JSX.Element {
  const {
    data: validationData = [],
    isLoading: isValidating
  } = useValidateBatchSwapOrder({
    machineId: formValues.machineId,
    productId: formValues.swapInfo.product.id,
    slot: formValues.swapInfo.slot
  });

  const validMachines = validationData.filter(
    machine => machine.error === null
  );
  const validMachineIds = validMachines.map(machine => machine.machineId);

  const previousProductsForValidMachines = Array.from(
    validMachines.reduce((acc: Set<string>, curr) => {
      acc.add(curr.swapOut.productName);
      return acc;
    }, new Set<string>())
  );

  const machinesWithoutSlot = validationData.filter(
    machine => machine.errorCode === 4
  );

  const machinesWithExistingOrders = validationData.filter(
    machine => machine.errorCode === 3 || machine.errorCode === 2
  );

  const machinesSwappingToSameProduct = validationData.filter(
    machine => machine.errorCode === 1
  );

  const {
    mutate: postBatchSwapOrder,
    isLoading: isSubmitting
  } = usePostBatchSwapOrder();

  const getValue = mapReplaceMethodToValue(
    formValues.swapInfo.replacementMethod
  );

  return (
    <Modal
      contentClassName={styles.SwapInventoryConfirmationModal}
      closeButton={true}
      onClose={onClose}
      closeButtonColor="onSurfaceHigh"
    >
      {/* Header section - Replace method and slot name */}
      <header className={styles.header}>
        <Typography
          type="headline-6"
          translationKey={[
            getValue(
              "label_header_swap_inventory",
              "label_header_swap_inventory",
              "label_header_refill_without_removing"
            ),
            ` (${formValues.swapInfo.slot})`
          ]}
        />
      </header>

      <Typography
        type="body-2"
        translationKey={[
          "" + formValues.machineId.length,
          " ",
          "label_machines_selected",
          " (",
          validMachines.length + ' ',
          "label_valid",
          ")"
        ]}
        className={styles.selectedMachines}
      />

      {/* Swap inventory summary */}
      <section
        className={[
          styles.swapInventorySummary,
          getValue(
            styles.swapInventorySummary__blueTheme,
            styles.swapInventorySummary__redTheme,
            styles.swapInventorySummary__blueTheme
          )
        ].join(" ")}
      >
        <div className={styles.oldMaterialContainer}>
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_replacement_action_remove",
              "label_replacement_action_remove",
              "label_replacement_action_current"
            )}
            className={styles.oldMaterialAction}
          />
          <Typography
            type="body-1"
            translationKey={
              previousProductsForValidMachines.length > 1
                ? [
                    "label_multiple_material",
                    ` (${previousProductsForValidMachines.join(", ")})`
                  ]
                : previousProductsForValidMachines.length === 1
                ? previousProductsForValidMachines[0]
                : "-"
            }
            className={styles.oldMaterialName}
          />
        </div>
        <div className={styles.newMaterialContainer}>
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_replacement_action_swap_to",
              "label_replacement_action_swap_to",
              "label_replacement_action_continue_refill"
            )}
            className={styles.newMaterialAction}
          />
          <Typography
            type="body-1"
            text={formValues.swapInfo.product.name}
            className={styles.newMaterialName}
          />
        </div>
      </section>

      {/* Confirmation & Cancellation */}
      <section className={styles.confirmationSection}>
        <div className={styles.confirmationStringContainer}>
          <Typography
            type="body-1"
            translationKey={getValue(
              "label_confirm_create_swap_sheet_later",
              "label_confirm_create_swap_sheet_now",
              "label_confirm_refill_without_removing"
            )}
          />
        </div>
        {machinesWithoutSlot.length > 0 && (
          <Typography
            type="body-1"
            translationKey={[
              machinesWithoutSlot.length + " ",
              "label_machines_dont_have_slot",
              " ",
              formValues.swapInfo.slot,
              " ",
              `(${machinesWithoutSlot
                .map(machine => machine.machineId)
                .join(", ")})`
            ]}
            className={styles.errorString}
          />
        )}
        {machinesWithExistingOrders.length > 0 && (
          <Typography
            type="body-1"
            translationKey={[
              machinesWithExistingOrders.length + " ",
              "label_machines_have_existing_order",
              " ",
              `(${machinesWithExistingOrders
                .map(machine => machine.machineId)
                .join(", ")})`
            ]}
            className={styles.errorString}
          />
        )}

        {machinesSwappingToSameProduct.length > 0 && (
          <Typography
            type="body-1"
            translationKey={[
              machinesSwappingToSameProduct.length + " ",
              "label_machines_swapping_to_same_product",
              " ",
              `(${machinesSwappingToSameProduct
                .map(machine => machine.machineId)
                .join(", ")})`
            ]}
            className={styles.errorString}
          />
        )}
        <div className={styles.confirmationButtons}>
          <Button
            translationKey="action_cancel"
            onClick={onBack}
            disabled={isSubmitting || isValidating}
            type="secondary"
          />
          <Button
            translationKey={getValue(
              "action_create_swap_sheet_later",
              "action_create_swap_sheet_now",
              "action_refill_without_removing"
            )}
            onClick={() => {
              postBatchSwapOrder(
                buildRequestObject({
                  ...formValues,
                  machineId: validMachineIds
                }),
                {
                  onSuccess: () => onClose()
                }
              );
            }}
            type={getValue("primary", "status", "primary")}
            loading={isSubmitting}
            disabled={validMachines.length === 0}
          />
        </div>
      </section>
    </Modal>
  );
}

function mapReplaceMethodToValue(
  replacementMethod: ISwapInfo["swapInfo"]["replacementMethod"]
) {
  function getValue(
    removeOldOnCriticalValue,
    removeOldNowValue,
    continueToRefillOnTopValue
  ) {
    if (replacementMethod === "removeOldOnCritical")
      return removeOldOnCriticalValue;
    if (replacementMethod === "removeOldNow") return removeOldNowValue;
    if (replacementMethod === "continueToRefillOnTop")
      return continueToRefillOnTopValue;
  }
  return getValue;
}

function buildRequestObject(formValue: ISwapInfo): PostBatchSwapOrderBody {
  return {
    machineId: formValue.machineId,
    swapMethod: (() => {
      switch (formValue.swapInfo.replacementMethod) {
        case "continueToRefillOnTop":
          return "append";
        case "removeOldNow":
          return "immediate";
        case "removeOldOnCritical":
          return "await_low_level";
      }
    })(),
    itemsRequested: [
      {
        productId: formValue.swapInfo.product.id,
        slotConfig: {
          slot: formValue.swapInfo.slot,
          parLevel: formValue.newSlotConfig.parLevel,
          refillLevel: formValue.newSlotConfig.refillTarget,
          capacity: formValue.newSlotConfig.capacity,
          noPartialRefill: formValue.newSlotConfig.noPartialRefill,
          additional: {
            inventoryInfo: {
              importanceLevel: Number(formValue.newSlotConfig.priority)
            }
          }
        }
      }
    ]
  };
}
