// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Map--3scGkMeW_i{display:flex;flex-direction:column;flex:1;margin:0 -35px -20px -35px}@media screen and (max-width: 750px){.Map--3scGkMeW_i{margin:0 -20px -20px -20px}}.Map--3scGkMeW_i .machineCard--jeS5VsGwcA{width:340px}", ""]);
// Exports
exports.locals = {
	"Map": "Map--3scGkMeW_i",
	"machineCard": "machineCard--jeS5VsGwcA"
};
module.exports = exports;
