import * as React from "react";

import Icon from "common/components/icon/Icon";
import Typography from "../typography/Typography";
import Select from "../select/Select";
import { ToggleSwitch } from "../ToggleSwitch";

import { useGetOrganisations } from "userandauth/hooks/useGetOrganisations";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";
import { usePatchUserDetails } from "userandauth/hooks/usePatchUserDetails";

import * as styles from "./TopNav.module.scss";

function TopNav(): JSX.Element {
  const { data: userInfo } = useGetCurrentUser();
  const { data: allOrganisation } = useGetOrganisations();
  const { mutate: patchUser } = usePatchUserDetails();

  function onChangeUserDetails(value: string | boolean, key: string) {
    patchUser({
      userId: userInfo.userId,
      newUserData: {
        [key]: value,
      },
    });
  }

  return (
    <div className={styles.TopNav}>
      <div className={styles.button}>
        <div className={styles.iconContainer}>
          <Icon name="Notification" color="onSurfaceHigh" />
        </div>
      </div>
      <div className={styles.button}>
        <div className={styles.iconContainer}>
          <Icon name="Question" color="onSurfaceHigh" />
        </div>
      </div>
      {userInfo.canToggleViewAllMachines && (
        <div className={styles.ViewAllContainer}>
          <ToggleSwitch
            isOn={userInfo.canViewAllMachines}
            onClick={() =>
              onChangeUserDetails(
                !userInfo.canViewAllMachines,
                "canViewAllMachines"
              )
            }
          />
          <div className={styles.toggleButtonText}>
            <Typography translationKey="label_all_machine" type="subtitle-1" />
          </div>
        </div>
      )}

      <div className={styles.organisation}>
        <div className={styles.organisationPicture}>
          {userInfo.orgAvatarUrl && (
            <img className={styles.image} src={userInfo.orgAvatarUrl} />
          )}
        </div>
        <div className={styles.orgName}>
          {userInfo.canImpersonate ? (
            <Select
              renderStyle="nobox"
              className={styles.select}
              options={
                allOrganisation
                  ? allOrganisation
                      ?.map((eachOrg) => {
                        return { label: eachOrg.name, value: eachOrg.id };
                      })
                      .sort((a, b) => a.label.localeCompare(b.label))
                  : [{ label: userInfo?.orgName, value: userInfo?.orgId }]
              }
              value={userInfo?.orgId}
              onSelect={(value) =>
                onChangeUserDetails(value as string, "organisationId")
              }
            />
          ) : (
            <Typography type="body-1" text={userInfo.orgName} />
          )}
        </div>
      </div>
      <div className={styles.user}>
        <div className={styles.iconContainer}>
          <div className={styles.avatar}>
            <img src={userInfo.userAvatarUrl} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopNav;
