import * as React from "react";
import { useState } from "react";
import Icon from "common/components/icon/Icon";
import { Filters } from "react-table";
import Typography from "common/components/typography/Typography";
import Input from "common/components/input/Input";
import Checkbox from "common/components/checkbox/Checkbox";
import Button from "common/components/button/Button";
import { IUnionFilterConfig } from "./types";

import * as styles from "./Filter.module.scss";
import analytics from "utils/analytics";

interface FilterProps<
  D extends object,
  F extends Partial<Record<keyof D, IUnionFilterConfig>>,
> {
  filterConfig: F;
  setFilter: (columnId: string, updater: any) => void;
  setAllFilters: (
    updater: Filters<D> | ((filters: Filters<D>) => Filters<D>)
  ) => void;
  currentFilters: Filters<D>;
}

export default function Filter<
  D extends object,
  F extends Partial<Record<keyof D, IUnionFilterConfig>>,
>({
  filterConfig,
  setFilter,
  setAllFilters,
  currentFilters,
}: FilterProps<D, F>): JSX.Element {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [step, setStep] = useState<"menu" | "form">("form");
  const [colIdToFilter, setColIdToFilter] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const [checkedState, setCheckedState] = useState<Array<string>>([]);

  function handleFilterIconClick() {
    analytics.track({
      name: "table_filter_menu_icon_click",
      properties: {},
    });
    setIsOpen(!isOpen);
    setStep("menu");
  }

  function handleMenuOptionClick(colId: string) {
    analytics.track({
      name: "table_filter_menu_click",
      properties: {
        colId,
      },
    });
    setStep("form");
    setColIdToFilter(colId);

    if (filterConfig[colId].filterType === "contains") {
      setValue(currentFilters.filter((item) => item.id === colId)[0]?.value);
    } else if (filterConfig[colId].filterType === "checkbox") {
      setCheckedState(
        currentFilters.filter((item) => item.id === colId)[0]?.value || []
      );
    }
  }

  function handleBackClick() {
    analytics.track({
      name: "table_filter_menu_back_button_click",
      properties: {},
    });
    setStep("menu");
    setColIdToFilter("");
    setValue("");
    setCheckedState([]);
  }

  function handleCloseFilterPicker() {
    analytics.track({
      name: "table_filter_menu_close_button_click",
      properties: {},
    });
    setIsOpen(!isOpen);
    setStep("menu");
    setColIdToFilter("");
    setValue("");
    setCheckedState([]);
  }

  function handleCheckboxToggle(queryVal: string) {
    if (checkedState.includes(queryVal)) {
      setCheckedState(checkedState.filter((p) => p !== queryVal));
    } else {
      setCheckedState([...checkedState, queryVal]);
    }
  }

  function handleApplyFilter() {
    if (filterConfig[colIdToFilter].filterType === "contains") {
      setFilter(colIdToFilter, value);
    } else if (filterConfig[colIdToFilter].filterType === "checkbox") {
      if (checkedState.length !== 0) {
        setFilter(colIdToFilter, checkedState);
      }
    }
    analytics.track({
      name: "table_filter_apply_button_click",
      properties: {
        type: filterConfig[colIdToFilter].filterType,
        columnId: colIdToFilter,
        state: checkedState.join("-"),
        value,
      },
    });

    handleCloseFilterPicker();
  }

  function handleRemoveFilter(colId: string) {
    setAllFilters(currentFilters.filter((filter) => filter.id !== colId));
  }

  return (
    <div className={styles.Filter}>
      {/* open/close icon */}
      <div onClick={handleFilterIconClick} className={styles.Icon}>
        <Icon name="Filter" color={isOpen ? "primary" : "onSurfaceHigh"} />
      </div>

      {/* filter picker */}
      {isOpen && (
        <div className={styles.Picker}>
          {/* menu */}
          {step === "menu" &&
            Object.keys(filterConfig).map((colId) => (
              <div
                key={colId}
                className={styles.Options}
                onClick={() => handleMenuOptionClick(colId)}
              >
                <Typography
                  translationKey={filterConfig[colId].translationKey}
                  type="body-1"
                />
              </div>
            ))}

          {/* filter form */}
          {step === "form" && (
            <div className={styles.Form}>
              <div className={styles.Header}>
                <div className={styles.TitleWrapper}>
                  <div className={styles.Icon} onClick={handleBackClick}>
                    <Icon name="Chevron" color="surface" />
                  </div>
                  <Typography
                    translationKey={filterConfig[colIdToFilter].translationKey}
                    type="body-1"
                  />
                </div>
                <div className={styles.Icon} onClick={handleCloseFilterPicker}>
                  <Icon name="Close" color="surface" />
                </div>
              </div>

              <div className={styles.ValuePicker}>
                {filterConfig[colIdToFilter]?.filterType === "contains" && (
                  <Input
                    label="label_contains"
                    renderStyle="line"
                    value={value}
                    onChange={setValue}
                  />
                )}

                {filterConfig[colIdToFilter]?.filterType === "checkbox" &&
                  filterConfig[colIdToFilter].checkboxOptions.map((option) => {
                    const checked = checkedState.includes(option.queryParam);
                    return (
                      <div
                        key={option.queryParam}
                        className={styles.CheckboxWrapper}
                      >
                        <Checkbox
                          checked={checked}
                          onClick={() => {
                            analytics.track({
                              name: "table_filter_checkbox_click",
                              properties: {
                                name: option.title,
                                checked,
                              },
                            });
                            handleCheckboxToggle(option.queryParam);
                          }}
                        />
                        <Typography
                          translationKey={option.title}
                          type="body-1"
                        />
                      </div>
                    );
                  })}
              </div>

              <div className={styles.Footer}>
                <Button
                  translationKey="label_apply"
                  onClick={handleApplyFilter}
                  type="secondary"
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* filter values */}
      <div className={styles.FilterValues}>
        {currentFilters.map(
          (filter) =>
            filter.value.length > 0 && (
              <div key={filter?.id} className={styles.FilterValue}>
                <div>
                  <Typography
                    translationKey={
                      filterConfig[String(filter.id)].translationKey
                    }
                    type="body-1"
                  />
                  &nbsp;
                  <Typography translationKey={"label_contains"} type="body-1" />
                  &nbsp;
                  {[].concat(filter.value).map((text, idx) => (
                    <span key={text}>
                      {idx === 0 ? "" : ", "}
                      <Typography translationKey={text} type="body-1" />
                    </span>
                  ))}
                </div>
                <div
                  className={styles.Icon}
                  onClick={() => handleRemoveFilter(filter.id)}
                >
                  <Icon name="CloseFilled" color="onSurfaceMedium" />
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
}
