import { UNIXTimeStampTodayInMS } from 'common/utils/momentUtils';

const defaultParamsForGroupByNone = {
  groupBy: 'none',
  orderBy: 'time',
  sort: 'DESC',
  page: '1',
  limit: '1000'
};

const defaultParamsForGroupByProductName = {
  groupBy: 'productName',
  orderBy: 'productName',
  sort: 'ASC',
  page: '1',
  limit: '20'
};

const defaultParamsForGroupByTelephoneNumber = {
  groupBy: 'telephoneNumber',
  orderBy: 'telephoneNumber',
  sort: 'ASC',
  page: '1',
  limit: '20'
};

const defaultParamsForGroupByStatus = {
  groupBy: 'status',
  orderBy: 'status',
  sort: 'DESC'
};

const defaultParamsForGroupByMachineId = {
  groupBy: 'machineId',
  orderBy: 'machineId',
  sort: 'ASC'
};

const defaultParamsForGroupByDailyMachineSales = {
  groupBy: 'dailyMachineSales'
};

const defaultParamsForGroupByWeeklyMachineSales = {
  groupBy: 'weeklyMachineSales'
};

const defaultParamsForGroupByCodeId = {
  groupBy: 'codeId'
};

const defaultParamsForGroupByCodeType = {
  groupBy: 'codeType'
};

const defaultParamsForGroupByLocationId = {
  groupBy: 'locationId'
};

const defaultParamsForGroupByLocationType = {
  groupBy: 'locationType'
};

const defaultQueryParams = {
  none: defaultParamsForGroupByNone,
  productName: defaultParamsForGroupByProductName,
  telephoneNumber: defaultParamsForGroupByTelephoneNumber,
  status: defaultParamsForGroupByStatus,
  machineId: defaultParamsForGroupByMachineId,
  dailyMachineSales: defaultParamsForGroupByDailyMachineSales,
  weeklyMachineSales: defaultParamsForGroupByWeeklyMachineSales,
  codeId:defaultParamsForGroupByCodeId,
  codeType:defaultParamsForGroupByCodeType,
  locationId: defaultParamsForGroupByLocationId,
  locationType: defaultParamsForGroupByLocationType
};

function getDefaultQueryParamsForGroupBy(
  groupBy
) {
  switch (groupBy) {
    case 'none':
    case 'productName':
    case 'telephoneNumber':
    case 'status':
    case 'codeId':
    case 'codeType':
    case 'locationId':
    case 'locationType':
    case 'machineId':
      return {
        ...defaultQueryParams[groupBy],
        from: UNIXTimeStampTodayInMS({ timeOfDay: 'dayStart' }),
        to: UNIXTimeStampTodayInMS({ timeOfDay: 'dayEnd' })
      };
    case 'dailyMachineSales':
    case 'weeklyMachineSales':
      return {
        ...defaultQueryParams[groupBy]
      };
  }
}

export { getDefaultQueryParamsForGroupBy };
