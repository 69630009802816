// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Table--2mvaRy0BKh,.Table--2mvaRy0BKh *{box-sizing:border-box}.Table--2mvaRy0BKh{height:100%;min-width:min-content !important;border-radius:4px;background-color:#fff;display:flex;flex-direction:column}.TableHead--chkAWP960g{border-radius:inherit;border-bottom-left-radius:0;border-bottom-right-radius:0;border-bottom:1px solid rgba(0,0,0,.12);border-top:1px solid rgba(0,0,0,.12);background-color:#fff}.TableBody--245rsiigCH{display:block;overflow-y:auto;overflow-x:hidden;height:100%;width:100%}.Row--bGRC6NUJXd{min-height:45px;padding:0 10px;display:flex;justify-content:stretch;align-items:stretch}.Row--bGRC6NUJXd.Data--3VJmVze291{border-bottom:1px solid rgba(0,0,0,.12)}.Cell--l2LAIoo5jO{padding:0 8px;position:relative}.NoDataDisplay--1oPAynrjM2{color:#d7d7d7;padding-top:70px;display:flex;justify-content:center;align-items:center;gap:10px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Table": "Table--2mvaRy0BKh",
	"TableHead": "TableHead--chkAWP960g",
	"TableBody": "TableBody--245rsiigCH",
	"Row": "Row--bGRC6NUJXd",
	"Data": "Data--3VJmVze291",
	"Cell": "Cell--l2LAIoo5jO",
	"NoDataDisplay": "NoDataDisplay--1oPAynrjM2"
};
module.exports = exports;
