import {
  GetMaintenanceBoardCoffeeMaterialErrorDocument,
  GetMaintenanceBoardCoffeeMaterialErrorQuery,
  GetMaintenanceBoardCoffeeMaterialErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getCoffeeBeans({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardCoffeeMaterialErrorQuery,
    GetMaintenanceBoardCoffeeMaterialErrorQueryVariables
  >(GetMaintenanceBoardCoffeeMaterialErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
