import * as React from "react";

import Typography from "components/Typography";
import { ErrorBoardTabs } from "./ErrorBoardNav";
import { Route, Switch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "../../routes";
import { ServiceZoneDropDown } from "./ServiceZoneDropDown";
import { RefreshButton } from "./RefreshButton";
import { LastUpdatedIndicator } from "./LastUpdatedIndicator";
import { ErrorBoardProvider } from "./providers/ErrorBoardProvider";
import { InventoryView } from "./views/InventoryView";
import { TransactionsView } from "./views/TransactionsView";
import { MajorTroubleView } from "./views/MajorTroubleView";
import { ErrorOverviews } from "./ErrorOverviews";

import * as styles from "./ErrorBoard.module.scss";

export function ErrorBoard() {
  const { t } = useTranslation("errorBoard");

  return (
    <ErrorBoardProvider>
      <div className={styles.ErrorBoardContainer}>
        <div className={styles.HeadingContainer}>
          <Typography type="subtitle-1" color="onSurfaceMedium">
            {t("maintenance")}
          </Typography>
          <div style={{ width: "8px" }} />
          <Typography type="subtitle-1" color="onSurfaceMedium">
            {"/"}
          </Typography>
          <div style={{ width: "12px" }} />
          <Typography type="subtitle-1" color="primary500">
            {t("errorBoard")}
          </Typography>
        </div>
        <div className={styles.ServiceZoneAndRefreshButtonContainer}>
          <ServiceZoneDropDown />

          <div className={styles.RefreshButtonContainer}>
            <LastUpdatedIndicator />
            <RefreshButton />
          </div>
        </div>

        <div>
          <ErrorOverviews />
        </div>

        <div className={styles.ErrorBoard}>
          <div>
            <ErrorBoardTabs />
          </div>

          <div className={styles.SubNavAndTableContainer}>
            <Switch>
              <Route path={routes.majorTrouble.index()}>
                <MajorTroubleView />
              </Route>
              <Route path={routes.inventory.index()}>
                <InventoryView />
              </Route>
              <Route path={routes.transactions.index()}>
                <TransactionsView />
              </Route>
              <Route path="*">
                <MajorTroubleView />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </ErrorBoardProvider>
  );
}


