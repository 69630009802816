import { SortByFn } from "react-table";
import * as _ from "lodash";

const STATUS_MAP = {
  status_online: 1,
  status_maintenance_short: 2,
  status_offline: 3,
  status_out_of_service_short: 4,
  status_standby: 5,
};

export default function statusCompareFn<D extends object>(path: string) {
  const compare: SortByFn<D> = (rowA, rowB, columnId) => {
    const statusA = path.length
      ? _.get(rowA.original[String(columnId)], path)
      : rowA.original[String(columnId)];
    const statusB = path.length
      ? _.get(rowB.original[String(columnId)], path)
      : rowB.original[String(columnId)];

    return STATUS_MAP[statusA] > STATUS_MAP[statusB] ? 1 : -1;
  };

  return compare;
}
