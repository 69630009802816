import {
  GetMaintenanceBoardNoTransaction2HoursDocument,
  GetMaintenanceBoardNoTransaction2HoursQuery, GetMaintenanceBoardNoTransaction2HoursQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getNoTransaction2Hours({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransaction2HoursQuery,
    GetMaintenanceBoardNoTransaction2HoursQueryVariables
  >(GetMaintenanceBoardNoTransaction2HoursDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
