import * as React from "react";

export function LocationIdContainer({ children }: { children: React.ReactNode; }) {
  return (
    <div
      style={{
        margin: "-20px -35px",
        flex: "1 1 0",
        minHeight: "0px",
        backgroundColor: "white",
        overflow: "auto",
        padding: "20px 35px",
      }}
    >
      {children}
    </div>
  );
}
