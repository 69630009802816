// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { deleteMachineInventories } from 'machines/service';

// Toasts
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';

// Types
import { AxiosError } from 'axios';
import { Dispatch, AnyAction } from 'redux';

// Keys
import { machineKeys } from './machineKeys';
interface IUseDeleteMachineInventory {
  machineId: string;
  machineInventoryId: string;
}

function useDeleteMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, IUseDeleteMachineInventory>(
    ({ machineId, machineInventoryId }) =>
      deleteMachineInventories(machineId, machineInventoryId),
    {
      onSuccess: (_, { machineId }) => {
        addToast({
          type: 'success',
          text: 'toast_machine_delete_success'
        })(store.dispatch as Dispatch<AnyAction>);
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { useDeleteMachineInventory };