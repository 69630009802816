import * as React from "react";

import Typography from "components/Typography";

import * as styles from "./Note.module.scss";

import moment = require("moment");

interface INote {
  name: string;
  imgUrl: string | null;
  timestamp: string;
  type:
    | "created"
    | "in_progress"
    | "blocked"
    | "awaiting_approval"
    | "follow_up"
    | "closed";
  description: string;
}

export function Note({
  name,
  timestamp,
  imgUrl,
  type,
  description
}: INote): JSX.Element {
  return (
    <div className={styles.Note}>
      <div className={styles.Left}>
        <div className={styles.ImageWrapper}>
          <img src={imgUrl ?? '/images/favicon-192.png'}></img>
        </div>
      </div>
      <div className={styles.Right}>
        <div className={styles.NameAndDate}>
          <Typography type="subtitle-2" color="onSurfaceHigh" className={styles.Name}>
            {name}
          </Typography>
          <Typography
            type="body-3"
            color="onSurfaceDisabled"
            className={styles.Date}
          >
            {moment(Number(timestamp)).format("DD/MM/YY HH:mm")}
          </Typography>
        </div>
        <div className={styles.Type}>
          <Typography
            translate
            type="body-3"
            color={NOTE_TYPE_COLOR_LOOKUP[type]}
          >
            {NOTE_TYPE_TRANSLATION_LOOKUP[type]}
          </Typography>
        </div>
        <div className={styles.Description}>
          <Typography type="body-3">{description}</Typography>
        </div>
      </div>
    </div>
  );
}

const NOTE_TYPE_COLOR_LOOKUP: Record<Exclude<INote['type'], 'cancelled' | ''>, string> = {
  created: 'error',
  in_progress: 'warning',
  awaiting_approval: 'warning',
  blocked: 'error',
  closed: 'success',
  follow_up: 'success'
};

const NOTE_TYPE_TRANSLATION_LOOKUP: Record<INote['type'], `label_note_${INote['type']}`> = {
  created: 'label_note_created',
  in_progress: 'label_note_in_progress',
  awaiting_approval: 'label_note_awaiting_approval',
  blocked: 'label_note_blocked',
  follow_up: 'label_note_follow_up',
  closed: 'label_note_closed',
};
