// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".settings--2i74_V-Pye{display:flex;align-items:flex-start;background:#fff;box-shadow:0px 1px 4px rgba(0,0,0,.13);padding:40px}.leftContainer--1WbXpT1V8J{margin-right:20px}.sidebar--3YbFEqRF0b{margin-top:20px;min-width:200px}.option--Kda-w1He-Z{display:flex;border-top-right-radius:25px;border-bottom-right-radius:25px;align-items:center;margin-left:-40px;padding:10px 0 10px 40px;cursor:pointer}.option--Kda-w1He-Z:hover{background-color:#eeeef7}.option--Kda-w1He-Z>.iconContainer--1gDiXyw7bn{margin-right:10px;line-height:0}.option--Kda-w1He-Z>.iconContainer--1gDiXyw7bn>.organisationIcon--3gv3NXIeZx{margin-left:3px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"settings": "settings--2i74_V-Pye",
	"leftContainer": "leftContainer--1WbXpT1V8J",
	"sidebar": "sidebar--3YbFEqRF0b",
	"option": "option--Kda-w1He-Z",
	"iconContainer": "iconContainer--1gDiXyw7bn",
	"organisationIcon": "organisationIcon--3gv3NXIeZx"
};
module.exports = exports;
