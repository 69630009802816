

import { httpClient } from 'common/services/transportService';
import { ISwapOrder } from './getSwapOrders';

interface IPatchSwapOrderRecommendedToCreated {
  orderNumber: string,
  status: Extract<ISwapOrder['status'], "created">
  warehouseId: string,
  assignee: string,
  itemsRequested: Array<{
    id: string,
    productConfig: {
      uom: string,
      packageQuantity: number,
      packageType: string,
      packageSize: string,
      workInPackages: boolean
    }
  }>
}

interface IPatchSwapOrderOtherStatusChanges {
  orderNumber: string,
  status: Extract<ISwapOrder['status'], "completed" | "adjustment" | "cancelled" | "en_route" | "ready_for_collection" >
}

export type IPatchSwapOrder = IPatchSwapOrderRecommendedToCreated | IPatchSwapOrderOtherStatusChanges

export async function patchSwapOrder (patchSwapOrderData: IPatchSwapOrder): Promise<ISwapOrder> {
  const response = await httpClient.patch<ISwapOrder>('/swaporder', patchSwapOrderData);
  return response.data;
}