import {
  GetMaintenanceBoardOutOfServiceErrorDocument,
  GetMaintenanceBoardOutOfServiceErrorQuery,
  GetMaintenanceBoardOutOfServiceErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getOutOfServiceError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardOutOfServiceErrorQuery,
    GetMaintenanceBoardOutOfServiceErrorQueryVariables
  >(GetMaintenanceBoardOutOfServiceErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
