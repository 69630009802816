import { Dispatch } from 'redux';
import { keyBy } from 'lodash';

import {
  IMachineData,
  IMachineInventory,
  IMachineInventoryData,
  IMachineInventoryTemplate,
  IMachineStatusData,
  IMachineTransactionData
} from 'machines/interfaces';
import { IFilter } from 'common/interfaces';
import { serializeError } from 'common/services/errorService';

import { addToast } from 'common/store/actions/uiActions';

import * as machineService from 'machines/service';

export function createMachine (machineData: Partial<IMachineData>) {
  return async function (dispatch: Dispatch): Promise<IMachineData> {
    try {
      dispatch({ type: 'MACHINES/CREATE/REQUEST', payload: true });
      const createdMachine = await machineService.createMachine(machineData);
      dispatch({ type: 'MACHINES/CREATE/SUCCESS', payload: createdMachine });
      return createdMachine;
    } catch (error) {
      dispatch({ type: 'MACHINES/CREATE/ERROR', payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_create_error',
        raw: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: 'MACHINES/CREATE/REQUEST', payload: false });
    }
  };
}

export function deleteMachine (machineId: string) {
  return async function (dispatch: Dispatch): Promise<string> {
    try {
      dispatch({ type: 'MACHINES/DELETE/REQUEST', payload: true });
      const deletedMachineId = await machineService.deleteMachine(machineId);
      dispatch({ type: 'MACHINES/DELETE/SUCCESS', payload: deletedMachineId });
      addToast({
        type: 'success',
        text: 'toast_machine_delete_success'
      })(dispatch);
      return deletedMachineId;
    } catch (error) {
      dispatch({ type: 'MACHINES/DELETE/ERROR', payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_delete_error',
        raw: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: 'MACHINES/DELETE/REQUEST', payload: false });
    }
  };
}

export function patchMachine (machineId: string, machineData: Partial<IMachineData>) {
  return async function (dispatch: Dispatch): Promise<IMachineData> {
    try {
      dispatch({ type: `MACHINES/PATCH_${machineId}/REQUEST`, payload: true });
      const editedMachine = await machineService.patchMachine(machineId, machineData);
      dispatch({ type: `MACHINES/PATCH_${machineId}/SUCCESS`, payload: editedMachine });
      addToast({
        type: 'success',
        text: 'toast_machine_patch_success'
      })(dispatch);
      return editedMachine;
    } catch (error) {
      dispatch({ type: `MACHINES/PATCH_${machineId}/ERROR`, payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_patch_error',
        raw: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: `MACHINES/PATCH_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getMachine (machineId: string) {
  return async function (dispatch: Dispatch): Promise<IMachineData> {
    try {
      dispatch({ type: `MACHINES/GET_${machineId}/REQUEST`, payload: true });
      const machineData = await machineService.getMachine(machineId);
      dispatch({
        type: `MACHINES/GET_${machineId}/SUCCESS`,
        payload: {
          [machineId]: machineData
        }
      });
      return machineData;
    } catch (error) {
      dispatch({ type: `MACHINES/GET_${machineId}/ERROR`, payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_fetch_error'
      })(dispatch);
    } finally {
      dispatch({ type: `MACHINES/GET_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getMachines () {
  return async function (dispatch: Dispatch): Promise<IMachineData[]> {
    try {
      dispatch({ type: 'MACHINES/GET_ALL/REQUEST', payload: true });
      const machineData = await machineService.getMachines();
      dispatch({
        type: 'MACHINES/GET_ALL/SUCCESS',
        payload: keyBy(machineData, 'id')
      });
      return machineData;
    } catch (error) {
      dispatch({ type: 'MACHINES/GET_ALL/ERROR', payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_fetch_all_error'
      })(dispatch);
    } finally {
      dispatch({ type: 'MACHINES/GET_ALL/REQUEST', payload: false });
    }
  };
}

export function refillMachineInventories (machineId: string) {
  return async function (dispatch: Dispatch): Promise<IMachineInventoryData[]> {
    try {
      dispatch({ type: `MACHINE_INVENTORY/REFILL_${machineId}/REQUEST`, payload: true });
      const machineInventoryData = await machineService.refillMachineInventories(machineId);
      dispatch({
        type: `MACHINE_INVENTORY/REFILL_${machineId}/SUCCESS`,
        payload: {
          [machineId]: machineInventoryData
        }
      });
      return machineInventoryData;
    } catch (error) {
      dispatch({ type: `MACHINE_INVENTORY/REFILL_${machineId}/ERROR`, payload: serializeError(error) });
    } finally {
      dispatch({ type: `MACHINE_INVENTORY/REFILL_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getMachineInventoryTemplates (type: string) {
  return async function (dispatch: Dispatch): Promise<IMachineInventoryTemplate[]> {
    try {
      dispatch({ type: 'MACHINE_INVENTORY_TEMPLATES/GET_ALL/REQUEST', payload: true });
      const inventoryTemplates = await machineService.getMachineInventoryTemplates(type);
      dispatch({
        type: 'MACHINE_INVENTORY_TEMPLATES/GET_ALL/SUCCESS',
        payload: keyBy(inventoryTemplates, 'id')
      });
      return inventoryTemplates;
    } catch (error) {
      dispatch({ type: 'MACHINE_INVENTORY_TEMPLATES/GET_ALL/ERROR', payload: serializeError(error) });
    } finally {
      dispatch({ type: 'MACHINE_INVENTORY_TEMPLATES/GET_ALL/REQUEST', payload: false });
    }
  };
}

export function createMachineInventory (machineId: string, machineInventoryData: Array<Partial<IMachineInventory>>) {
  return async function (dispatch: Dispatch): Promise<IMachineInventory[]> {
    try {
      dispatch({ type: `MACHINE_INVENTORY/CREATE_${machineId}/REQUEST`, payload: true });
      const createdInventory = await machineService.createMachineInventory(machineId, machineInventoryData);
      dispatch({
        type: `MACHINE_INVENTORY/CREATE_${machineId}/SUCCESS`,
        payload: {
          machineId,
          inventories: createdInventory
        }
      });
      return createdInventory;
    } catch (error) {
      dispatch({ type: `MACHINE_INVENTORY/CREATE_${machineId}/ERROR`, payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_inventory_create_error',
        raw: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: `MACHINE_INVENTORY/CREATE_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function patchMachineInventories (machineId: string, machineInventories: Partial<IMachineInventoryData>[]) {
  return async function (dispatch: Dispatch): Promise<IMachineInventoryData[]> {
    try {
      dispatch({ type: `MACHINE_INVENTORY/PATCH_${machineId}/REQUEST`, payload: true });
      const updatedMachineInventoryData = await machineService.patchMachineInventories(machineId, machineInventories);
      dispatch({
        type: `MACHINE_INVENTORY/PATCH_${machineId}/SUCCESS`,
        payload: {
          machineId,
          updatedInventory: keyBy(updatedMachineInventoryData, 'id')
        }
      });
      return updatedMachineInventoryData;
    } catch (error) {
      dispatch({ type: `MACHINE_INVENTORY/PATCH_${machineId}/ERROR`, payload: serializeError(error) });
      addToast({
        type: 'error',
        text: 'toast_machine_inventory_update_error',
        raw: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: `MACHINE_INVENTORY/PATCH_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getMachineInventory (machineId: string) {
  return async function (dispatch: Dispatch): Promise<IMachineInventoryData[]> {
    try {
      dispatch({ type: `MACHINE_INVENTORY/GET_${machineId}/REQUEST`, payload: true });
      const machineInventoryData = await machineService.getMachineInventory(machineId);
      dispatch({
        type: `MACHINE_INVENTORY/GET_${machineId}/SUCCESS`,
        payload: {
          [machineId]: keyBy(machineInventoryData, 'id')
        }
      });
      return machineInventoryData;
    } catch (error) {
      dispatch({ type: `MACHINE_INVENTORY/GET_${machineId}/ERROR`, payload: serializeError(error) });
    } finally {
      dispatch({ type: `MACHINE_INVENTORY/GET_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function deleteMachineInventory (machineId: string, machineInventoryId: string) {
  return async function (dispatch: Dispatch): Promise<string> {
    try {
      dispatch({ type: `MACHINE_INVENTORY/DELETE_${machineId}/REQUEST`, payload: true });
      await machineService.deleteMachineInventories(machineId, machineInventoryId);
      dispatch({ type: `MACHINE_INVENTORY/DELETE_${machineId}_${machineInventoryId}/SUCCESS`, payload: { machineId, machineInventoryId } });
      return machineInventoryId;
    } catch (error) {
      addToast({
        type: 'error',
        text: serializeError(error)
      })(dispatch);
    } finally {
      dispatch({ type: `MACHINE_INVENTORY/DELETE_${machineId}_${machineInventoryId}/REQUEST`, payload: false });
    }
  };
}

export function getMachineStatus (machineId: string, filters?: IFilter) {
  return async function (dispatch: Dispatch): Promise<IMachineStatusData[]> {
    try {
      dispatch({ type: `MACHINE_STATUS/GET_${machineId}/REQUEST`, payload: true });
      const machineStatusData = await machineService.getMachineStatus(machineId, filters);
      dispatch({
        type: `MACHINE_STATUS/GET_${machineId}/SUCCESS`,
        payload: {
          [machineId]: keyBy(machineStatusData, 'time')
        }
      });
      return machineStatusData;
    } catch (error) {
      dispatch({ type: `MACHINE_STATUS/GET_${machineId}/ERROR`, payload: serializeError(error) });
    } finally {
      dispatch({ type: `MACHINE_STATUS/GET_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getMachineTransactions (machineId: string, filters?: IFilter) {
  return async function (dispatch: Dispatch): Promise<IMachineTransactionData[]> {
    try {
      dispatch({ type: `MACHINE_TRANSACTION/GET_${machineId}/REQUEST`, payload: true });
      const machineTransactionData = await machineService.getMachineTransactions(machineId, filters);
      dispatch({
        type: `MACHINE_TRANSACTION/GET_${machineId}/SUCCESS`,
        payload: {
          [machineId]: keyBy(machineTransactionData.results, 'time')
        }
      });
      return machineTransactionData.results;
    } catch (error) {
      dispatch({ type: `MACHINE_TRANSACTION/GET_${machineId}/ERROR`, payload: serializeError(error) });
    } finally {
      dispatch({ type: `MACHINE_TRANSACTION/GET_${machineId}/REQUEST`, payload: false });
    }
  };
}

export function getAllTransactions (filters?: IFilter) {
  return async function (dispatch: Dispatch): Promise<IMachineTransactionData[]> {
    try {
      dispatch({ type: 'MACHINE_TRANSACTION/GET_ALL/REQUEST', payload: true });
      const machineTransactions = await machineService.getAllTransactions(filters);
      const transactionsByMachine = {};
      for (const transaction of machineTransactions.results) {
        transactionsByMachine[transaction.machineId] = {
          ...transactionsByMachine[transaction.machineId],
          [transaction.time]: transaction
        };
      }
      dispatch({
        type: 'MACHINE_TRANSACTION/GET_ALL/SUCCESS',
        payload: transactionsByMachine
      });
      return machineTransactions.results;
    } catch (error) {
      dispatch({ type: 'MACHINE_TRANSACTION/GET_ALL/ERROR', payload: serializeError(error) });
    } finally {
      dispatch({ type: 'MACHINE_TRANSACTION/GET_ALL/REQUEST', payload: false });
    }
  };
}
