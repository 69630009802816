import { AxiosError } from 'axios';
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';
import { Dispatch, AnyAction } from 'redux';

import { useMutation, useQueryClient } from 'react-query';
import { inviteMachineUser } from 'machines/service';
import { machineKeys } from './machineKeys';
import { IMachineUser } from 'machines/interfaces';



function useInviteMachineUser (machineId:string) {
    const queryClient = useQueryClient();
    return useMutation<string, AxiosError, IMachineUser>(
        (newUserData:IMachineUser) => inviteMachineUser(newUserData),{
            onSuccess:() => {
                addToast({
                    type: 'success',
                    text: 'toast_user_add_success'
                })(store.dispatch as Dispatch<AnyAction>);
                queryClient.invalidateQueries(machineKeys.machineUsers(machineId));
            }
        }
    );
}

export { useInviteMachineUser };