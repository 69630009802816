/* eslint-disable react/jsx-key */
import * as React from "react";
import { useRef, useEffect } from "react";
import {
  useTable,
  useRowSelect,
  useSortBy,
  useFilters,
  UseTableOptions,
  useFlexLayout,
} from "react-table";
import { useVirtual } from "react-virtual";
import Filter from "./Filter/Filter";
import { useCheckbox } from "./hooks";
import Typography from "common/components/typography/Typography";
import Icon from "common/components/icon/Icon";
import { LoadingCell } from "./Cells";
import { IUnionFilterConfig } from "./Filter/types";

import * as styles from "./Table.module.scss";

const NUM_LOADING_ROWS = 20;

interface TableProps<
  D extends object,
  F extends Partial<Record<keyof D, IUnionFilterConfig>>
> extends UseTableOptions<D> {
  isLoading: boolean;
  filterConfig?: F;
  onRowSelect?: (rows: number[]) => void;
}

export default function Table<
  D extends object,
  F extends Partial<Record<keyof D, IUnionFilterConfig>>
>({
  data,
  isLoading,
  columns,
  initialState,
  filterConfig,
  onRowSelect,
}: TableProps<D, F>): JSX.Element {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    visibleColumns,
    rows,
    prepareRow,
    setFilter,
    setAllFilters,
    state,
  } = useTable<D>(
    {
      columns,
      data,
      initialState: initialState,
      autoResetFilters: false,
      autoResetSelectedRows: false,
      autoResetSortBy: false,
    },
    useFlexLayout,
    !!filterConfig && useFilters,
    useSortBy,
    !!onRowSelect && useRowSelect,
    !!onRowSelect && useCheckbox
  );

  const parentRef = useRef();

  const rowVirtualizer = useVirtual({
    size: rows.length,
    parentRef,
    overscan: 10,
  });

  useEffect(() => {
    return onRowSelect
      ? onRowSelect(Object.keys(state.selectedRowIds).map((id) => Number(id)))
      : null;
  }, [state.selectedRowIds]);

  return (
    <>
      <div className={styles.Table} {...getTableProps()}>
        {/* THEAD */}
        <div className={styles.TableHead}>
          {/* filter */}
          {!!filterConfig && (
            <Filter
              filterConfig={filterConfig}
              setFilter={setFilter}
              setAllFilters={setAllFilters}
              currentFilters={state.filters}
            />
          )}

          {/* header row */}
          {headerGroups.map((headerGroup) => (
            <div className={styles.Row} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <div
                  className={styles.Cell}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                </div>
              ))}
            </div>
          ))}
        </div>

        {/* TBODY */}
        <div
          {...getTableBodyProps()}
          className={styles.TableBody}
          ref={parentRef}
        >
          <div
            style={{
              height: isLoading ? undefined : `${rowVirtualizer.totalSize}px`,
              width: "100%",
              position: "relative",
            }}
          >
            {/* loading */}
            {isLoading &&
              Array(NUM_LOADING_ROWS)
                .fill("")
                .map((_i, idx) => (
                  <div key={idx} className={`${styles.Row} ${styles.Data}`}>
                    {visibleColumns.map((column, idx) => (
                      <div
                        key={idx}
                        className={styles.Cell}
                        style={{
                          flexGrow: Number(column.width),
                          flexBasis: "auto",
                          width: column.width,
                          minWidth: column.minWidth,
                        }}
                      >
                        <LoadingCell />
                      </div>
                    ))}
                  </div>
                ))}

            {/* loaded & no data */}
            {!isLoading && rows.length === 0 && (
              <div className={styles.NoDataDisplay}>
                <Icon name="Critical" color="disabled" />
                <Typography translationKey="label_no_data" type="body-2" />
              </div>
            )}

            {/* loaded & has data */}
            {!isLoading &&
              rows.length > 0 &&
              rowVirtualizer.virtualItems.map((virtualRow) => {
                const row = rows[virtualRow.index];
                prepareRow(row);

                return (
                  <div
                    className={`${styles.Row} ${styles.Data}`}
                    {...row.getRowProps()}
                    ref={virtualRow.measureRef}
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      transform: `translateY(${virtualRow.start}px)`,
                    }}
                  >
                    {row.cells.map((cell) => (
                      <div className={styles.Cell} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </div>
                    ))}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
