// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--3G12YVbc01{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--3G12YVbc01 .ButtonsContainer--31PwBT2SOx{width:100%;display:flex;gap:25px}.TableHeader--3G12YVbc01 .ButtonsContainer--31PwBT2SOx>*:last-child{margin-left:auto}@media screen and (max-width: 750px){.TableHeader--3G12YVbc01 .ButtonsContainer--31PwBT2SOx{flex-direction:column}.TableHeader--3G12YVbc01 .ButtonsContainer--31PwBT2SOx>*:last-child{margin-left:revert}}.TableContainer--3sZi98ZiJp{overflow:auto}.TableContainer--3sZi98ZiJp>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--3G12YVbc01",
	"ButtonsContainer": "ButtonsContainer--31PwBT2SOx",
	"TableContainer": "TableContainer--3sZi98ZiJp"
};
module.exports = exports;
