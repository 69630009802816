// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SnapshotTable--3GdO4pgMyO{max-width:70vw}@media screen and (max-width: 1200px){.SnapshotTable--3GdO4pgMyO{max-width:unset}}.SnapshotTable--3GdO4pgMyO .ColumnContainer--29utTyN2Q2{display:flex}.SnapshotTable--3GdO4pgMyO .ColumnContainer--29utTyN2Q2>*{flex:1 1 0;min-width:0;white-space:nowrap;text-overflow:ellipsis;overflow:hidden;margin:2px;padding:2px}.SnapshotTable--3GdO4pgMyO .ColumnContainer--29utTyN2Q2>:first-child{flex:2 1 0}", ""]);
// Exports
exports.locals = {
	"SnapshotTable": "SnapshotTable--3GdO4pgMyO",
	"ColumnContainer": "ColumnContainer--29utTyN2Q2"
};
module.exports = exports;
