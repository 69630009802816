import * as React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { routes } from "../../routes";
import { useErrorBoard } from "./hooks/useErrorBoard";

import * as styles from "./ErrorBoardNav.module.scss";

function sumObjectValues(obj: Record<string, number>) {
  return Object.values(obj).reduce((partialSum, a) => partialSum + a, 0);
}

export function ErrorBoardTabs() {
  const { t } = useTranslation('errorBoard');
  const { search } = useLocation();

  const { state } = useErrorBoard();
  const { summary } = state.data;

  const majorTroubleAmount = summary?.majorTrouble
    ? sumObjectValues(summary.majorTrouble)
    : 0;
  const materialsAmount = summary?.inventory
    ? sumObjectValues(summary.inventory)
    : 0;
  const transactionsAmount = summary?.transactions
    ? sumObjectValues(summary.transactions)
    : 0;

  return (
    <div className={styles.ErrorBoardNav}>
      <TabButton
        path={routes.majorTrouble.machineUnsellable() + search}
        activePath={routes.majorTrouble.index()}
        amount={majorTroubleAmount}
      >
        {t("majorTrouble")}
      </TabButton>
      <TabButton
        path={routes.inventory.whipper() + search}
        activePath={routes.inventory.index()}
        amount={materialsAmount}
      >
        {t("inventory")}
      </TabButton>
      <TabButton
        path={routes.transactions.aaNoTransaction30Mins() + search}
        activePath={routes.transactions.index()}
        amount={transactionsAmount}
      >
        {t("transactionTx")}
      </TabButton>
    </div>
  );
}

function TabButton({
  amount = 0,
  children,
  activePath,
  path,
}: {
  amount?: number;
  children: React.ReactNode;
  path: string;
  activePath: string;
}) {
  const match = useRouteMatch(activePath);
  return (
    <Link
      to={path}
      className={styles.NavLink}
      style={{
        backgroundColor: match ? "#2C2E78" : "white",
        color: match ? "white" : "#2C2E78",
      }}
    >
      {children}
      {amount > 0 ? (
        <span className={styles.Amount}>{amount > 99 ? "99+" : amount}</span>
      ) : null}
    </Link>
  );
}
