import * as React from "react";
import Icon from "common/components/icon/Icon";

import * as styles from "./Checkbox.module.scss";

interface CheckboxProps {
  checked: boolean;
  handleChecked: (e: React.ChangeEvent<Element>) => void;
}

export function Checkbox({
  checked,
  handleChecked,
}: CheckboxProps): JSX.Element {
  return (
    <div className={styles.CustomCheckbox}>
      <input type="checkbox" checked={checked} onChange={handleChecked} />
      <Icon name="Check" color="surface" className={styles.CheckMark} />
    </div>
  );
}
