import {
  GetMaintenanceBoardNoTransactionTodayDocument,
  GetMaintenanceBoardNoTransactionTodayQuery,
  GetMaintenanceBoardNoTransactionTodayQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getNoTransactionToday({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardNoTransactionTodayQuery,
    GetMaintenanceBoardNoTransactionTodayQueryVariables
  >(GetMaintenanceBoardNoTransactionTodayDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
