import * as React from "react";
import { Link, useRouteMatch } from "react-router-dom";
import * as styles from "./TabButton.module.scss";


export function TabButton({
  amount = 0, children, path, activePath,
}: {
  amount?: number;
  children: React.ReactNode;
  path: string;
  activePath: string;
}) {
  const match = useRouteMatch(activePath);
  return (
    <Link
      to={path}
      className={styles.TabButton}
      style={{
        fontWeight: match ? "500" : "300",
        color: match ? "#4B4D9B" : "#8688C5",
      }}
    >
      <div className={styles.ChildTextContainer}>
        <span aria-hidden={true} className={styles.OverlaidText}>
          {children}
        </span>
        <span className={styles.DisplayText}>{children}</span>
      </div>

      {match ? <div className={styles.HighlightedUnderline}></div> : null}
      {amount > 0 ? (
        <span className={styles.Amount}>{amount > 99 ? "99+" : amount}</span>
      ) : null}
    </Link>
  );
}
