import * as React from "react";
import { Indicator, IndicatorProps } from "../element";
import Tooltip from "@mui/material/Tooltip";
import { capitalizeFirstLetter } from "common/utils/format";
import { CellProps } from "react-table";
import { CellAlignmentProps } from "../types";

import * as styles from "./MultipleIndicatorCell.module.scss";

interface MultipleIndicatorCellProps extends CellAlignmentProps {
  indicatorType?: IndicatorProps["type"];
}

export type MultipleIndicatorCellValues = {
  name: string;
  color: IndicatorProps["color"];
}[];

export default function MultipleIndicatorCell<D extends object>({
  indicatorType = "circle",
  xAlign = "flex-start",
  yAlign = "center",
}: MultipleIndicatorCellProps = {}) {
  return function Componenet({
    value,
  }: CellProps<D, MultipleIndicatorCellValues>): JSX.Element {
    return (
      <div
        className={styles.MultipleIndicatorCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        <div className={styles.Row}>
          {value.map((each) => (
            <Tooltip
              disableFocusListener
              title={capitalizeFirstLetter(each.name)}
              key={each.name}
              placement="top"
            >
              <div className="fixMuiToolTipError">
                <Indicator color={each.color} type={indicatorType} />
              </div>
            </Tooltip>
          ))}
        </div>
      </div>
    );
  };
}
