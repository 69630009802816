// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dashboard--1X35nSiLoL{display:flex;flex-direction:column}.Dashboard--1X35nSiLoL .Header--2qdrNt9TEB{margin-bottom:20px}.Dashboard--1X35nSiLoL .CardContainer--2TNQyDveYu{display:flex;flex-wrap:wrap;gap:32px}@media screen and (max-width: 750px){.Dashboard--1X35nSiLoL .CardContainer--2TNQyDveYu{justify-content:center}}.Dashboard--1X35nSiLoL .CardContainer--2TNQyDveYu .Card--2OSAZ4nR3c{width:unset;flex-basis:360px}", ""]);
// Exports
exports.locals = {
	"Dashboard": "Dashboard--1X35nSiLoL",
	"Header": "Header--2qdrNt9TEB",
	"CardContainer": "CardContainer--2TNQyDveYu",
	"Card": "Card--2OSAZ4nR3c"
};
module.exports = exports;
