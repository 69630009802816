import * as React from 'react';

import { Bar } from 'react-chartjs-2';
import Typography from 'common/components/typography/Typography';

import * as styles from './Histogram.module.scss';

interface IHistogramProps {
  data: Array<[string, number]>;
  barStyles: {
    backgroundColor: string;
    borderColor: string;
  };
  scales: {
    xAxis: {
      label: string;
    };
  };
  className?: string;
}
export function Histogram ({ data, scales, barStyles, className = '' }: IHistogramProps): JSX.Element {
  return (
    <>
      <div className={[ styles.Histogram, className ].join(' ')}>
        <Bar
          data={{
            labels: data.map(([bin]) => bin),
            datasets: [
              {
                data: data.map(([ _, value ]) => value),
                borderColor: barStyles.borderColor,
                backgroundColor: barStyles.backgroundColor,
                borderRadius: 100,
                borderWidth: 1,
                borderSkipped: false,
                barPercentage: 1,
                categoryPercentage: 1
              }
            ]
          }}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              tooltip: {
                callbacks: {
                  title: () => "",
                  label: (ctx) =>
                    ctx.dataIndex !== data.length - 1
                      ? [
                          `${data[ctx.dataIndex][0]}-${
                            data[ctx.dataIndex + 1][0]
                          }`,
                          String(data[ctx.dataIndex][1]),
                        ]
                      : String(data[ctx.dataIndex][0]),
                },
                displayColors: false,
              },
              legend: {
                display: false,
              },
            },
            scales: {
              xAxis: {
                grid: {
                  display: true,
                  borderColor: '#9E9E9E',
                  borderWidth: 1,
                  offset: false
                },
                ticks: {
                  display: true,
                  maxRotation: 0,
                  minRotation: 0,
                  font: {
                    size: 10
                  }
                }
              },
              yAxis: {
                grid: {
                  display: false,
                  borderColor: '#9E9E9E',
                  borderWidth: 1
                },
                ticks: {
                  display: false
                }
              }
            }
          }} />
      </div>
      <Typography
        translationKey={scales.xAxis.label}
        type='body-1'
        className={styles.HistogramXAxisLabel} />
    </>
  );
}
