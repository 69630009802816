import {
  GetMaintenanceBoardMachineErrorDocument,
  GetMaintenanceBoardMachineErrorQuery,
  GetMaintenanceBoardMachineErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getMachineUnsellableError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return await fetcher<
    GetMaintenanceBoardMachineErrorQuery,
    GetMaintenanceBoardMachineErrorQueryVariables
  >(GetMaintenanceBoardMachineErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
