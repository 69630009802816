import {
  GetMaintenanceBoardOfflineErrorDocument,
  GetMaintenanceBoardOfflineErrorQuery,
  GetMaintenanceBoardOfflineErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getOfflineError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardOfflineErrorQuery,
    GetMaintenanceBoardOfflineErrorQueryVariables
  >(GetMaintenanceBoardOfflineErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
