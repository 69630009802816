// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SwapOrderForm--3prUustEpf{flex-direction:column;min-height:750px}.Header--Yd8oEXJX7p{margin-bottom:24px}.NewMaterialSelectComponent--292Dm4Oau8{margin-bottom:8px}.ReplacementMethodContainer--3nG2IiloLs{margin-bottom:12px}.ReplacementMethodHeaderContainer--1ImcLqE_DK{margin-bottom:4px}.RemoveOldRadioAndTextContainer--3Bf8uay-zK{display:flex;align-items:center}.RemoveOldLabel--1nCNSpT64W{margin-left:6px}.RefillOnTopRadioAndTextContainer--2v05mqs1cz{display:flex;align-items:center}.RefillOnTopLabel--3ZnCrQTuyF{margin-left:6px}.RefillOnTopLabel__disabled--38JP_R5DTM{color:#d7d7d7}.RemoveOldWhenContainer--2hP2NWguGz{margin-bottom:16px}.RemoveOldWhenHeaderContainer--3zx-KyEo5V{margin-bottom:4px}.RemoveOldWhenLowRadioAndTextContainer--1oCs1bY-MT{display:flex;align-items:center}.RemoveOldWhenLowLabel--3SFe8UePDA{margin-left:6px}.RemoveOldNowRadioAndTextContainer--lwUrt5DhBs{display:flex;align-items:center}.RemoveOldNowLabel--v4cCTMFPdv{margin-left:6px}.ParLevelInputComponent--3lIlzr23zV{margin-bottom:20px}.RefillLevelInputComponent--SBFjEIWyQl{margin-bottom:20px}.PrioritySelectComponent--wvkhrluk_J{margin-bottom:20px}.CapacityInputComponent--CNnYcjhueN{margin-bottom:11px}.NoPartialRefillContainer--NibRpOGwF2{display:flex;align-items:center;margin-bottom:20px}.NoPartialRefillLabel--2fSOkrlU-g{margin-left:8px}.ButtonsContainer--3-WlIYwNyX{display:flex;justify-content:space-between;margin-top:auto}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"SwapOrderForm": "SwapOrderForm--3prUustEpf",
	"Header": "Header--Yd8oEXJX7p",
	"NewMaterialSelectComponent": "NewMaterialSelectComponent--292Dm4Oau8",
	"ReplacementMethodContainer": "ReplacementMethodContainer--3nG2IiloLs",
	"ReplacementMethodHeaderContainer": "ReplacementMethodHeaderContainer--1ImcLqE_DK",
	"RemoveOldRadioAndTextContainer": "RemoveOldRadioAndTextContainer--3Bf8uay-zK",
	"RemoveOldLabel": "RemoveOldLabel--1nCNSpT64W",
	"RefillOnTopRadioAndTextContainer": "RefillOnTopRadioAndTextContainer--2v05mqs1cz",
	"RefillOnTopLabel": "RefillOnTopLabel--3ZnCrQTuyF",
	"RefillOnTopLabel__disabled": "RefillOnTopLabel__disabled--38JP_R5DTM",
	"RemoveOldWhenContainer": "RemoveOldWhenContainer--2hP2NWguGz",
	"RemoveOldWhenHeaderContainer": "RemoveOldWhenHeaderContainer--3zx-KyEo5V",
	"RemoveOldWhenLowRadioAndTextContainer": "RemoveOldWhenLowRadioAndTextContainer--1oCs1bY-MT",
	"RemoveOldWhenLowLabel": "RemoveOldWhenLowLabel--3SFe8UePDA",
	"RemoveOldNowRadioAndTextContainer": "RemoveOldNowRadioAndTextContainer--lwUrt5DhBs",
	"RemoveOldNowLabel": "RemoveOldNowLabel--v4cCTMFPdv",
	"ParLevelInputComponent": "ParLevelInputComponent--3lIlzr23zV",
	"RefillLevelInputComponent": "RefillLevelInputComponent--SBFjEIWyQl",
	"PrioritySelectComponent": "PrioritySelectComponent--wvkhrluk_J",
	"CapacityInputComponent": "CapacityInputComponent--CNnYcjhueN",
	"NoPartialRefillContainer": "NoPartialRefillContainer--NibRpOGwF2",
	"NoPartialRefillLabel": "NoPartialRefillLabel--2fSOkrlU-g",
	"ButtonsContainer": "ButtonsContainer--3-WlIYwNyX"
};
module.exports = exports;
