import * as React from 'react';
import { useEffect } from 'react';

import Typography from 'common/components/typography/Typography';
import Icon from 'common/components/icon/Icon';

import * as styles from './Toast.module.scss';

export interface IToast {
  id?: string,
  type?: 'success' | 'warning' | 'error',
  text: string,
  raw?: string
}
interface IToastProps extends IToast {
  open: boolean,
  onClose?: () => void,
  className?: string,
  icon?: string,
  position?: number
}

function Toast ({
  text,
  open,
  type = 'success',
  raw,
  icon,
  onClose,
  className,
  position
}: IToastProps): JSX.Element {
  const timeOut:number = 5000;
  const styleObj = { transform: `translate(-50%, ${position ? (position * 100) + (position * 10) : 0}%)` };

  useEffect(() => {
    setTimeout(function () {
      onClose();
    }, timeOut);
  }, []);
  return (
    <div
      className={[
        styles.Toast,
        open ? styles.open : '',
        styles[type],
        className
      ].join(' ')}
      style={styleObj}
    >
      <div className={styles.left}>
        {icon && (
          <Icon
            name={icon}
            color='surface'
          />
        )}

        <Typography
          type='body-1'
          translationKey={text}
          className={styles.text}
        />

        {raw && (
          <Typography
            type='body-1'
            text={raw}
            className={styles.text}
          />
        )}
      </div>

      {onClose && (
        <div
          className={styles.close}
          onClick={onClose}
        >
          <Icon
            name='Close'
            color='surface'
          />
        </div>
      )}
    </div>
  );
}

export default Toast;
