import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";

import config from "common/config";
import {
  accessTokenexpirationTimestamp,
  logout,
  refreshToken,
} from "userandauth/utilsAuth";
import { useAuthStore } from "userandauth/useAuthStore";

import { serializeError } from "./errorService";
import { toast } from "react-toastify";
import i18n from "common/i18n";
import { toasti18n } from "utils/toast";

export const httpClient: AxiosInstance = axios.create({
  baseURL: config.API_URL,
  timeout: 60000,
  transitional: {
    clarifyTimeoutError: true,
    silentJSONParsing: true,
    forcedJSONParsing: true,
  },
});

// const REFRESH_TOKEN_EXPIRATION = 15 * 60 * 1000;
const REFRESH_BEFORE_EXPIRE_MS = 15 * 1000;
let refreshTokenPromise = null;

httpClient.interceptors.request.use(
  async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (
      config.url === "/auth/refresh-token" ||
      config.url === "/auth/clean-cache"
    ) {
      return config;
    }

    if (
      useAuthStore.getState().isLoggedIn &&
      Date.now() - (accessTokenexpirationTimestamp - REFRESH_BEFORE_EXPIRE_MS) >
        0
    ) {
      if (refreshTokenPromise === null) {
        refreshTokenPromise = refreshToken();
        await refreshTokenPromise;
        refreshTokenPromise = null;
      } else {
        await refreshTokenPromise;
      }
      config.headers.common["Authorization"] =
        httpClient.defaults.headers.common["Authorization"];
      return config;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let logoutPromise = null;

httpClient.interceptors.response.use(
  function handleResponse(response: AxiosResponse): AxiosResponse {
    if (response.data === undefined) {
      console.warn(
        `${response.config.url} returning undefined with status ${response.status}`
      );
    }
    return response;
  },
  async function handleError(
    error: AxiosError
  ): Promise<AxiosError | undefined> {
    if (error.response?.status === 401) {
      if (logoutPromise === null && useAuthStore.getState().isLoggedIn) {
        logoutPromise = logout();
        await logoutPromise;
        logoutPromise = null;
      } else if (logoutPromise !== null && useAuthStore.getState().isLoggedIn) {
        await logoutPromise;
      }
      throw error;
    }

    if (error.config.url === "/auth/clean-cache") {
      return; // Clean-cache returns 400? - Need to prevent from failing
    }

    if (error.config.url === "/auth/refresh-token") {
      throw error;
    }

    if (error.response?.status === 500) {
      toast.error(i18n.t("toast_500_error"));
    }
    if (error.response?.status === 403 && error.config.url !== '/auth/query-permissions') {
      toast.error(i18n.t("toast_unauthorized"));
    }
    if (error.response?.status === 404) {
      toast.error(i18n.t("toast_404_error"));
    }

    if (error.response?.status === 409) {
      toast.error([
        i18n.t("toast_409_error"),
        serializeError(error) ?? ""
      ].join(" "));
    }

    if (error.response?.status === 429) {
      toast.error(i18n.t("toast_too_many_requests"));
    }

    if (
      error.response?.status === 400 &&
      error.config.url !== "/auth/refresh-token"
    ) {
      toast.error(
        [i18n.t("toast_400_error"), serializeError(error) ?? ""].join(" ")
      );
    }

    if (error.code === "ETIMEDOUT") {
      toasti18n.error({ message: `${error.config.url} Timeout` });
    }

    if (error.code === "ECONNABORTED") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }

    error.message = `URL: ${error.config.url} STATUS: ${
      error.response?.status
    } MESSAGE: ${serializeError(error)}`;

    throw error;
  }
);
