import { useQuery } from "react-query";
import { getSwapOrders } from "services/swaporders";
import { swapOrderKeys } from "./swapOrderKeys";

function useGetSwapOrders({enabled = true} = {}){
  return useQuery(
    swapOrderKeys.all()
    ,
    () => getSwapOrders()
    ,{
      enabled: enabled,
      staleTime: 180 * 1000 // Stay fresh for three minutes
  });
}

export { useGetSwapOrders };