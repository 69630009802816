import * as React from 'react';
import * as moment from 'moment';
import { useState } from 'react';
import MuiModal from 'components/Modal/MuiModal';

import Typography from 'components/Typography';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { IGetHealthReportCSV } from 'machines/interfaces';
import { getHealthReportCSV } from 'machines/service';

import i18n from 'common/i18n';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';

import * as styles from './DownloadHealthModal.module.scss';

interface IDownloadHealthModalProps {
  open: boolean;
  onClose: () => void;
  selectedMachine: Array<string>;
}

function DownloadHealthModal({
  open,
  onClose,
  selectedMachine,
}: IDownloadHealthModalProps): JSX.Element {
  const [startTime, setStartTime] = useState<moment.Moment | null>(
    moment().startOf('day')
  );
  const [endTime, setEndTime] = useState<moment.Moment | null>(moment());
  const [inputError, setInputError] = useState<boolean>(false);

  async function downloadHealthReportCSV({
    from,
    to,
    machineId,
  }: IGetHealthReportCSV) {
    const file = await getHealthReportCSV({
      from,
      to,
      machineId,
    });

    const CSVFile = new File(['\ufeff' + file], 'health report.csv', {
      type: 'text/csv',
    });

    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = 'health report.csv';
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  }

  function setTimes(value: moment.Moment, type: string) {
    if (value.isValid()) {
      setInputError(false);
    }
    type === 'start' && setStartTime(value);
    type === 'end' && setEndTime(value);
  }

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      minWidth="416px"
      minHeight="286px"
      padding="24px 32px"
    >
      <>
        <div>
          <Typography translate type="headline-6">
            action_download_machine_health
          </Typography>
        </div>

        <div className={styles.MachineContainer}>
          <div className={styles.DateTimeContainer}>
            <div className={styles.widthContainer}>
              <DateTimePicker
                label="label_time_from"
                value={startTime}
                onChange={(newValue) => {
                  setTimes(newValue, 'start');
                }}
                maxDate={moment(Date.now())}
              />
            </div>
            <div className={styles.widthContainer}>
              <DateTimePicker
                label="label_time_to"
                value={endTime}
                onChange={(newValue) => {
                  setTimes(newValue, 'end');
                }}
                maxDate={moment(Date.now())}
              />
            </div>
          </div>
          {(endTime?.isBefore(startTime) || inputError) && (
            <Typography translate type="body-1" className={styles.Warning}>
              label_please_enter_valid_time
            </Typography>
          )}
        </div>

        <div className={styles.ButtonContainer}>
          <Button type="secondary" onClick={onClose}>
            {'action_cancel'}
          </Button>
          <Button
            onClick={async () => {
              const loadingToast = toast.loading(i18n.t('label_loading'));
              try {
                await downloadHealthReportCSV({
                  from: startTime,
                  to: endTime,
                  machineId: selectedMachine,
                });
                toast.update(loadingToast, {
                  type: 'success',
                  isLoading: false,
                  render: i18n.t('toast_request_successful'),
                  autoClose: 5000,
                });
              } catch (e) {
                toast.update(loadingToast, {
                  type: 'error',
                  isLoading: false,
                  render: i18n.t('toast_request_failed'),
                  autoClose: 5000,
                });
              }
              onClose();
            }}
            className={styles.download}
            type="primary"
            disabled={
              startTime === null ||
              endTime === null ||
              endTime.isBefore(startTime) ||
              inputError
            }
          >
            {'action_download'}
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export { DownloadHealthModal };
