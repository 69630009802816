import * as React from 'react';
import { Redirect, Switch, Route } from 'react-router';

import { MachineTicket } from './MachineTicket';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsOperator,
  useIsRouteman,
  useIsTechnician,
} from 'utils/user-role';

export function Service(): JSX.Element {
  const canViewContent =
    useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsClerk() || useIsTechnician() || useIsRouteman();

  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  return (
    <Switch>
      <Route path="/service/machine-ticket">
        <MachineTicket />
      </Route>
      <Redirect to="/service/machine-ticket" />
    </Switch>
  );
}
