/* eslint-disable react/display-name */
import * as React from "react";

import * as moment from "moment";

import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import Checkbox from "components/Checkbox";
import Typography from "components/Typography";
import Icon from "common/components/icon/Icon";

import {
  Container,
  Header,
  OperationTable,
  TextButtonsContainer,
} from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";

import { useIdSelection } from "hooks/useIdSelection";
import { useSortState } from "hooks/useSortState";
import {
  GetAdjustedPlansQuery,
  GetCompletedPlansQuery,
  MachineKind,
  PlanStatus,
  useArchivePlansMutation,
  useGetAdjustedPlansQuery,
  useGetCompletedPlansQuery,
} from "gql/generated";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { DownloadOperationPDFButton } from "../operation-pdf/DownloadOperationPDFButton";
import { AdjustmentModal } from "./modals/AdjustmentModal/AdjustmentModal";
import { useQueryClient } from "react-query";
import Button from "components/Button";
import { toasti18n } from "utils/toast";
import TextButton from "common/components/textbutton/TextButton";
import DownloadMaterialMovementReportModal from "./DownloadMaterialMovementReportModal";
import { ArrayElement } from "types/utils";
import DownloadMaterialMovementReportByRefillZoneModal from "./DownloadMaterialMovementReportByRefillZoneModal";
import { Modal } from "@mui/material";
import { useDownloadInventorySummaryByPlanId } from "containers/new-operations/hooks";
import { CompletedModal } from "./modals/CompletedModal/CompletedModal";
import { useTranslation } from "react-i18next";
import analytics from "utils/analytics";

export default function Complete() {
  const { t } = useTranslation();
  const { isLoading: isAdjustedPlansLoading, data: adjustedPlansQuery } =
    useGetAdjustedPlansQuery({
      status: [PlanStatus.Adjusted],
    });
  const { isLoading: isCompletedPlansLoading, data: completedPlansQuery } =
    useGetCompletedPlansQuery({
      status: [PlanStatus.Completed],
    });

  const isLoading = isCompletedPlansLoading || isAdjustedPlansLoading;

  const adjustedPlans = React.useMemo(
    () =>
      isAdjustedPlansLoading
        ? undefined
        : mapAdjustedPlansToTable(adjustedPlansQuery),
    [adjustedPlansQuery]
  );
  const completedPlans = React.useMemo(
    () =>
      isCompletedPlansLoading
        ? undefined
        : mapCompletedPlansToTable(completedPlansQuery),
    [completedPlansQuery]
  );
  const plans = React.useMemo(
    () =>
      isLoading
        ? undefined
        : [...(adjustedPlans ?? []), ...(completedPlans ?? [])],
    [adjustedPlans, completedPlans]
  );
  const completedPlanIds = React.useMemo(
    () => completedPlans?.map((plan) => plan.planId) ?? [],
    [completedPlans]
  );
  const adjustedPlanIds = React.useMemo(
    () => adjustedPlans?.map((plan) => plan.planId) ?? [],
    [adjustedPlans]
  );

  const allPlanIds = React.useMemo(
    () => [...(completedPlanIds ?? []), ...(adjustedPlanIds ?? [])],
    [completedPlanIds, adjustedPlanIds]
  );

  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredPlans = React.useMemo(
    () =>
      appliedFilter
        ? plans?.filter((plan) => {
            switch (appliedFilter.field) {
              case columns.planName:
                return plan.planName
                  .toLowerCase()
                  .includes(appliedFilter.value.toLowerCase());
              case columns.warehouse:
                return plan.warehouseName
                  .toLowerCase()
                  .includes(appliedFilter.value.toLowerCase());

              case columns.completeDate:
                return plan.completeDate
                  ? moment(plan.completeDate).format("L") ===
                      moment(Number(appliedFilter.value)).format("L")
                  : false;
              default:
                return true;
            }
          })
        : plans,
    [plans, appliedFilter]
  );
  const filteredPlanIds = React.useMemo(
    () => filteredPlans?.map((plan) => plan.planId),
    [filteredPlans]
  );

  const {
    selectionStatus,
    isIdSelected,
    toggleId,
    clearSelectionInView,
    selectAllInView,
    selectedIds,
  } = useIdSelection(allPlanIds, filteredPlanIds);

  const selectedCompletedPlanIds = React.useMemo(
    () => selectedIds.filter((id) => completedPlanIds.includes(id)),
    [selectedIds, completedPlanIds]
  );
  const selectedAdjustedPlanIds = React.useMemo(
    () => selectedIds.filter((id) => adjustedPlanIds.includes(id)),
    [selectedIds, adjustedPlanIds]
  );

  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({
      columnId: columns.planName,
      direction: "ASC",
    });

  const sortedPlans = React.useMemo(
    () =>
      sortState
        ? filteredPlans
            ?.map((elem) => elem)
            ?.sort((planA, planB) => {
              return (
                sortFnMap[sortState.columnId](planA, planB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredPlans,
    [filteredPlans, sortState]
  );

  const { isLoading: isDownloadingReport, downloadInventorySummaryByPlanId } =
    useDownloadInventorySummaryByPlanId();

  const [planIdForAdjustmentModal, setPlanIdForAdjustmentModal] =
    React.useState<string | null>(null);
  const [planIdForCompletedModal, setPlanIdForCompletedModal] = React.useState<
    string | null
  >(null);

  const [isArchiveModalOpen, setIsArchiveModalOpen] =
    React.useState<boolean>(false);

  const queryClient = useQueryClient();

  const [
    isDownloadMaterialMovementReportOpen,
    setIsDownloadMaterialMovementReportOpen,
  ] = React.useState<boolean>(false);

  const [
    isDownloadMaterialMovementReportฺByRefillZoneOpen,
    setIsDownloadMaterialMovementReportByRefillZoneOpen,
  ] = React.useState<boolean>(false);

  const { mutate, isLoading: isArchivingPlans } = useArchivePlansMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      toasti18n.success();
      queryClient.setQueryData<GetCompletedPlansQuery>(
        ["GetCompletedPlans", { status: ["COMPLETED"] }],
        (cachedData) => {
          return {
            plans: cachedData.plans.filter(
              (plan) => !selectedCompletedPlanIds.includes(plan.id)
            ),
          };
        }
      );
    },
  });

  return (
    <>
      {isArchiveModalOpen ? (
        <ArchiveModal
          onClose={() => setIsArchiveModalOpen(false)}
          planIds={selectedCompletedPlanIds}
          onArchiveSuccess={() => {
            queryClient.setQueryData<GetCompletedPlansQuery>(
              ["GetCompletedPlans", { status: ["COMPLETED"] }],
              (cachedData) => {
                return {
                  plans: cachedData.plans.filter(
                    (plan) => !selectedCompletedPlanIds.includes(plan.id)
                  ),
                };
              }
            );
          }}
        />
      ) : null}
      {isDownloadMaterialMovementReportOpen ? (
        <DownloadMaterialMovementReportModal
          onCancel={() => setIsDownloadMaterialMovementReportOpen(false)}
        />
      ) : null}
      {planIdForAdjustmentModal ? (
        <AdjustmentModal
          planId={planIdForAdjustmentModal}
          onAdjustmentSuccess={({ adjustedPlan, variables }) => {
            const plan = adjustedPlans?.find(
              (plan) => plan.planId === planIdForAdjustmentModal
            );
            let InventoriesData = [];
            if (variables.inventories instanceof Array) {
              InventoriesData = variables.inventories.map((inventory) => {
                const packageInfo = adjustedPlan?.packages?.find(p => p.packageInfo.product.id === inventory?.productId);
                return ({
                  productId: inventory.productId,
                  name: packageInfo?.packageInfo?.product?.id,
                  package_take: packageInfo?.refillInfo?.productPackedInPackages,
                  unit_take: packageInfo?.refillInfo?.productPackedInProductUOMInPackage,
                  package_return: inventory?.actualBag,
                  unit_return: inventory?.actualRemaining,
                  unit: packageInfo.packageInfo.productUOMInPackage,
                });
              });
            }
            analytics.track({
              name: "plan return submitted",
              properties: {
                Inventories_data: InventoriesData,
                plan_name: plan?.planName,
                plan_id: plan?.planId,
                warehouse: plan?.warehouseName,
                no_of_completed_machine: plan?.taoBinCompleted + plan?.spiralCompleted,
                no_of_machine_in_plan: plan?.taoBinInPlan + plan?.spiralInPlan,
                // spiral machine
                no_of_completed_spiral_machine: plan?.spiralCompleted,
                no_of_spiral_machine_in_plan: plan?.spiralInPlan,
                // taobin machine
                no_of_completed_taobin_machine: plan?.taoBinCompleted,
                no_of_taobin_machine_in_plan: plan?.taoBinInPlan,
              },
            });
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            queryClient.invalidateQueries(["GetAdjustedPlans"]);
            queryClient.invalidateQueries(["GetCompletedPlans"]);
          }}
          onClose={() => setPlanIdForAdjustmentModal(null)}
        />
      ) : null}
      {planIdForCompletedModal ? (
        <CompletedModal
          planId={planIdForCompletedModal}
          onClose={() => setPlanIdForCompletedModal(null)}
          planData={completedPlans?.find(
            (plan) => plan.planId === planIdForCompletedModal
          )}
        />
      ) : null}
      {isDownloadMaterialMovementReportฺByRefillZoneOpen ? (
        <DownloadMaterialMovementReportByRefillZoneModal
          onCancel={() =>
            setIsDownloadMaterialMovementReportByRefillZoneOpen(false)
          }
        />
      ) : null}
      <Container>
        <Header>
          <TextButtonsContainer>
            <TextButton
              disabled={
                selectedCompletedPlanIds.length === 0 || isArchivingPlans
              }
              icon="CheckedClipboard"
              translationKey="label_archive_plans"
              onClick={() => {
                if (selectedAdjustedPlanIds.length !== 0) {
                  setIsArchiveModalOpen(true);
                } else {
                  mutate({ archivePlansId: selectedCompletedPlanIds });
                }
              }}
            />
            <TextButton
              icon="DownloadTray"
              translationKey="label_download_material_movement"
              onClick={() => setIsDownloadMaterialMovementReportOpen(true)}
            />
            <TextButton
              icon="DownloadTray"
              translationKey="label_download_material_movement_by_refill_zone"
              onClick={() =>
                setIsDownloadMaterialMovementReportByRefillZoneOpen(true)
              }
            />
          </TextButtonsContainer>

          <Typography type="body-2" translate>
            {selectedIds.length}
            {` `}
            {selectedIds.length === 1 ? "label_plan" : "label_plans"}
            {` `}
            label_selected
          </Typography>
        </Header>

        <PureFilterBar
          placeholder="label_search"
          filterOptions={[
            {
              field: columns.planName,
              queryType: "contains",
              translationKey: "label_plan",
            },
            {
              field: columns.warehouse,
              queryType: "contains",
              translationKey: "label_warehouse",
            },
            {
              field: columns.completeDate,
              queryType: "date",
              translationKey: "label_complete_date",
            },
          ]}
          onChange={setAppliedFilter}
          value={appliedFilter}
        />

        <OperationTable
          data={sortedPlans}
          columns={[
            {
              cell: (plan) => (
                <Checkbox
                  checked={isIdSelected(plan.planId)}
                  onClick={() => {
                    toggleId(plan.planId);
                  }}
                />
              ),
              headerCell: () => (
                <Checkbox
                  onClick={() => {
                    if (selectionStatus === "ALL") {
                      clearSelectionInView();
                    } else {
                      selectAllInView();
                    }
                  }}
                  checked={selectionStatus === "ALL"}
                  indeterminate={selectionStatus === "PARTIAL"}
                />
              ),
              id: columns.checkbox,
              style: {
                flex: "0 0 42px",
                minWidth: "42px",
                paddingRight: "var(--table-h-padding)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            },
            {
              id: columns.planName,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <div
                  style={{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: 400,
                  }}
                >
                  {plan.planName}
                </div>
              ),
              style: {
                flex: "1 0 235px",
                minWidth: "235px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.taobinMachinesInPlan,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_taobin_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.taoBinInPlan}
                </Typography>
              ),
              style: {
                flex: "1 0 120px",
                minWidth: "120px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.spiralMachinesInPlan,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_spiral_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.spiralInPlan}
                </Typography>
              ),
              style: {
                flex: "1 0 120px",
                minWidth: "120px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.completed,
              headerCell: () => (
                <div className="flex-1 text-body2 text-brainstorming-black-tint-300 font-kanit px-2">
                  <p className="">{t("label_complete")}</p>
                  <div className="flex">
                    <p className="flex-1">{t("label_operation_complete_taobin_machine")}</p>
                    <p className="flex-1">{t("label_operation_complete_spiral_machine")}</p>
                  </div>
                </div>
              ),
              cell: (plan) => (
                <div className="flex flex-1 px-2">
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.taoBinCompleted}
                  </Typography>
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.spiralCompleted}
                  </Typography>
                </div>
              ),
              style: {
                flex: "0 0 128px",
                minWidth: "128px",
                display: "flex",
                alignItems: "center",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderColor: "#0000001f",
                margin: "-4px 0"
              },
            },
            {
              id: columns.adjusted,
              headerCell: () => (
                <div className="flex-1 text-body2 text-brainstorming-black-tint-300 font-kanit px-2">
                  <p className="">{t("label_adjusted")}</p>
                  <div className="flex">
                    <p className="flex-1">{t("label_operation_complete_taobin_machine")}</p>
                    <p className="flex-1">{t("label_operation_complete_spiral_machine")}</p>
                  </div>
                </div>
              ),
              cell: (plan) => (
                <div className="flex flex-1 px-2">
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.taoBinAdjusted}
                  </Typography>
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.spiralAdjusted}
                  </Typography>
                </div>
              ),
              style: {
                flex: "0 0 128px",
                minWidth: "128px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.incomplete,
              headerCell: () => (
                <div className="flex-1 text-body2 text-brainstorming-black-tint-300 font-kanit px-2">
                  <p className="">{t("label_incomplete")}</p>
                  <div className="flex">
                    <p className="flex-1">{t("label_operation_complete_taobin_machine")}</p>
                    <p className="flex-1">{t("label_operation_complete_spiral_machine")}</p>
                  </div>
                </div>
              ),
              // fix this
              cell: (plan) => (
                <div className="flex flex-1 px-2">
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.taoBinIncomplete}
                  </Typography>
                  <Typography className="flex-1" type="body-2" color="onSurfaceHigh">
                    {plan.spiralIncomplete}
                  </Typography>
                </div>
              ),
              style: {
                flex: "0 0 128px",
                minWidth: "128px",
                display: "flex",
                alignItems: "center",
                borderLeftWidth: "1px",
                borderRightWidth: "1px",
                borderColor: "#0000001f",
                margin: "-4px 0",
                marginRight: "8px"
              },
            },
            {
              id: columns.completeDate,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_complete_date")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.completeDate
                    ? moment(plan.completeDate).format("DD/MM/YYYY")
                    : "-"}
                </Typography>
              ),
              style: {
                flex: "1 0 150x",
                minWidth: "150px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.warehouse,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_warehouse")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.warehouseName}
                </Typography>
              ),
              style: {
                flex: "1 0 154px",
                minWidth: "154px",
                display: "flex",
                alignItems: "center",
              },
            },

            {
              id: columns.download,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  action_download
                </Typography>
              ),
              cell: (plan) => (
                <DownloadOperationPDFButton planId={plan.planId} />
              ),
              style: {
                flex: "0 0 78px",
                minWidth: "78px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.inventorySummary,
              headerCell: () => (
                <Typography
                  type="body-2"
                  translate
                  color="brainStormingBlackTint300"
                >
                  label_inventory_summary
                </Typography>
              ),
              cell: (plan) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: "40px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "grid",
                        placeContent: "center",
                      }}
                      onClick={
                        isDownloadingReport
                          ? () => {}
                          : () =>
                              downloadInventorySummaryByPlanId(
                                plan.planId,
                                plan.planName
                              )
                      }
                    >
                      <Icon name="DownloadTray" color="primary500" />
                    </div>

                    {plan.status === "adjustment" ? (
                      <Button
                        type="primary"
                        onClick={() => setPlanIdForAdjustmentModal(plan.planId)}
                        style={{
                          lineHeight: 1.05,
                          minWidth: 87,
                        }}
                      >
                        label_adjust
                      </Button>
                    ) : (
                      <Button
                        style={{
                          lineHeight: 1.05,
                          minWidth: 87,
                        }}
                        type="secondary"
                        onClick={() => {
                          setPlanIdForCompletedModal(plan.planId);
                          analytics.track({
                            name: "plan return view button clicked",
                            properties: {
                              plan_name: plan.planName,
                              plan_id: plan.planId,
                              warehouse: plan.warehouseName,
                              no_of_completed_machine: plan?.taoBinCompleted + plan?.spiralCompleted,
                              no_of_machine_in_plan: plan?.taoBinInPlan + plan?.spiralInPlan,
                              // spiral machine
                              no_of_completed_spiral_machine: plan?.spiralCompleted,
                              no_of_spiral_machine_in_plan: plan?.spiralInPlan,
                              // taobin machine
                              no_of_completed_taobin_machine: plan?.taoBinCompleted,
                              no_of_taobin_machine_in_plan: plan?.taoBinInPlan,
                            }
                          });
                        }}
                      >
                        label_view
                      </Button>
                    )}
                  </div>
                );
              },
              style: {
                flex: "0 0 186px",
                minWidth: "186px",
                display: "flex",
                alignItems: "center",
              },
            },
          ]}
          isLoading={isLoading}
          getKey={(plan) => plan.planId}
          isSelected={(plan) => isIdSelected(plan.planId)}
        />
      </Container>
    </>
  );
}

function ArchiveModal({
  onClose,
  planIds,
  onArchiveSuccess,
}: {
  onClose: () => void;
  planIds: string[];
  onArchiveSuccess: () => void;
}) {
  const { mutate, isLoading } = useArchivePlansMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      onArchiveSuccess();
      onClose();
      toasti18n.success();
    },
  });

  return (
    <Modal open={true} onClose={isLoading ? undefined : onClose}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          pointerEvents: "none",
          height: "100%",
        }}
      >
        <div
          style={{
            backgroundColor: "white",
            pointerEvents: "auto",
            padding: "25px",
            width: "340px",
            maxWidth: "100%",
            borderRadius: "3px",
          }}
        >
          <Typography type="headline-6" translate>
            label_archive_plans
          </Typography>
          <div>
            <Typography type="body-2" translate>
              label_adjusted_plans_will_not_be_archived
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button onClick={onClose} type="secondary" disabled={isLoading}>
              action_cancel
            </Button>
            <Button
              onClick={() => mutate({ archivePlansId: planIds })}
              type="primary"
              loading={isLoading}
            >
              action_confirm
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export interface TableRow {
  planId: string;
  planName: string;
  taoBinCompleted: number;
  taoBinAdjusted: number;
  taoBinIncomplete: number;
  taoBinInPlan: number;
  spiralCompleted: number;
  spiralAdjusted: number;
  spiralIncomplete: number;
  spiralInPlan: number;
  completeDate: string;
  warehouseName: string;
  status: "completed" | "adjustment";
}
function mapAdjustedPlansToTable(query: GetAdjustedPlansQuery): TableRow[] {
  const plans = query.plans;
  return plans.map((plan) => ({
    ...mapPlanToCommonRows(plan),
    status: "adjustment",
  }));
}
function mapCompletedPlansToTable(query: GetCompletedPlansQuery): TableRow[] {
  const plans = query.plans;
  return plans.map((plan) => ({
    ...mapPlanToCommonRows(plan),
    status: "completed",
  }));
}

function countMachinesInPlan(plan: ArrayElement<GetCompletedPlansQuery["plans"]>
  | ArrayElement<GetAdjustedPlansQuery["plans"]>, type: MachineKind) {
  return plan.refillOrders.reduce((agg, curr) => {
    if(curr.machine.kind === type) {
      return agg + 1;
    }
    return agg;
  }, 0);
}

function mapPlanToCommonRows(
  plan:
    | ArrayElement<GetCompletedPlansQuery["plans"]>
    | ArrayElement<GetAdjustedPlansQuery["plans"]>
): Omit<TableRow, "status"> {
  return {
    completeDate: plan.completedAt,
    taoBinCompleted: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "completed" && ro.machine.kind === MachineKind.BeverageVending ? agg + 1 : agg),
      0
    ),
    taoBinAdjusted: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "adjusted" && ro.machine.kind === MachineKind.BeverageVending ? agg + 1 : agg),
      0
    ),
    taoBinIncomplete: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "failed" && ro.machine.kind === MachineKind.BeverageVending ? agg + 1 : agg),
      0
    ),
    taoBinInPlan: countMachinesInPlan(plan, MachineKind.BeverageVending),
    spiralCompleted: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "completed" && ro.machine.kind === MachineKind.SpiralVending ? agg + 1 : agg),
      0
    ),
    spiralAdjusted: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "adjusted" && ro.machine.kind === MachineKind.SpiralVending ? agg + 1 : agg),
      0
    ),
    spiralIncomplete: plan.refillOrders.reduce(
      (agg, ro) => (ro.state === "failed" && ro.machine.kind === MachineKind.SpiralVending ? agg + 1 : agg),
      0
    ),
    spiralInPlan: countMachinesInPlan(plan, MachineKind.SpiralVending),
    planId: plan.id,
    planName: plan.name,
    warehouseName: plan.warehouse.name,
  };
}

const columns = {
  checkbox: "checkbox",
  planName: "planName",
  taobinMachinesInPlan: "taobinMachinesInPlan",
  spiralMachinesInPlan: "spiralMachinesInPlan",
  completed: "completed",
  adjusted: "adjusted",
  incomplete: "incomplete",
  completeDate: "completeDate",
  warehouse: "warehouse",
  download: "download",
  inventorySummary: "inventorySummary",
} as const;

type Columns = keyof typeof columns;

const sortFnMap: Record<Columns, (planA: TableRow, planB: TableRow) => number> =
  {
    planName: (planA, planB) => {
      return alphanumeric(planA.planName, planB.planName);
    },
    taobinMachinesInPlan: (planA, planB) => {
      let comparisonValue = planA.taoBinInPlan - planB.taoBinInPlan;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralMachinesInPlan: (planA, planB) => {
      let comparisonValue = planA.spiralInPlan - planB.spiralInPlan;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    completeDate: (planA, planB) => {
      // completedData can be null
      if (!planA.completeDate) return 1;
      if (!planB.completeDate) return -1;
      let comparisonValue = planA.completeDate.localeCompare(
        planB.completeDate
      );
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    warehouse: (planA, planB) => {
      return planA.warehouseName.localeCompare(planB.warehouseName);
    },
    adjusted: () => {
      throw new Error("Adjusted column not sortable");
    },
    completed: () => {
      throw new Error("Completed column not sortable");
    },
    incomplete: () => {
      throw new Error("Incomplete column not sortable");
    },
    checkbox: () => {
      throw new Error("Check box column not sortable");
    },
    download: () => {
      throw new Error("Download column not sortable");
    },
    inventorySummary: () => {
      throw new Error("Download column not sortable");
    },
  };
