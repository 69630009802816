import store from "common/store";
import { addToast } from "common/store/actions/uiActions";

import { postMachineMaintenance } from "machines/service";

import { useMutation, useQueryClient } from "react-query";

import { AnyAction, Dispatch } from "redux";

import { machineKeys } from "./machineKeys";

function usePostMachineMaintenance() {
  const queryClient = useQueryClient();
  return useMutation(postMachineMaintenance, {
    onSuccess: (_, { machineId }) => {
      addToast({
        type: "success",
        text: "toast_maintenance_post_succes"
      })(store.dispatch as Dispatch<AnyAction>);
      queryClient.invalidateQueries(machineKeys.machineMaintenance(machineId));
    }
  });
}

export { usePostMachineMaintenance };
