import Typography from "common/components/typography/Typography";
import * as React from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import { CellAlignmentProps } from "../types";

import * as styles from "./LinkCell.module.scss";

export interface LinkCellValues {
  to: string;
  translationKey: string;
}

export default function LinkCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
}: CellAlignmentProps = {}) {
  return function Component({
    value,
  }: CellProps<D, LinkCellValues>): JSX.Element {
    const to = value.to;
    const translationKey = value.translationKey;

    return (
      <div
        className={styles.LinkCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        {translationKey !== undefined && (
          <Link to={to} target="_blank" rel="noopener noreferrer">
            <Typography
              type="body-2"
              className={styles.Link}
              translationKey={translationKey}
            />
          </Link>
        )}
      </div>
    );
  };
}
