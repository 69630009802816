// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TextAndIndicatorCell--lfFvLmfBE2{width:100%;height:100%;display:flex;gap:8px}.TextAndIndicatorCell--lfFvLmfBE2 .Indicator--19cJldhees{flex-shrink:0}", ""]);
// Exports
exports.locals = {
	"TextAndIndicatorCell": "TextAndIndicatorCell--lfFvLmfBE2",
	"Indicator": "Indicator--19cJldhees"
};
module.exports = exports;
