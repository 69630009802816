import {
  GetMaintenanceBoardBillCoinErrorDocument,
  GetMaintenanceBoardBillCoinErrorQuery,
  GetMaintenanceBoardBillCoinErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getBillAndCoin({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardBillCoinErrorQuery,
    GetMaintenanceBoardBillCoinErrorQueryVariables
  >(GetMaintenanceBoardBillCoinErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
