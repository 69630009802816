import * as React from 'react';
import * as styles from './SpecificError.module.scss';

import { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { IErrorTypeFromAPI, errorTypeArray } from '../../Monitor';
import Button from 'components/Button';
import Icon from 'common/components/icon/Icon';
import Typography from 'components/Typography';

const SpecificError = ({ setSelectedMachine }: ISpecificErrorProps) => {
  useEffect(() => {
    setSelectedMachine([]);
  }, []);
  const params = useParams();
  const pageQuery = (params as IParams).errortype;
  const history = useHistory();
  const isIncludedErrorType = errorTypeArray.includes(pageQuery);

  return (
    <>
      {!isIncludedErrorType ? (
        <div className={styles.Error}>
          <Icon className={styles.icon} name="Info" color="error" />
          <Typography translate type="headline-5">
            label_page_not_found
          </Typography>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => history.push('/machines/monitor/overview')}
          >
            action_back_monitor
          </Button>
        </div>
      ) : (
        <div className={styles.TableContainer}>
          <div className="">not aviable at the moment</div>
        </div>
      )}
    </>
  );
};

export default SpecificError;

interface IParams {
  errortype: IErrorTypeFromAPI;
}

interface ISpecificErrorProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<string[]>>;
}
