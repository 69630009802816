// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Hamburger--3rod7KX9QG{cursor:pointer}.Hamburger--3rod7KX9QG .line--1AQD2TeCXR{height:2px;width:18px;background-color:rgba(0,0,0,.87);margin:3px 0;transition:all 200ms ease-in}.Hamburger--3rod7KX9QG .line1--1Z3mKaK6dp{transform-origin:top left;transform:initial}.Hamburger--3rod7KX9QG .line1--1Z3mKaK6dp.open--3MYssJ_kVM{transform:rotate(45deg) translateY(-2px)}.Hamburger--3rod7KX9QG .line2--2Ndk0_BL0j{opacity:1}.Hamburger--3rod7KX9QG .line2--2Ndk0_BL0j.open--3MYssJ_kVM{opacity:0}.Hamburger--3rod7KX9QG .line3--1rMhs5gM-Y{transform-origin:bottom left;transform:initial}.Hamburger--3rod7KX9QG .line3--1rMhs5gM-Y.open--3MYssJ_kVM{transform:rotate(-45deg) translateY(2px)}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Hamburger": "Hamburger--3rod7KX9QG",
	"line": "line--1AQD2TeCXR",
	"line1": "line1--1Z3mKaK6dp",
	"open": "open--3MYssJ_kVM",
	"line2": "line2--2Ndk0_BL0j",
	"line3": "line3--1rMhs5gM-Y"
};
module.exports = exports;
