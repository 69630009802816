import * as React from 'react';

// Library hooks
import { useState } from 'react';
import { useDispatch } from 'react-redux';

// Custom components
import Modal from 'common/components/modal/Modal';
import Typography from 'common/components/typography/Typography';
import { FilePicker } from 'common/components/FilePicker';
import Button from 'common/components/button/Button';

// Redux selectors and actions
import { addToast } from 'common/store/actions/uiActions';

// Services
import { postMachineLimitXLSX } from 'machines/service';

// Styles
import * as styles from './UpdateLimitModal.module.scss';

interface IUpdateLimitModalProps {
  onClose: () => void;
}

function UpdateLimitModal ({ onClose }: IUpdateLimitModalProps): JSX.Element {
  const dispatch = useDispatch();
  const [ XLSXFile, setXLSXFile ] = useState<string>('');
  const [ XLSXFileName, setXLSXFileName ] = useState('');
  const [ uploadingLimitFile, setUploadingLimitFile ] = useState<boolean>(false);

  const disableUpload = XLSXFile === '';

  function onFileChange (e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files.length === 0) {
      return;
    }

    const xlsx = new FileReader();

    xlsx.readAsDataURL(e.target.files[0]);

    xlsx.onload = () => {
      if (e.target.files[0].size > 4096000) {
        dispatch(
          addToast({
            type: 'error',
            text: 'toast_max_image_upload_size',
            raw: `${e.target.files[0].name}`
          })
        );
        setXLSXFile('');
        setXLSXFileName('');
      } else {
        setXLSXFile(xlsx.result.toString());
        setXLSXFileName(e.target.files[0].name);
      }
    };
  }

  async function onFileSubmit () {
    setUploadingLimitFile(true);
    try {
      await postMachineLimitXLSX(XLSXFile);
      dispatch(
        addToast({
          type: 'success',
          text: 'toast_limit_update_success',
          raw: `${XLSXFileName}`
        })
      );
      onClose();
    } catch {
      setUploadingLimitFile(false);
    }
  }

  return (
    <Modal contentClassName={styles.UpdateLimitModal}>

      <Typography
        type="headline-6"
        translationKey="action_upload_limit"
        className={styles.header}
      />

      <FilePicker
        id="XLSXFile"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        onChange={onFileChange}
        className={styles.filePicker}
      >
        <FilePicker.FormField
          icon="Upload"
          iconColor="primary500"
          placeHolderText="label_select_limit_file"
          fileName={XLSXFileName}
        />
      </FilePicker>

      <div className={styles.buttonGroup}>
        <Button
          translationKey="action_cancel"
          onClick={onClose}
          type="secondary"
        />
        <Button
          translationKey="action_update"
          onClick={onFileSubmit}
          type="primary"
          disabled={disableUpload}
          loading={uploadingLimitFile}
        />
      </div>

    </Modal>
  );
}

export { UpdateLimitModal };
