import { AxiosError } from 'axios';
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';
import { Dispatch, AnyAction } from 'redux';

import { useMutation, useQueryClient } from 'react-query';
import { deleteMachineUser } from 'machines/service';
import { machineKeys } from './machineKeys';
import { IMachineUser } from 'machines/interfaces';

function useDeleteMachineUser () {
    const queryClient = useQueryClient();
    // use mutation , return value , error , var
    return useMutation<string, AxiosError, IMachineUser>(
        (userDetails:IMachineUser) => deleteMachineUser(userDetails),{
            onSuccess:(_,{machineId}) => {
                addToast({
                    type: 'success',
                    text: 'toast_user_delete_success'
                })(store.dispatch as Dispatch<AnyAction>);
                queryClient.invalidateQueries(machineKeys.machineUsers(machineId));
            }
        }
    );
}

export { useDeleteMachineUser };