// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".DeleteSlotConfirmationModal--3Wtli4S0ur{flex-direction:column;max-width:416px}@media screen and (max-width: 750px){.DeleteSlotConfirmationModal--3Wtli4S0ur{max-width:unset}}.DeleteSlotConfirmationModal--3Wtli4S0ur .header--34lOwkSE1K{margin-bottom:20px}.DeleteSlotConfirmationModal--3Wtli4S0ur .confirmationStringContainer--3dOztKovWA{margin-bottom:20px}.DeleteSlotConfirmationModal--3Wtli4S0ur .cancelAndConfirmButtonsContainer--ATfcSHQqI1{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"DeleteSlotConfirmationModal": "DeleteSlotConfirmationModal--3Wtli4S0ur",
	"header": "header--34lOwkSE1K",
	"confirmationStringContainer": "confirmationStringContainer--3dOztKovWA",
	"cancelAndConfirmButtonsContainer": "cancelAndConfirmButtonsContainer--ATfcSHQqI1"
};
module.exports = exports;
