import * as React from "react";
import { useState } from "react";

import Icon from "common/components/icon/Icon";
import Typography from "common/components/typography/Typography";
import Input from "common/components/input/Input";
import Button from "common/components/button/Button";
import GoogleLogin from "common/components/googlelogin/GoogleLogin";
import { GoogleCredentialResponse } from "@react-oauth/google";

import config from "common/config";

import * as styles from "./Login.module.scss";
import { useHistory, useLocation, Redirect } from "react-router";
import { useAuthStore } from "userandauth/useAuthStore";
import { login } from "userandauth/utilsAuth";
import { userTracking } from "utils/userTracking";
import {
  AUTH_ERROR_TYPE,
  AuthErrorType,
} from "userandauth/servicesAuth";
import i18n from "common/i18n";
import { toasti18n } from "utils/toast";
import { setSentryUser } from "utils/sentryUserTracking";

const errorTranslations: Record<AuthErrorType, string> = {
  [AUTH_ERROR_TYPE.InvalidUserOrPassword]:
    "toast_invalid_login_or_password_error",
  [AUTH_ERROR_TYPE.ServerError]: "toast_failed_signin_server_error",
  [AUTH_ERROR_TYPE.SigninWithGoogleError]:
    "toast_failed_signin_with_google_error",
  [AUTH_ERROR_TYPE.NetworkError]: "toast_network_unknown_error",
  [AUTH_ERROR_TYPE.UnknownAuthError]: "toast_network_unknown_error",
};

function LoginSection(): JSX.Element {
  const history = useHistory();
  const location = useLocation();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  async function handlePasswordLogin(): Promise<void> {
    try {
      setIsLoading(true);
      const { userInfo } = await login({
        loginWith: "password",
        postBody: { email: email?.toLowerCase(), password },
      });
      await userTracking(userInfo);
      await setSentryUser(userInfo);
      history.replace((location as any).state?.from?.pathname ?? "/");
    } catch (err) {
      if (err.name === 'AuthError') {         
        const errMessage: string = i18n.t(errorTranslations[err.errorType]);
        toasti18n.error({ message: errMessage, excludeDefaultTitle: true });
      }
      setIsLoading(false);
    }
  }

  async function handleGoogleLogin(
    googleObject: GoogleCredentialResponse
  ): Promise<void> {
    try {
      const { userInfo } = await login({
        loginWith: "google",
        postBody: {
          idToken: googleObject.credential,
        },
      });
      await userTracking(userInfo);
      await setSentryUser(userInfo);
      history.replace((location as any).state?.from?.pathname ?? "/");
    } catch (err) {
      if (err.name === 'AuthError') {     
        const errMessage: string = i18n.t(errorTranslations[err.errorType]);
        toasti18n.error({ message: errMessage, excludeDefaultTitle: true });
      }
    }
  }

  return (
    <div className={styles.Login}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <Icon name="Vendii" className={styles.logo} />
        </div>
        <div className={styles.card}>
          <Typography
            type="headline-6"
            translationKey="login_title"
            className={styles.title}
          />

          {config.GOOGLE_CLIENT_ID && (
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => alert(JSON.stringify("Something went wrong"))}
                />
              </div>

              <div className={styles.orLine}>
                <div className={styles.line} />
                <Typography
                  type="body-1"
                  translationKey="label_or"
                  className={styles.or}
                />
                <div className={styles.line} />
              </div>
            </>
          )}

          <Input
            value={email}
            onChange={setEmail}
            type="text"
            label="login_email_placeholder"
            className={styles.input}
          />
          <Input
            value={password}
            onChange={setPassword}
            type="password"
            label="login_password_placeholder"
            className={styles.input}
          />

          <Button
            className={styles.signInButton}
            translationKey="login_signin"
            type="primary"
            onClick={handlePasswordLogin}
            disabled={email === "" || password === ""}
            loading={isLoading}
          />
        </div>
      </div>

      <img alt="vending city splash screen" className={styles.vendingcity} src="/images/vendingcity.png" />
    </div>
  );
}

export default LoginSection;
