import * as React from "react";
import { UseRowSelectInstanceProps, CellProps, Hooks } from "react-table";
import { CheckboxCell } from "../Cells";

export function useCheckbox<D extends object>(hooks: Hooks<D>) {
  hooks.visibleColumns.push((columns) => [
    {
      id: "selection",
      Header: function HeaderCheckbox({
        getToggleAllRowsSelectedProps,
      }: UseRowSelectInstanceProps<D>) {
        return <CheckboxCell {...getToggleAllRowsSelectedProps()} />;
      },
      Cell: function CellCheckbox({ row }: CellProps<D>) {
        return <CheckboxCell {...row.getToggleRowSelectedProps()} />;
      },
      width: 40,
      minWidth: 40,
    },
    ...columns,
  ]);
}
