// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChangeZone--1LtkZLi_X1{display:flex;flex-direction:column}.ChangeZone--1LtkZLi_X1 .EditZoneContent--2dE-H3wvwY{margin-top:10px}.ChangeZone--1LtkZLi_X1 .ButtonContainer--1ri7nD2L-V{display:flex;margin-top:10px}.ChangeZone--1LtkZLi_X1 .ButtonContainer--1ri7nD2L-V .save--3ILWxP69IU{margin-left:auto}", ""]);
// Exports
exports.locals = {
	"ChangeZone": "ChangeZone--1LtkZLi_X1",
	"EditZoneContent": "EditZoneContent--2dE-H3wvwY",
	"ButtonContainer": "ButtonContainer--1ri7nD2L-V",
	"save": "save--3ILWxP69IU"
};
module.exports = exports;
