import { AxiosError } from 'axios';
import i18n from 'common/i18n';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { PostBatchSwapOrderBody, postBatchSwapOrder } from 'services/swaporders/postBatchSwapOrder';
import { swapOrderKeys } from '.';

function usePostBatchSwapOrder(){
  const queryClient = useQueryClient();
  return useMutation<unknown, AxiosError, PostBatchSwapOrderBody>(
    (swapOrder) => postBatchSwapOrder(swapOrder)
    ,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(swapOrderKeys.all());
        toast.success(i18n.t('toast_request_successful'));
      }
    }
  );
}

export { usePostBatchSwapOrder };
