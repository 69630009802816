// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { createMachineInventory } from 'machines/service';

// Toasts
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';

// Types
import { IMachineInventory } from 'machines/interfaces';
import { AxiosError } from 'axios';
import { Dispatch, AnyAction } from 'redux';

// Keys
import { machineKeys } from './machineKeys';

interface IUsePostMachineInventory {
  machineId: string,
  machineInventoryData: Partial<IMachineInventory>[]
}

function usePostMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<IMachineInventory[], AxiosError, IUsePostMachineInventory>(
    ({
      machineId,
      machineInventoryData
    }) => createMachineInventory(machineId, machineInventoryData),
    {
      onSuccess: (_, { machineId }) => {
        addToast({
          type: 'success',
          text: 'toast_machine_patch_success'
        })(store.dispatch as Dispatch<AnyAction>);
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { usePostMachineInventory };