import * as React from "react";
import {
  GetCreatedPlanByIdQuery,
  GetMachineUsageAtEtaForecastQuery,
  GetMachineUsageAtEtaForecastQueryVariables,
  useGetMachineUsageAtEtaForecastQuery,
} from "gql/generated";
import * as moment from "moment";
import { useQueryClient } from "react-query";

type RequestArg = GetMachineUsageAtEtaForecastQueryVariables;
/**
 * Returns forecast usage for machines in the plan. Will never refire unless remounted
 * This is to prevent the query from refiring with different args when removing machines
 * Firing with a different arg will cause react query to resolve to a different cache entry due to
 * cache key being different
 */
export function useForecastUsage(
  planQuery: GetCreatedPlanByIdQuery,
  { enabled = true }: { enabled: boolean } = { enabled: true }
) {
  const requestArgRef = React.useRef<{ arg: RequestArg; fixed: boolean }>({
    arg: undefined,
    fixed: false,
  });

  if (!requestArgRef.current.fixed && planQuery) {
    requestArgRef.current.arg = buildUsageRequestArgFromPlanQuery(planQuery);
    requestArgRef.current.fixed = true;
  }

  return useGetMachineUsageAtEtaForecastQuery(requestArgRef.current.arg, {
    enabled: Boolean(planQuery) && enabled,
    staleTime: Infinity,
    cacheTime: 0,
  });
}

function buildUsageRequestArgFromPlanQuery(
  planQueryResult: GetCreatedPlanByIdQuery
): GetMachineUsageAtEtaForecastQueryVariables {
  const { plan } = planQueryResult;
  const formatDateForAPI = (arrival: string) =>
    moment(Number(arrival)).format("YYYY-MM-DD HH:mm:ss");

  return {
    machines: plan.refillOrders.map((ro) => ({
      machineId: ro.machineId,
      estimatedArrival: formatDateForAPI(ro.estimatedArrival),
      positionInPlan: ro.positionInPlan,
    })),
    time: formatDateForAPI(moment().valueOf() + ""),
  };
}

/**
 * Get forecast usage for the currently open plan. Relies on the fact that
 * at any moment in time, there will only be one open plan and that the forecast usage is not cached
 */
export function useGetContextualForecastUsage() {
  const queryClient = useQueryClient();
  const usageQuery =
    queryClient.getQueriesData<GetMachineUsageAtEtaForecastQuery>([
      "GetMachineUsageAtEtaForecast",
    ])[0][1];
  return usageQuery;
}

/**
 * Gets the contextual forecast usage based on current plan being viewed
 * creates a look up table with machineId as keys and array of slot usage as values
 */
export function useGetContextualMachineUsageMap(): SlotUsagesByMachineId {
  const contextualUsage = useGetContextualForecastUsage();
  const slotUsagesByMachineId = React.useMemo(
    () =>
      Object.fromEntries(
        contextualUsage.machineUsageAtEtaForecast
          .filter(Boolean)
          .map((usage) => [usage.machineId, usage.usage])
      ),
    [contextualUsage]
  );
  return slotUsagesByMachineId;
}
export type SlotUsagesByMachineId = {
  [machineId: string]: SlotUsages | undefined;
};
export type SlotUsages = Array<{ slot: string; usage: number }>;
