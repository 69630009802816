import * as React from "react";
import { CellProps } from "react-table";
import Typography from "common/components/typography/Typography";
import { CellAlignmentProps } from "../types";

import * as styles from "./CurrencyCell.module.scss";
import { formatCurrency } from "utils/currency";

export type CurrencyCellValues = number;

export default function CurrencyCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
}: CellAlignmentProps = {}) {
  return function Component({
    value,
  }: CellProps<D, CurrencyCellValues>): JSX.Element {
    return (
      <div
        className={styles.CurrencyCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        <Typography
          text={formatCurrency({input: value, minimumFractionDigits: 2})}
          type="body-2"
          className={styles.Label}
        />
      </div>
    );
  };
}
