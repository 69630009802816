import {
  GetMachinesAndOrdersInRefillZoneQuery,
  RefillOrderCandidateInput,
} from "gql/generated";
import * as moment from "moment";
import analytics from "utils/analytics";

type TrackMachineShortlistedToPlanStepArgs = {
  variables: RefillOrderCandidateInput | RefillOrderCandidateInput[];
  data: GetMachinesAndOrdersInRefillZoneQuery;
};

export function trackMachineShortlistedToPlanStep({
  data,
  variables,
}: TrackMachineShortlistedToPlanStepArgs) {
  const inputs = Array.isArray(variables) ? variables : [variables];

  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i];
    const location = findLocation(input, data);
    if (location) {
      const machine =
        location.__typename === "Location"
          ? location.machine
          : location.machines.find((machine) => machine.id === input.machineId);

      if (machine) {
        const locationData = {
          day_since_last_refill: Number(machine.lastRefilled)
            ? moment().diff(moment(Number(machine.lastRefilled)), "days")
            : "-",
          location_grade: location.locationGrade,
          location_id: location.id,
          location_name: location.locationName,
          location_type: location.locationType,
          machine_id: machine.id,
          warehouse: location.warehouse.name,
          zone: machine.zone,
          revenue_since_last_refill: location.revenueSinceLastRefilled,
          total_cash: machine.cashValue,
          cash_error: machine.cashStatus,
        };

        analytics.track({
          name: "machine shortlisted to plan step",
          properties: locationData,
        });
      }
    }
  }
}

function findLocation(
  input: RefillOrderCandidateInput,
  data: GetMachinesAndOrdersInRefillZoneQuery
) {
  return data.refillZone.locations.find((location) => {
    if (location.__typename === "Location") {
      return location.id === input.locationId;
    } else if (location.__typename === "Store") {
      return location.machines.some(
        (machine) => machine.id === input.machineId
      );
    }
    return false;
  });
}
