import * as React from "react";
import Autocomplete from "components/Autocomplete";
import { useServiceZoneQueryParam } from './hooks/useServiceZoneQueryParam';
import { useGetServiceZonesByOrgIdQuery } from "gql/generated";
import { useGetCurrentUser } from "userandauth/hooks/useGetCurrentUser";

const SERVICE_ZONE_DEFAULT_VALUE = "all";

export function ServiceZoneDropDown() {
  let [selectedServiceZone, setServiceZone] = useServiceZoneQueryParam();

  if (selectedServiceZone === null) {
    selectedServiceZone = SERVICE_ZONE_DEFAULT_VALUE;
  }

  const getServiceZoneByOrdIdQueryResult = useGetServiceZonesByOrgIdQuery(
    {
      organisationId: useGetCurrentUser().data.orgId,
    },
    {
      useErrorBoundary: false,
      staleTime: 5 * 60 * 1000,
    }
  );

  const optionsFromNetworkRequest =
    getServiceZoneByOrdIdQueryResult.data?.serviceZones
      .map((serviceZone) => ({
        label: serviceZone.friendlyId,
        value: serviceZone.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)) ?? [];

  const shouldAddLoadingOption =
    selectedServiceZone !== SERVICE_ZONE_DEFAULT_VALUE &&
    getServiceZoneByOrdIdQueryResult.isLoading;

  const options = [
    {
      label: "label_all",
      value: SERVICE_ZONE_DEFAULT_VALUE,
    },
  ]
    .concat(
      shouldAddLoadingOption
        ? [{ label: "label_loading", value: selectedServiceZone }]
        : []
    )
    .concat(optionsFromNetworkRequest);

  return (
    <Autocomplete
      loading={getServiceZoneByOrdIdQueryResult.isLoading}
      label="label_service_zone"
      id="service_zone"
      disableClearable
      translateLabels
      style={{ backgroundColor: "white" }}
      value={selectedServiceZone}
      onChange={(newValue) => {
        if (newValue === SERVICE_ZONE_DEFAULT_VALUE) {
          setServiceZone(null);
        } else {
          setServiceZone(newValue);
        }
      }}
      options={options}
    />
  );
}
