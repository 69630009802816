import * as React from "react";
import { PaginationButton } from "./button";
import Icon from "common/components/icon/Icon";
import { PaginationType } from "../types/tableTypes";
import { tableV2Tracking } from "../tracking";

type PaginationProps = { pagination?: PaginationType, tableName: string };

export function Pagination({ pagination, tableName }: PaginationProps) {
  const {
    hasNextPage,
    hasPreviousPage,
    onNextPage,
    onPreviousPage,
    resetPagination,
  } = pagination;

  if(!pagination) return null;

  return (
    <div className="flex justify-end gap-3 mt-4">
      <div className="flex gap-1">
        {pagination?.resetPagination ? (
          <PaginationButton
            disabled={!hasPreviousPage}
            onClick={() => {
              resetPagination();
              tableV2Tracking({
                name: "reset pagination clicked",
                properties: {
                  has_next_page: hasNextPage,
                  has_previous_page: hasPreviousPage,
                },
                tableName,
              });
            }}
          >
            <Icon
              className="w-4 h-4"
              name="DoubleChevronLeft"
              color={!hasPreviousPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
            />
          </PaginationButton>
        ) : null}
        <PaginationButton
          disabled={!hasPreviousPage}
          onClick={() => {
            onPreviousPage();
            tableV2Tracking({
              name: "previous button clicked",
              properties: {
                has_next_page: hasNextPage,
                has_previous_page: hasPreviousPage,
              },
              tableName,
            });
          }}
        >
          <Icon
            className="w-4 h-4 rotate-180"
            name="ChevronRight"
            color={!hasPreviousPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
          />
        </PaginationButton>
        <PaginationButton
          disabled={!hasNextPage}
          onClick={() => {
            onNextPage();
            tableV2Tracking({
              name: "next button clicked",
              properties: {
                has_next_page: hasNextPage,
                has_previous_page: hasPreviousPage,
              },
              tableName,
            });
          }}
        >
          <Icon
            className="w-4 h-4"
            name="ChevronRight"
            color={!hasNextPage ? "onSurfaceDisabled" : "onSurfaceHigh"}
          />
        </PaginationButton>
      </div>
    </div>
  );
}
