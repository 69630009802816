// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal--30zAS3oDcT{opacity:0;pointer-events:none;position:fixed;top:0;right:0;left:0;bottom:0;z-index:6;transition:opacity 100ms linear;display:flex;justify-content:center;align-items:center}.Modal--30zAS3oDcT.background--3VZjwC75fA{background-color:rgba(0,0,0,.6)}.Modal--30zAS3oDcT .content--3sV9SZHMos{background-color:#fff;border-radius:4px;box-shadow:0px 24px 38px rgba(0,0,0,.14),0px 9px 46px rgba(0,0,0,.12),0px 11px 15px rgba(0,0,0,.2);display:flex;padding:30px;position:relative;min-width:415px;box-sizing:border-box;margin:20px;overflow-y:auto;max-height:95vh}@media screen and (max-width: 750px){.Modal--30zAS3oDcT .content--3sV9SZHMos{width:100%;min-width:initial;padding:15px}}.Modal--30zAS3oDcT .content--3sV9SZHMos .closeButton--2tj0JK8bUC{position:absolute;top:10px;right:10px;cursor:pointer}@media screen and (max-width: 750px){.Modal--30zAS3oDcT .content--3sV9SZHMos.withClose---484fSV-ep{padding-top:30px}}.Modal--30zAS3oDcT.open--2zXHW_rfv0{opacity:1;pointer-events:initial}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "background--3VZjwC75fA",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Modal": "Modal--30zAS3oDcT",
	"content": "content--3sV9SZHMos",
	"closeButton": "closeButton--2tj0JK8bUC",
	"withClose": "withClose---484fSV-ep",
	"open": "open--2zXHW_rfv0"
};
module.exports = exports;
