import * as React from "react";
import Typography from "components/Typography";
import { Redirect, Switch, Route } from "react-router";
import { Link } from "react-router-dom";
import TabButton from "components/TabButton";

import { Complete } from "./tabs/Complete";
import { ErrorBoundary } from "react-error-boundary";

import * as styles from "./Archive.module.scss";
import { FallBackWrapper } from "../Components";

export function Archive(): JSX.Element {
  return (
    <>
      <div className={styles.Header}>
        <div className={styles.HeadingContainer}>
          <Typography type="headline-5" translate>
            {"workspace_operations"}
          </Typography>
          <Link to="/new-operations/refillorder" className={styles.InactiveTab}>
            <Typography type="body-2" translate>
              label_refill_order
            </Typography>
          </Link>
          <Link to="/new-operations/archive" className={styles.ActiveTab}>
            <Typography type="body-2" translate>
              label_archive
            </Typography>
          </Link>
        </div>

        <div className={styles.TabButtonsContainer}>
          <TabButton to="/new-operations/archive/complete" type="primary">
            label_complete
          </TabButton>
        </div>
      </div>

      <Switch>
        <Route path="/new-operations/archive/complete">
          <ErrorBoundary FallbackComponent={FallBackWrapper} key="route">
            <Complete />
          </ErrorBoundary>
        </Route>
        <Redirect to="/new-operations/archive/complete" />
      </Switch>
    </>
  );
}
