import { useState } from 'react';
import { Dispatch, AnyAction } from 'redux';

import store from 'common/store';
import { addToast } from 'common/store/actions/uiActions';
import elementToPdf from 'common/utils/elementToPdf';

interface IUseCreatePdf {
  loading: boolean,
  createPdf: () => Promise<void>
}

function useCreatePdf (
  elementId: string,
  pdfName: string
): IUseCreatePdf {
  const [ loading, setLoading ] = useState(false);

  async function createPdf () {
    try {
      setLoading(true);
      await elementToPdf(elementId, pdfName);
    } catch (error) {
      addToast({
        type: 'error',
        text: 'toast_pdf_error',
        raw: error.message
      })(store.dispatch as Dispatch<AnyAction>);
    } finally {
      setLoading(false);
    }
  }

  return {
    createPdf,
    loading
  };
}

export default useCreatePdf;
