// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Base64FileUploadModal--3qWZ7odLXX{box-sizing:border-box;padding:24px 32px 20px 32px;width:416px;max-width:100%}.Base64FileUploadModal--3qWZ7odLXX .Header--2PM0FhJjNc{margin-bottom:32px}.Base64FileUploadModal--3qWZ7odLXX .ButtonsGroup--2DnqfmLHCW{display:flex;justify-content:space-between;margin-top:40px}", ""]);
// Exports
exports.locals = {
	"Base64FileUploadModal": "Base64FileUploadModal--3qWZ7odLXX",
	"Header": "Header--2PM0FhJjNc",
	"ButtonsGroup": "ButtonsGroup--2DnqfmLHCW"
};
module.exports = exports;
