import * as React from "react";
import { CellProps } from "react-table";
import { Indicator, IndicatorProps } from "../element";
import { CellAlignmentProps } from "../types";

import * as styles from "./IndicatorCell.module.scss";

interface IndicatorCellProps extends CellAlignmentProps {
  type?: IndicatorProps["type"];
}

export type IndicatorCellValues = IndicatorProps["color"];

export default function IndicatorCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
  type = "circle",
}: IndicatorCellProps = {}) {
  return function Component({
    value,
  }: CellProps<D, IndicatorCellValues>): JSX.Element {
    return (
      <div
        className={styles.IndicatorCell}
        style={{ justifyContent: xAlign, alignItems: yAlign }}
      >
        <Indicator color={value} type={type} />
      </div>
    );
  };
}
