import { LocationKind } from "gql/generated";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Location } from "../types";


export function PageTitle({ location }: { location: Location; }) {
  const { t } = useTranslation();
  const translations = {
    [LocationKind.Factory]: t("label_factory"),
    [LocationKind.Store]: t("label_store"),
    [LocationKind.Location]: t("label_location"),
  };

  return (
    <h1
      style={{
        margin: 0,
        fontFamily: "Kanit",
        fontSize: 40,
      }}
    >
      {`${translations[location.kind]}: ${location.locationInfo.friendlyId}`}
    </h1>
  );
}
