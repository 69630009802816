import { httpClient } from "common/services/transportService";

import { machineKeys } from "machines/hooks/machineKeys";
import * as React from "react";
import { Dialog } from "@mui/material";
import { FilePicker } from "common/components/FilePicker";
import Button from "components/Button";
import Typography from "components/Typography";
import { toasti18n } from "utils/toast";
import * as styles from "./OnBoardMachineModal.module.scss";

import { useQueryClient } from "react-query";
import Select from "components/Select";

export function OnBoardMachineModal({
  renderProp,
}: {
  renderProp: (openModal: () => void) => JSX.Element;
}) {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      {renderProp(() => setIsOpen(true))}
      {isOpen ? (
        <OnBoardMachineModalView onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

interface Props {
  onClose: () => void;
}

function OnBoardMachineModalView({ onClose }: Props) {
  const queryClient = useQueryClient();
  const [based64File, setBase64File] = React.useState<string>("");
  const [fileName, setFileName] = React.useState("");
  const [country, setCountry] = React.useState<OnBoardMachineCountry | "">();
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);

  const isFormDisabled = based64File === "" || country === "";

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files.length === 0) {
      return;
    }
    const xlsx = new FileReader();
    xlsx.readAsDataURL(e.target.files[0]);
    xlsx.onload = () => {
      setBase64File(xlsx.result.toString());
      setFileName(e.target.files[0].name);
    };
  }

  async function handleSubmit() {
    if(!country) return;

    setIsUploadingFile(true);
    try {
      await onboardMachine({
        file: based64File,
        country: country,
      });
      toasti18n.success();
      queryClient.invalidateQueries(machineKeys.all({ extraFields: true }));
      onClose();
    } catch (err) {
      setIsUploadingFile(false);
    }
  }

  return (
    <Dialog open={true} onClose={isUploadingFile ? () => {} : onClose}>
      <div className={styles.Base64FileUploadModal}>
        <div className={styles.Header}>
          <Typography type="headline-6" translate>
            action_onboard_machine
          </Typography>
        </div>
        <FilePicker id="XLSXFile" onChange={handleChange} accept={".csv"}>
          <FilePicker.FormField
            icon="Upload"
            iconColor="primary500"
            placeHolderText={"label_upload_machine_list"}
            fileName={fileName}
          />
        </FilePicker>
        <div style={{ height: "10px" }}></div>
        <Select
          required={true}
          label="label_country"
          id="country"
          value={country}
          onChange={(e) => setCountry(e.target.value)}
          options={[
            {
              value: "Thailand",
              label: "label_thailand",
            },
            {
              value: "Malaysia",
              label: "label_malaysia",
            },
            {
              value: "Australia",
              label: "label_australia",
            },
            {
              value: "Singapore",
              label: "label_singapore",
            },
            {
              value: "United Arab Emirates",
              label: "label_united_arab_emirates",
            },
          ]}
        />
        <div className={styles.ButtonsGroup}>
          <Button onClick={onClose} disabled={isUploadingFile} type="secondary">
            action_cancel
          </Button>
          <Button
            onClick={handleSubmit}
            type="primary"
            disabled={isFormDisabled}
            loading={isUploadingFile}
          >
            label_add
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export type OnBoardMachineCountry =
  | "Thailand"
  | "Malaysia"
  | "Australia"
  | "Singapore"
  | "United Arab Emirates";

export async function onboardMachine(body: {
  file: string;
  country: OnBoardMachineCountry;
}) {
  return httpClient.post("/machines/onboard", {
    file: body.file,
    country: body.country,
  });
}
