import * as React from "react";
import { Table } from "@tanstack/table-core/build/lib/types";
import { flexRender } from "@tanstack/react-table";
import { SkeletonCell } from "./SkeletonCell";
import {
  InputValuesState,
  getColumnInputValue,
  setColumnInputValue,
} from "../inputState";
import { InputStateType } from "../types/tableTypes";
import { SortIcon } from "./SortIcon";
import { tableV2Tracking } from "../tracking";
import { clsx } from "clsx";

type TableProps<T> = InputStateType & {
  table: Table<T>;
  isLoading: boolean;
  rowPerPage: number;
};

export function TableComponent<T>({
  table,
  isLoading,
  rowPerPage,
  inputState,
  onInputStateChange,
}: TableProps<T>) {
  const tableName = table.getState().tableName;
  const [inputClientState, setInputClientState] =
    React.useState<InputValuesState>([]);

  return (
    <table className="table-auto border-collapse overflow-hidden min-w-full font-kanit max-w-full overflow-x-auto">
      <thead className="h-[50px] bg-black/5">
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              if (header.column.columnDef.meta?.hide) return null;

              return (
                <th
                  key={header.id}
                  className={clsx(
                    "relative py-1 gap-1 text-left !border-b !border-outline border-solid text-on-surface-medium text-caption",
                    header.column.columnDef.meta?.columnBorder
                      ? "!border-x"
                      : "!border-x-0",
                    !(header.column.columnDef.meta as any)?.rowSelection &&
                      "px-2",
                    header.column.columnDef.meta?.className,
                    header.column.columnDef.meta?.headerClassName,
                    header.column.getCanSort()
                      ? "cursor-pointer select-none"
                      : ""
                  )}
                  colSpan={header.colSpan}
                  style={{
                    width: header.column.columnDef.size,
                    minWidth: header.column.columnDef.minSize,
                    maxWidth: header.column.columnDef.maxSize,
                  }}
                  onClick={() => {
                    header.column.toggleSorting();
                    tableV2Tracking({
                      name: "table header cell clicked",
                      properties: {
                        column_id: header.column.id,
                        can_sort: header.column.getCanSort(),
                        is_sorted: header.column.getIsSorted(),
                      },
                      tableName,
                    });
                  }}
                >
                  <div className="flex items-center justify-between w-full">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                    <>
                      {(header.column.getCanSort() &&
                        {
                          asc: (
                            <SortIcon canSort isSorted isSortedDesc={false} />
                          ),
                          desc: (
                            <SortIcon canSort isSorted isSortedDesc={true} />
                          ),
                        }[header.column.getIsSorted() as string]) ??
                        null}
                    </>
                    {!header.column.getIsSorted() &&
                      header.column.getCanSort() && (
                        <SortIcon
                          canSort
                          isSorted={false}
                          isSortedDesc={true}
                        />
                      )}
                  </div>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {isLoading &&
          Array(rowPerPage)
            .fill("")
            .map((_, key) => (
              <tr key={key}>
                {table.getAllLeafColumns().map((column) => (
                  <td
                    key={column.id}
                    className="px-2.5 py-4 !border-y !border-x-0 !border-outline border-solid"
                  >
                    <SkeletonCell />
                  </td>
                ))}
              </tr>
            ))}
        {!isLoading &&
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={clsx(
                "h-[50px]",
                row.getIsSelected() && "bg-primary-50"
              )}
            >
              {row.getVisibleCells().map((cell) => {
                if (cell.column.columnDef.meta?.hide) return null;

                return (
                  <td
                    key={`${cell.id}`}
                    className={clsx(
                      "relative py-2 !border-y !border-outline border-solid text-body2 text-on-surface-high",
                      cell.column.columnDef.meta?.columnBorder
                        ? "!border-x"
                        : "!border-x-0",
                      !cell.column.columnDef.meta?.rowSelection && "px-2",
                      cell.column.columnDef.meta?.className,
                      cell.column.columnDef.meta?.bodyClassName
                    )}
                    width={cell.column.columnDef.size}
                    style={{
                      width: cell.column.columnDef.size,
                      minWidth: cell.column.columnDef.minSize,
                      maxWidth: cell.column.columnDef.maxSize,
                    }}
                    onClick={() =>
                      tableV2Tracking({
                        name: "table body cell clicked",
                        properties: {
                          cell_id: cell.id,
                          column_id: cell.column.id,
                        },
                        tableName,
                      })
                    }
                  >
                    {flexRender(cell.column.columnDef.cell, {
                      ...cell.getContext(),
                      getInputValue: getColumnInputValue({
                        id: cell.id,
                        inputState: inputState || inputClientState,
                      })(),
                      onInputChange: (val) => {
                        onInputStateChange
                          ? onInputStateChange(
                              setColumnInputValue({
                                id: cell.id,
                                inputState: inputState,
                                val,
                              })
                            )
                          : setInputClientState(
                              setColumnInputValue({
                                id: cell.id,
                                inputState: inputClientState,
                                val,
                              })
                            );
                      },
                    })}
                  </td>
                );
              })}
            </tr>
          ))}
      </tbody>
    </table>
  );
}
