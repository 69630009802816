import { httpClient } from 'common/services/transportService';

export interface PostBatchSwapOrderBody {
   machineId : Array<string>,
   swapMethod :  'await_low_level' | 'immediate' | 'append' ,
   itemsRequested : Array<{
      productId :  string,
      slotConfig : {
          slot :  string,
          parLevel : number,
          refillLevel : number,
          capacity : number,
          additional : {
              inventoryInfo : {
                  importanceLevel : number
            }
        },
          noPartialRefill : boolean
    }
   }>
}

export async function postBatchSwapOrder (postSwapOrderData: PostBatchSwapOrderBody) {
  const response = await httpClient.post('/swaporder/batch', postSwapOrderData);
  return response.data;
}