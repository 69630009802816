// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Base64FileUploadModal--1XX6vIeMc2{box-sizing:border-box;padding:24px 32px 20px 32px;width:416px;max-width:100%}.Base64FileUploadModal--1XX6vIeMc2 .Header--3TN9x90BcD{margin-bottom:32px}.Base64FileUploadModal--1XX6vIeMc2 .ButtonsGroup--1Qww5PPDc5{display:flex;justify-content:space-between;margin-top:40px}", ""]);
// Exports
exports.locals = {
	"Base64FileUploadModal": "Base64FileUploadModal--1XX6vIeMc2",
	"Header": "Header--3TN9x90BcD",
	"ButtonsGroup": "ButtonsGroup--1Qww5PPDc5"
};
module.exports = exports;
