// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Paginator--1lWSVRc7vZ{display:flex;justify-content:flex-end;align-items:center;margin-top:22px}@media screen and (max-width: 750px){.Paginator--1lWSVRc7vZ{justify-content:center}}.Paginator--1lWSVRc7vZ .PaginationIconFirstPage--bh8waiKBuf{margin-right:8px}.Paginator--1lWSVRc7vZ .PaginationIconPreviousPage--3L0hRl6jVt{margin-right:11px}.Paginator--1lWSVRc7vZ .PaginationIconNextPage--14VaswlXb0{transform:rotate(180deg);margin-left:11px}.Paginator--1lWSVRc7vZ .PaginationIconLastPage--bjNM6Ua5UX{transform:rotate(180deg);margin-left:8px}.Paginator--1lWSVRc7vZ .Page--2rgRuB-f7_{min-width:16px;min-height:15px;border-radius:2px;margin:0px 3.5px;color:rgba(0,0,0,.6);display:flex;align-items:center;justify-content:center}.Paginator--1lWSVRc7vZ .PageActive--2DIWVGzhoc{background-color:#4b4d9b;color:#fff}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Paginator": "Paginator--1lWSVRc7vZ",
	"PaginationIconFirstPage": "PaginationIconFirstPage--bh8waiKBuf",
	"PaginationIconPreviousPage": "PaginationIconPreviousPage--3L0hRl6jVt",
	"PaginationIconNextPage": "PaginationIconNextPage--14VaswlXb0",
	"PaginationIconLastPage": "PaginationIconLastPage--bjNM6Ua5UX",
	"Page": "Page--2rgRuB-f7_",
	"PageActive": "PageActive--2DIWVGzhoc"
};
module.exports = exports;
