import * as React from "react";
import { CellProps } from "react-table";
import Typography from "common/components/typography/Typography";
import { Indicator, IndicatorProps } from "../element";
import Tooltip from "@mui/material/Tooltip";
import { CellAlignmentProps } from "../types";

import * as SCSScolors from "common/styles/colors.scss";
import * as styles from "./TextAndIndicatorCell.module.scss";

interface TextAndIndicatorCellProps extends CellAlignmentProps {
  indicatorType?: IndicatorProps["type"];
  tooltipTitle?: string | string[];
  textColor?: string;
}

export interface TextAndIndicatorCellValues {
  cellData: {
    indicatorColor: IndicatorProps["color"];
    translationKey: string | string[];
  };
}

export default function TextAndIndicatorCell<D extends object>({
  xAlign = "flex-start",
  yAlign = "center",
  indicatorType = "circle",
  tooltipTitle,
  textColor,
}: TextAndIndicatorCellProps = {}) {
  return function Component({
    value,
  }: CellProps<D, TextAndIndicatorCellValues>): JSX.Element {
    const indicatorColor = value.cellData.indicatorColor;
    const translationKey = value.cellData.translationKey;

    return (
      <Tooltip disableFocusListener title={tooltipTitle ?? ""} placement="top">
        <div
          className={styles.TextAndIndicatorCell}
          style={{
            color: SCSScolors[textColor],
            justifyContent: xAlign,
            alignItems: yAlign,
          }}
        >
          {indicatorColor && (
            <Indicator
              color={indicatorColor}
              type={indicatorType}
              className={styles.Indicator}
            />
          )}
          <Typography translationKey={translationKey} type="body-2" />
        </div>
      </Tooltip>
    );
  };
}
