// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorBoardContainer--1Ke85K3pwP{display:flex;flex-direction:column;box-sizing:border-box;width:100%;height:100%;gap:8px;padding:8px 16px 16px 16px}@media(min-width: 750px){.ErrorBoardContainer--1Ke85K3pwP{gap:16px;padding:16px 40px 24px 40px}}.ErrorBoardContainer--1Ke85K3pwP .HeadingContainer--3kwJ8OFfr_{display:inline-flex;padding-left:16px;padding-bottom:8px;border-bottom:1px solid rgba(0,0,0,.12)}.ErrorBoardContainer--1Ke85K3pwP .ServiceZoneAndRefreshButtonContainer--zBVvycvMiW{display:flex;justify-content:space-between;gap:57px}.ErrorBoardContainer--1Ke85K3pwP .ServiceZoneAndRefreshButtonContainer--zBVvycvMiW .RefreshButtonContainer--1whhsXD5YX{display:flex;align-items:center;gap:4px;flex-shrink:0}.ErrorBoardContainer--1Ke85K3pwP .ErrorBoard--2sSuHIe81G{padding:8px;display:flex;gap:16px;flex-direction:column;background-color:#fff;box-shadow:0px 1px 4px 0px rgba(0,0,0,.13);border-radius:8px;flex:1 1 0;min-height:0px}@media(min-width: 750px){.ErrorBoardContainer--1Ke85K3pwP .ErrorBoard--2sSuHIe81G{padding:16px}}.ErrorBoardContainer--1Ke85K3pwP .ErrorBoard--2sSuHIe81G .SubNavAndTableContainer--1FV2qhYMB2{border-radius:8px;border:1px solid rgba(0,0,0,.12);display:flex;flex-direction:column;gap:2px;flex:1 1 0;min-height:0px}", ""]);
// Exports
exports.locals = {
	"ErrorBoardContainer": "ErrorBoardContainer--1Ke85K3pwP",
	"HeadingContainer": "HeadingContainer--3kwJ8OFfr_",
	"ServiceZoneAndRefreshButtonContainer": "ServiceZoneAndRefreshButtonContainer--zBVvycvMiW",
	"RefreshButtonContainer": "RefreshButtonContainer--1whhsXD5YX",
	"ErrorBoard": "ErrorBoard--2sSuHIe81G",
	"SubNavAndTableContainer": "SubNavAndTableContainer--1FV2qhYMB2"
};
module.exports = exports;
