import * as React from "react";
import { TableToggleCommonProps } from "react-table";
import { Checkbox } from "../element";

import * as styles from "./CheckboxCell.module.scss";

export default function CheckboxCell(
  tableToggleProps: TableToggleCommonProps
): JSX.Element {
  return (
    <div className={styles.CheckboxCell}>
      <Checkbox
        checked={tableToggleProps.checked}
        handleChecked={tableToggleProps.onChange}
      />
    </div>
  );
}
