// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Indicator--300wjNfdBV{width:8px;height:8px}.Indicator--300wjNfdBV.Circle--3rQKdb2Zi9{border-radius:50%}", ""]);
// Exports
exports.locals = {
	"Indicator": "Indicator--300wjNfdBV",
	"Circle": "Circle--3rQKdb2Zi9"
};
module.exports = exports;
