// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ProgressBar--3fPs1eWQ3X{display:flex;flex-direction:column;flex:1}.ProgressBar--3fPs1eWQ3X .topRow--2B5QDQwnDU{display:flex;flex-direction:row;justify-content:space-between;margin-bottom:5px}.ProgressBar--3fPs1eWQ3X .topRow--2B5QDQwnDU .labelContainer--3pc8u3_qCI{width:70%;position:relative}.ProgressBar--3fPs1eWQ3X .topRow--2B5QDQwnDU .labelContainer--3pc8u3_qCI .labelOverflowWrapper--2YZ6TygQOz{overflow:hidden;text-overflow:ellipsis;position:absolute;width:100%;height:100%}.ProgressBar--3fPs1eWQ3X .topRow--2B5QDQwnDU .label--28pkLWbTn5{color:#b3b3b3;white-space:nowrap}.ProgressBar--3fPs1eWQ3X .barWrapper--_aLv4lBm7f{position:relative;width:100%}.ProgressBar--3fPs1eWQ3X .barWrapper--_aLv4lBm7f .bar--3mw14011Vv{height:8px;border-radius:10px}.ProgressBar--3fPs1eWQ3X .barWrapper--_aLv4lBm7f .overlay--3YwHVTVb59{position:absolute;top:0;bottom:0;left:0;right:0;transition:width 1000ms ease-in-out}.ProgressBar--3fPs1eWQ3X .barWrapper--_aLv4lBm7f .background--Fy0jiNzpb4{background-color:#d7d7d7}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "background--Fy0jiNzpb4",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"ProgressBar": "ProgressBar--3fPs1eWQ3X",
	"topRow": "topRow--2B5QDQwnDU",
	"labelContainer": "labelContainer--3pc8u3_qCI",
	"labelOverflowWrapper": "labelOverflowWrapper--2YZ6TygQOz",
	"label": "label--28pkLWbTn5",
	"barWrapper": "barWrapper--_aLv4lBm7f",
	"bar": "bar--3mw14011Vv",
	"overlay": "overlay--3YwHVTVb59"
};
module.exports = exports;
