import {
  BadgeCell,
  CurrencyCell,
  HeaderCell,
  IndicatorCell,
  LinkCell,
  MultipleIndicatorCell,
  StringCell,
  StringFillBackgroundCell,
  TextAndIndicatorCell,
} from "components/Table/Cells";
import {
  errorCompareFn,
  errorsCompareFn,
  listFilterFn,
  statusCompareFn,
  stringCompareFn,
  timestampCompareFn,
  valueFilterFn,
} from "components/Table/util";
import { Column } from "react-table";
import { ITableRow } from "./mapDataToTable";

export function generateColumnConfig(): Column<ITableRow>[] {
  return [
    {
      Header: HeaderCell({ label: "label_machine_id" }),
      accessor: "machineId",
      width: 94,
      minWidth: 94,
      Cell: LinkCell(),
      sortType: stringCompareFn("translationKey"),
      filter: valueFilterFn("translationKey", true),
    },
    {
      Header: HeaderCell({ label: "label_status" }),
      accessor: "status",
      minWidth: 81,
      width: 81,
      Cell: BadgeCell(),
      sortType: statusCompareFn("translationKey"),
      filter: listFilterFn("translationKey"),
    },
    {
      Header: HeaderCell({ label: "label_org" }),
      accessor: "organisation",
      minWidth: 81,
      width: 81,
      Cell: StringCell(),
      sortType: stringCompareFn(""),
      filter: valueFilterFn(""),
    },
    {
      Header: HeaderCell({ label: "label_name" }),
      accessor: "name",
      minWidth: 216,
      width: 216,
      Cell: StringCell(),
      sortType: stringCompareFn(""),
      filter: valueFilterFn(""),
    },
    {
      Header: HeaderCell({ label: "label_cup" }),
      accessor: "cup",
      minWidth: 81,
      width: 81,
      Cell: MultipleIndicatorCell(),
      sortType: errorsCompareFn("", "color"),
    },
    {
      Header: HeaderCell({ label: "label_coffee" }),
      accessor: "coffee",
      minWidth: 81,
      width: 81,
      Cell: MultipleIndicatorCell(),
      sortType: errorsCompareFn("", "color"),
    },
    {
      Header: HeaderCell({ label: "label_lid" }),
      accessor: "lid",
      minWidth: 81,
      width: 81,
      Cell: MultipleIndicatorCell(),
      sortType: errorsCompareFn("", "color"),
    },
    {
      Header: HeaderCell({ label: "label_milk" }),
      accessor: "milk",
      minWidth: 81,
      width: 81,
      Cell: IndicatorCell(),
      sortType: errorCompareFn(""),
    },
    {
      Header: HeaderCell({ label: "label_sugar" }),
      accessor: "sugar",
      minWidth: 81,
      width: 81,
      Cell: IndicatorCell(),
      sortType: errorCompareFn(""),
    },
    {
      Header: HeaderCell({ label: "label_ice" }),
      accessor: "ice",
      minWidth: 81,
      width: 81,
      Cell: IndicatorCell(),
      sortType: errorCompareFn(""),
    },
    {
      Header: HeaderCell({ label: "label_soda" }),
      accessor: "soda",
      minWidth: 81,
      width: 81,
      Cell: IndicatorCell(),
      sortType: errorCompareFn(""),
    },
    {
      Header: HeaderCell({ label: "label_cash" }),
      accessor: "cash",
      minWidth: 81,
      width: 81,
      Cell: MultipleIndicatorCell(),
      sortType: errorsCompareFn("", "color"),
    },
    {
      Header: HeaderCell({ label: "label_waste_unit" }),
      accessor: "wasteUnit",
      minWidth: 81,
      width: 81,
      Cell: IndicatorCell(),
      sortType: errorCompareFn(""),
    },
    {
      Header: HeaderCell({ label: "label_last_sell" }),
      accessor: "lastSell",
      minWidth: 88,
      width: 88,
      Cell: TextAndIndicatorCell(),
      sortType: timestampCompareFn("sortingInfo.lastSellTimeStamp"),
    },
    {
      Header: HeaderCell({ label: "label_expected" }),
      accessor: "expected",
      minWidth: 90,
      width: 90,
      Cell: CurrencyCell(),
    },
    {
      Header: HeaderCell({ label: "label_today" }),
      accessor: "today",
      minWidth: 90,
      width: 90,
      Cell: CurrencyCell(),
    },
    {
      Header: HeaderCell({ label: "status_error" }),
      accessor: "error",
      minWidth: 73,
      width: 73,
      Cell: StringFillBackgroundCell({ fillFullCell: true }),
      sortType: errorCompareFn("backgroundColor"),
      filter: listFilterFn("backgroundColor"),
    },
  ];
}
