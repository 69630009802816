// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableHeader--ruT7V2dAnG{padding-top:32px;padding-bottom:32px;display:flex;justify-content:space-between}.TableHeader--ruT7V2dAnG .ButtonsContainer--1WaOEyyMA7{width:100%;display:flex;gap:25px}.TableHeader--ruT7V2dAnG .ButtonsContainer--1WaOEyyMA7>*:last-child{margin-left:auto}@media screen and (max-width: 750px){.TableHeader--ruT7V2dAnG .ButtonsContainer--1WaOEyyMA7{flex-direction:column}.TableHeader--ruT7V2dAnG .ButtonsContainer--1WaOEyyMA7>*:last-child{margin-left:revert}}.TableContainer--26WKTrXItr{overflow:auto}.TableContainer--26WKTrXItr>:first-child{min-width:min-content}", ""]);
// Exports
exports.locals = {
	"TableHeader": "TableHeader--ruT7V2dAnG",
	"ButtonsContainer": "ButtonsContainer--1WaOEyyMA7",
	"TableContainer": "TableContainer--26WKTrXItr"
};
module.exports = exports;
