import { AxiosError } from 'axios';
import store from 'common/store';
import { addToast } from 'common/store/actions/uiActions';
import { useMutation, useQueryClient } from 'react-query';
import { Dispatch, AnyAction } from 'redux';
import { IPatchUserRole } from 'userandauth/interfacesUser';
import { userKeys } from './userKeys';
import { patchUserRole } from 'userandauth/servicesUser';

function usePatchUserRole() {
  const queryClient = useQueryClient();

  return useMutation<string, AxiosError, IUsePatchUser>(
    ({ newUserData, userId }) => patchUserRole(newUserData, userId),
    {
      onSuccess: (_, { userId }) => {
        addToast({
          type: 'success',
          text: 'toast_user_patch_success',
        })(store.dispatch as Dispatch<AnyAction>);
        queryClient.invalidateQueries(userKeys.user(userId));
        queryClient.invalidateQueries(userKeys.all());
      },
    }
  );
}

export { usePatchUserRole };

interface IUsePatchUser {
  userId: string;
  newUserData: IPatchUserRole;
}
