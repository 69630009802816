import * as React from 'react';
import { useState } from 'react';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';
import Input from 'common/components/input/Input';
import Button from 'common/components/button/Button';
import GoogleLogin from 'common/components/googlelogin/GoogleLogin';

import config from 'common/config';

import * as styles from './Signup.module.scss';
import { useHistory, useLocation, Redirect } from 'react-router';
import { useAuthStore } from 'userandauth/useAuthStore';
import { signup } from 'userandauth/utilsAuth';
import { useDispatch } from 'react-redux';
import { addToast } from 'common/store/actions/uiActions';

function SignUp(): JSX.Element {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const setupToken = params.get('setupToken');

  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isLoggedIn = useAuthStore((state) => state.isLoggedIn);

  if (isLoggedIn) {
    return <Redirect to="/" />;
  }

  async function handlePasswordSignUp(): Promise<void> {
    try {
      setIsLoading(true);
      await signup({
        loginWith: 'password',
        postBody: {
          password,
          setupToken,
        },
      });
      // should do something else here that let the user know that the account setup is successful
      // instead of redirecting them to the login page
      history.replace((location as any).state?.from?.pathname ?? '/');
    } catch (err) {
      addToast({
        type: 'error',
        text: 'toast_login_failed',
        raw: err.message,
      })(dispatch);
      setIsLoading(false);
    }
  }

  async function handleGoogleSignUp(googleObject): Promise<void> {
    try {
      await signup({
        loginWith: 'google',
        postBody: {
          accessToken: googleObject.tokenId,
          setupToken,
        },
      });
      // should do something else here that let the user know that the account setup is successful
      // instead of redirecting them to the login page
      history.replace((location as any).state?.from?.pathname ?? '/');
    } catch (err) {
      addToast({
        type: 'error',
        text: 'toast_login_failed',
        raw: err.message,
      })(dispatch);
    }
  }

  function isCorrectFormat(password: string) {
    return password.length >= 8 && password.search(/[A-Z]/) >= 0;
  }

  return (
    <div className={styles.Login}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <Icon name="Vendii" className={styles.logo} />
        </div>
        <div className={styles.card}>
          <Typography
            type="headline-6"
            translationKey="signup_title"
            className={styles.title}
          />

          {config.GOOGLE_CLIENT_ID && (
            <>
              <GoogleLogin
                onSuccess={handleGoogleSignUp}
                onError={() => alert(JSON.stringify('Something went wrong'))}
              />

              <div className={styles.orLine}>
                <div className={styles.line} />
                <Typography
                  type="body-1"
                  translationKey="label_or"
                  className={styles.or}
                />
                <div className={styles.line} />
              </div>
            </>
          )}

          <Input
            value={password}
            onChange={setPassword}
            type="password"
            label="login_password_placeholder"
            className={styles.input}
          />

          <Input
            value={confirmPassword}
            onChange={setConfirmPassword}
            type="password"
            label="login_confirm_password_placeholder"
            className={styles.input}
          />

          <Button
            className={styles.signInButton}
            translationKey="login_finish_sign_up"
            type="primary"
            onClick={handlePasswordSignUp}
            disabled={
              confirmPassword === '' ||
              password === '' ||
              confirmPassword !== password ||
              !isCorrectFormat(password) ||
              !isCorrectFormat(confirmPassword)
            }
            loading={isLoading}
          />
          {confirmPassword !== password && (
            <div>
              <Typography
                type="subtitle-1"
                translationKey="please_match_password"
                className={styles.Warn}
              />
            </div>
          )}
          {!isCorrectFormat(password) && !isCorrectFormat(confirmPassword) && (
            <div>
              <Typography
                type="subtitle-1"
                translationKey="password_condition"
                className={styles.Warn}
              />
            </div>
          )}
        </div>
      </div>

      <img className={styles.vendingcity} src="/images/vendingcity.png" />
    </div>
  );
}

export default SignUp;
