import * as moment from 'moment';

import { APIMonitorMachine, ICompartmentStatus } from 'machines/interfaces';
import {
  BadgeCellValues,
  CurrencyCellValues,
  IndicatorCellValues,
  LinkCellValues,
  MultipleIndicatorCellValues,
  StringCellValues,
  StringFillBackgroundCellValues,
  TextAndIndicatorCellValues,
} from 'components/Table/Cells';
import { IIndicatorProps } from 'common/components/Indicator';

type ErrorBackgroundColor = 'success' | 'warning' | 'error';

export interface ITableRow {
  machineId: LinkCellValues;
  status: BadgeCellValues & {
    translationKey:
      | 'status_online'
      | 'status_maintenance_short'
      | 'status_out_of_service_short'
      | 'status_offline'
      | 'status_standby'
      | undefined;
    backgroundColor:
      | 'success'
      | 'warning'
      | 'error'
      | 'onSurfaceDisabled'
      | undefined;
    textColor: 'surface' | undefined;
  };
  organisation: StringCellValues & APIMonitorMachine['organisationName'];
  name: StringCellValues & APIMonitorMachine['machineName'];
  coffee: MultipleIndicatorCellValues;
  milk: IndicatorCellValues;
  sugar: IndicatorCellValues;
  ice: IndicatorCellValues;
  soda: IndicatorCellValues;
  cup: MultipleIndicatorCellValues;
  cash: MultipleIndicatorCellValues;
  lid: MultipleIndicatorCellValues;
  wasteUnit: IndicatorCellValues;
  lastSell: {
    sortingInfo: {
      lastSellTimeStamp: number | undefined;
    };
    cellData: TextAndIndicatorCellValues['cellData'];
  };
  error: StringFillBackgroundCellValues & {
    backgroundColor: ErrorBackgroundColor;
  };
  expected: CurrencyCellValues;
  today: CurrencyCellValues;
}

export function mapDataToTable(
  filteredData: APIMonitorMachine[] | undefined | null
): Array<ITableRow> {
  if (filteredData === null || filteredData === undefined) {
    return null;
  }

  return filteredData
    .map((machine) => ({
      machineId: {
        translationKey: machine.machineId,
        to: `/machines/detail/${machine.machineId}/inventory`,
      },
      status: computeStatusCell(machine.machineStatus),
      organisation: machine.organisationName,
      name: machine.machineName,
      coffee: computeMultipleIndicatorColor(machine.compartmentStatus.coffee),
      milk: computeIndicatorColor(machine.compartmentStatus.milk),
      sugar: computeIndicatorColor(machine.compartmentStatus.sugar),
      ice: computeIndicatorColor(machine.compartmentStatus.ice),
      soda: computeIndicatorColor(machine.compartmentStatus.soda),
      cash: computeMultipleIndicatorColor(machine.compartmentStatus.cash),
      cup: computeMultipleIndicatorColor(machine.compartmentStatus.cup),
      lid: computeMultipleIndicatorColor(machine.compartmentStatus.lid),
      wasteUnit: computeIndicatorColor(machine.compartmentStatus.wasteUnit),
      lastSell: {
        sortingInfo: {
          lastSellTimeStamp: machine.lastSell, // Work around to compare the cell by the timestamp
        },
        cellData: computeLastSellField(machine.lastSell), // What will be inserted given to the cell
      },
      error: computerErrorField(machine.error),
      expected: machine.historicalSale ?? 0,
      today: machine.todaySale ?? 0,
    }))
    .sort((a, b) =>
      a.machineId.translationKey.localeCompare(b.machineId.translationKey)
    );
}
function computeIndicatorColor(
  compartmentStatus: ICompartmentStatus | undefined
): IIndicatorProps['color'] {
  switch (compartmentStatus) {
    case 'normal':
      return 'success';
    case 'warning':
      return 'warning';
    case 'error':
      return 'error';
    default:
      return undefined;
  }
}
function computeMultipleIndicatorColor(multipleCompartmentStatus: {
  [k: string]: ICompartmentStatus;
}) {
  const mapped = Object.keys(multipleCompartmentStatus).map((key) => {
    return {
      name: key,
      color: computeIndicatorColor(multipleCompartmentStatus[key]),
    };
  });
  return mapped;
}

function computeLastSellField(
  lastSell: APIMonitorMachine['lastSell']
): ITableRow['lastSell']['cellData'] {
  if (lastSell === undefined || lastSell === 0) {
    return {
      translationKey: 'N/A',
      indicatorColor: undefined,
    };
  }

  const differenceInSeconds = Math.floor(
    (moment().valueOf() - lastSell) / 1000
  );
  const differenceInMins = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMins / 60);
  const differenceInDays = Math.floor(differenceInHours / 24);

  if (differenceInDays >= 1) {
    return {
      indicatorColor: 'error',
      translationKey: [String(differenceInDays), ' ', 'label_day'],
    };
  }

  if (differenceInHours >= 3) {
    return {
      indicatorColor: 'error',
      translationKey: [String(differenceInHours), ' ', 'label_hour_short'],
    };
  }

  if (differenceInHours >= 2) {
    return {
      indicatorColor: 'warning',
      translationKey: [String(differenceInHours), ' ', 'label_hour_short'],
    };
  }

  if (differenceInHours >= 1) {
    return {
      indicatorColor: 'success',
      translationKey: [String(differenceInHours), ' ', 'label_hour_short'],
    };
  }

  return {
    indicatorColor: 'success',
    translationKey: [
      String(Math.max(differenceInMins, 1)),
      ' ',
      'label_minute_short',
    ],
  };
}

function computeStatusCell(
  machineStatus: APIMonitorMachine['machineStatus']
): ITableRow['status'] {
  switch (machineStatus) {
    case 'online':
      return {
        translationKey: 'status_online',
        backgroundColor: 'success',
        textColor: 'surface',
      };
    case 'maintenance':
      return {
        translationKey: 'status_maintenance_short',
        backgroundColor: 'warning',
        textColor: 'surface',
      };
    case 'offline':
      return {
        translationKey: 'status_offline',
        backgroundColor: 'error',
        textColor: 'surface',
      };
    case 'out_of_service':
      return {
        translationKey: 'status_out_of_service_short',
        backgroundColor: 'error',
        textColor: 'surface',
      };
    case 'standby':
      return {
        translationKey: 'status_standby',
        backgroundColor: 'onSurfaceDisabled',
        textColor: 'surface',
      };
    default: {
      return {
        translationKey: undefined,
        backgroundColor: undefined,
        textColor: undefined,
      };
    }
  }
}

function computerErrorField(
  error: APIMonitorMachine['error'] | undefined
): ITableRow['error'] {
  let translationKey: undefined | string;
  let backgroundColor: ErrorBackgroundColor;
  let textColor: undefined | 'surface';

  if (error === undefined || error === null) {
    translationKey = undefined;
    backgroundColor = 'success';
    textColor = undefined;
  } else if (error !== undefined && error.type === 'warning') {
    backgroundColor = 'warning';
    textColor = 'surface';
    translationKey = error.code;
  } else if (error !== undefined && error.type === 'error') {
    backgroundColor = 'error';
    textColor = 'surface';
    translationKey = error.code;
  }

  return {
    translationKey: translationKey,
    backgroundColor: backgroundColor,
    textColor: textColor,
  };
}
