import { LinkCellProps } from "common/components/table/cells/LinkCell/LinkCell";
import { ItextAndIndicatorCellRenderProp } from "common/components/table/cells/textAndIndicatorCell";
import { IMachineDataWithExtraFields } from "machines/interfaces";

export interface ITableRowForMachineList {
  machineId: IMachineDataWithExtraFields['id'];
  status: IMachineDataWithExtraFields['status'];
  org: IMachineDataWithExtraFields['organisationName'];
  machineName: IMachineDataWithExtraFields['name'];
  todaySales: IMachineDataWithExtraFields['todaySales'];
  inventory: ItextAndIndicatorCellRenderProp;
  disabledSlots: IMachineDataWithExtraFields['disabledSlots'];
  sales7DaysAgo: number,
  zone: string | null,
  lastRefill: {
    timeStamp: number | null,
    displayString: string,
  },
  refillStatus: string,
  revenueSinceLastRefill: number,
  table: {
    machineId: LinkCellProps
  }
}

const SORT_MAP : Record< ITableRowForMachineList['status'], number> = {
  online: 1,
  maintenance: 2,
  out_of_service: 3,
  offline: 4
};

function statusCellSortingFn (rowA: ITableRowForMachineList, rowB: ITableRowForMachineList): number {
  const rowAMachineStatus = rowA.status;
  const rowBMachineStatus = rowB.status;
  return SORT_MAP[rowAMachineStatus] - SORT_MAP[rowBMachineStatus];
}

export { statusCellSortingFn };
