import * as React from "react";
import { toasti18n } from "utils/toast";
import Icon from "common/components/icon/Icon";
import Menu from "@mui/material/Menu";
import { MenuWithIcon } from "./MenuWithIcon";
import { DownloadTransactionReportGroupByLocationModal } from "../modals/DownloadTransactionReportGroupByLocation";
import { useTranslation } from "react-i18next";
import { useDownloadFactoryLocationsCSV } from "./hooks/useDownloadFactoriesLocationsCSV";
import { useDownloadMachineLocationsCSV } from "./hooks/useDownloadMachineLocationsCSV";
import UploadServiceZoneModal from "../modals/UploadServiceZoneModal";
import UploadRefillZoneModal from "../modals/UploadRefillZoneModal";

export function LocationDropDownV2() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // download report hooks
  const { downloadFactoryLocationsMutate, isLoading: isLoadingDownloadFactoryLocations } = useDownloadFactoryLocationsCSV();
  const { downloadMachineLocationsMutate, isLoading: isLoadingDownloadMachineLocation } = useDownloadMachineLocationsCSV();

  // modal state
  const [isDownloadAllLocationsModalOpen, setIsDownloadAllLocationsModalOpen] =
    React.useState(false);
  const [isUploadRefillZoneModalOpen, setIsUploadRefillZoneModalOpen] =
    React.useState(false);
  const [isUploadServiceZoneModalOpen, setIsUploadServiceZoneModalOpen] =
    React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id="location-dropdown"
        className="cursor-pointer !mb-2 p-2"
        aria-controls={open ? "icon-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        role="button"
        onClick={handleClick}
      >
        <Icon name="Ellipsis" color="primary400" />
      </div>
      <Menu
        id="location-list-dropdown"
        aria-labelledby="location list dropdown"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuWithIcon
          iconName="DownloadTray"
          onClick={() => {
            setIsDownloadAllLocationsModalOpen(true);
            handleClose();
          }}
        >
          {t("label_download_all_locations")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="DownloadTray"
          onClick={async () => {
            await downloadFactoryLocationsMutate();
            handleClose();
          }}
          disabled={isLoadingDownloadFactoryLocations}
        >
          {t("label_download_all_factories")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="DownloadTray"
          onClick={async () => {
            await downloadMachineLocationsMutate();
            handleClose();
          }}
          disabled={isLoadingDownloadMachineLocation}
        >
          {t("label_download_machine_locations")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Upload"
          onClick={async () => {
            setIsUploadRefillZoneModalOpen(true);
            handleClose();
          }}
        >
          {t("label_upload_refill_zone")}
        </MenuWithIcon>
        <MenuWithIcon
          iconName="Upload"
          onClick={async () => {
            setIsUploadServiceZoneModalOpen(true);
            handleClose();
          }}
        >
          {t("label_upload_service_zone")}
        </MenuWithIcon>
      </Menu>
      <DownloadTransactionReportGroupByLocationModal
        open={isDownloadAllLocationsModalOpen}
        onClose={() => setIsDownloadAllLocationsModalOpen(false)}
        onSuccess={(data) => {
          setIsDownloadAllLocationsModalOpen(false);
          window.open(`/download-report/${data.generateReport}`);
        }}
        onError={() => toasti18n.error({ message: "error" })}
      />
      {isUploadRefillZoneModalOpen && (
        <UploadRefillZoneModal
          onClose={() => setIsUploadRefillZoneModalOpen(false)}
        />
      )}

      {isUploadServiceZoneModalOpen && (
        <UploadServiceZoneModal
          onClose={() => setIsUploadServiceZoneModalOpen(false)}
        />
      )}
    </>
  );
}
