import * as React from "react";
import {
  CursorPagination,
  LocationType,
  useGetLocationsV2Query,
} from "gql/generated";
import {
  ColumnFiltersState,
  TableV2,
  createColumnHelper,
} from "components/tableV2";
import {
  mapDataToTableLocationsV2,
  LocationsTableDataRow
} from "./mapDataToTable";
import { Link } from "react-router-dom";
import { mapColumnFilterToLocationFilter } from "./mapColumnFilterToLocationFilter";
import LocationHeader from "../../components/LocationHeader";
import { useTranslation } from "react-i18next";
import { startCase } from "lodash";

const columnHelper = createColumnHelper<LocationsTableDataRow>();

const RowPerPage = 20;

const initialPagination: CursorPagination = {
  first: RowPerPage,
};

export default function LocationList() {
  const { t } = useTranslation();
  const { t: locationI18n } = useTranslation("location", {
    keyPrefix: "table"
  });
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );
  const [pagination, setPagination] =
    React.useState<CursorPagination>(initialPagination);
  const { data, isLoading } = useGetLocationsV2Query({
    pagination: pagination,
    filter: mapColumnFilterToLocationFilter(columnFilters),
  });
  const dataRows = React.useMemo(
    () => mapDataToTableLocationsV2(data),
    [data]
  );

  const handleColumnFilterChange = (state: ColumnFiltersState) => {
    setPagination(initialPagination);
    setColumnFilters(state);
  };

  const columns = React.useMemo(() => [
    columnHelper.accessor("friendlyId", {
      header: () => <>{t("label_location_id")}</>,
      maxSize: 120,
      cell: (info) => (
        <div>
          <Link
            to={`/location/${info.row.original.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="font-normal text-primary"
          >
            {info.getValue()}
          </Link>
        </div>
      ),
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          location: "primary",
          type: "text",
          placeholder: t("label_location_id"),
          label: t("label_location_id"),
          order: 2,
        },
      },
    }),
    columnHelper.accessor("machineId", {
      header: () => <>{t("label_machine_id")}</>,
      maxSize: 120,
      cell: (info) => (
        <div className="flex gap-1 flex-wrap">
          {info.getValue().length === 0 && "-"}
          {info.getValue().length === 1 &&
            info.getValue().map((machineId) => (
              <Link
                key={machineId}
                to={`/machines/detail/${machineId}/details`}
                className="font-normal text-primary"
              >
                {machineId}
              </Link>
            ))}
          {info.getValue().length > 1 && (
            <div>
              <Link
                to={`/location/${info.row.original.id}`}
                className="font-normal text-primary"
              >
                {locationI18n("column.label_machine_ids", { count: info.getValue().length })}
              </Link>
            </div>
          )}
        </div>
      ),
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          location: "primary",
          type: "text",
          placeholder: t("label_machine_id"),
          label: t("label_machine_id"),
          order: 1,
        },
      },
    }),
    columnHelper.accessor("locationName", {
      header: () => <>{t("label_location_name")}</>,
      cell: (info) => <div>{info.getValue()}</div>,
      enableColumnFilter: true,
      minSize: 400,
      meta: {
        columnFilter: {
          location: "primary",
          type: "text",
          label: t("label_location_name"),
          placeholder: t("label_location_name"),
          order: 3,
        },
      },
    }),
    columnHelper.accessor("locationType", {
      header: () => <>{t("label_location_type")}</>,
      cell: (info) => (
        <div>
          {t(`label_${info.getValue().toLowerCase().split(" ").join("_")}`)}
        </div>
      ),
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          location: "secondary",
          label: t("label_location_type"),
          type: "autocomplete",
          options: Object.keys(LocationType).map((key) => ({
            label: startCase(LocationType[key as keyof typeof LocationType].toLowerCase()),
            value: LocationType[key as keyof typeof LocationType],
          })),
          placeholder: t("label_location_type"),
          multiple: true,
        },
      },
    }),
    columnHelper.accessor("refillZoneName", {
      header: () => <>{t("label_refill_zone")}</>,
      cell: (info) => <div>{info.getValue()}</div>,
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          location: "modal",
          type: "text",
          label: t("label_refill_zone"),
        },
      },
    }),
    columnHelper.accessor("serviceZoneName", {
      header: () => <>{t("label_service_zone")}</>,
      cell: (info) => <div>{info.getValue()}</div>,
      enableColumnFilter: true,
      meta: {
        columnFilter: {
          location: "modal",
          type: "text",
          label: t("label_service_zone"),
        },
      },
    }),
    columnHelper.accessor("locationGrade", {
      header: () => <>{t("label_location_grade")}</>,
      cell: (info) => <div>{info.getValue()}</div>,
    }),
  ], [t]);

  return (
    <div>
      <LocationHeader />
      <TableV2
        tableName="Location Table"
        columns={columns}
        isLoading={isLoading}
        data={dataRows}
        onColumnFiltersChange={handleColumnFilterChange}
        pagination={{
          hasNextPage: data?.locationsV2?.pageInfo?.hasNextPage || false,
          hasPreviousPage:
            data?.locationsV2?.pageInfo?.hasPreviousPage || false,
          onNextPage: () =>
            setPagination(() => ({
              after: data?.locationsV2?.pageInfo?.endCursor,
              first: RowPerPage,
              last: undefined,
              before: undefined,
            })),
          onPreviousPage: () =>
            setPagination(() => ({
              first: undefined,
              after: undefined,
              last: RowPerPage,
              before: data?.locationsV2?.pageInfo?.startCursor,
            })),
          resetPagination: () => setPagination(initialPagination),
        }}
      />
    </div>
  );
}
