import * as React from "react";

import { Modal, Tooltip } from "@mui/material";
import LoadingScreen from "components/LoadingScreen";
import useKittingModalState, {
  KittingModalState,
} from "./useKittingModalState";

import * as styles from "./KittingModal.module.scss";

import Icon from "common/components/icon/Icon";
import TextButton from "common/components/textbutton/TextButton";
import Button from "components/Button";
import Typography from "components/Typography";
import Checkbox from "components/Checkbox";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";
import {
  PriorityCell,
  isValidRefillPriority,
  STATUS_COLOR_MAP,
  STATUS_TRANSLATION_KEY_MAP,
  ModalFallback,
} from "../../../Components";
import { toasti18n } from "utils/toast";
import { ErrorBoundary } from "react-error-boundary";
import {
  GetCreatedPlanByIdQuery,
  MachineKind,
  useGetCreatedPlanByIdQuery,
  useReplanMutation,
} from "gql/generated";

import * as moment from "moment";
import { useDownloadOperationPDFByPlanId } from "containers/new-operations/refill-order/operation-pdf/useDownloadOperationPDFByPlanId";
import { ConfirmationModal } from "./ConfirmationModal";
import { InventoryBarWithSelection } from "containers/new-operations/refill-order/InventoryBar";
import { useForecastUsage } from "./useForecastUsage";
import { useGetWarehouseProducts } from "./useGetWarehouseProducts";
import {
  trackMachineKittingPlanGenerated,
  trackPlanMoveToPrekits,
} from "./tracking";
import { useTranslation } from "react-i18next";
import analytics from "utils/analytics";
import { Link } from "react-router-dom";
import { clsx } from "clsx";

interface Props {
  planId: string;
  onClose: () => void;
  onReplanSuccess: () => void;
  onForecastedSuccess: () => void;
  onRemoveMachineFromPlanSuccess: () => void;
}

function ModalContainer(props: Props): JSX.Element {
  return (
    <Modal open={true} onClose={props.onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          <ErrorBoundary
            fallbackRender={({ error }) => (
              <ModalFallback
                error={error}
                metaData={`operations/refillorder/kitting | ID: ${props.planId}`}
                onClose={props.onClose}
              />
            )}
          >
            <KittingModalDataFetcher {...props} />
          </ErrorBoundary>
        </div>
      </div>
    </Modal>
  );
}

export function checkIfRoutingSkipped(
  plan: GetCreatedPlanByIdQuery["plan"] | undefined
) {
  if (!plan) return undefined;

  const startTime = plan.startTime;
  const refillOrders = plan.refillOrders;
  const refillCandidateTimes = refillOrders.map(
    (order) => order.estimatedArrival
  );
  return refillCandidateTimes.every((time) => time === startTime);
}

function KittingModalDataFetcher(props: Props) {
  const { data: planQuery, isLoading: isPlanLoading } =
    useGetCreatedPlanByIdQuery(
      {
        planId: props.planId,
      },
      {
        cacheTime: 0,
      }
    );
  const { isLoading: isWarehouseProductsLoading } = useGetWarehouseProducts(
    planQuery?.plan.warehouseId
  );

  const { isLoading: isUsageLoading } = useForecastUsage(planQuery, {
    enabled: !checkIfRoutingSkipped(planQuery?.plan),
  });

  const isLoading =
    isWarehouseProductsLoading || isPlanLoading || isUsageLoading;

  return (
    <Modal open={true} onClose={props.onClose}>
      <div className={styles.ModalOverlay}>
        <div className={styles.ModalContainer}>
          {isLoading ? <LoadingScreen /> : <KittingModal {...props} />}
        </div>
      </div>
    </Modal>
  );
}

function KittingModal({
  planId,
  onClose,
  onForecastedSuccess,
  onReplanSuccess,
}: Props) {
  const kittingModalState = useKittingModalState({
    planId: planId,
  });
  const {
    isSubmitting,
    submitForm: forecastPlan,
    hasChanged,
  } = kittingModalState.form;
  const [step, setStep] = React.useState<"REVIEW" | "SELECT_ITEMS">(
    "SELECT_ITEMS"
  );
  const [showWarningUponExitModal, setShowWarningUponExitModal] =
    React.useState(false);
  const [isPlanSuccessfullyCreated, setIsPlanSuccessfullyCreated] =
    React.useState(false);

  const {
    downloadOperationPDFByPlanId,
    isLoading: isDownloadingOperationSheet,
  } = useDownloadOperationPDFByPlanId();

  const [_selectedMachineId, setSelectedMachineId] = React.useState(
    () => Object.keys(kittingModalState.machines)[0]
  );

  const selectedMachineId = kittingModalState["machines"][_selectedMachineId]
    ? _selectedMachineId
    : Object.values(kittingModalState["machines"]).sort(
        (a, b) => a.positionInPlan - b.positionInPlan
      )[0].machineId;

  const { mutate: replan, isLoading: isReplanLoading } = useReplanMutation();

  return (
    <>
      {showWarningUponExitModal ? (
        <ConfirmationModal
          onAccept={onClose}
          onClose={() => setShowWarningUponExitModal(false)}
        />
      ) : null}
      <div className={styles.KittingModal}>
        {kittingModalState.plan.isRoutingSkipped ? (
          <ForecastingTurnedOffNotifier />
        ) : null}
        <div className={styles.Header}>
          <Typography type="headline-6" color="onSurfaceHigh" translate>
            label_plan
            {` ${kittingModalState.plan.planName} `}
          </Typography>
          <Typography type="subtitle-1" color="onSurfaceMedium" translate>
            {"("}
            {kittingModalState.aggregates.numberOfRecommendedRefillSlots}
            {` `}
            label_recommended
            {` `}
            label_refill
            {")"}
          </Typography>
        </div>

        <div className={styles.GrowArea}>
          {step === "SELECT_ITEMS" ? (
            <KittingTable
              kittingModalState={kittingModalState}
              selectedMachineId={selectedMachineId}
              onMachineRowSelect={setSelectedMachineId}
            />
          ) : null}
          {step === "REVIEW" ? (
            <ReviewTable kittingModalState={kittingModalState} />
          ) : null}
        </div>

        <div className={styles.ButtonsContainer}>
          {step === "SELECT_ITEMS" ? (
            <>
              <Button
                type="secondary"
                onClick={
                  hasChanged ? () => setShowWarningUponExitModal(true) : onClose
                }
                disabled={isReplanLoading}
              >
                label_close
              </Button>
              <div className={styles.ReplanAndReviewContainer}>
                <Button
                  type="secondary"
                  loading={isReplanLoading}
                  onClick={() => {
                    replan(
                      { rePlanId: planId },
                      {
                        onError: (err) => {
                          toasti18n.error(err as Error);
                        },
                        onSuccess: () => {
                          onClose();
                          toasti18n.success();
                          onReplanSuccess();
                        },
                      }
                    );
                  }}
                >
                  label_replan
                </Button>
                <Button
                  type="primary"
                  onClick={() => setStep("REVIEW")}
                  disabled={
                    !kittingModalState.form.isFormValid || isReplanLoading
                  }
                >
                  action_review
                </Button>
              </div>
            </>
          ) : null}
          {step === "REVIEW" ? (
            <>
              <Button
                type="secondary"
                onClick={() => setStep("SELECT_ITEMS")}
                disabled={isSubmitting}
                style={
                  isPlanSuccessfullyCreated ? { visibility: "hidden" } : {}
                }
              >
                label_back
              </Button>
              <div className={styles.ReplanAndReviewContainer}>
                {isPlanSuccessfullyCreated ? (
                  <TextButton
                    translationKey="action_download_refill_paper"
                    onClick={() => downloadOperationPDFByPlanId(planId)}
                    icon="DownloadTray"
                    disabled={isDownloadingOperationSheet}
                  />
                ) : null}

                {isPlanSuccessfullyCreated ? (
                  <Button
                    type="primary"
                    loading={isDownloadingOperationSheet}
                    onClick={onClose}
                  >
                    label_close
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={isSubmitting}
                    onClick={() => {
                      forecastPlan({
                        onError: (err: Error) => {
                          toasti18n.error(err);
                        },
                        onSuccess: (_, variables) => {
                          toasti18n.success();
                          setIsPlanSuccessfullyCreated(true);
                          onForecastedSuccess();

                          trackMachineKittingPlanGenerated({
                            data: kittingModalState,
                            planId: variables.planId,
                            variables,
                          });
                          trackPlanMoveToPrekits(kittingModalState);
                        },
                      });
                    }}
                  >
                    action_generate
                  </Button>
                )}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}
export { ModalContainer as KittingModal };

function ReviewTable({
  kittingModalState,
}: {
  kittingModalState: KittingModalState;
}) {
  const machines = Object.values(kittingModalState.machines);

  return (
    <div className={styles.ReviewTableScrollContainer}>
      <div className={styles.ReviewTableContainer}>
        <div className={styles.ReviewTableHeader}>
          <div className={styles.OrderColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              menu_order
            </Typography>
          </div>
          <div className={styles.MachineIdColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_machine_id
            </Typography>
          </div>
          <div className={styles.PriorityColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_priority
            </Typography>
          </div>
          <div className={styles.LocationNameColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_location_name
            </Typography>
          </div>
          <div className={styles.TypeColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_type
            </Typography>
          </div>
          <div className={styles.ErrorColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              status_error
            </Typography>
          </div>
          <div className={styles.RevenueColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_revenue
            </Typography>
          </div>
          <div className={styles.TotalColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_total
            </Typography>
          </div>
          <div className={styles.NoteColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_note
            </Typography>
          </div>
          <div className={styles.TimeToColumn}>
            <Typography type="body-2" translate color="onSurfaceDisabled">
              label_time_to
            </Typography>
          </div>
        </div>
        {[...machines]
          .sort(
            (machineA, machineB) =>
              machineA.positionInPlan - machineB.positionInPlan
          )
          .map((machine) => (
            <div key={machine.machineId} className={styles.Row}>
              <div className={styles.OrderColumn}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {machine.positionInPlan}
                </Typography>
              </div>
              <div className={styles.MachineIdColumn}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {machine.machineId}
                </Typography>
              </div>
              <div className={styles.PriorityColumn}>
                <PriorityCell
                  priority={
                    isValidRefillPriority(machine.refillPriority)
                      ? machine.refillPriority
                      : null
                  }
                />
              </div>
              <div className={styles.LocationNameColumn}>
                <div className={styles.TwoLinesWrapper}>
                  <Typography type="body-2" translate color="onSurfaceHigh">
                    {machine.location.name}
                  </Typography>
                </div>
              </div>
              <div className={styles.TypeColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  label_refill
                  {(() => {
                    const numberOfSwapItems =
                      machine.refillOrder.swapOrders.reduce((curr, next) => {
                        return curr + next.swapItems.length;
                      }, 0);
                    return numberOfSwapItems > 0
                      ? [" & ", "label_swap", ` (${numberOfSwapItems})`]
                      : null;
                  })()}
                </Typography>
              </div>
              <div className={styles.ErrorColumn}>
                {machine.error.mostRecentErrorCode ? (
                  <Typography type="body-2" translate color="error">
                    {machine.error.mostRecentErrorCode}
                  </Typography>
                ) : (
                  <Typography type="body-2" translate color="onSurfaceHigh">
                    -
                  </Typography>
                )}
              </div>
              <div className={styles.RevenueColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  {machine.location.revenue}
                </Typography>
              </div>
              <div className={styles.TotalColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  {
                    kittingModalState.aggregates.totalNumberOfItemsPermachine[
                      machine.machineId
                    ]
                  }
                </Typography>
              </div>
              <div className={styles.NoteColumn}>
                <div className={styles.TwoLinesWrapper}>
                  <Typography type="body-2" color="onSurfaceDisabled">
                    {machine.refillOrder.criticalNote ? (
                      <Typography type="body-2" color="error">
                        {machine.refillOrder.criticalNote}{" "}
                      </Typography>
                    ) : null}
                    {machine.refillOrder.locationNote ? (
                      <Typography type="body-2" color="onSurfacehigh">
                        {(machine.refillOrder.criticalNote ? ", " : "") +
                          (machine.refillOrder.locationNote ?? "")}
                      </Typography>
                    ) : null}
                  </Typography>
                </div>
              </div>
              <div className={styles.TimeToColumn}>
                <Typography type="body-2" translate color="onSurfaceHigh">
                  label_before
                  {` ${moment(Number(machine.refillOrder.refillTime)).format(
                    "HH:mm"
                  )}`}
                </Typography>
              </div>
            </div>
          ))}
        <div style={{ height: 100 }}></div>
      </div>
    </div>
  );
}

function KittingTable({
  kittingModalState,
  selectedMachineId,
  onMachineRowSelect,
}: {
  kittingModalState: KittingModalState;
  selectedMachineId: string;
  onMachineRowSelect: (machineId: string) => void;
}) {
  const { t } = useTranslation();
  const selectedMachine = kittingModalState.machines[selectedMachineId];
  const { machineValidity } = kittingModalState.form;

  const spiralMachineData = React.useMemo(() => {
    return selectedMachineId
      ? Object.values(kittingModalState.machines).filter(
          (machine) =>
            machine.parentId === selectedMachineId &&
            machine.kind === MachineKind.SpiralVending
        )
      : [];
  }, [selectedMachineId]);

  return (
    <div className={styles.KittingTableContainer}>
      <div className={styles.MachineIdContainer}>
        <div className={styles.Row}>
          <div className={styles.OrderColumn}>
            <Typography
              translate
              type="body-2"
              color="brainStormingBlackTint300"
            >
              menu_order
            </Typography>
          </div>
          <div className={styles.MachineIdColumn}>
            <Typography
              className="flex-1"
              type="body-2"
              translate
              color="brainStormingBlackTint300"
            >
              label_machine_id
            </Typography>
          </div>
        </div>
        <div className={styles.MachineIdRowsContainer}>
          {Object.values(kittingModalState.machines)
            .sort(
              (machineA, machineB) =>
                machineA.positionInPlan - machineB.positionInPlan
            )
            .map((machine) => (
              <div
                key={machine.machineId}
                className={
                  selectedMachineId === machine.machineId
                    ? styles.RowSelectableSelected
                    : machineValidity[machine.machineId] === "WARN"
                      ? styles.RowSelectableWarning
                      : machineValidity[machine.machineId] === "INVALID"
                        ? styles.RowSelectableError
                        : styles.RowSelectable
                }
                onClick={() => onMachineRowSelect(machine.machineId)}
              >
                <div className={styles.OrderColumn}>
                  <div className={styles.OrderCell}>
                    <Typography type="body-2" color="onSurfaceHigh">
                      {machine.positionInPlan}
                    </Typography>

                    {machine.refillOrder.critical ? (
                      <div className={styles.CriticalIcon}>
                        <Icon name="PriorityMajor" />
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className={styles.MachineIdColumn}>
                  <div className="flex flex-1 items-center">
                    <div className="flex-1">
                      {machine.kind === MachineKind.SpiralVending ? (
                        <div>
                          <Typography
                            type="body-2"
                            translate
                            color="onSurfaceHigh"
                            className="leading-5"
                          >
                            {machine.machineId}
                          </Typography>
                          <p className="font-kanit text-caption leading-4 text-on-surface-disabled">
                            {machine?.parentId}
                          </p>
                        </div>
                      ) : (
                        <Typography
                          type="body-2"
                          translate
                          color="onSurfaceHigh"
                        >
                          {machine.machineId}
                        </Typography>
                      )}
                    </div>
                    <div className="w-20 flex justify-center">
                      {machine.kind === MachineKind.SpiralVending && (
                        <Icon name="Snack" color="primary" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className={styles.KittingArea}>
        <div className={clsx(styles.KittingAreaHeader, "!h-auto !block py-2 space-y-2")}>
          <div className="flex">
            <div style={{ flexShrink: 0 }}>
              <Typography type="headline-6">
                {selectedMachine.machineId}
              </Typography>
            </div>

            <Typography type="headline-6">
              {selectedMachine.location.name}
            </Typography>
            <div style={{ marginLeft: "auto", flexShrink: 0 }}>
              <Typography type="body-2" translate>
                {
                  kittingModalState.aggregates.numberOfSelectedSlots[
                    selectedMachineId
                  ]
                }
                {` `}
                label_selected
              </Typography>
            </div>
          </div>
          {selectedMachine.kind === MachineKind.BeverageVending &&
              spiralMachineData.length > 0 && (
                <div className="flex items-center gap-1">
                  <Icon name="Snack" color="primary" />
                  <Typography type="body-2" color="onSurfaceMedium">
                    {spiralMachineData?.length}
                  </Typography>
                  <Typography type="body-2" color="onSurfaceMedium" translate>
                    label_spiral_machine
                  </Typography>
                  <div>
                    {spiralMachineData?.map((spiralMachine) => (
                      <Link
                        target="_blank"
                        to={`/machines/detail/${spiralMachine?.machineId}`}
                        key={spiralMachine?.machineId}
                        className="font-medium underline text-body2 text-primary"
                      >
                        {spiralMachine?.machineId}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
          {selectedMachine.kind === MachineKind.SpiralVending && (
            <div className="flex items-center gap-1">
              <Icon name="Machine" color="primary" />
              <p className="text-body2 text-on-surface-medium">
                {t("label_taobin_machine")} :{" "}
              </p>
              <Link
                target="_blank"
                to={`/machines/detail/${selectedMachine?.parentId}`}
                className="font-medium underline text-body2 text-primary"
              >
                {selectedMachine?.parentId}
              </Link>
            </div>
          )}
        </div>
        <div className={styles.KittingTableScrollArea}>
          <div className={styles.KittingTableMinContentWrapper}>
            <InventoryTable
              isForecastingDisabled={kittingModalState.plan.isRoutingSkipped}
              inventory={selectedMachine.inventory}
              inventoryValidity={
                kittingModalState.form.inventoryValidity[selectedMachineId]
              }
              state={kittingModalState}
            />
            <div className={styles.NotesContainer}>
              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_cash_status
                </Typography>
                <div className={styles.NoteItems}>
                  {selectedMachine.cashStatus.isCoinFull ? (
                    <Typography
                      type="subtitle-1"
                      translate
                      color="onSurfaceHigh"
                    >
                      label_coin_full
                    </Typography>
                  ) : null}
                  {selectedMachine.cashStatus.isBankNotesFull ? (
                    <Typography
                      type="subtitle-1"
                      translate
                      color="onSurfaceHigh"
                    >
                      label_bank_full
                    </Typography>
                  ) : null}
                </div>
              </div>

              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_error_detail
                </Typography>
                <div className={styles.NoteItems}>
                  {selectedMachine.error.recentErrorDetails.map(
                    (errorDetail, index) => (
                      <div key={index} className={styles.ErrorDetail}>
                        <Typography type="subtitle-1" color="onSurfaceHigh">
                          {errorDetail.detail}
                        </Typography>
                        <Typography type="subtitle-1" color="onSurfaceHigh">
                          {" "}
                        </Typography>
                        <Typography type="body-3" color="onSurfaceDisabled">
                          {moment(Number(errorDetail.createdAt)).format(
                            "DD/MM/YYYY H:mm"
                          )}
                        </Typography>
                      </div>
                    )
                  )}
                </div>
              </div>

              <div className={styles.NoteContainer}>
                <Typography translate type="caption" color="onSurfaceMedium">
                  label_note
                </Typography>
                <div>
                  {selectedMachine.refillOrder.criticalNote ? (
                    <Typography type="subtitle-1" color="error">
                      {selectedMachine.refillOrder.criticalNote}
                    </Typography>
                  ) : null}
                  {selectedMachine.refillOrder.locationNote ? (
                    <Typography type="subtitle-1" color="onSurfacehigh">
                      {(selectedMachine.refillOrder.criticalNote ? ", " : "") +
                        (selectedMachine.refillOrder.locationNote ?? "")}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
            <div className={styles.KittingTableScrollAreaSpacer}></div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InventoryTable({
  inventory,
  inventoryValidity,
  isForecastingDisabled,
  state,
}: {
  inventory: KittingModalState["machines"][string]["inventory"];
  inventoryValidity: KittingModalState["form"]["inventoryValidity"][string];
  isForecastingDisabled: boolean;
  state: KittingModalState;
}) {
  const sortedInventory = Object.values(inventory).sort(
    (inventoryA, inventoryB) => alphanumeric(inventoryA.slot, inventoryB.slot)
  );

  return (
    <div>
      <div className={styles.KittingTableHeader}>
        <div className={styles.ColumnSlot}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_slot
          </Typography>
        </div>
        <div className={styles.ColumnSKU}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_sku
          </Typography>
        </div>
        <div className={styles.ColumnImage}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_image
          </Typography>
        </div>
        <div className={styles.ColumnIngredient}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_ingredient
          </Typography>
        </div>
        <div className={styles.ColumnLevel}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_level
          </Typography>
        </div>
        <div className={styles.ColumnInventory}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_inventory
          </Typography>
        </div>
        <div className={styles.ColumnStatus}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_status
          </Typography>
        </div>
        <div className={styles.ColumnAvailable}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_available_stocks
          </Typography>
        </div>
        <div className={styles.ColumnPackages}>
          <Typography type="caption" translate color="onSurfaceMedium">
            label_packages
          </Typography>
        </div>
      </div>

      {sortedInventory.map((inventory) => (
        <div
          key={inventory.inventoryId}
          className={
            inventoryValidity[inventory.inventoryId] === "INVALID"
              ? styles.KittingTableRowError
              : inventoryValidity[inventory.inventoryId] === "WARN"
                ? styles.KittingTableRowWarning
                : inventory.selected
                  ? styles.KittingTableRowSelected
                  : styles.KittingTableRow
          }
        >
          <div className={styles.ColumnSlot}>
            <div className={styles.SlotCell}>
              <Checkbox
                onClick={inventory.toggleInventorySelection}
                onChange={(e) => {
                  analytics.track({
                    name: "slot adjusted",
                    properties: {
                      value: e.target.checked,
                      ingredient_name: inventory.productToUse.name,
                      ingredient_sku: inventory.productToUse.sku,
                      location_id: state.machines[0]?.location?.id,
                      machine_id: state.machines[0]?.machineId,
                      machine_priority: state.machines[0]?.refillPriority,
                      plan_name: state.plan.planName,
                      remaining_amount:
                        inventory.productToUse.availablePackages,
                      slot_name: inventory.slot,
                      slot_status: inventory.inventoryStatus,
                      recommended_action: inventory.recommendedAction,
                      suggested_refill_amount:
                        state.aggregates.numberOfRecommendedRefillSlots,
                    },
                  });
                }}
                checked={inventory.selected}
                disabled={
                  inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE"
                }
              />
              <div className={styles.SlotCellText}>
                <Typography type="body-2">{inventory.slot}</Typography>
              </div>
              <div className={styles.RecommendedIconContainer}>
                {inventory.recommendedAction === "REFILL" ? (
                  <Icon name="Diamond" color="primary500" />
                ) : inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE" ? (
                  <Icon name="RefreshAlert" color="primary500" />
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles.ColumnSKU}>
            <div className={styles.SKUTextContainer}>
              <Typography type="caption" className={styles.SKUTextStyles}>
                {inventory.productToUse.sku}
              </Typography>
              {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
              inventory.recommendedAction === "SWAP_REPLACE" ? (
                <div className={styles.SKUTextContainer}>
                  <Typography
                    type="caption"
                    className={styles.SKUTextStyles}
                    color="onSurfaceDisabled"
                  >
                    {inventory.currentProduct.sku}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>

          <div className={styles.ColumnImage}>
            <div className={styles.ImageContainer}>
              {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
              inventory.recommendedAction === "SWAP_REPLACE" ? (
                <div className={styles.IconContainer}>
                  <Icon name="RefreshAlert" color="primary500" />
                </div>
              ) : null}
              {inventory.productToUse.img ? (
                <img
                  src={inventory.productToUse.img}
                  className={styles.Image}
                  alt={inventory?.productToUse?.name}
                  style={{
                    opacity:
                      inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                      inventory.recommendedAction === "SWAP_REPLACE"
                        ? "50%"
                        : undefined,
                  }}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.ColumnIngredient}>
            <div
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Typography type="body-2" color="onSurfaceHigh">
                    {inventory.productToUse.name}
                  </Typography>
                </div>
                <div
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {inventory.recommendedAction === "SWAP_REFILL_ON_TOP" ||
                  inventory.recommendedAction === "SWAP_REPLACE" ? (
                    <Typography type="caption" color="onSurfaceDisabled">
                      {inventory.currentProduct.name}
                    </Typography>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ColumnLevel}>
            {inventory.recommendedAction !== "SWAP_REPLACE" ? (
              <Typography type="body-2" color="onSurfaceHigh">
                {`${inventory.inventoryLevel.current} ${inventory.productToUse.uom}`}
              </Typography>
            ) : (
              <Tooltip
                followCursor={true}
                title={
                  <Typography type="caption" translate color="background">
                    label_current_level_to_be_removed
                  </Typography>
                }
              >
                <div>
                  <Typography type="body-2" color="onSurfaceDisabled">
                    {`(${inventory.inventoryLevel.current} ${inventory.productToUse.uom})`}
                  </Typography>
                </div>
              </Tooltip>
            )}
          </div>
          <div className={styles.ColumnInventory}>
            <InventoryBarWithSelection
              selected={inventory.selected}
              inventory={{
                autoRefill: inventory.isAutoRefillOn,
                capacity: inventory.inventoryLevel.capacity,
                currentLevel: inventory.inventoryLevel.current,
                highLevel: inventory.inventoryLevel.high,
                lowLevel: inventory.inventoryLevel.low,
                pendingSwap: inventory.pendingSwapMaterial,
                uom: inventory.productToUse.uom,
                isForecastingDisabled: isForecastingDisabled,
                forecastedLevel: inventory.inventoryLevel.forecasted,
                swapType:
                  inventory.recommendedAction === "SWAP_REFILL_ON_TOP"
                    ? "REFILL_ON_TOP"
                    : inventory.recommendedAction === "SWAP_REPLACE"
                      ? "REPLACE"
                      : null,
              }}
              amountTaken={
                inventory.productToUse.amountUsedInPackages *
                inventory.productToUse.packageSize
              }
              recommendation={{
                baseline: inventory.inventoryLevel.baseline,
                recommendedAmount:
                  inventory.productToUse.amountRequired.roundedToPackageSize,
                total: inventory.inventoryLevel.travelAndBaselineSum,
                travel: inventory.inventoryLevel.travel,
              }}
            />
          </div>
          <div className={styles.ColumnStatus}>
            <Typography
              type="body-2"
              translate
              color={STATUS_COLOR_MAP[inventory.inventoryStatus]}
            >
              {STATUS_TRANSLATION_KEY_MAP[inventory.inventoryStatus]}
            </Typography>
          </div>
          <div className={styles.ColumnAvailable}>
            <Typography
              type="body-2"
              color={STATUS_COLOR_MAP[inventory.inventoryStatus]}
            >
              {inventory.productToUse.availablePackages ?? "N/A"}
            </Typography>
          </div>
          <div className={styles.ColumnPackages}>
            <div className={styles.PackageCell}>
              <div
                className={styles.PackageIconContainer}
                onClick={
                  inventory.productToUse.amountUsedInPackages === 0
                    ? () => {}
                    : () =>
                        inventory.productToUse.changePackageNumber(" decrement")
                }
              >
                <Icon
                  name="CircleMinus"
                  color={
                    inventory.productToUse.amountUsedInPackages === 0
                      ? "onSurfaceDisabled"
                      : "primary500"
                  }
                />
              </div>
              <div className={styles.PackageNumberContainer}>
                <Typography type="body-2" color="onSurfaceHigh">
                  {inventory.productToUse.amountUsedInPackages}
                </Typography>
              </div>
              <div
                className={styles.PackageIconContainer}
                onClick={() =>
                  inventory.productToUse.changePackageNumber("increment")
                }
              >
                <Icon name="CirclePlus" color={"primary500"} />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function ForecastingTurnedOffNotifier() {
  const { t } = useTranslation();
  return (
    <div
      style={{
        padding: "18px 33px",
        backgroundColor: "#FFB025",
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
      }}
    >
      <p
        style={{
          fontFamily: "Kanit",
          fontSize: "14px",
          fontWeight: 700,
          letterSpacing: "0.15px",
          marginLeft: "14px",
          marginRight: "14px",
        }}
      >
        {t("label_ingredient_usage_forecast_turned_off")}
      </p>
      <p
        style={{
          fontFamily: "Kanit",
          fontSize: "14px",
          fontWeight: 400,
          letterSpacing: "0.15px",
          textAlign: "center",
        }}
      >
        {t("label_this_happens_when_we_couldnt_determine_eta")}
      </p>
    </div>
  );
}
