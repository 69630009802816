import {
  GetMaintenanceOverviewDocument,
  GetMaintenanceOverviewQuery,
  GetMaintenanceOverviewQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getErrorOverviews({
  serviceZoneId,
}: {
  serviceZoneId?: string;
}) {
  return fetcher<
    GetMaintenanceOverviewQuery,
    GetMaintenanceOverviewQueryVariables
  >(GetMaintenanceOverviewDocument, {
    filter: {
      serviceZoneId: serviceZoneId,
    },
  })();
}
