import * as React from "react";
import {
  RENT_FEE_TYPE,
  RentFee,
  RentFeeType,
  getRentFeeTypeTranslation,
} from "../../hooks/useGetLocationFee";
import TextButton from "common/components/textbutton/TextButton";
import TextFieldViewOnly from "components/TextFieldViewOnly/TextFieldViewOnly";
import TextField from "components/TextField/TextField";
import Typography from "components/Typography/Typography";
import { RadioButton } from "common/components/radiobutton/RadioButton";
import DatePicker from "components/DatePicker/DatePicker";
import MuiLabel from "components/MuiLabel";

export function RentFeeView({ rentFee }: { rentFee: RentFee }) {
  return (
    <div>
      <Typography type="headline-6" translate>
        label_rent_fee
      </Typography>
      <div style={{ height: "20px" }} />

      <TextFieldViewOnly
        label="label_payment_arrangement"
        value={getRentFeeTypeTranslation(rentFee.type)}
        translateValue
      />

      {rentFee.type === RENT_FEE_TYPE.LADDER ? (
        <div
          style={{
            gridTemplateColumns: "repeat(3, 1fr)",
            display: "grid",
          }}
        >
          {rentFee.value.map((value, index) => (
            <React.Fragment key={index}>
              <TextFieldViewOnly
                label="label_from"
                value={value.from.format("MM/YYYY")}
              />
              <TextFieldViewOnly
                label="label_to"
                value={value.to.format("MM/YYYY")}
              />
              <TextFieldViewOnly
                label="label_amount"
                value={value.rentalPrice}
              />
            </React.Fragment>
          ))}
        </div>
      ) : null}

      {rentFee.type === RENT_FEE_TYPE.PERCENTAGE ? (
        <>
          <TextFieldViewOnly
            label="label_minimum_amount"
            value={rentFee.value.minimumAmount}
          />

          <div
            style={{
              gridTemplateColumns: "repeat(3, 1fr)",
              display: "grid",
            }}
          >
            {rentFee.value.segments.map((segment, index) => (
              <React.Fragment key={index}>
                <TextFieldViewOnly
                  label="label_sales_lower"
                  value={segment.lower}
                />
                <TextFieldViewOnly
                  label="label_sales_upper"
                  value={segment.upper}
                />
                <TextFieldViewOnly
                  label="label_percentage"
                  value={segment.percentage}
                />
              </React.Fragment>
            ))}
          </div>
        </>
      ) : null}

      {rentFee.type === RENT_FEE_TYPE.FIXED ? (
        <TextFieldViewOnly
          label="label_amount"
          value={rentFee.value.toLocaleString()}
        />
      ) : null}

      <TextFieldViewOnly
        label="label_pay_with_code"
        translateValue
        value={rentFee.payWithCode ? "label_yes" : "label_no"}
      />
    </div>
  );
}

export type RentFeeFormValue = RentFee;

type RentFeeFormProps = {
  onChange: (value: RentFeeFormValue) => void;
  value: RentFeeFormValue;
};

export function mapRentFeeToRentFeeFormValue(
  rentFee: RentFee
): RentFeeFormValue {
  return rentFee;
}
export function initializeRentFeeFormValue(): RentFeeFormValue {
  return {
    payWithCode: undefined,
    type: RENT_FEE_TYPE.FIXED,
    value: "",
  };
}

export function RentFeeForm({ value, onChange }: RentFeeFormProps) {
  function handleRadioButtonClick(newType: RentFeeType) {
    if (value.type === newType) {
      return;
    }

    if (newType === RENT_FEE_TYPE.LADDER) {
      onChange({
        ...value,
        type: RENT_FEE_TYPE.LADDER,
        value: [
          {
            from: null,
            to: null,
            rentalPrice: "",
          },
        ],
      });
      return;
    }
    if (newType === RENT_FEE_TYPE.PERCENTAGE) {
      onChange({
        ...value,
        type: RENT_FEE_TYPE.PERCENTAGE,
        value: {
          minimumAmount: "",
          segments: [
            {
              percentage: "",
              lower: "",
              upper: "",
            },
          ],
        },
      });
      return;
    }

    onChange({
      ...value,
      type: newType,
      value: "",
    });
  }

  return (
    <div>
      <Typography type="headline-6" translate>
        label_rent_fee
      </Typography>
      <div style={{ height: "20px" }} />

      {Object.values(RENT_FEE_TYPE).map((type) => (
        <div
          style={{ display: "flex", alignItems: "center", gap: "12px" }}
          key={type}
        >
          <RadioButton
            key={type}
            checked={value.type === type}
            onClick={() => {
              handleRadioButtonClick(type);
            }}
          />
          <Typography type="subtitle-1" translate>
            {getRentFeeTypeTranslation(type)}
          </Typography>
        </div>
      ))}

      <div style={{ height: "10px" }} />

      <Typography type="subtitle-1" translate>
        label_rent_fee
        {" ("}
        {getRentFeeTypeTranslation(value.type)}
        {")"}
      </Typography>

      <div style={{ height: "10px" }} />

      {value.type === RENT_FEE_TYPE.PERCENTAGE ? (
        <>
          <TextField
            label="label_minimum_amount"
            value={value.value.minimumAmount}
            onChange={(e) => {
              onChange({
                ...value,
                value: {
                  ...value.value,
                  minimumAmount: e.target.value,
                },
              });
            }}
          />
          <div style={{ height: "20px" }} />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(0,1fr)",
              gap: "20px",
            }}
          >
            {value.value.segments.map((entry, index) => (
              <React.Fragment key={index}>
                <TextField
                  label="label_sales_lower"
                  value={entry.lower}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      value: {
                        ...value.value,
                        segments: value.value.segments.map((segment, i) => {
                          if (i !== index) {
                            return segment;
                          }

                          return {
                            ...segment,
                            lower: e.target.value,
                          };
                        }),
                      },
                    })
                  }
                />

                <TextField
                  label="label_sales_upper"
                  value={entry.upper}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      value: {
                        ...value.value,
                        segments: value.value.segments.map((segment, i) => {
                          if (i !== index) {
                            return segment;
                          }

                          return {
                            ...segment,
                            upper: e.target.value,
                          };
                        }),
                      },
                    })
                  }
                />

                <TextField
                  label="label_percentage"
                  value={entry.percentage}
                  onChange={(e) =>
                    onChange({
                      ...value,
                      value: {
                        ...value.value,
                        segments: value.value.segments.map((segment, i) => {
                          if (i !== index) {
                            return segment;
                          }

                          return {
                            ...segment,
                            percentage: e.target.value,
                          };
                        }),
                      },
                    })
                  }
                />
              </React.Fragment>
            ))}
          </div>
          <div style={{ height: "10px" }} />
          <div style={{ display: "flex" }}>
            <TextButton
              onClick={() => {
                onChange({
                  ...value,
                  value: {
                    ...value.value,
                    segments: [
                      ...value.value.segments,
                      {
                        lower: "",
                        upper: "",
                        percentage: "",
                      },
                    ],
                  },
                });
              }}
              icon="Plus"
              translationKey="label_add_more"
            />
          </div>
        </>
      ) : null}

      {value.type === RENT_FEE_TYPE.LADDER ? (
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, minmax(0,1fr)",
              gap: "20px",
            }}
          >
            {value.value.map((entry, index) => (
              <React.Fragment key={index}>
                <DatePicker
                  label="label_from"
                  value={entry.from}
                  onChange={(newValue) =>
                    onChange({
                      ...value,
                      value: value.value.map((value, i) => {
                        if (i !== index) {
                          return value;
                        }

                        return {
                          ...value,
                          from: newValue,
                        };
                      }),
                    })
                  }
                />

                <DatePicker
                  label="label_to"
                  value={entry.to}
                  onChange={(newValue) =>
                    onChange({
                      ...value,
                      value: value.value.map((value, i) => {
                        if (i !== index) {
                          return value;
                        }

                        return {
                          ...value,
                          to: newValue,
                        };
                      }),
                    })
                  }
                />
                <TextField
                  label="label_amount"
                  value={entry.rentalPrice}
                  onChange={(e) => {
                    onChange({
                      ...value,
                      value: value.value.map((value, i) => {
                        if (i !== index) {
                          return value;
                        }

                        return {
                          ...value,
                          rentalPrice: e.target.value,
                        };
                      }),
                    });
                  }}
                />
              </React.Fragment>
            ))}
          </div>
          <div style={{ height: "10px" }} />
          <div style={{ display: "flex" }}>
            <TextButton
              onClick={() => {
                onChange({
                  ...value,
                  value: [
                    ...value.value,
                    {
                      from: null,
                      to: null,
                      rentalPrice: "",
                    },
                  ],
                });
              }}
              icon="Plus"
              translationKey="label_add_more"
            />
          </div>
        </>
      ) : null}

      {value.type === RENT_FEE_TYPE.FIXED ? (
        <TextField
          label="label_amount"
          value={value.value}
          onChange={(e) => {
            onChange({
              ...value,
              value: e.target.value,
            });
          }}
        />
      ) : null}

      <div style={{ height: "20px" }}></div>

      <MuiLabel label="label_pay_with_code">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <RadioButton
              checked={value.payWithCode === true}
              onClick={() => {
                onChange({
                  ...value,
                  payWithCode: true,
                });
              }}
            />
            <Typography type="subtitle-1" translate>
              label_yes
            </Typography>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "12px",
            }}
          >
            <RadioButton
              checked={value.payWithCode === false}
              onClick={() => {
                onChange({
                  ...value,
                  payWithCode: false,
                });
              }}
            />
            <Typography type="subtitle-1" translate>
              label_no
            </Typography>
          </div>
        </div>
      </MuiLabel>
    </div>
  );
}
