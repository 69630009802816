import * as React from 'react';
import * as moment from 'moment';
import { useState } from 'react';
import MuiModal from 'components/Modal/MuiModal';

import Typography from 'components/Typography';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import * as styles from './DownloadReportModal.module.scss';
import { getSlotUsageCSV } from 'services/machine/SlotUsage';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import DatePicker from 'components/DatePicker/DatePicker';
import TimePicker from 'components/TimePicker/TimePicker';

interface IDownloadReportModalProps {
  open: boolean;
  onClose: () => void;
  selectedMachine: string[];
}

function DownloadReportModal({
  open,
  onClose,
  selectedMachine,
}: IDownloadReportModalProps): JSX.Element {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<moment.Moment | null>(moment());
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    moment().add(1, 'days')
  );

  const [startTime, setStartTime] = useState<moment.Moment | null>(
    moment().startOf('day')
  );
  const [endTime, setEndTime] = useState<moment.Moment | null>(
    moment().startOf('day')
  );

  function isValidInput(
    startDate: moment.Moment,
    endDate: moment.Moment,
    startTime: moment.Moment,
    endTime: moment.Moment
  ) {
    if (
      startDate === null ||
      endDate === null ||
      startTime === null ||
      endTime === null
    ) {
      return false;
    } else if (
      endDate.isAfter(startDate) &&
      !startDate.isSame(endDate, 'day')
    ) {
      return true;
    } else if (startDate.isSame(endDate, 'day')) {
      return endTime.isAfter(startTime);
    } else {
      return false;
    }
  }

  async function downloadDocument() {
    const from = String(
      moment(
        startDate.format('DD-MM-YYYY') + ' ' + startTime.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).valueOf()
    );
    const to = String(
      moment(
        endDate.format('DD-MM-YYYY') + ' ' + endTime.format('HH:mm'),
        'DD-MM-YYYY HH:mm'
      ).valueOf()
    );

    const rawCSV = await getSlotUsageCSV({
      selectedMachine,
      from,
      to,
    });
    const CSVFile = new File(['\ufeff' + rawCSV], 'MachineMaterialUsage.csv', {
      type: 'text/csv',
    });
    const anchorElement = document.createElement('a');
    anchorElement.href = URL.createObjectURL(CSVFile);
    anchorElement.download = 'MachineMaterialUsage.csv';
    anchorElement.click();
    URL.revokeObjectURL(anchorElement.href);
  }
  const isInputCorrect = isValidInput(startDate, endDate, startTime, endTime);

  return (
    <MuiModal
      open={open}
      onClose={onClose}
      minWidth="416px"
      minHeight="296px"
      padding="24px 32px"
    >
      <>
        <div>
          <Typography translate type="headline-6">
            label_inventory_usage_report
          </Typography>
        </div>

        <div className={styles.MachineContainer}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <div className={styles.DateTimeContainer}>
              <div className={styles.widthContainer}>
                <DatePicker
                  label={'label_date_from'}
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                />
              </div>
              <div className={styles.widthContainer}>
                <TimePicker
                  label="label_time_from"
                  value={startTime}
                  onChange={(time) => setStartTime(time)}
                />
              </div>
            </div>
            <div className={styles.DateTimeContainer}>
              <div className={styles.widthContainer}>
                <DatePicker
                  label="label_date_to"
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
              </div>
              <div className={styles.widthContainer}>
                <TimePicker
                  label="label_time_to"
                  value={endTime}
                  onChange={(time) => setEndTime(time)}
                />
              </div>
            </div>
          </LocalizationProvider>
        </div>
        <div>
          {!isInputCorrect ? (
            <Typography className={styles.warn} translate type="subtitle-1">
              please_enter_valid_date_and_time
            </Typography>
          ) : (
            <div className={styles.placeholder}></div>
          )}
        </div>
        <div className={styles.ButtonContainer}>
          <Button type="secondary" onClick={onClose}>
            action_cancel
          </Button>
          <Button
            onClick={async () => {
              const loadingToast = toast.loading(t('label_loading'));
              try {
                await downloadDocument();
                toast.update(loadingToast, {
                  type: 'success',
                  isLoading: false,
                  render: t('toast_request_successful'),
                  autoClose: 5000,
                });
                onClose();
              } catch (e) {
                toast.update(loadingToast, {
                  type: 'error',
                  isLoading: false,
                  render: t('toast_request_failed'),
                  autoClose: 5000,
                });
              }
            }}
            className={styles.download}
            type="primary"
            disabled={!isInputCorrect}
          >
            action_download
          </Button>
        </div>
      </>
    </MuiModal>
  );
}

export { DownloadReportModal };
