import { httpClient } from "common/services/transportService";

interface ValidateBatchSwapOrderBody {
  machineId: Array<string>,
  slot: string,
  productId: string
}

type ValidateBatchSwapOrderResponse = Array<{
  machineId: string,
  swapOut: {
    productId: string,
    productName: string
  },
  swapIn: {
    productId: string,
    productName: string,
  },
  error: null | string,
  errorCode: null | number
}>

async function validateBatchSwapOrder(body: ValidateBatchSwapOrderBody): Promise<ValidateBatchSwapOrderResponse>{
  const result = await httpClient.post<ValidateBatchSwapOrderResponse>('/swaporder/batch/validate', body);
  return result.data;
}

export { validateBatchSwapOrder };