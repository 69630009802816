import * as React from "react";

import * as SCSScolors from "common/styles/colors.scss";
import * as styles from "./Indicator.module.scss";

export interface IndicatorProps {
  className?: string;
  color?: "error" | "success" | "warning" | "investigate" | undefined;
  type?: "circle" | "square";
}

export function Indicator({
  color,
  type = "circle",
  className = "",
}: IndicatorProps): JSX.Element {
  return (
    <div
      style={{ backgroundColor: SCSScolors[color] }}
      className={`
          ${styles.Indicator}
          ${type === "circle" ? styles.Circle : ""}
          ${type === "square" ? styles.Square : ""}
          ${className}
        `}
    ></div>
  );
}
