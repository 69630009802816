import { useGetOwnPermission } from "hooks/permission";

export function useGetLocationPermissions() {
  const canViewLocationEditPage = useCanViewLocationEditPage();
  const canEditGeneral = useCanEditGeneralInfo();
  const canEditOperationalInfo = useCanEditOperationalInfo();
  const canUpdateFactory = useCanUpdateFactory();
  const canViewAndEditAdditionalContractInfo =
    useCanViewAndEditAdditionalContractInfo();
  const isLoading =
    canViewLocationEditPage.isLoading ||
    canEditGeneral.isLoading ||
    canEditOperationalInfo.isLoading ||
    canUpdateFactory.isLoading ||
    canViewAndEditAdditionalContractInfo.isLoading;
  return {
    canViewAndEditAdditionalContractInfo,
    canViewLocationEditPage,
    canEditGeneral,
    canEditOperationalInfo,
    canUpdateFactory,
    isLoading,
  };
}

export function useCanViewAndEditAdditionalContractInfo() {

  const { allowed: canWriteLocation, isLoading: isWriteLocationLoading } =
    useGetOwnPermission({
      action: "WRITE",
      resourceType: "LOCATION",
    });

  const { allowed: canManageLocation, isLoading: isManageLocationLoading } =
    useGetOwnPermission({
      action: "MANAGE",
      resourceType: "LOCATION",
    });

  const isPermissionsLoading =
    isWriteLocationLoading || isManageLocationLoading;

  if (isPermissionsLoading) {
    return {
      isLoading: true,
      allowed: undefined,
    };
  }

  return {
    isLoading: false,
    allowed: (canWriteLocation || canManageLocation),
  };
}

export function useCanUpdateFactory() {
  return useGetOwnPermission({
    action: "MANAGE",
    resourceType: "LOCATION",
  });
}
export function useCanViewLocationEditPage() {
  return useGetOwnPermission({
    action: "WRITE",
    resourceType: "LOCATION",
  });
}
function useCanEditGeneralInfo() {
  return useGetOwnPermission({
    action: "MANAGE",
    resourceType: "LOCATION",
  });
}
function useCanEditOperationalInfo() {
  return useGetOwnPermission({
    action: "WRITE",
    resourceType: "LOCATION",
  });
}
