import * as React from "react";
import { useLocation } from "react-router";

import Typography from "common/components/typography/Typography";
import { Link } from "react-router-dom";

import * as styles from "./TabButton.module.scss";

interface ITabButtonProps {
  type?: "primary" | "status";
  number?: number | null | undefined;
  alertNumber?: number | null | undefined;
  to: string;
  children: string;
}

export default function TabButton({
  type = "primary",
  number = null,
  to,
  children,
  alertNumber,
}: ITabButtonProps): JSX.Element {
  const { pathname } = useLocation();
  const active = pathname.includes(to);

  return (
    <Link
      to={to}
      className={[
        styles.TabButton,
        type === "primary" ? styles.TabButtonPrimary : "",
        type === "primary" && active ? styles.TabButtonPrimaryActive : "",
        type === "status" ? styles.TabButtonStatus : "",
        type === "status" && active ? styles.TabButtonStatusActive : "",
      ].join(" ")}
      style={{
        position: "relative",
      }}
    >
      <Typography translationKey={children} type="body-2" />
      {number !== 0 && number && <Typography text={number} type="caption" />}
      {alertNumber ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#E95D50",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            top: "50%",
            transform: "translateY(-50%)",
            right: "6px",
            color: "white",
            display: "grid",
            placeContent: "center",
            overflow: "hidden",
          }}
        >
          <Typography translationKey={alertNumber > 99 ? "99+" : alertNumber + ""} className={styles.AlertNumber} type="body-2" />
        </div>
      ) : null}
    </Link>
  );
}
