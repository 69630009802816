import * as React from 'react';

import { Pie } from 'react-chartjs-2';

import * as styles from './PieChart.module.scss';

interface PieChartProps {
  className?: string;
  data: Array<PieChartSegment>;
}

interface PieChartSegment {
  label: string;
  color: string;
  amount: number;
}

function PieChart ({ data, className = '' }: PieChartProps): JSX.Element {
  return (
    <div className={[ styles.PieChart, className ].join(' ')}>
      <Pie
        data={{
          datasets: [
            {
              data: data.map(segment => segment.amount),
              backgroundColor: data.map(segment => segment.color)
            }
          ]
        }}
      />
    </div>
  );
}

export { PieChart };
