import * as React from 'react';

import { Card } from '../atoms/Card';
import { CardTitle } from '../molecules/CardTitle';
import Typography from 'common/components/typography/Typography';

import * as styles from './TextCard.module.scss';

export interface TextCardProps {
  title: string;
  icon: string;
  className?: string;
  labelType: string;
  valueType: string;
  data: Array<{
    label: string;
    value: string;
  }>;
}

function TextCard ({
  title,
  icon,
  labelType,
  valueType,
  data,
  className = ''
}: TextCardProps): JSX.Element {
  const slicedData = data.slice(0, 6);
  return (
    <Card className={[ styles.LineGraphCard, className ].join(' ')}>
      <CardTitle icon={icon} title={title} />
      <Table className={styles.Table}>
        <Row>
          <Cell className={styles.LeftCell}>
            <Typography translationKey={labelType} className={styles.TableHeader} type="body-1" />
          </Cell>
          <Cell className={styles.RightCell}>
            <Typography translationKey={valueType} className={styles.TableHeader} type="body-1" />
          </Cell>
        </Row>
        <>
          {slicedData.map((dataPoint, index) => (
            <Row key={index}>
              <Cell className={styles.LeftCell}>
                <Typography translationKey={dataPoint.label} className={styles.Label} type="body-2" />
              </Cell>
              <Cell className={styles.RightCell}>
                <Typography translationKey={dataPoint.value} className={styles.Value} type="body-2" />
              </Cell>
            </Row>
          ))}
        </>
      </Table>
    </Card>
  );
}

export { TextCard };

interface TableProps {
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
}

function Table ({ children, className = '' }: TableProps): JSX.Element {
  return <div className={[ styles.Table, className ].join(' ')}>{children}</div>;
}

interface CellProps {
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
}

function Cell ({ children, className = '' }: CellProps): JSX.Element {
  return <div className={[ styles.Cell, className ].join(' ')}>{children}</div>;
}

interface RowProps {
  children: JSX.Element | Array<JSX.Element>;
  className?: string;
}

function Row ({ children, className = '' }: RowProps): JSX.Element {
  return <div className={[ styles.Row, className ].join(' ')}>{children}</div>;
}
