import * as React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { routes } from "../../../../routes";
import { useServiceZoneQueryParam } from "../../hooks/useServiceZoneQueryParam";
import { ErrorBoundary } from "@sentry/react";
import { WhipperTable } from "./WhipperTable";
import { MilkAndSugarTable } from "./MilkAndSugarTable";
import { CoffeeBeansTable } from "./CoffeeBeansTable";
import { CupsTable } from "./CupsTable";
import { Fallback } from "../../components/Fallback";

export function InventoryTables() {
  const { search } = useLocation();
  const [serviceZone] = useServiceZoneQueryParam();
  return (
    <Switch>
      <Route path={routes.inventory.whipper()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"whipper" + (serviceZone ?? +"")}
        >
          <WhipperTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.milkAndSugar()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"milk-and-sugar" + (serviceZone ?? +"")}
        >
          <MilkAndSugarTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.coffeeBeans()}>
        <ErrorBoundary
          fallback={Fallback}
          key={"coffee-beans" + (serviceZone ?? +"")}
        >
          <CoffeeBeansTable />
        </ErrorBoundary>
      </Route>
      <Route path={routes.inventory.cups()}>
        <ErrorBoundary fallback={Fallback} key={"cups" + (serviceZone ?? +"")}>
          <CupsTable />
        </ErrorBoundary>
      </Route>
      <Redirect to={routes.inventory.whipper() + search} />
    </Switch>
  );
}
