import create from 'zustand';

type MachineModalType = "OnboardMachine" | "DownloadAllReport" | "DownloadHealth" | "DownloadInventoryUsage" | "ChangeZone" | "ChangeServiceZone" | "UploadLimit";

export interface MachineStoreType {
  enable: boolean;
  selectedMachine: string[];
  modalOpen: null | MachineModalType;
  setEnable: (state: boolean) => void;
  setModalOpen: (state : MachineModalType) => void;
  setSelectedMachine: (selected: string[]) => void;
}

export const useMachinesStore = create<MachineStoreType>(set => ({
  enable: localStorage.getItem("disable-machine-overview-v2") !== "true",
  selectedMachine: [],
  modalOpen: null,
  setEnable: state => set({ enable: state }),
  setModalOpen: state => set({ modalOpen: state }),
  setSelectedMachine: state => set({ selectedMachine: state })
}));
