import Button from "components/Button";
import {
  LocationAggregateAttributesInput,
  useUpdateLocationMutation,
} from "gql/generated";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { toasti18n } from "utils/toast";
import { SingleMachineWithContract } from "../../types";
import { LocationColumnSpacer } from "../../components/LocationColumnSpacer";
import { Title } from "../../components/Title";
import { PageTitle } from "../../components/PageTitle";
import {
  OperationInfo,
  OperationInfoFormValue,
  mapOperationInfoToOperationInfoFormValue,
  OperationInfoForm,
} from "../../components/OperationInfo";
import {
  mapLocationInfoToGeneralLocationInfoFormValue,
  GeneralLocationInfoForm,
  GeneralLocationInfoFormValue,
  GeneralLocationInfo,
} from "../../components/GeneralLocationInfo";
import { LocationContracts } from "../../components/LocationContracts";
import { MachineIds } from "../../components/MachineIds";
import {
  AdditionalContractInfoForm,
  AdditionalContractInfoFormValue,
  mapAdditionalContractInfoToAdditionalContractInfoFormValue,
} from "containers/location/components/ContractInfo";

type UpdateLocationFormValue = {
  locationId: string;
  generalLocationInfo?: GeneralLocationInfoFormValue;
  operationInfo?: OperationInfoFormValue;
  additionalContractInfo?: AdditionalContractInfoFormValue;
};
function useUpdateLocation({ onSuccess }: { onSuccess: () => void }) {
  const { mutate, ...rest } = useUpdateLocationMutation({
    onError: (err: Error) => {
      toasti18n.error(err);
    },
    onSuccess: () => {
      toasti18n.success();
      onSuccess();
    },
  });

  function submit(form: UpdateLocationFormValue) {
    let formInput: LocationAggregateAttributesInput = {
      id: form.locationId,
    };

    if (form.generalLocationInfo) {
      formInput = {
        ...formInput,
        address: {
          province: form.generalLocationInfo.address.province,
          street: form.generalLocationInfo.address.street,
          city: "",
          country: "",
          postalCode: "",
        },
        coordinates: {
          latitude: form.generalLocationInfo.coordinates.latitude,
          longitude: form.generalLocationInfo.coordinates.longitude,
        },
        googleMapLink: form.generalLocationInfo.googleMapLink,
        name: form.generalLocationInfo.name,
        type: form.generalLocationInfo.type,
        organizationId: form.generalLocationInfo.organizationId,
      };
    }

    if (form.operationInfo) {
      formInput = {
        ...formInput,
        refillOperationNote: form.operationInfo.refillOperationNote,
        refillZoneId: form.operationInfo.refillZoneId,
        serviceZoneId: form.operationInfo.serviceZoneId,
        warehouseId: form.operationInfo.warehouseId,
      };
    }

    if (form.additionalContractInfo) {
      formInput = {
        ...formInput,
        projectTypeId: Number(form.additionalContractInfo.projectTypeId),
        sales: {
          name: form.additionalContractInfo.sales,
        },
      };
    }

    mutate({
      input: formInput,
    });
  }
  return { submit, ...rest };
}

export function LocationForm({
  location,
  canView,
  canEditGeneralInfo,
  canEditOperationalInfo,
}: {
  location: SingleMachineWithContract;
  canView: boolean;
  canEditGeneralInfo: boolean;
  canEditOperationalInfo: boolean;
}) {
  if (!canView) {
    throw new Error("User do not have permission to edit location");
  }

  const [generalLocationInfoFormValue, setGeneralLocationInfoFormValue] =
    React.useState(
      mapLocationInfoToGeneralLocationInfoFormValue(location.locationInfo)
    );

  const [operationInfoFormValue, setOperationInfoFormValue] = React.useState(
    mapOperationInfoToOperationInfoFormValue(location.operation)
  );
  const isOperationInfoFormInvalid =
    !operationInfoFormValue.refillZoneId ||
    !operationInfoFormValue.serviceZoneId ||
    !operationInfoFormValue.warehouseId;

  const [additionalContractFormValue, setAdditionalContractFormValue] =
    React.useState(
      mapAdditionalContractInfoToAdditionalContractInfoFormValue(
        location.additionalContractInformation
      )
    );

  const history = useHistory();
  const useUpdateLocationResult = useUpdateLocation({
    onSuccess: () => {
      history.push(`/location/${location.locationInfo.id}`);
    },
  });

  function submitForm() {
    useUpdateLocationResult.submit({
      locationId: location.locationInfo.id,
      generalLocationInfo: canEditGeneralInfo
        ? generalLocationInfoFormValue
        : undefined,
      operationInfo: canEditOperationalInfo
        ? operationInfoFormValue
        : undefined,
      additionalContractInfo: additionalContractFormValue
    });
  }

  return (
    <>
      <PageTitle location={location} />
      <Title>label_general</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <MachineIds machineIds={[location.machineId]} />
        <LocationContracts contracts={location.contracts} />
        {canEditGeneralInfo ? (
          <GeneralLocationInfoForm
            value={generalLocationInfoFormValue}
            initialOrgOptions={[
              {
                label: location.locationInfo.organization.name,
                value: location.locationInfo.organization.id,
              },
            ]}
            onChange={setGeneralLocationInfoFormValue}
          />
        ) : (
          <GeneralLocationInfo locationInfo={location.locationInfo} />
        )}
      </LocationColumnSpacer>
      <div style={{ height: "20px" }} />
      <Title>label_operation</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        {canEditOperationalInfo ? (
          <OperationInfoForm
            organizationId={generalLocationInfoFormValue.organizationId}
            initialRefillZoneOptions={[
              {
                label: location.operation.refillZone.friendlyId,
                value: location.operation.refillZone.id,
              },
            ]}
            initialServiceZoneOptions={[
              {
                label: location.operation.serviceZone.friendlyId,
                value: location.operation.serviceZone.id,
              },
            ]}
            initialWarehouseOptions={[
              {
                label: location.operation.warehouse.name,
                value: location.operation.warehouse.id,
              },
            ]}
            value={operationInfoFormValue}
            onChange={setOperationInfoFormValue}
          />
        ) : (
          <OperationInfo operationInfo={location.operation} />
        )}
      </LocationColumnSpacer>

      {/* edit contract  */}
      <div style={{ height: "20px" }} />
      <Title>label_contract</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <AdditionalContractInfoForm
          initialProjectTypeOptions={[
            {
              label: `${location.additionalContractInformation.projectType.name} - ${location.additionalContractInformation.projectType.id}`,
              value: location.additionalContractInformation.projectType.id,
            },
          ]}
          onChange={setAdditionalContractFormValue}
          value={additionalContractFormValue}
        />
      </LocationColumnSpacer>

      <LocationColumnSpacer>
        <div style={{ height: "20px" }} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            to={`/location/${location.locationInfo.id}`}
            style={{
              pointerEvents: useUpdateLocationResult.isLoading
                ? "none"
                : "auto",
            }}
          >
            <Button
              type="secondary"
              disabled={useUpdateLocationResult.isLoading}
            >
              action_cancel
            </Button>
          </Link>
          <Button
            type="primary"
            onClick={submitForm}
            loading={useUpdateLocationResult.isLoading}
            disabled={isOperationInfoFormInvalid}
          >
            action_save
          </Button>
        </div>
      </LocationColumnSpacer>
    </>
  );
}
