import { AxiosError } from 'axios';
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';
import { Dispatch, AnyAction } from 'redux';

import { useMutation, useQueryClient } from 'react-query';
import { deleteUser } from 'userandauth/servicesUser';
import { userKeys } from './userKeys';

function useDeleteUser () {
    const queryClient = useQueryClient();
    // use mutation , return value , error , var
    return useMutation<string, AxiosError, string>(
        (userId:string) => deleteUser(userId),{
            onSuccess:() => {
                addToast({
                    type: 'success',
                    text: 'toast_user_delete_success'
                })(store.dispatch as Dispatch<AnyAction>);
                queryClient.invalidateQueries(userKeys.all());
            }
        }
    );
}

export { useDeleteUser };