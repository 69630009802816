// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Error--14SjpopTML{display:flex;flex-direction:column;justify-content:center;align-items:center}.Error--14SjpopTML .icon--1Zd32yg3Q7{width:170px;height:170px}.TableContainer--3Bw4ISz00G{flex:1 1 0;min-height:0;overflow-x:auto;overflow-y:hidden}", ""]);
// Exports
exports.locals = {
	"Error": "Error--14SjpopTML",
	"icon": "icon--1Zd32yg3Q7",
	"TableContainer": "TableContainer--3Bw4ISz00G"
};
module.exports = exports;
