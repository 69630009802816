import {
  ICheckboxFilterConfig,
  IContainsFilterConfig,
} from "components/Table/Filter/types";

interface IFilterConfig {
  machineId: IContainsFilterConfig;
  status: ICheckboxFilterConfig;
  organisation: IContainsFilterConfig;
  name: IContainsFilterConfig;
  error: ICheckboxFilterConfig;
}

export const filterConfig: IFilterConfig = {
  machineId: { translationKey: "label_machine_id", filterType: "contains" },
  status: {
    translationKey: "label_status",
    filterType: "checkbox",
    checkboxOptions: [
      {
        title: "status_online",
        queryParam: "status_online",
      },
      {
        title: "status_offline",
        queryParam: "status_offline",
      },
      {
        title: "status_maintenance",
        queryParam: "status_maintenance_short",
      },
      {
        title: "status_out_of_service",
        queryParam: "status_out_of_service_short",
      },
      {
        title: "status_standby",
        queryParam: "status_standby",
      },
    ],
  },
  organisation: { translationKey: "label_org", filterType: "contains" },
  name: { translationKey: "label_name", filterType: "contains" },
  error: {
    translationKey: "status_error",
    filterType: "checkbox",
    checkboxOptions: [
      {
        title: "status_warning",
        queryParam: "warning",
      },
      {
        title: "status_error",
        queryParam: "error",
      },
    ],
  },
};
