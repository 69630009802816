import {
  GetMaintenanceBoardCoffeeErrorDocument,
  GetMaintenanceBoardCoffeeErrorQuery,
  GetMaintenanceBoardCoffeeErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getCoffeeError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardCoffeeErrorQuery,
    GetMaintenanceBoardCoffeeErrorQueryVariables
  >(GetMaintenanceBoardCoffeeErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
