import * as React from "react";
import { Table } from "@tanstack/table-core/build/lib/types";
import { SecondaryFilter } from "./SecondaryFilter";
import { MoreFilter } from "./MoreFilter";
import { PrimaryFilter } from "./PrimaryFilter";
import { FilterState } from "./filterState";

type FilterProps<T> = {
  table: Table<T>;
};

export function Filter<T>({ table }: FilterProps<T>) {
  return (
    <div className="flex-1">
      <div className="flex gap-3 flex-1 p-2">
        <PrimaryFilter table={table} />
        <SecondaryFilter className="flex-1" table={table} />
        <MoreFilter table={table} />
      </div>
      <FilterState table={table} />
    </div>
  );
}
