import * as React from "react";

import withSuspense from "common/hoc/withSuspense";
import { useTranslation } from "react-i18next";

import * as styles from "./MuiLabel.module.scss";

interface MuiLabelProps {
  children: React.ReactNode;
  required?: boolean;
  label: string | string[];
}

function MuiLabel({ children, required, label }: MuiLabelProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <div className={styles.MuiLabelContainer}>
      <div className={styles.Label}>
        {Array.isArray(label)
          ? label.map((s) => (s.includes("_") ? t(s) : s)).join("")
          : t(label)}
        {required && <span className={styles.RequiredAsterisk}>*</span>}
      </div>
      <div className={styles.ChildrenContainer}>{children}</div>
    </div>
  );
}

export default React.memo(withSuspense(MuiLabel));
