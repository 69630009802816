import * as React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { routes } from "../../../../routes";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import { ScrollContainer } from "../../components/ScrollContainer";
import { TabButton } from "../../components/TabButton";

export function MajorTroubleSubNav() {
  return (
    <ScrollContainer>
      <MajorTroubleTabs />
    </ScrollContainer>
  );
}

export function MajorTroubleTabs() {
  const { search } = useLocation();

  const { state } = useErrorBoard();
  const majorTroubleSummary = state.data.summary?.majorTrouble;

  const { t } = useTranslation('errorBoard');

  return (
    <>
      <TabButton
        path={routes.majorTrouble.machineUnsellable() + search}
        activePath={routes.majorTrouble.machineUnsellable()}
        amount={majorTroubleSummary?.machineUnsellable ?? 0}
      >
        {t("majorTroubleTabs.machineUnsellable")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.outOfService() + search}
        activePath={routes.majorTrouble.outOfService()}
        amount={majorTroubleSummary?.outOfService ?? 0}
      >
        {t("majorTroubleTabs.outOfService")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.offline() + search}
        activePath={routes.majorTrouble.offline()}
        amount={majorTroubleSummary?.offline ?? 0}
      >
        {t("majorTroubleTabs.offline")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.iceError() + search}
        activePath={routes.majorTrouble.iceError()}
        amount={majorTroubleSummary?.iceError ?? 0}
      >
        {t("majorTroubleTabs.iceError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.coffeeError() + search}
        activePath={routes.majorTrouble.coffeeError()}
        amount={majorTroubleSummary?.coffeeError ?? 0}
      >
        {t("majorTroubleTabs.coffeeError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.iceRateLow() + search}
        activePath={routes.majorTrouble.iceRateLow()}
        amount={majorTroubleSummary?.iceRateLow ?? 0}
      >
        {t("majorTroubleTabs.iceRateLow")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.sodaError() + search}
        activePath={routes.majorTrouble.sodaError()}
        amount={majorTroubleSummary?.sodaError ?? 0}
      >
        {t("majorTroubleTabs.sodaError")}
      </TabButton>
      <TabButton
        path={routes.majorTrouble.spiralUnsellable() + search}
        activePath={routes.majorTrouble.spiralUnsellable()}
        amount={majorTroubleSummary?.spiralUnsellable ?? 0}
      >
        {t("majorTroubleTabs.spiralUnsellable")}
      </TabButton>
    </>
  );
}

