// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UpdateLimitModal--1Gbgrb7YAV{flex-direction:column}.UpdateLimitModal--1Gbgrb7YAV .header--outM-PKM7h{margin-bottom:25px}.UpdateLimitModal--1Gbgrb7YAV .filePicker--1UQS7mDGdn{margin-bottom:25px}.UpdateLimitModal--1Gbgrb7YAV .buttonGroup--1NefqGhPd3{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"UpdateLimitModal": "UpdateLimitModal--1Gbgrb7YAV",
	"header": "header--outM-PKM7h",
	"filePicker": "filePicker--1UQS7mDGdn",
	"buttonGroup": "buttonGroup--1NefqGhPd3"
};
module.exports = exports;
