import {
  GetMaintenanceBoardCupErrorDocument,
  GetMaintenanceBoardCupErrorQuery,
  GetMaintenanceBoardCupErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getCups({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardCupErrorQuery,
    GetMaintenanceBoardCupErrorQueryVariables
  >(GetMaintenanceBoardCupErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
