import * as React from 'react';
import Table, { IColumn } from 'common/components/table/Table';
import {
  CurrencyCell,
  StatusCell,
  StringCell,
} from 'common/components/table/cells/Cells';
import { statusCellSortingFn } from './utils';
import { LinkCell } from 'common/components/table/cells/LinkCell/LinkCell';
import { useGetMachinesWithExtraFields } from 'machines/hooks/useGetMachinesWithExtraFields';
import * as styles from './Cash.module.scss';
import { useIsAdmin, useIsAdminContract, useIsOperator, useIsSuperViewer } from 'utils/user-role';

import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

function Cash(): JSX.Element {
  const canViewContent = useIsAdmin() || useIsAdminContract() || useIsOperator() || useIsSuperViewer();
  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }
  const { data, isLoading } = useGetMachinesWithExtraFields();
  const tableData: ICashTableData[] = data
    ?.filter((machine) => machine.deployed === true)
    .map((eachMachine) => {
      return {
        machineId: eachMachine.id,
        status: eachMachine.status,
        organisation: eachMachine.organisationName,
        machineName: eachMachine.name,
        machineCash: eachMachine.cashValue,
        noteNumbers: eachMachine.bankNoteCount,
        refillSheet: eachMachine.refillSheetStatus,
        zone: eachMachine.zone,
        action: eachMachine.action,
        link: {
          translationKey: eachMachine.id,
          to: `detail/${eachMachine.id}/inventory`,
        },
      };
    });
  const column: IColumn[] = [
    {
      dataKey: 'link',
      headerLabel: 'label_machine_id',
      cellRenderer: LinkCell,
      sortingFn: (a: ICashTableData, b: ICashTableData) =>
        a.machineId.localeCompare(b.machineId),
      align: 'flex-start',
      columnFlex: 1,
      maxWidth: 100,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'status',
      headerLabel: 'label_status',
      cellRenderer: StatusCell,
      sortingFn: statusCellSortingFn,
      align: 'flex-start',
      columnFlex: 1,
      maxWidth: 90,
      sortable: true,
    },
    {
      dataKey: 'organisation',
      headerLabel: 'label_organisation',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      maxWidth: 120,
      showMobile: true,
    },
    {
      dataKey: 'machineName',
      headerLabel: 'label_name',
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'zone',
      headerLabel: 'label_zone',
      columnFlex: 1,
      maxWidth: 80,
      cellRenderer: StringCell,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'machineCash',
      headerLabel: 'label_machine_cash',
      columnFlex: 1,
      maxWidth: 100,
      cellRenderer: CurrencyCell,
      sortingFn: (a: ICashTableData, b: ICashTableData) =>
        Number(a.machineCash) - Number(b.machineCash),
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'noteNumbers',
      headerLabel: 'label_note_numbers',
      cellRenderer: StringCell,
      maxWidth: 100,
      sortingFn: (a: ICashTableData, b: ICashTableData) =>
        Number(a.noteNumbers) - Number(b.noteNumbers),
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,
      showMobile: true,
    },
    {
      dataKey: 'refillSheet',
      headerLabel: 'label_refill_sheet',
      maxWidth: 100,
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,

      showMobile: true,
    },
    {
      dataKey: 'action',
      headerLabel: 'label_action_needed',
      maxWidth: 120,
      cellRenderer: StringCell,
      align: 'flex-start',
      columnFlex: 1,
      sortable: true,

      showMobile: true,
    },
  ];
  return (
    <div className={styles.Cash}>
      <Table
        loading={isLoading}
        defaultSortColumn="link"
        defaultSortDirection="asc"
        data={tableData?.sort(
          (a, b) => Number(a.machineId) - Number(b.machineId)
        )}
        columns={column}
      />
    </div>
  );
}

export default Cash;

interface ICashTableData {
  machineId: string;
  status: 'online' | 'maintenance' | 'out_of_service' | 'offline' | null;
  organisation: string | null;
  machineName: string | null;
  machineCash: string | null;
  noteNumbers: number | null;
  refillSheet: string | null;
  action: string | null;
  zone: string | null;
  link: {
    translationKey: string;
    to: string;
  };
}
