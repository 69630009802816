import * as React from "react";
import { renderToStaticMarkup } from "react-dom/server";
import * as mapboxgl from "mapbox-gl";
import * as geoViewport from "geo-viewport";
import config from "common/config";
import { BBox } from "geojson";
import useSupercluster from "use-supercluster";
import { MapProps } from "containers/machines/views/map/Map";
import { debounce } from "lodash";
import { Marker } from "./Marker";
import { Cluster } from "./Cluster";
import { MachineCard } from "./MachineCard";
import { useTranslation } from "react-i18next";
import { calculateBoundingBox } from "./calculateBoundingBox";
import analytics from "utils/analytics";

import "mapbox-gl/dist/mapbox-gl.css";
import "./style.css";
import { toasti18n } from 'utils/toast';

type MapComponentProps = MapProps & {
  loading: boolean;
};

function Map({ points = [], loading }: MapComponentProps) {
  const { t } = useTranslation("machine", {
    keyPrefix: "map_page.map"
  });
  const mapContainer = React.useRef(null);
  const mapInstance = React.useRef<mapboxgl.Map>(null);
  const markers = React.useRef([]);
  // Use Bangkok coordinate as default
  const [viewport, setViewport] = React.useState({
    latitude: 13.7306, 
    longitude: 100.5435,
    zoom: 5,
  });
  const [bounds, setBounds] = React.useState<BBox>(undefined);

  const { clusters, supercluster } = useSupercluster({
    points,
    bounds,
    zoom: viewport.zoom,
    options: {
      radius: 80,
      maxZoom: 13,
    },
  });

  React.useEffect(() => {
    if(!mapContainer.current) return;
    if (mapInstance.current) return;

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [viewport.longitude, viewport.latitude],
      zoom: viewport.zoom,
      accessToken: config.MAPBOX_TOKEN,
    });

    mapInstance.current = map;

    // fix problem with map not showing properly on first load
    map.once("load", () => {
      map.resize();
    });
  
    map.on("load", () => {
      updateBounds(map);
    });

    map.on(
      "move",
      debounce(() => {
        updateBounds(map);
        setViewport((prev) => ({
          ...prev,
          latitude: map.getCenter().lat,
          longitude: map.getCenter().lng,
        }));
        analytics.track({
          name: "map moved",
          properties: {
            latitude: map.getCenter().lat,
            longitude: map.getCenter().lng,
          }
        });
      }, 200)
    );

    map.on(
      "zoomend",
      debounce(() => {
        setViewport((prev) => ({
          ...prev,
          zoom: map.getZoom(),
        }));
        analytics.track({
          name: "map zoomed",
          properties: {
            zoom_level: map.getZoom(),
          }
        });
      }, 200)
    );

    map.addControl(new mapboxgl.NavigationControl({
      showCompass: false,
      showZoom: true
    }), "bottom-right");

    return () => {
      map.remove();
      if (mapInstance.current) mapInstance.current = null;
    };
  }, [mapContainer]);

  const updateBounds = (map: mapboxgl.Map) => {
    const { _ne, _sw } = map.getBounds();
    setBounds([_sw.lng, _sw.lat, _ne.lng, _ne.lat]);
  };

  React.useEffect(() => {
    if (!mapInstance.current) return;

    // Remove existing markers
    markers.current.forEach((marker) => marker.remove());
    markers.current = [];

    // find max and min clusters point_count to scale the cluster size
    const max = Math.max(
      ...clusters.map((feature) => feature?.properties?.point_count || 0)
    );
    const min = Math.min(
      ...clusters.map((feature) => feature?.properties?.point_count || 0)
    );

    // Add new markers
    clusters.forEach((feature) => {
      if (feature?.properties?.cluster) {
        const markerDOMElement = document.createElement("div");
        const staticElement = renderToStaticMarkup(
          <Cluster size={feature?.properties?.point_count} max={max} min={min} />
        );
        markerDOMElement.innerHTML = staticElement;
        const [longitude, latitude] = feature.geometry.coordinates;
        const marker = new mapboxgl.Marker(markerDOMElement)
          .setLngLat([longitude, latitude])
          .addTo(mapInstance.current);
        markers.current.push(marker);
        marker.getElement().addEventListener("click", () => {
          const expansionZoom = Math.min(
            supercluster.getClusterExpansionZoom(feature.id),
            18
          );

          mapInstance.current!.easeTo({
            center: [longitude, latitude],
            zoom: expansionZoom,
          });
          analytics.track({
            name: "cluster clicked",
            properties: {
              count: feature?.properties?.point_count,
              cluster_id: feature.id,
              target_zoom_level: expansionZoom,
              current_zoom_level: mapInstance.current.getZoom(),
              lat: latitude,
              long: longitude,
            }
          });
        });
        return;
      }

      const markerDOMElement = document.createElement("div");
      const staticElement = renderToStaticMarkup(
        <Marker
          status={feature?.properties?.status || "online"}
          text={feature?.properties?.text || feature?.properties?.point_count}
        />
      );
      markerDOMElement.innerHTML = staticElement;
      const [longitude, latitude] = feature.geometry.coordinates;

      const popupStaticElement = renderToStaticMarkup(
        <MachineCard
          id={feature.id}
          href={feature?.properties?.link}
          icon="VMWater"
          machineName={feature?.properties?.locationName}
          machineId={feature?.properties?.machineId}
          
          badgeStatus={feature?.properties?.status}
          refillZoneName={feature?.properties?.refillZoneName}
          serviceZoneName={feature?.properties?.serviceZoneName}
          coordinates={{
            latitude,
            longitude
          }}
        />
      );

      const popup =  new mapboxgl.Popup({
        closeButton: false,
        offset: 10,
        maxWidth: "400px",
        className: "min-w-[350px]",
        focusAfterOpen: false,
      }).setHTML(popupStaticElement);
      popup.on('open', () => {
        const analyticsProperties = {
          machine_id: feature?.properties?.machineId,
          machine_name: feature?.properties?.locationName,
          refill_zone_name: feature?.properties?.refillZoneName,
          service_zone_name: feature?.properties?.serviceZoneName,
        };
        analytics.track({
          name: "map popup open",
          properties: analyticsProperties
        });
        // handle close popup
        const closeButton = document.querySelector('.map-popup-close-button');
        if (closeButton) {
          closeButton.addEventListener('click', () => {
            popup.remove();
          });
        }
        // handle click copy map link
        // use this way instead of inputRef because ReactDOMServer(or renderToStaticMarkup) not support
        const copyMapLinkButton = document.querySelector(`#copy-button-${feature.id}`);
        const copyMapLinkInput = document.querySelector(`#copy-map-input-${feature.id}`);
        if(copyMapLinkButton && copyMapLinkInput) {
          copyMapLinkButton?.addEventListener('click',() => {
            (copyMapLinkInput as HTMLInputElement).select();
            const mapLink = (copyMapLinkInput as HTMLInputElement).value;
            navigator.clipboard.writeText(mapLink);
            if(mapLink) {
              toasti18n.success(t("map_link_copied_label"));
              analytics.track({
                name: "map link copied",
                properties: analyticsProperties
              });
            }
          });
        }
      });

      const marker = new mapboxgl.Marker(markerDOMElement)
        .setLngLat([longitude, latitude])
        .setPopup(popup)
        .addTo(mapInstance.current);
      markers.current.push(marker);
    });
  }, [clusters]);

  React.useEffect(() => {
    if (points.length > 0 && mapInstance && mapContainer) {
      const bounds = calculateBoundingBox(points);
      const viewport = geoViewport.viewport(bounds, [
        mapContainer.current.clientWidth,
        mapContainer.current.clientHeight,
      ]);
      mapInstance.current.setCenter(viewport.center);
      mapInstance.current.setZoom(viewport.zoom - 1);
    }
  }, [points, mapInstance, mapContainer]);

  return (
    <div className="w-full h-full flex-1 relative flex items-center justify-center">
      <div
        ref={mapContainer}
        className="absolute top-0 left-0 bottom-0 right-0 w-full h-full"
      ></div>
      {loading && (
        <div
          id="machine-list-map-loading"
          className="bg-white rounded-2xl shadow-md px-6 py-4 z-50"
        >
          {t("label_loading")}
        </div>
      )}
    </div>
  );
}

export default Map;
