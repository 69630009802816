import { useQuery } from "react-query";
import { validateBatchSwapOrder } from "services/swaporders";
import { swapOrderKeys } from "./swapOrderKeys";

function useValidateBatchSwapOrder(
  {
    machineId,
    slot,
    productId
  }: { machineId: Array<string>; slot: string; productId: string },
  { enabled = true } = {}
) {
  return useQuery(
    swapOrderKeys.validateBatch(machineId, slot, productId),
    () => validateBatchSwapOrder({ machineId, slot, productId }),
    {
      enabled: enabled
    }
  );
}

export { useValidateBatchSwapOrder };
