import {
  GetMaintenanceFrequentErrorsDocument,
  GetMaintenanceFrequentErrorsQuery,
  GetMaintenanceFrequentErrorsQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getFrequentErrors({
  serviceZoneId,
}: {
  serviceZoneId?: string;
}) {
  return fetcher<
    GetMaintenanceFrequentErrorsQuery,
    GetMaintenanceFrequentErrorsQueryVariables
  >(GetMaintenanceFrequentErrorsDocument, {
    filter: {
      serviceZoneId: serviceZoneId,
    },
  })();
}
