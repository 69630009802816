import * as React from "react";
import { warehousingRoutes } from "../../routes";
import TextButton from "common/components/textbutton/TextButton";
import { ErrorBoundary } from "react-error-boundary";
import { Fallback } from "components/Fallback";
import { CreateOrEditInventoryModal } from "./CreateOrEditInventoryModal";
import {
  WarehousingNavigation,
  useCanViewMasterInventoryList,
} from "../../components/WarehousingNavigation";
import { WarehousingHeader } from "../../components/WarehousingHeader";
import { InventoryListView } from "./InventoryListView";
import { Redirect } from "react-router-dom";
import {
  useIsAdmin,
  useIsAdminContract,
  useIsClerk,
  useIsForthCKUser,
  useIsMalaysianUser,
  useIsSingaporeanUser,
  useIsAustralianUser
} from "utils/user-role";

export function MasterInventoryList() {
  const isVisible = useCanViewMasterInventoryList();

  if (!isVisible) {
    return <Redirect to={warehousingRoutes.index()} />;
  }

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <WarehousingNavigation />
      <WarehousingHeader
        title="label_master_inventory_list"
        breadCrumbs={[
          {
            label: "label_warehousing",
          },
          {
            label: "label_master_inventory_list",
            link: warehousingRoutes.masterInventoryList(),
          },
        ]}
        buttons={
          <div style={{ display: "flex", gap: "48px" }}>
            <AddNewInventoryButton />
            <TextButton
              icon="DownloadDocument"
              translationKey="label_download_inventory_report"
              disabled
            />
          </div>
        }
      />
      <div style={{ flex: "1 1 0" }}>
        <ErrorBoundary FallbackComponent={Fallback}>
          <InventoryListView />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function useCanAddNewInventory() {
  const canAdd =
    (useIsForthCKUser() || useIsMalaysianUser() || useIsSingaporeanUser() || useIsAustralianUser()) &&
    (useIsAdmin() || useIsClerk() || useIsAdminContract());
  return canAdd;
}

export function AddNewInventoryButton() {
  const [isOpen, setIsOpen] = React.useState(false);

  const canAdd = useCanAddNewInventory();

  if (!canAdd) {
    return null;
  }

  return (
    <>
      {isOpen && (
        <CreateOrEditInventoryModal onClose={() => setIsOpen(false)} />
      )}
      <TextButton
        icon="Plus"
        translationKey="label_add_new_inventory"
        onClick={() => setIsOpen(true)}
      />
    </>
  );
}
