// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".organisationPicture--31EHknR64C{display:flex}.organisationPicture--31EHknR64C>*{margin-top:35px}.organisationPicture--31EHknR64C .imagePlaceholder--bPPlOCtxaM{background-color:#aaa;width:74px;height:74px}.organisationPicture--31EHknR64C .organisationAvatar--5svK87lM_b{width:74px;height:74px;border-radius:37px;object-fit:cover}.organisationPicture--31EHknR64C>.photoInfo--3Fg8PYlwpg{margin-left:20px}.organisationPicture--31EHknR64C>.photoInfo--3Fg8PYlwpg>.organisationPhotoLabel--3ltGYGdDo2{color:#b0b0b0}.organisationPicture--31EHknR64C>.photoInfo--3Fg8PYlwpg>.uploadButton--3Mph4qUDrh{margin-top:9px;max-width:90px}.organisationName--Ty9ombsYJ0{margin-top:35px}.organisationName--Ty9ombsYJ0>span{padding:0 !important}.buttonContainer--1HMobzk754{display:flex;flex-direction:row}.buttonContainer--1HMobzk754>.save--D1MNqMbGVg{margin-top:35px}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"organisationPicture": "organisationPicture--31EHknR64C",
	"imagePlaceholder": "imagePlaceholder--bPPlOCtxaM",
	"organisationAvatar": "organisationAvatar--5svK87lM_b",
	"photoInfo": "photoInfo--3Fg8PYlwpg",
	"organisationPhotoLabel": "organisationPhotoLabel--3ltGYGdDo2",
	"uploadButton": "uploadButton--3Mph4qUDrh",
	"organisationName": "organisationName--Ty9ombsYJ0",
	"buttonContainer": "buttonContainer--1HMobzk754",
	"save": "save--D1MNqMbGVg"
};
module.exports = exports;
