import * as React from "react";

import TextFieldViewOnly from "components/TextFieldViewOnly";
import { translateProvince } from "containers/location/utils/getProvince";
import {
  LocationInfo,
  getLocationTypeTranslation
} from "../types";
import TextField from "components/TextField";
import { AutocompleteOption } from "components/Autocomplete";
import { OrganisationIdAutoComplete } from "containers/contract/components/contract/OrganisationIdAutoComplete";
import { LocationTypeSelect } from "containers/location/components/form/LocationTypeSelect";
import { ProvinceAutoComplete } from "containers/location/components/form/ProvinceAutoComplete";

export function GeneralLocationInfo({ locationInfo }: { locationInfo: LocationInfo; }) {
  return (
    <>
      <TextFieldViewOnly
        label="label_location_name"
        value={locationInfo.name} />
      <TextFieldViewOnly
        label="label_organisation"
        value={locationInfo.organization.name}
        required />
      <TextFieldViewOnly
        label="label_location_type"
        value={getLocationTypeTranslation(locationInfo.type)}
        required />
      <TextFieldViewOnly
        label="label_google_map_link"
        value={locationInfo.googleMapLink} />
      <TextFieldViewOnly
        value={`${locationInfo.coordinates.latitude}, ${locationInfo.coordinates.longitude}`}
        label="label_GPS_location" />
      <TextFieldViewOnly
        label="label_address"
        value={locationInfo.address.street} />
      <TextFieldViewOnly
        label="label_province"
        value={translateProvince(locationInfo.address.province)} />
    </>
  );
}

export type GeneralLocationInfoFormValue = Pick<
  LocationInfo, "address" | "googleMapLink" | "type" | "coordinates" | "name"
> & { organizationId: string; };
type GeneralLocationFormProps = {
  value: GeneralLocationInfoFormValue;
  onChange: (value: GeneralLocationInfoFormValue) => void;
  initialOrgOptions: Array<AutocompleteOption>;
};

export function mapLocationInfoToGeneralLocationInfoFormValue(
  locationInfo: LocationInfo
): GeneralLocationInfoFormValue {
  return {
    address: locationInfo.address,
    googleMapLink: locationInfo.googleMapLink,
    type: locationInfo.type,
    coordinates: locationInfo.coordinates,
    name: locationInfo.name,
    organizationId: locationInfo.organization.id,
  };
}

export function GeneralLocationInfoForm({
  value, onChange, initialOrgOptions,
}: GeneralLocationFormProps) {
  return (
    <div style={{ display: "grid", gap: "10px" }}>
      <TextField
        label="label_location_name"
        value={value.name}
        onChange={(e) => {
          onChange({
            ...value,
            name: e.target.value,
          });
        } } />
      <OrganisationIdAutoComplete
        onChange={(orgId) => {
          onChange({
            ...value,
            organizationId: orgId,
          });
        } }
        value={value.organizationId}
        fallBackOptions={initialOrgOptions} />
      <LocationTypeSelect
        required
        value={value.type}
        onChange={(type) => {
          onChange({
            ...value,
            type,
          });
        } } />
      <TextField
        label="label_google_map_link"
        value={value.googleMapLink}
        onChange={(e) => {
          onChange({
            ...value,
            googleMapLink: e.target.value,
          });
        } } />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0,1fr))",
          gap: "10px",
        }}
      >
        <TextField
          label="label_latitude"
          value={value.coordinates.latitude}
          onChange={(e) => {
            onChange({
              ...value,
              coordinates: {
                ...value.coordinates,
                latitude: e.target.value,
              },
            });
          } } />
        <TextField
          label="label_longitude"
          value={value.coordinates.longitude}
          onChange={(e) => {
            onChange({
              ...value,
              coordinates: {
                ...value.coordinates,
                longitude: e.target.value,
              },
            });
          } } />
      </div>
      <TextField
        label="label_address"
        value={value.address.street}
        onChange={(e) => {
          onChange({
            ...value,
            address: {
              ...value.address,
              street: e.target.value,
            },
          });
        } } />
      <ProvinceAutoComplete
        required
        value={value.address.province}
        onChange={(province) => {
          onChange({
            ...value,
            address: {
              ...value.address,
              province,
            },
          });
        } } />
    </div>
  );
}

