import { CreatePlanInput, CreatePlanMutation } from "gql/generated";
import * as moment from "moment";
import analytics from "utils/analytics";
import { PlanInfo, RefillCandidate } from "./types";

type TrackMachineAddedToPlanArgs = {
  planId: string;
  variables: CreatePlanInput;
  data: RefillCandidate[];
};

export function trackMachineAddedToPlan({
  planId,
  data,
}: TrackMachineAddedToPlanArgs) {
  for (let i = 0; i < data.length; i++) {
    const machine = data[i];

    const locationData = {
      day_since_last_refill: Number(machine.lastRefilled)
        ? moment().diff(moment(Number(machine.lastRefilled)), "days")
        : "-",
      location_grade: machine.locationGrade,
      location_id: machine.locationId,
      location_name: machine.locationName,
      location_type: machine.locationType,
      machine_id: machine.candidateId,
      plan_id: planId,
      warehouse: machine.warehouse,
      zone: machine.zone,
      revenue_since_last_refill: machine.revenue,
      total_cash: machine.cashValue,
    };

    analytics.track({
      name: "machine added to a plan",
      properties: locationData,
    });
  }
}

export function trackPlanCreated({
  successData,
  variables,
  planInfo,
}: {
  successData: CreatePlanMutation,
  variables: CreatePlanInput;
  planInfo: PlanInfo
}) {
  analytics.track({
    name: "plan created",
    properties: {
      leave_time: variables.startTime,
      plan_id: successData?.createPlan?.id,
      plan_name: variables.name,
      no_of_machine_in_plan: variables?.refillOrderCandidates?.length,
      warehouse_id: planInfo.warehouseId,
    }
  });
}
