// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormField--2fAgFgLVQt{box-sizing:border-box;min-height:54px;padding:0px 14px;display:flex;justify-content:space-between;align-items:center;border:1px solid #e0e0e0;border-radius:4px}.FormField--2fAgFgLVQt *{box-sizing:border-box}.FormField--2fAgFgLVQt .textContainer--3vdpZpQBTo{height:100%;max-width:70%;display:flex;align-items:center}.FormField--2fAgFgLVQt .textContainer--3vdpZpQBTo .fileName--1O2j2Ydulf{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:#000}.FormField--2fAgFgLVQt .textContainer--3vdpZpQBTo .placeHolderText--d7u_OIowur{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;color:rgba(0,0,0,.6)}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"FormField": "FormField--2fAgFgLVQt",
	"textContainer": "textContainer--3vdpZpQBTo",
	"fileName": "fileName--1O2j2Ydulf",
	"placeHolderText": "placeHolderText--d7u_OIowur"
};
module.exports = exports;
