import Typography from "components/Typography";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useErrorBoard } from "../../hooks/useErrorBoard";
import * as moment from "moment";
import { Link } from "react-router-dom";
import { MaintenanceTable } from "../../components/MaintenanceTable";
import Icon from "common/components/icon/Icon";
import { ModeBadge } from "../../components/ModeBadge";

export function IceErrorTable() {
  const { state, paginate } = useErrorBoard();
  const { t } = useTranslation("errorBoard");

  if (state.status === "error") {
    throw state.error;
  }

  return (
    <MaintenanceTable
      isLoading={state.status === "loading" || state.status === "refreshing"}
      data={
        state.data.table?.type === "ICE_ERROR"
          ? state.data.table.data
          : undefined
      }
      containerStyles={{
        scrollPaddingLeft: "80px",
      }}
      onScrollNearEnd={paginate}
      getKey={(dataPoint) => dataPoint.id}
      isPaginating={state.status === "paginating"}
      columns={[
        {
          id: "MACHINE_ID",
          commonStyle: {
            minWidth: "80px",
            flex: "0 0 80px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          headerStyle: {
            position: "sticky",
            top: "0",
            left: "0",
            backgroundColor: "#F5F5F5",
          },
          headerCell: () => (
            <Typography type="caption" color="onSurfaceHigh">
              {t("machineId")}
            </Typography>
          ),
          rowStyle: {
            display: "flex",
            alignItems: "center",
            position: "sticky",
            left: "0",
            backgroundColor: "white",
          },
          cell: (event) => (
            <Link to={`/machines/detail/${event.machineId}/status`}>
              <Typography type="subtitle-2" color="primary500">
                {event.machineId}
              </Typography>
            </Link>
          ),
          loadingRowStyle: {
            position: "sticky",
            left: "0",
            backgroundColor: "white",
            zIndex: 1,
          },
        },
        {
          id: "ERROR_DETAIL",
          commonStyle: {
            minWidth: "104px",
            flex: "0 0 104px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("errorDetail")}
              </Typography>
            );
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="subtitle-2" color="error">
                {event.errorDetail}
              </Typography>
            );
          },
        },
        {
          id: "GRADE",
          commonStyle: {
            minWidth: "128px",
            flex: "0 0 128px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          headerStyle: {
            display: "flex",
            flexDirection: "column",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.grade.weekday ?? "-"}/{event.grade.weekend ?? "-"}
              </Typography>
            );
          },
          headerCell() {
            return (
              <>
                <Typography type="caption" color="onSurfaceHigh">
                  {t("grade")}
                </Typography>
                <Typography type="caption" color="onSurfaceMedium">
                  {t("weekday")}/{t("weekend")}
                </Typography>
              </>
            );
          },
        },
        {
          id: "LOCATION",
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.location}
              </Typography>
            );
          },
          commonStyle: {
            flex: "1 0 200px",
            minWidth: "200px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("locationName")}
              </Typography>
            );
          },
        },
        {
          id: "SERVICE_ZONE",
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.serviceZone}
              </Typography>
            );
          },
          commonStyle: {
            minWidth: "110px",
            flex: "0 0 110px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("serviceZone")}
              </Typography>
            );
          },
        },
        {
          id: "REFILL_ZONE",
          commonStyle: {
            minWidth: "110px",
            flex: "0 0 110px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.refillZone}
              </Typography>
            );
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("refillZone")}
              </Typography>
            );
          },
        },
        {
          id: "DATE_TIME",
          rowStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            scrollSnapAlign: "start",
          },
          commonStyle: {
            minWidth: "104px",
            flex: "0 0 104px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          cell(event) {
            return (
              <>
                <Typography type="body-2" color="onSurfaceHigh">
                  {moment(event.date).format("HH:mm")}
                </Typography>
                <Typography type="caption" color="onSurfaceDisabled">
                  {moment(event.date).format("MMM DD, YYYY")}
                </Typography>
              </>
            );
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("dateTime")}
              </Typography>
            );
          },
        },
        {
          id: "RATE",
          rowStyle: {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            scrollSnapAlign: "start",
          },
          commonStyle: {
            minWidth: "65px",
            flex: "0 0 65px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.rate === undefined ? "-" : event.rate.toLocaleString()}
              </Typography>
            );
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("rate")}
              </Typography>
            );
          },
        },
        {
          id: "TEMP",
          commonStyle: {
            minWidth: "62px",
            flex: "0 0 62px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            scrollSnapAlign: "start",
          },
          headerStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          },
          cell(event) {
            return (
              <Typography type="body-2" color="onSurfaceHigh">
                {event.temperature}
              </Typography>
            );
          },
          headerCell() {
            return (
              <>
                <Typography type="caption" color="onSurfaceHigh">
                  {t("temp")}
                </Typography>
                <Typography type="caption" color="onSurfaceMedium">
                  {"°C"}
                </Typography>
              </>
            );
          },
        },
        {
          id: "COMPRESSOR",
          commonStyle: {
            minWidth: "96px",
            flex: "0 0 96px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return <ModeBadge status={event.compressorStatus} />;
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("compressor")}
              </Typography>
            );
          },
        },
        {
          id: "STAFF_AT_MACHINE",
          commonStyle: {
            minWidth: "75px",
            flex: "0 0 75px",
            padding: "2px 8px",
            boxSizing: "border-box",
            lineHeight: "16px",
          },
          rowStyle: {
            display: "flex",
            alignItems: "center",
            scrollSnapAlign: "start",
          },
          cell(event) {
            return event.staffAtMachine ? (
              <Icon
                name="StaffAtMachine"
                style={{ marginLeft: "4px" }}
                color="onSurfaceMedium"
              />
            ) : (
              <Icon name="StaffNotAtMachine" color="outlineColor" />
            );
          },
          headerCell() {
            return (
              <Typography type="caption" color="onSurfaceHigh">
                {t("staffAtMachine")}
              </Typography>
            );
          },
        },
      ]}
    />
  );
}
