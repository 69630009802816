import { FilterType } from "react-table";
import * as _ from "lodash";

export default function valueFilterFn<D extends object>(
  path: string,
  caseSensitive = false
) {
  const filter: FilterType<D> = (rows, columnIds, filterValue) => {
    if (filterValue.length === 0) return rows;

    return rows.filter((row) => {
      const currentValue = path.length
        ? _.get(row.original[String(columnIds[0])], path)
        : row.original[String(columnIds[0])];

      if (!caseSensitive) {
        return currentValue.toLowerCase().includes(filterValue.toLowerCase());
      }
      return currentValue.includes(filterValue);
    });
  };

  return filter;
}
