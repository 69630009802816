import { SortByFn } from "react-table";
import * as _ from "lodash";

export default function stringCompareFn<D extends object>(path: string) {
  const compare: SortByFn<D> = (rowA, rowB, columnId) => {
    const numA = path.length
      ? _.get(rowA.original[String(columnId)], path)
      : rowA.original[String(columnId)];
    const numB = path.length
      ? _.get(rowB.original[String(columnId)], path)
      : rowB.original[String(columnId)];

    return numA.localeCompare(numB);
  };

  return compare;
}
