// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Container--1D9DJtmP0_{display:flex;flex:1 1 0;min-height:0;overflow-x:auto;overflow-y:hidden}.selectContainer--2_xJbuJna5{display:flex;justify-content:end;align-items:end;margin-bottom:20px;gap:10px}@media screen and (max-width: 1200px){.selectContainer--2_xJbuJna5{justify-content:start;flex-direction:row-reverse}}.selectContainer--2_xJbuJna5 .select--1SQ3UCsiMD{width:200px}", ""]);
// Exports
exports.locals = {
	"Container": "Container--1D9DJtmP0_",
	"selectContainer": "selectContainer--2_xJbuJna5",
	"select": "select--1SQ3UCsiMD"
};
module.exports = exports;
