import * as React from "react";
import * as styles from "./Overview.module.scss";
import { useMemo, useEffect } from "react";
import Table from "components/Table";
import { useGetMonitorMachines } from "machines/hooks/useGetMonitorMachines";
import { mapDataToTable, generateColumnConfig, filterConfig } from "./utils";

const Overview = ({ setSelectedMachine }: OverviewProps) => {
  useEffect(() => {
    setSelectedMachine([]);
  }, []);

  const { data = [], isLoading } = useGetMonitorMachines();

  const tableData = useMemo(() => mapDataToTable(data), [data]);

  const columns = useMemo(() => generateColumnConfig(), []);

  const defaultInitialState = useMemo(
    () => ({ sortBy: [{ id: "machineId", desc: false }] }),
    []
  );

  function getSelectedMachineIds(selectedRowIdxs: number[]): void {
    setSelectedMachine(
      selectedRowIdxs.map((idx) => tableData[idx].machineId.translationKey)
    );
  }
  return (
    <div className={styles.TableContainer}>
      <Table
        data={tableData}
        isLoading={isLoading}
        columns={columns}
        initialState={defaultInitialState}
        filterConfig={filterConfig}
        onRowSelect={getSelectedMachineIds}
      />
    </div>
  );
};

export default Overview;

interface OverviewProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<string[]>>;
}
