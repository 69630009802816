import * as React from "react";
import { CellAlignmentProps } from "../types";

import * as styles from "./LoadingCell.module.scss";

export default function LoadingCell({
  xAlign = "flex-start",
  yAlign = "center",
}: CellAlignmentProps = {}): JSX.Element {
  return (
    <div
      className={styles.LoadingCell}
      style={{ justifyContent: xAlign, alignItems: yAlign }}
    >
      <div className={styles.LoadingBar}></div>
    </div>
  );
}
