// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Maintenance--3xZ7G9Y7Cb{margin:-20px -20px;flex:1 1 0;min-height:0}@media(min-width: 750px){.Maintenance--3xZ7G9Y7Cb{margin:-20px -35px}}", ""]);
// Exports
exports.locals = {
	"Maintenance": "Maintenance--3xZ7G9Y7Cb"
};
module.exports = exports;
