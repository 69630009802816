import * as React from 'react';
import { useMemo, useEffect } from 'react';
import * as styles from './AllError.module.scss';
import Table from 'components/Table';
import * as moment from 'moment';

import {
  useGetMachineModuleErrorQuery,
  GetMachineModuleErrorQuery,
} from 'gql/generated';

// table column config
import { Column } from 'react-table';
import { HeaderCell, LinkCell, StringCell } from 'components/Table/Cells';
import { stringCompareFn, valueFilterFn } from 'components/Table/util';
import { IContainsFilterConfig } from 'components/Table/Filter/types';

const AllError = ({ setSelectedMachine }: AllErrorProps) => {
  useEffect(() => {
    setSelectedMachine([]);
  }, []);

  const { data, isLoading } = useGetMachineModuleErrorQuery();

  const tableData = useMemo(() => mapDataToTable(data), [data]);

  const columns = useMemo(() => getColumnConfig(), []);

  const defaultInitialState = useMemo(
    () => ({ sortBy: [{ id: 'machineId', desc: false }] }),
    []
  );

  //   function getSelectedMachineIds(selectedRowIdxs: number[]): void {
  //     setSelectedMachine(
  //       selectedRowIdxs.map((idx) => tableData[idx].machineId.translationKey)
  //     );
  //   }

  return (
    <div className={styles.AllError}>
      <Table
        data={tableData}
        isLoading={isLoading}
        columns={columns}
        initialState={defaultInitialState}
        filterConfig={filterConfig}
        // onRowSelect={getSelectedMachineIds}
      />
    </div>
  );
};

export default AllError;

interface AllErrorProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<string[]>>;
}

function mapDataToTable(data: GetMachineModuleErrorQuery): TableData[] {
  const mappedData =
    data?.machineModuleError.map((each) => {
      const location = each.machine.location;

      return {
        machineId: {
          translationKey: each.machine.id,
          to: `/machines/detail/${each.machine.id}/inventory`,
        },
        name: each.machine.name,
        organisation: each.machine.organisation.name,
        errorCode: each.errorCode,
        errorDetail: each.errorCode,
        serviceZone: location.__typename !== 'Factory' ? location.serviceZone?.name : '',
        disableTime: formatDate(each.errorTimestamp) as any,
      };
    }) ?? [];
  return mappedData;
}

function getColumnConfig(): Column<TableData>[] {
  return [
    {
      Header: HeaderCell({ label: 'label_machine_id' }),
      accessor: 'machineId',
      width: 94,
      minWidth: 94,
      Cell: LinkCell(),
      sortType: stringCompareFn('translationKey'),
      filter: valueFilterFn('translationKey', true),
    },
    {
      Header: HeaderCell({ label: 'label_name' }),
      accessor: 'name',
      minWidth: 216,
      width: 216,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
    {
      Header: HeaderCell({ label: 'label_organisation' }),
      accessor: 'organisation',
      minWidth: 94,
      width: 94,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
    {
      Header: HeaderCell({ label: 'label_error_code' }),
      accessor: 'errorCode',
      minWidth: 94,
      width: 94,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
    {
      Header: HeaderCell({ label: 'label_error_detail' }),
      accessor: 'errorDetail',
      minWidth: 94,
      width: 94,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
    {
      Header: HeaderCell({ label: 'label_service_zone' }),
      accessor: 'serviceZone',
      minWidth: 94,
      width: 94,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
    {
      Header: HeaderCell({ label: 'label_disable_time' }),
      accessor: 'disableTime',
      minWidth: 94,
      width: 94,
      Cell: StringCell(),
      sortType: stringCompareFn(''),
      filter: valueFilterFn(''),
    },
  ];
}

interface IFilterConfig {
  machineId: IContainsFilterConfig;
  organisation: IContainsFilterConfig;
  name: IContainsFilterConfig;
  errorCode: IContainsFilterConfig;
  errorDetail: IContainsFilterConfig;
  serviceZone: IContainsFilterConfig;
  disableTime: IContainsFilterConfig;
}
const filterConfig: IFilterConfig = {
  machineId: { translationKey: 'label_machine_id', filterType: 'contains' },
  name: { translationKey: 'label_name', filterType: 'contains' },
  organisation: { translationKey: 'label_org', filterType: 'contains' },
  errorCode: { translationKey: 'label_error_code', filterType: 'contains' },
  errorDetail: { translationKey: 'label_error_detail', filterType: 'contains' },
  serviceZone: { translationKey: 'label_service_zone', filterType: 'contains' },
  disableTime: { translationKey: 'label_disable_time', filterType: 'contains' },
};

interface TableData {
  machineId: {
    translationKey: string;
    to: string;
  };
  name: string;
  organisation: string;
  errorCode: string;
  errorDetail: string;
  serviceZone: string;
  disableTime: string;
}

function formatDate(time: string) {
  return moment(time, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm');
}
