// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MultipleIndicatorCell--xbWZmF-ojI{width:100%;height:100%;display:flex}.MultipleIndicatorCell--xbWZmF-ojI .Row--3oWygGPzxI{display:flex;align-items:center;justify-content:flex-start;gap:4px}", ""]);
// Exports
exports.locals = {
	"MultipleIndicatorCell": "MultipleIndicatorCell--xbWZmF-ojI",
	"Row": "Row--3oWygGPzxI"
};
module.exports = exports;
