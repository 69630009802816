// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CurrencyCell--3z3CfzUbfN{width:100%;height:100%;display:flex}.CurrencyCell--3z3CfzUbfN .Label--3A0THZ4i7n{white-space:nowrap}", ""]);
// Exports
exports.locals = {
	"CurrencyCell": "CurrencyCell--3z3CfzUbfN",
	"Label": "Label--3A0THZ4i7n"
};
module.exports = exports;
