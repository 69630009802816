// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableContainer--2oypSrmjDT{width:100%;height:100%;overflow:auto;scrollbar-gutter:stable;scroll-snap-type:both mandatory;overscroll-behavior:none;display:flex;flex-direction:column;scroll-padding-top:40px}.TableContainer--2oypSrmjDT .MinWidthContainer--3nSLdZwci8{min-width:min-content}.TableContainer--2oypSrmjDT .HeaderRow--30Lcmh3U6E{background-color:#f5f5f5;display:flex;border-bottom:1px solid rgba(102,102,102,.32);position:sticky;top:0;z-index:2;height:40px}.TableContainer--2oypSrmjDT .Row--5lzoMaeyJH{display:flex;border-bottom:1px solid rgba(102,102,102,.32);min-height:48px}.TableContainer--2oypSrmjDT .Row--5lzoMaeyJH .LoadingCell--3G4WQx9lUM{display:flex;align-items:center}.TableContainer--2oypSrmjDT .Row--5lzoMaeyJH .LoadingCell--3G4WQx9lUM .SkeletonContainer--3Uk9JD8A-r{width:100%;padding:3px}.FadeIn--1cXNBpThpI{animation:FadeIn--1cXNBpThpI 200ms ease-in-out}@keyframes FadeIn--1cXNBpThpI{0%{opacity:0}100%{opacity:1}}", ""]);
// Exports
exports.locals = {
	"TableContainer": "TableContainer--2oypSrmjDT",
	"MinWidthContainer": "MinWidthContainer--3nSLdZwci8",
	"HeaderRow": "HeaderRow--30Lcmh3U6E",
	"Row": "Row--5lzoMaeyJH",
	"LoadingCell": "LoadingCell--3G4WQx9lUM",
	"SkeletonContainer": "SkeletonContainer--3Uk9JD8A-r",
	"FadeIn": "FadeIn--1cXNBpThpI"
};
module.exports = exports;
