import * as React from 'react';
import { useEffect } from 'react';
import {
  GetErrorScoreboardQueryVariables,
  GetErrorScoreboardQuery,
  GetErrorScoreboardDocument,
} from '../../../../../../gqlpaginatedquery/machine/errorscoreboard/index';

import * as styles from './Snapshot.module.scss';
import Typography from 'components/Typography';
import SnapshotTable from './table/SnapshotTable';
import Button from 'components/Button';
import { useInfiniteQuery } from 'react-query';
import { AxiosError } from 'axios';
import { fetcher } from 'gql/fetcher';
import { toast } from 'react-toastify';
import i18n from 'common/i18n';

const Snapshot = ({ setSelectedMachine }: ISnapshotProps) => {
  function makeNetworkRequest({
    limit = 3,
    cursor = null,
  }: GetErrorScoreboardQueryVariables = {}) {
    return fetcher<GetErrorScoreboardQuery, GetErrorScoreboardQueryVariables>(
      GetErrorScoreboardDocument,
      {
        limit,
        cursor,
      }
    )();
  }

  const {
    data: tableData,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = useInfiniteQuery<GetErrorScoreboardQuery, AxiosError>(
    ['InfiniteErrorScoreboardQuery'],
    ({
      pageParam,
    }: {
      pageParam?: GetErrorScoreboardQuery['moduleErrorSummarySnapshot']['meta']['cursor']['next'];
    }) =>
      makeNetworkRequest({
        cursor: pageParam,
      }),
    {
      getNextPageParam: (lastPage) =>
        lastPage.moduleErrorSummarySnapshot.meta.cursor.next ?? undefined,
      refetchOnWindowFocus: false,
      onError: () => {
        toast.error(i18n.t('toast_request_failed'));
      },
    }
  );

  const cardData: IResults[] = isLoading
    ? []
    : (
        tableData.pages.map(
          (each) => each.moduleErrorSummarySnapshot.results
        ) as any
      ).flat();

  useEffect(() => {
    setSelectedMachine([]);
  }, []);

  return (
    <div className={styles.Snapshot}>
      {!isLoading &&
        cardData.map((each) => (
          // eslint-disable-next-line react/jsx-key
          <div className={styles.Container}>
            <div className={styles.Header}>
              <Typography className={styles.Heading} type="headline-6">
                {`${each.date} ${each.hour}`}
              </Typography>
              <Typography type="body-2">{`(Machine Count: ${each.machineCount})`}</Typography>
            </div>
            <SnapshotTable data={each.errors} />
          </div>
        ))}

      <div className={styles.ButtonContainer}>
        {!isLoading && tableData.pages.length !== 0 && hasNextPage && (
          <Button
            type="secondary"
            loading={isLoading}
            disabled={isLoading}
            onClick={() => fetchNextPage()}
          >
            action_load_more
          </Button>
        )}
      </div>
    </div>
  );
};

export default Snapshot;

export interface IMachineAPIData {
  name: string;
  totalDisable: number;
  change: number;
  new: number;
  old: number;
  fixed: number;
}
interface ISnapshotProps {
  setSelectedMachine: React.Dispatch<React.SetStateAction<string[]>>;
}
export interface IResults {
  date: string;
  hour: string;
  machineCount: number;
  errors: IMachineAPIData[];
}
