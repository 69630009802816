import { LocationKind } from "gql/generated";
import * as React from "react";
import { useGetLocationById } from "../../hooks/useGetLocationById";
import { useGetLocationPermissions } from "../../hooks/useGetLocationPermissions";
import { LocationIdLoading } from "../../components/LocationIdLoading";
import { LocationIdContainer } from "../../components/LocationIdContainer";
import { useGetLocationIdUrlParams } from "../../hooks/useGetLocationIdUrlParams";
import { FactoryForm } from "./FactoryForm";
import { LocationForm } from "./LocationForm";


export default function EditLocationId() {
  const locationId = useGetLocationIdUrlParams();
  const getLocationByIdResult = useGetLocationById(locationId);
  const locationPermissions = useGetLocationPermissions();
  const isLoading = locationPermissions.isLoading || getLocationByIdResult.isLoading;

  let childrenLocation: React.ReactNode;

  if (isLoading) {
    childrenLocation = <LocationIdLoading />;
  } else {
    const location = getLocationByIdResult.data;
    switch (location.kind) {
      case LocationKind.Store:
        throw new Error("Not allowed to edit store");
      case LocationKind.Factory:
        childrenLocation = (
          <FactoryForm
            canEdit={locationPermissions.canUpdateFactory.allowed}
            factory={location} />
        );
        break;
      case LocationKind.Location:
        childrenLocation = (
          <LocationForm
            canView={locationPermissions.canViewLocationEditPage.allowed}
            canEditGeneralInfo={locationPermissions.canEditGeneral.allowed}
            canEditOperationalInfo={locationPermissions.canEditOperationalInfo.allowed}
            location={location} />
        );
    }
  }

  return <LocationIdContainer>{childrenLocation}</LocationIdContainer>;
}
