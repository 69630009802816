import { SortByFn } from "react-table";
import * as _ from "lodash";

const ERR_BG_MAP = {
  success: 100,
  warning: 10,
  error: 1,
  undefined: 0,
};

export default function errorsCompareFn<D extends object>(
  path: string,
  attribute: string
) {
  const compare: SortByFn<D> = (rowA, rowB, columnId) => {
    const statusesA = path.length
      ? _.get(rowA.original[String(columnId)], path).map(
          (item: object) => ERR_BG_MAP[item[attribute]]
        )
      : rowA.original[String(columnId)].map(
          (item: object) => ERR_BG_MAP[item[attribute]]
        );
    const statusesB = path.length
      ? _.get(rowB.original[String(columnId)], path).map(
          (item: object) => ERR_BG_MAP[item[attribute]]
        )
      : rowB.original[String(columnId)].map(
          (item: object) => ERR_BG_MAP[item[attribute]]
        );

    const prioritiesA = statusesA.map(
      (val: number, idx: number) => val * Math.pow(10, statusesA.length - idx)
    );
    const prioritiesB = statusesB.map(
      (val: number, idx: number) => val * Math.pow(10, statusesB.length - idx)
    );

    const scoreA = prioritiesA.reduce(
      (partialSum: number, val: number) => partialSum + val,
      0
    );
    const scoreB = prioritiesB.reduce(
      (partialSum: number, val: number) => partialSum + val,
      0
    );

    return scoreA > scoreB ? 1 : -1;
  };

  return compare;
}
