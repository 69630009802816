// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CheckboxCell--nTD2bBbOf0{width:100%;height:100%;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"CheckboxCell": "CheckboxCell--nTD2bBbOf0"
};
module.exports = exports;
