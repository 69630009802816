import { useGetSwapOrders } from "./useGetSwapOrders";

function useSelectSwapOrder(orderNumber: string) {
  const { data, ...rest } = useGetSwapOrders({ enabled: false }); // Subscriber mode
  const swapOrder = data?.find(
    swapOrder => swapOrder.orderNumber === orderNumber
  );
  return { data: swapOrder, ...rest };
}

export { useSelectSwapOrder };
