import * as React from "react";

import MuiLabel from "components/MuiLabel";
import Typography from "components/Typography";

import { contractRoutes } from "containers/contract";

import { Link } from "react-router-dom";

import { Contracts, ContractArray } from "../types";

import { insertElementBetweenExcludingEnd } from "../utils/insertElementBetweenExcludingEnd";

export function LocationContracts({ contracts }: { contracts: Contracts; }) {
  return (
    <>
      <ContractList
        title="label_current_contract"
        contracts={contracts.active}
        collapsible={false} />
      <ContractList
        title="label_upcoming_contract"
        contracts={contracts.inactive} />
      <ContractList
        title="label_past_contract"
        contracts={contracts.pastContract} />
    </>
  );
}

function ContractList({
  contracts, title, collapsible = true,
}: {
  contracts: ContractArray;
  title: string;
  collapsible?: boolean;
}) {
  const [isCollapsed, setIsCollapsed] = React.useState(collapsible);

  const sortedContracts = contracts.sort((a, b) => a.friendlyId.localeCompare(b.friendlyId)
  );

  if (contracts.length === 0) return null;

  const linksArray = sortedContracts?.map((contract) => (
    <Link
      to={contractRoutes.contractId(contract.id)}
      target="_blank"
      rel="noopener noreferrer"
      key={`contract-${contract.id}`}
    >
      <Typography type="subtitle-1" color="primary500">
        {contract.friendlyId}
      </Typography>
    </Link>
  ));
  const renderedLinks = insertElementBetweenExcludingEnd(
    linksArray,
    (index) => (
      <Typography type="subtitle-1" color="primary500" key={"$" + index}>
        {", "}
      </Typography>
    )
  );

  return (
    <MuiLabel label={title}>
      {collapsible ? (
        <div
          style={{
            ...triangleStyles,
            rotate: isCollapsed ? "0deg" : "90deg",
          }}
          onClick={() => setIsCollapsed(!isCollapsed)}
        ></div>
      ) : null}

      {isCollapsed ? null : renderedLinks}
    </MuiLabel>
  );
}

const triangleStyles = {
  position: "absolute",
  top: "-4px",
  left: "0",
  borderLeft: "5px solid black",
  zIndex: 99999,
  cursor: "pointer",
  boxSizing: "border-box",
  borderTop: "5px solid transparent",
  borderBottom: "5px solid transparent",
  borderRight: "5px solid transparent",
  transformOrigin: "0% 50%",
  transition: "all 0.2s",
} as const;
