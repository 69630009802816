// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SwapInventoryConfirmationModal--13VfOouUtb{flex-direction:column;min-width:436px}.SwapInventoryConfirmationModal--13VfOouUtb .header--7R59VkULcA{margin-bottom:13px}.SwapInventoryConfirmationModal--13VfOouUtb .machineInfo--2taMpwRDtu{margin-bottom:8px}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary--3mkomrPREx{border-radius:4px;padding:8px 11px;margin-bottom:8px}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary--3mkomrPREx .oldMaterialContainer--1UvyayfKlC{margin-bottom:8px;display:flex}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary--3mkomrPREx .oldMaterialContainer--1UvyayfKlC .oldMaterialAction--2H_QaLkJdJ{font-weight:700;margin-right:5px;flex-shrink:0}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary--3mkomrPREx .newMaterialContainer--3GFarAknUJ{display:flex}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary--3mkomrPREx .newMaterialContainer--3GFarAknUJ .newMaterialAction--1cJ2nAVo15{font-weight:700;margin-right:5px;flex-shrink:0}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary__redTheme--1RBF0Y2nyE{background-color:#f8cecb}.SwapInventoryConfirmationModal--13VfOouUtb .swapInventorySummary__blueTheme--3Zy0ORVC5a{background-color:#eeeef7}.SwapInventoryConfirmationModal--13VfOouUtb .confirmationSection--OKvcgLFdxK .confirmationStringContainer--1eBXATl_Y0{margin-bottom:15px}.SwapInventoryConfirmationModal--13VfOouUtb .confirmationSection--OKvcgLFdxK .confirmationButtons--3lv3W7TJYU{display:flex;justify-content:space-between}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"SwapInventoryConfirmationModal": "SwapInventoryConfirmationModal--13VfOouUtb",
	"header": "header--7R59VkULcA",
	"machineInfo": "machineInfo--2taMpwRDtu",
	"swapInventorySummary": "swapInventorySummary--3mkomrPREx",
	"oldMaterialContainer": "oldMaterialContainer--1UvyayfKlC",
	"oldMaterialAction": "oldMaterialAction--2H_QaLkJdJ",
	"newMaterialContainer": "newMaterialContainer--3GFarAknUJ",
	"newMaterialAction": "newMaterialAction--1cJ2nAVo15",
	"swapInventorySummary__redTheme": "swapInventorySummary__redTheme--1RBF0Y2nyE",
	"swapInventorySummary__blueTheme": "swapInventorySummary__blueTheme--3Zy0ORVC5a",
	"confirmationSection": "confirmationSection--OKvcgLFdxK",
	"confirmationStringContainer": "confirmationStringContainer--1eBXATl_Y0",
	"confirmationButtons": "confirmationButtons--3lv3W7TJYU"
};
module.exports = exports;
