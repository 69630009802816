// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".TableContainer--2pZSsh4s5O{flex:1 1 0;min-height:0;overflow-x:auto;overflow-y:hidden}", ""]);
// Exports
exports.locals = {
	"TableContainer": "TableContainer--2pZSsh4s5O"
};
module.exports = exports;
