import { httpClient } from 'common/services/transportService';

export interface ISwapOrder {
  machineId: string  ,
  zone: string | null,
  orderNumber  : string,
  status  :   'created' |  'completed' | 'adjustment' | 'cancelled' | 'en_route' | 'ready_for_collection' | 'await_low_level' | 'recommended'
  createdAt  :   string ,
  updatedAt  :   string ,
  assignee  :     string ,
  swapMethod  :   'await_low_level' | 'immediate' | 'append'
  name  :   string,
  type  :   'Coffee',
  enableWorkOrderCreation: boolean,
  itemsRequested  : Array<{
    kit  : Kit | Record<string,never>,
    id  :   string  ,
    parLevel  : number,
    capacity  : number,
    refillLevel  : number,
    noPartialRefill  : boolean,
    additional  : {
        inventoryInfo  : {
            importanceLevel  : number
      }
  },
    productId  :   string,
    machineInventoryId  :  string,
    image  : null | string,
    name  :   string,
    packageQuantity  : number,
    packageSize  : number,
    packageType  :   string,
    uom  :   string,
    value  : number
    workInPackages  : boolean,
    slot  :  string 
  }>
}

interface Kit {
		lots: Array<
			{
				lotId: string,
				lotNo: string,
				location: string,
				quantity: number,
				createdAt: string,
				productId: string,
				updatedAt: string,
				additional: {
					reason: {
						reason: null
					}
				},
				expiryDate: string,
				supplierId: string,
				warehouseId: string,
				receivedDate: string
			}>,
		totalQuantity: number
}

export async function getSwapOrders(): Promise<Array<ISwapOrder>> {
  const response = await httpClient.get<Array<ISwapOrder>>(`/swaporder`, {
    timeout: 60 * 1000,
  });
  return response.data;
}
