// Hooks
import { useQuery } from 'react-query';

// Service function
import { getMachinesSlots } from 'machines/service';

// Types
import { IMachineSlots } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';

function useGetMachinesSlots (machineIds: Array<string>, {enabled = true} = {}) {
  return useQuery<Array<IMachineSlots>, AxiosError>(
    machineKeys.slots(machineIds),
    () => getMachinesSlots(machineIds),
    {
      enabled: enabled,
    }
  );
}

export { useGetMachinesSlots };