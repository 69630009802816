import * as React from 'react';

import Typography from 'common/components/typography/Typography';
import { formatCurrencyWithoutSymbol, getCurrencySymbol } from 'utils/currency';

import * as styles from './CardAggregate.module.scss';

interface CardAggregateProps {
  header: string;
  value: string;
  type: 'currency' | 'numeric';
  className?: string;
}

function CardAggregate({
  header,
  value,
  type,
  className,
}: CardAggregateProps): JSX.Element {
  return (
    <div className={[styles.CardAggregate, className].join(' ')}>
      <div>
        <Typography
          translationKey={header}
          type="caption"
          className={styles.Header}
        />
      </div>
      <div className={styles.Value}>
        {type === 'numeric' && <Typography text={value} type="headline-4" />}
        {type === 'currency' && (
          <>
            <Typography text={getCurrencySymbol()} type="subtitle-1" />
            <Typography
              text={formatCurrencyWithoutSymbol({
                input: value,
                minimumFractionDigits: 0,
              })}
              type="headline-4"
            />
          </>
        )}
      </div>
    </div>
  );
}

export { CardAggregate };
