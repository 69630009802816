import * as React from "react";
import { useMachinesStore } from "../../../useMachinesStore";
import { OnBoardMachineModalModal } from "./OnBoardMachineModal";
import { DownloadHealthModal } from "./DownloadHealthModal";
import { DownloadInventoryUsageReportModal } from "./DownloadInventoryUsageReportModal";
import { ChangeServiceZoneModal } from "./ChangeServiceZoneModal";
import { ChangeZoneModal } from "./ChangeZoneModal";
import { UpdateLimitModal } from "./UpdateLimitModal";

export function MachineModals () {
  const { modalOpen, setModalOpen, selectedMachine } = useMachinesStore();
  const onCloseModal = () => setModalOpen(null);
  return (
    <>
      <OnBoardMachineModalModal isOpen={modalOpen === "OnboardMachine"} onClose={onCloseModal} />
      <DownloadHealthModal isOpen={modalOpen === "DownloadHealth"} onClose={onCloseModal} selectedMachine={selectedMachine} />
      <DownloadInventoryUsageReportModal isOpen={modalOpen === "DownloadInventoryUsage"}  onClose={onCloseModal} selectedMachine={selectedMachine} />
      <ChangeServiceZoneModal isOpen={modalOpen === "ChangeServiceZone"}  onClose={onCloseModal} selectedMachine={selectedMachine} />
      <ChangeZoneModal isOpen={modalOpen === "ChangeServiceZone"}  onClose={onCloseModal} selectedMachine={selectedMachine} />
      <UpdateLimitModal isOpen={modalOpen === "UploadLimit"}  onClose={onCloseModal} />
    </>
  );
}
