// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PieChartCard--3pV0qQxvFF{display:flex;flex-direction:column}.PieChartCard--3pV0qQxvFF .ChartContainer--2TwozYtfY8{margin-top:auto;display:flex;justify-content:space-between;align-items:center}.PieChartCard--3pV0qQxvFF .ChartContainer--2TwozYtfY8 .Legend--1YPQfglTER{min-width:140px;display:flex;flex-direction:column;gap:6px}", ""]);
// Exports
exports.locals = {
	"PieChartCard": "PieChartCard--3pV0qQxvFF",
	"ChartContainer": "ChartContainer--2TwozYtfY8",
	"Legend": "Legend--1YPQfglTER"
};
module.exports = exports;
