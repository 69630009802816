/* eslint-disable react/display-name */
import * as React from "react";

import { KittingModal } from "./KittingModal/KittingModal";
import Typography from "components/Typography";
import alphanumeric from "components/Table/util/compareFn/alphanumeric";

import { useQueryClient } from "react-query";
import { Container, OperationTable } from "../../Components";
import { TableHeader } from "components/TableHeader/TableHeader";
import {
  GetCreatedPlansQuery,
  MachineKind,
  PlanStatus,
  useGetCreatedPlansQuery,
} from "gql/generated";
import {
  IAppliedFilter,
  PureFilterBar,
} from "common/components/filterbar/PureFilterBar";
import { useSortState } from "hooks/useSortState";
import { useTranslation } from "react-i18next";

export default function Kitting() {
  const { t } = useTranslation();
  const { data: createdPlansQuery, isLoading } = useGetCreatedPlansQuery({
    status: [PlanStatus.Created],
  });
  const plans = isLoading ? undefined : mapDataToTable(createdPlansQuery);

  const [selectedPlanId, setSelectedPlanId] = React.useState(null);

  const queryClient = useQueryClient();
  const [appliedFilter, setAppliedFilter] =
    React.useState<IAppliedFilter>(null);
  const filteredPlans = React.useMemo(
    () =>
      appliedFilter
        ? plans?.filter((plan) => {
            let value;
            switch (appliedFilter.field) {
              case columns.planName:
                value = plan.planName;
                break;
              case columns.warehouseName:
                value = plan.warehouseName;
                break;
              default:
                value = plan.planName;
                break;
            }
            return value
              .toLowerCase()
              .includes(appliedFilter.value.toLowerCase());
          })
        : plans,
    [plans, appliedFilter]
  );

  const { sortState, getSortDirectionByColumnId, toggleSortColumnByColumnId } =
    useSortState<Columns>({ columnId: columns.planName, direction: "ASC" });

  const sortedPlans = React.useMemo(
    () =>
      sortState
        ? filteredPlans
            ?.map((elem) => elem)
            ?.sort((planA, planB) => {
              return (
                sortFnMap[sortState.columnId](planA, planB) *
                (sortState.direction === "DESC" ? -1 : 1)
              );
            })
        : filteredPlans,
    [filteredPlans, sortState]
  );

  return (
    <>
      {selectedPlanId ? (
        <KittingModal
          planId={selectedPlanId}
          onClose={() => setSelectedPlanId(null)}
          onRemoveMachineFromPlanSuccess={() => {
            queryClient.invalidateQueries(["GetCreatedPlans"]);
            queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
          }}
          onReplanSuccess={() => {
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            queryClient.invalidateQueries(["GetCreatedPlans"]);
            queryClient.invalidateQueries(["GetRefillOrderCandidates"]);
          }}
          onForecastedSuccess={() => {
            queryClient.invalidateQueries(["GetOperationStatusCounts"]);
            queryClient.invalidateQueries(["GetCreatedPlans"]);
            queryClient.invalidateQueries(["GetKittedPlans"]);
          }}
        />
      ) : null}
      <Container>
        <PureFilterBar
          placeholder="label_search"
          filterOptions={[
            {
              field: columns.planName,
              queryType: "contains",
              translationKey: "label_plan",
            },
            {
              field: columns.warehouseName,
              queryType: "contains",
              translationKey: "label_warehouse",
            },
          ]}
          onChange={setAppliedFilter}
          value={appliedFilter}
        />
        <OperationTable
          data={sortedPlans}
          columns={[
            {
              id: columns.planName,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <div
                  style={{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: 700,
                    textDecoration: "underline",
                    color: "#4B4D9B",
                    cursor: "pointer",
                  }}
                  onClick={() => setSelectedPlanId(plan.planId)}
                >
                  {plan.planName}
                </div>
              ),
              style: {
                flex: "1 0 202px",
                minWidth: "202px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.warehouseName,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_warehouse")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.warehouseName}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.taobinMachinesInRoute,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_taobin_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.taobinMachinesInRoute}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.spiralMachinesInRoute,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_spiral_machines_in_plan")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.spiralMachinesInRoute}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
            {
              id: columns.swapSheet,
              headerCell: (columnId) => (
                <TableHeader
                  columnId={columnId}
                  sort={getSortDirectionByColumnId(columnId) ?? "NONE"}
                  onClick={() => toggleSortColumnByColumnId(columnId)}
                >
                  {t("label_swap_sheet")}
                </TableHeader>
              ),
              cell: (plan) => (
                <Typography type="body-2" color="onSurfaceHigh">
                  {plan.swapSheets > 0 ? plan.swapSheets : null}
                </Typography>
              ),
              style: {
                flex: "1 0 176px",
                minWidth: "176px",
                display: "flex",
                alignItems: "center",
              },
            },
          ]}
          isLoading={isLoading}
          getKey={(plan) => plan.planId}
        />
      </Container>
    </>
  );
}

interface TableRow {
  planId: string; // a455844e-f1ce-4e9a-a3b8-6d1c495a0f96
  planName: string; // 'Plan A'
  warehouseName: string;
  taobinMachinesInRoute: number;
  spiralMachinesInRoute: number;
  swapSheets: number;
}

function countMachinesInPlan(plan: GetCreatedPlansQuery["plans"][0], type: MachineKind) {
  return plan.refillOrders.reduce((agg, curr) => {
    if(curr.machine.kind === type) {
      return agg + 1;
    }
    return agg;
  }, 0);
}

function mapDataToTable(createdPlansQuery: GetCreatedPlansQuery): TableRow[] {
  const createdPlans = createdPlansQuery.plans;
  return createdPlans.map((plan) => ({
    planId: plan.id,
    planName: plan.name,
    taobinMachinesInRoute: countMachinesInPlan(plan, MachineKind.BeverageVending),
    spiralMachinesInRoute: countMachinesInPlan(plan, MachineKind.SpiralVending),
    warehouseName: plan.warehouse.name,
    swapSheets: plan.refillOrders.reduce(
      (agg, curr) =>
        curr.swapOrders.reduce((agg, curr) => curr.swapItems.length + agg, 0) +
        agg,
      0
    ),
  })).filter(plan => (plan.taobinMachinesInRoute > 0 || plan.spiralMachinesInRoute > 0));
}

const columns = {
  planName: "planName",
  warehouseName: "warehouseName",
  taobinMachinesInRoute: "taobinMachinesInRoute",
  spiralMachinesInRoute: "spiralMachinesInRoute",
  swapSheet: "swapSheet",
} as const;
type Columns = keyof typeof columns;

const sortFnMap: Record<Columns, (planA: TableRow, planB: TableRow) => number> =
  {
    planName: (planA, planB) => {
      return alphanumeric(planA.planName, planB.planName);
    },
    warehouseName: (planA, planB) => {
      return planA.warehouseName.localeCompare(planB.warehouseName);
    },
    taobinMachinesInRoute: (planA, planB) => {
      let comparisonValue = planA.taobinMachinesInRoute - planB.taobinMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    spiralMachinesInRoute: (planA, planB) => {
      let comparisonValue = planA.spiralMachinesInRoute - planB.spiralMachinesInRoute;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
    swapSheet: (planA, planB) => {
      let comparisonValue = planA.swapSheets - planB.swapSheets;
      comparisonValue =
        comparisonValue === 0
          ? alphanumeric(planA.planName, planB.planName)
          : comparisonValue;
      return comparisonValue;
    },
  };
