import {
  GetMaintenanceBoardErrorCountsDocument,
  GetMaintenanceBoardErrorCountsQuery,
  GetMaintenanceBoardErrorCountsQueryVariables,
} from "gql/generated";
import { fetcher } from "gql/fetcher";

export async function getSummary(serviceZoneId?: string) {
  return await fetcher<
    GetMaintenanceBoardErrorCountsQuery,
    GetMaintenanceBoardErrorCountsQueryVariables
  >(GetMaintenanceBoardErrorCountsDocument, {
    serviceZoneId: serviceZoneId,
  })();
}
