import * as React from "react";

import { Skeleton } from "@mui/material";
import Icon from "common/components/icon/Icon";
import Button from "components/Button/Button";
import {
  ResponsiveModal,
  Title,
  modalBorderStyle,
} from "components/ResponsiveModal";
import Typography from "components/Typography/Typography";

import { useGetUsers } from "userandauth/hooks/useGetUsers";
import { useGetUserAuditLogsByUserId } from "./use-get-audit-logs";
import moment = require("moment");

export function AuditLogModalButton({ userId }: { userId: string }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setIsOpen(true)}>
        <Icon name="RefillSheet" color="primary500" />
      </div>
      {isOpen ? (
        <UserAuditLogModal userId={userId} onClose={() => setIsOpen(false)} />
      ) : null}
    </>
  );
}

function UserAuditLogModal({
  onClose,
  userId,
}: {
  userId: string;
  onClose: () => void;
}) {
  const users = useGetUsers();
  const user = users.data?.find((user) => user.id === userId);
  const useGetUserAuditLogsByUserIdResult = useGetUserAuditLogsByUserId(userId);

  return (
    <ResponsiveModal
      onClose={onClose}
      open={true}
      displayCloseButton
      modalStyles={{
        maxWidth: "712px",
        heightStyles: {
          flex: "1 1 0",
          maxHeight: "760px",
          minHeight: "600px",
        },
        alignment: "start",
      }}
    >
      <Title>
        {["label_change_log_for", ` ${user.firstName} (${user.email})`]}
      </Title>
      <div
        style={{
          flex: "1 1 0",
          overflow: " auto",
          marginTop: "10px",
          borderTop: modalBorderStyle,
          overscrollBehavior: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            position: "sticky",
            top: "0",
            backgroundColor: "white",
            borderBottom: modalBorderStyle,
            zIndex: 10,
            minHeight: "45px",
          }}
        >
          <div style={{ flex: "1 1 0" }}>
            <Typography type="caption" color="onSurfaceDisabled" translate>
              label_date
            </Typography>
          </div>
          <div style={{ flex: "1 1 0" }}>
            <Typography type="caption" color="onSurfaceDisabled" translate>
              label_change
            </Typography>
          </div>
        </div>

        {useGetUserAuditLogsByUserIdResult.isLoading
          ? new Array(10).fill(null).map((_, index) => (
              <div
                style={{
                  display: "flex",
                  borderBottom: modalBorderStyle,
                  minHeight: "45px",
                  alignItems: "center",
                }}
                key={index}
              >
                <div style={{ flex: "1 1 0" }}>
                  <div
                    style={{
                      width: "100%",
                      padding: "3px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Skeleton width={"100%"} />
                  </div>
                </div>
                <div style={{ flex: "1 1 0" }}>
                  <div
                    style={{
                      width: "100%",
                      padding: "3px",
                      boxSizing: "border-box",
                    }}
                  >
                    <Skeleton width={"100%"} />
                  </div>
                </div>
              </div>
            ))
          : useGetUserAuditLogsByUserIdResult.data
              .sort(
                (a, b) =>
                  moment(b.createdAt).valueOf() - moment(a.createdAt).valueOf()
              )
              .map((entry) => (
                <div
                  key={entry.id}
                  style={{
                    display: "flex",
                    borderBottom: modalBorderStyle,
                    minHeight: "45px",
                    alignItems: "center",
                  }}
                >
                  <div style={{ flex: "1 1 0" }}>
                    <Typography type="body-2">
                      {moment(entry.createdAt).format("DD MMM YYYY hh:mmA")}
                    </Typography>
                  </div>
                  <div style={{ flex: "1 1 0" }}>
                    <Typography type="body-2">{entry.description}</Typography>
                  </div>
                </div>
              ))}
      </div>
      <div
        style={{ display: "flex", justifyContent: "end", marginTop: "32px" }}
      >
        <Button onClick={onClose} type="primary">
          label_close
        </Button>
      </div>
    </ResponsiveModal>
  );
}
