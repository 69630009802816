import * as React from "react";
import { useImmer } from "use-immer";

import Button from "common/components/button/Button";
import Checkbox from "common/components/checkbox/Checkbox";
import Input from "common/components/input/Input";
import Modal from "common/components/modal/Modal";
import { RadioButton } from "common/components/radiobutton/RadioButton";
import Select from "common/components/select/Select";
import Typography from "common/components/typography/Typography";

import { ISwapInfo } from "./BatchSwapOrderModal";

import { useGetProducts } from "product/hooks/useGetProducts";

import * as styles from "./NewSlotConfigFormModal.module.scss";


interface INewSlotConfigFormModal {
  initialValue: ISwapInfo;
  onSelect: (newSlotConfig: ISwapInfo) => void;
  onBack: (newSlotConfig: ISwapInfo) => void;
  onClose: () => void;
}

export function NewSlotConfigFormModal({
  initialValue,
  onSelect,
  onBack,
  onClose
}: INewSlotConfigFormModal): JSX.Element {
  const {
    data: productOptions,
    isLoading: isProductOptionsLoading
  } = useGetProducts();

  const [newSlotConfig, setNewSlotConfig] = useImmer<ISwapInfo>(initialValue);

  return (
    <Modal
      contentClassName={styles.SwapOrderForm}
      closeButton={true}
      onClose={onClose}
      closeButtonColor="onSurfaceHigh"
    >
      <div className={styles.Header}>
        <Typography
          type="headline-6"
          translationKey={[
            "label_header_swap_inventory",
            ` (${newSlotConfig.swapInfo.slot})`
          ]}
        />
      </div>

      <Select
        className={styles.NewMaterialSelectComponent}
        label="label_new_material"
        value={newSlotConfig.swapInfo.product.id}
        options={
          isProductOptionsLoading
            ? []
            : productOptions.map(product => ({
                label: product.name,
                value: product.productId
              }))
        }
        onSelect={selectedProductId => {
          const selectedProduct = productOptions.find(
            product => product.productId === selectedProductId
          );
          setNewSlotConfig(draft => {
            draft.swapInfo.product = {
              id: selectedProduct.productId,
              name: selectedProduct.name
            };
          });
        }}
      />

      <div className={styles.ReplacementMethodContainer}>
        <div className={styles.ReplacementMethodHeaderContainer}>
          <Typography
            type="caption"
            translationKey="label_replacement_method"
          />
        </div>
        <div className={styles.RemoveOldRadioAndTextContainer}>
          <RadioButton
            checked={
              newSlotConfig.swapInfo.replacementMethod !==
              "continueToRefillOnTop"
            }
            onClick={() => {
              setNewSlotConfig(draft => {
                draft.swapInfo.replacementMethod = "removeOldOnCritical";
              });
            }}
          />
          <Typography
            className={styles.RemoveOldLabel}
            type="body-1"
            translationKey="label_replace_method_remove_old"
          />
        </div>
        <div className={styles.RefillOnTopRadioAndTextContainer}>
          <RadioButton
            checked={
              newSlotConfig.swapInfo.replacementMethod ===
              "continueToRefillOnTop"
            }
            onClick={() => {
              setNewSlotConfig(draft => {
                draft.swapInfo.replacementMethod = "continueToRefillOnTop";
              });
            }}
          />
          <Typography
            type="body-1"
            translationKey="label_replace_method_refill_on_top"
            className={styles.RefillOnTopLabel}
          />
        </div>
      </div>

      {newSlotConfig.swapInfo.replacementMethod !== "continueToRefillOnTop" && (
        <div className={styles.RemoveOldWhenContainer}>
          <div className={styles.RemoveOldWhenHeaderContainer}>
            <Typography
              type="caption"
              translationKey="label_create_swap_sheet_when"
            />
          </div>
          <div className={styles.RemoveOldWhenLowRadioAndTextContainer}>
            <RadioButton
              checked={
                newSlotConfig.swapInfo.replacementMethod ===
                "removeOldOnCritical"
              }
              onClick={() => {
                setNewSlotConfig(draft => {
                  draft.swapInfo.replacementMethod = "removeOldOnCritical";
                });
              }}
            />
            <Typography
              className={styles.RemoveOldWhenLowLabel}
              type="body-1"
              translationKey="label_create_swap_when_low"
            />
          </div>
          <div className={styles.RemoveOldNowRadioAndTextContainer}>
            <RadioButton
              checked={
                newSlotConfig.swapInfo.replacementMethod === "removeOldNow"
              }
              onClick={() => {
                setNewSlotConfig(draft => {
                  draft.swapInfo.replacementMethod = "removeOldNow";
                });
              }}
            />
            <Typography
              type="body-1"
              translationKey="label_create_swap_now"
              className={styles.RemoveOldNowLabel}
            />
          </div>
        </div>
      )}

      <Input
        label="label_low_level"
        type="number"
        onChange={value =>
          setNewSlotConfig(draft => {
            draft.newSlotConfig.parLevel = value;
          })
        }
        value={newSlotConfig.newSlotConfig.parLevel}
        className={styles.ParLevelInputComponent}
      />

      <Input
        label="label_refill_target_level"
        type="number"
        onChange={value =>
          setNewSlotConfig(draft => {
            draft.newSlotConfig.refillTarget = value;
          })
        }
        value={newSlotConfig.newSlotConfig.refillTarget}
        className={styles.RefillLevelInputComponent}
      />

      <Select
        label="label_priority"
        value={newSlotConfig.newSlotConfig.priority}
        className={styles.PrioritySelectComponent}
        options={[
          { label: "0", value: "0" },
          { label: "1", value: "1" },
          { label: "2", value: "2" },
          { label: "3", value: "3" },
          { label: "4", value: "4" },
          { label: "5", value: "5" }
        ]}
        onSelect={selectedPriority => {
          setNewSlotConfig(draft => {
            draft.newSlotConfig.priority = selectedPriority as string;
          });
        }}
      />

      <Input
        label="label_slot_capacity"
        value={newSlotConfig.newSlotConfig.capacity}
        type="number"
        onChange={value =>
          setNewSlotConfig(draft => {
            draft.newSlotConfig.capacity = value;
          })
        }
        className={styles.CapacityInputComponent}
      />

      <div className={styles.NoPartialRefillContainer}>
        <Checkbox
          checked={newSlotConfig.newSlotConfig.noPartialRefill}
          onClick={checkedState => {
            setNewSlotConfig(draft => {
              draft.newSlotConfig.noPartialRefill = checkedState;
            });
          }}
        />
        <Typography
          translationKey="label_swap_modal_no_partial_refill"
          type="body-1"
          className={styles.NoPartialRefillLabel}
        />
      </div>

      <div className={styles.ButtonsContainer}>
        <Button
          translationKey="action_cancel"
          onClick={() => onBack(newSlotConfig)}
          type="secondary"
        />
        <Button
          onClick={() => onSelect(newSlotConfig)}
          translationKey="action_review"
          type="primary"
          disabled={!isFormValid(newSlotConfig)}
        />
      </div>
    </Modal>
  );
}

function isFormValid(formValues: ISwapInfo): boolean {
  if (
    formValues.swapInfo.product.id &&
    formValues.newSlotConfig.capacity &&
    typeof formValues.newSlotConfig.capacity === "number" &&
    formValues.newSlotConfig.parLevel &&
    typeof formValues.newSlotConfig.parLevel === "number" &&
    formValues.newSlotConfig.refillTarget &&
    typeof formValues.newSlotConfig.refillTarget === "number" &&
    formValues.newSlotConfig.parLevel < formValues.newSlotConfig.refillTarget &&
    formValues.newSlotConfig.refillTarget < formValues.newSlotConfig.capacity &&
    formValues.newSlotConfig.priority
  )
    return true;
  return false;
}
