// Hooks
import { useQuery } from 'react-query';
import { useState } from 'react';

// Service function
import { getMonitorMachines } from 'machines/service';

// Types
import { APIMonitorMachine } from 'machines/interfaces';
import { AxiosError } from 'axios';

// Keys
import { machineKeys } from './machineKeys';

function useGetMonitorMachines ({ enabled = true } = {}) {
  const [ isPolling, setIsPolling ] = useState(true);
  return useQuery<APIMonitorMachine[], AxiosError>(
    machineKeys.monitor(),
    getMonitorMachines,
    {
      enabled,
      refetchInterval: isPolling ? 30 * 1000 : undefined,
      refetchOnWindowFocus: false,
      retry: 0,
      onError: () => setIsPolling(false)
    }
  );
}

export { useGetMonitorMachines };
