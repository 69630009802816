import * as React from "react";
import { LocationKind } from "gql/generated";

import { useGetLocationById } from "../../hooks/useGetLocationById";
import {
  useGetLocationPermissions,
  useCanViewLocationEditPage,
  useCanUpdateFactory,
  useCanViewAndEditAdditionalContractInfo,
} from "../../hooks/useGetLocationPermissions";
import { useGetLocationIdUrlParams } from "../../hooks/useGetLocationIdUrlParams";

import { LocationIdLoading } from "../../components/LocationIdLoading";
import { LocationIdContainer } from "../../components/LocationIdContainer";
import { LocationColumnSpacer } from "../../components/LocationColumnSpacer";
import { Title } from "../../components/Title";
import { LocationContracts } from "../../components/LocationContracts";
import { GeneralLocationInfo } from "../../components/GeneralLocationInfo";
import { MachineIds } from "../../components/MachineIds";
import { OperationInfo } from "../../components/OperationInfo";
import {
  Factory,
  SingleMachineWithContract,
  Store,
  Location,
} from "../../types";
import TextButton from "common/components/textbutton/TextButton";
import { Link } from "react-router-dom";
import { PageTitle } from "../../components/PageTitle";
import { AdditionalContractInfo } from "containers/location/components/ContractInfo";

export default function LocationId() {
  const locationId = useGetLocationIdUrlParams();
  const getLocationByIdResult = useGetLocationById(locationId);
  const locationPermissions = useGetLocationPermissions();
  const isLoading =
    locationPermissions.isLoading || getLocationByIdResult.isLoading;

  let childrenLocation: React.ReactNode;
  if (isLoading) {
    childrenLocation = <LocationIdLoading />;
  } else {
    const location = getLocationByIdResult.data;
    switch (location.kind) {
      case LocationKind.Store:
        childrenLocation = <StoreView store={location} />;
        break;
      case LocationKind.Factory:
        childrenLocation = <FactoryView factory={location} />;
        break;
      case LocationKind.Location:
        childrenLocation = <LocationView location={location} />;
        break;
    }
  }

  return <LocationIdContainer>{childrenLocation}</LocationIdContainer>;
}

function LocationView({ location }: { location: SingleMachineWithContract }) {
  const { allowed: allowedToViewAdditionalContractInfo } =
    useCanViewAndEditAdditionalContractInfo();
  return (
    <>
      <PageViewHeader location={location} />
      <Title>label_general</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <MachineIds machineIds={[location.machineId]} />
        <LocationContracts contracts={location.contracts} />
        <GeneralLocationInfo locationInfo={location.locationInfo} />
      </LocationColumnSpacer>
      <Title>label_operation</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <OperationInfo operationInfo={location.operation} />
      </LocationColumnSpacer>

      {allowedToViewAdditionalContractInfo ? (
        <>
          <Title>label_contract</Title>
          <div style={{ height: "20px" }} />
          <LocationColumnSpacer>
            <AdditionalContractInfo
              additionalContractInfo={location.additionalContractInformation}
            />
          </LocationColumnSpacer>
        </>
      ) : null}
    </>
  );
}

function StoreView({ store }: { store: Store }) {
  return (
    <>
      <PageViewHeader location={store} />
      <Title>label_general</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <MachineIds machineIds={store.machineIds} />
        <GeneralLocationInfo locationInfo={store.locationInfo} />
      </LocationColumnSpacer>
      <Title>label_operation</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <OperationInfo operationInfo={store.operation} />
      </LocationColumnSpacer>
    </>
  );
}

export function FactoryView({ factory }: { factory: Factory }) {
  return (
    <>
      <PageViewHeader location={factory} />
      <Title>label_general</Title>
      <div style={{ height: "20px" }} />
      <LocationColumnSpacer>
        <MachineIds machineIds={factory.machineIds} />
        <GeneralLocationInfo locationInfo={factory.locationInfo} />
      </LocationColumnSpacer>
    </>
  );
}

export function PageViewHeader({ location }: { location: Location }) {
  const { allowed: canViewLocationEdit } = useCanViewLocationEditPage();
  const { allowed: canUpdateFactory } = useCanUpdateFactory();
  const locationId = useGetLocationIdUrlParams();

  let showEditLink: boolean = false;
  if (location.kind === LocationKind.Location && canViewLocationEdit) {
    showEditLink = true;
  } else if (location.kind === LocationKind.Factory && canUpdateFactory) {
    showEditLink = true;
  } else if (location.kind === LocationKind.Store) {
    showEditLink = false;
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <PageTitle location={location} />
      <div
        style={{
          marginLeft: "auto",
          flexShrink: 0,
        }}
      >
        {showEditLink ? (
          <Link to={`/location/${locationId}/edit`}>
            <TextButton icon="Edit" translationKey="action_edit" />
          </Link>
        ) : null}
      </div>
    </div>
  );
}
