import { ColumnFiltersState } from "components/tableV2";
import { MachineInventoryStatusEnum, MachineMonitorFilter, MachineStatusEnum, RangeValue } from "gql/generated";
import { LastSoldEnum } from "../type";
import { isArray } from "lodash";

export function mapColumnFilterToMachineMonitorFilter(columnFilters: ColumnFiltersState): MachineMonitorFilter {
  const locationFilter: MachineMonitorFilter = {};

  columnFilters.forEach((filter) => {
    const { id, value } = filter;
    switch (id) {
      case 'machineId':
        locationFilter.machineId = (value as string).trim();
        break;
      case "machineInventoryStatus":
        locationFilter.machineInventoryStatus = (isArray(value) ? value: [value]) as MachineInventoryStatusEnum[];
        break;
      case 'module':
        locationFilter.slots = value as string[];
        break;
      case 'lastSold':
        locationFilter.lastSoldMinutes = mapLastSoldOptionsToRangeValue(value as LastSoldEnum);
        break;
      case 'status':
        locationFilter.machineStatus = [value] as MachineStatusEnum[];
        break;
      case 'locationName':
        locationFilter.locationName = (value as string).trim();
        break;
      case 'serviceZoneName':
        locationFilter.serviceZoneName = (value as string).split(",")?.map((item) => item.trim());
        break;
      case 'refillZoneName':
        locationFilter.refillZoneName = (value as string).split(",")?.map((item) => item.trim());
        break;
      case 'todaySale':
        if (Array.isArray(value)) {
          locationFilter.todaySale = {
            min: isNotNullOrUndefined(value[0]) ? Number(value[0]): undefined,
            max: isNotNullOrUndefined(value[1]) ? Number(value[1]): undefined
          };
        }
        break;
      case 'expectedSale':
          if (Array.isArray(value)) {
            locationFilter.expectedSale = {
              min: isNotNullOrUndefined(value[0]) ? Number(value[0]): undefined,
              max: isNotNullOrUndefined(value[1]) ? Number(value[1]): undefined
            };
          }
          break;
      default:
        break;
    }
  });

  return locationFilter;
}


function mapLastSoldOptionsToRangeValue (value: LastSoldEnum): RangeValue {
  switch (value) {
    case LastSoldEnum.LessThan30Mins:
      return { min: 0, max: 30 };
    case LastSoldEnum.ThirtyMinsOneHr:
      return { min: 30, max: 60 };
    case LastSoldEnum.OneHrTwoHrs:
      return { min: 60, max: 120 };
    case LastSoldEnum.MoreThan2Hrs:
      return { min: 120 };
    case LastSoldEnum.MoreThan1Day:
      return { min: 1440 };
    case LastSoldEnum.MoreThan7Days:
      return { min: 10080 };
    default:
      return undefined;
  }
};


function isNotNullOrUndefined(value) {
  return value !== null && value !== undefined;
}
