// Hooks
import { useMutation, useQueryClient } from 'react-query';

// Service function
import { patchMachineInventories } from 'machines/service';

// Toasts
import { addToast } from 'common/store/actions/uiActions';
import store from 'common/store';

// Types
import { IMachineInventoryData } from 'machines/interfaces';
import { AxiosError } from 'axios';
import { Dispatch, AnyAction } from 'redux';

// Keys
import { machineKeys } from './machineKeys';

interface IUsePatchMachineInventory {
  machineId: string,
  machineInventories: Partial<IMachineInventoryData>[]
}

function usePatchMachineInventory () {
  const queryClient = useQueryClient();

  return useMutation<IMachineInventoryData[], AxiosError, IUsePatchMachineInventory>(
    ({
      machineId,
      machineInventories
    }) => patchMachineInventories(machineId, machineInventories),
    {
      onSuccess: (_, { machineId }) => {
        addToast({
          type: 'success',
          text: 'toast_machine_patch_success'
        })(store.dispatch as Dispatch<AnyAction>);
        queryClient.invalidateQueries(machineKeys.machineInventory(machineId));
      }
    }
  );
}

export { usePatchMachineInventory };