// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SelectSlotModal--5KrsQA9YCM{flex-direction:column;overflow:visible !important}.SelectSlotModal--5KrsQA9YCM .MachineSelected--pAD4xVqyZw{margin-top:14px;margin-bottom:24px}.SelectSlotModal--5KrsQA9YCM .ButtonContainer--3_Qmfy-vwn{display:flex;justify-content:space-between;margin-top:32px}", ""]);
// Exports
exports.locals = {
	"SelectSlotModal": "SelectSlotModal--5KrsQA9YCM",
	"MachineSelected": "MachineSelected--pAD4xVqyZw",
	"ButtonContainer": "ButtonContainer--3_Qmfy-vwn"
};
module.exports = exports;
