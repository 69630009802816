// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Filter--3fYqPuIwG8{padding:5px 18px;height:44px;border-bottom:1px solid rgba(0,0,0,.12);display:flex;align-items:center;justify-content:stretch;position:relative}.FilterValues--36MtViwhXT{padding:0 12px;display:flex;align-items:center;justify-content:flex-start;gap:12px}.FilterValues--36MtViwhXT .FilterValue--2YRJipFONJ{background-color:#ebebeb;border-radius:20px;padding:4px 8px 4px 12px;display:flex;align-items:center;justify-content:flex-start;gap:4px}.Picker--23PvU-Xx4S{position:absolute;top:45px;left:8px;z-index:1;min-width:200px;background-color:#fff;border-radius:4px;box-shadow:0px 2px 5px 1px rgba(0,0,0,.16);display:flex;flex-direction:column;justify-content:flex-start;align-items:stretch}.Picker--23PvU-Xx4S .Options--2bOMpxWVZ1{padding:8px 16px;cursor:pointer}.Picker--23PvU-Xx4S .Options--2bOMpxWVZ1:hover{background-color:#f5f5f5}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF{border-radius:inherit}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF .Header--3-UmKwq8D6{padding:12px 16px;background-color:#4b4d9b;color:#fff;border-radius:inherit;border-bottom-right-radius:0;border-bottom-left-radius:0;display:flex;justify-content:space-between;align-items:center}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF .Header--3-UmKwq8D6 .TitleWrapper--2AkUGZR9pY{display:flex;justify-content:flex-start;align-items:center;gap:10px}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF .ValuePicker--_YaIwvr5cZ{padding:16px}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF .ValuePicker--_YaIwvr5cZ .CheckboxWrapper--3dtE_-YxF6{padding:4px 0;display:flex;justify-content:flex-start;align-items:center;gap:10px}.Picker--23PvU-Xx4S .Form--10ti5ZAZHF .Footer--egRpEVi4oN{display:flex;justify-content:flex-end;align-items:center;padding:0 16px 16px 16px}.Icon--pDx7sR_cx5{cursor:pointer;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"primary": "#4b4d9b",
	"primaryVariant": "#292b56",
	"brainStormingBlackTint300": "#b3b3b3",
	"primary900": "#080911",
	"primary800": "#191a34",
	"primary700": "#292b56",
	"primary600": "#3a3c79",
	"primary500": "#4b4d9b",
	"primary400": "#6466b4",
	"primary300": "#8688c5",
	"primary200": "#8191d9",
	"primary100": "#cbcce6",
	"primary050": "#eeeef7",
	"secondary": "#b8e7e3",
	"secondaryVariant": "#4cbdb7",
	"surface": "#fff",
	"background": "#f3f3fd",
	"outline": "1px solid rgba(0,0,0,.12)",
	"outlineColor": "rgba(0,0,0,.12)",
	"error": "#e95d50",
	"critical": "#e95d50",
	"success": "#27b6ba",
	"successOverlay": "#b2d3d3",
	"warning": "#ffb025",
	"warningOverlay": "#ffedcc",
	"investigate": "#d36b0b",
	"disabled": "#d7d7d7",
	"onPrimaryHigh": "#fff",
	"onPrimaryMedium": "rgba(255,255,255,.74)",
	"onSurfaceHigh": "rgba(0,0,0,.87)",
	"onSurfaceMedium": "rgba(0,0,0,.6)",
	"onSurfaceDisabled": "rgba(0,0,0,.38)",
	"onBackground": "#1c1c1c",
	"chartIndigo": "#33568c",
	"chartCornflowerBlue": "#698cc2",
	"chartLightBlue": "#b5c7d5",
	"chartPeanutBrown": "#f5dcb3",
	"chartCoffeeBrown": "#a38d80",
	"chartPink": "#f18989",
	"captionDisable": "#b0b0b0",
	"Filter": "Filter--3fYqPuIwG8",
	"FilterValues": "FilterValues--36MtViwhXT",
	"FilterValue": "FilterValue--2YRJipFONJ",
	"Picker": "Picker--23PvU-Xx4S",
	"Options": "Options--2bOMpxWVZ1",
	"Form": "Form--10ti5ZAZHF",
	"Header": "Header--3-UmKwq8D6",
	"TitleWrapper": "TitleWrapper--2AkUGZR9pY",
	"ValuePicker": "ValuePicker--_YaIwvr5cZ",
	"CheckboxWrapper": "CheckboxWrapper--3dtE_-YxF6",
	"Footer": "Footer--egRpEVi4oN",
	"Icon": "Icon--pDx7sR_cx5"
};
module.exports = exports;
