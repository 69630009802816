import * as React from "react";
import { useState } from "react";
import { useImmer } from "use-immer";

import { SelectSlotModal } from "./SelectSlotModal";
import { SwapConfirmationModal } from "./SwapConfirmationModal";
import { NewSlotConfigFormModal } from "./NewSlotConfigFormModal";

interface IBatchSwapOrderModal {
  selectedMachines: Array<string>;
  onClose: () => void;
}

export interface ISwapInfo {
  machineId: Array<string>;
  swapInfo: {
    slot: string;
    product: {
      name: string;
      id: string;
    };
    replacementMethod:
      | "removeOldOnCritical"
      | "removeOldNow"
      | "continueToRefillOnTop";
  };
  newSlotConfig: {
    parLevel: number;
    refillTarget: number;
    priority: string;
    capacity: number;
    noPartialRefill: boolean;
  };
}

export function BatchSwapOrderModal({
  selectedMachines,
  onClose
}: IBatchSwapOrderModal): JSX.Element {
  const [step, setStep] = useState<
    "selectSlot" | "newSlotConfigForm" | "swapConfirmation"
  >("selectSlot");
  const [swapInfo, setSwapInfo] = useImmer<ISwapInfo>({
    machineId: [...selectedMachines],
    newSlotConfig: {
      capacity: undefined,
      noPartialRefill: false,
      parLevel: undefined,
      priority: undefined,
      refillTarget: undefined
    },
    swapInfo: {
      product: {
        id: undefined,
        name: undefined
      },
      slot: undefined,
      replacementMethod: "removeOldOnCritical"
    }
  });

  return (
    <>
      {step === "selectSlot" && (
        <SelectSlotModal
          onSelect={slot => {
            setSwapInfo(draft => {
              draft.swapInfo.slot = slot;
            });
            setStep("newSlotConfigForm");
          }}
          initialValue={swapInfo.swapInfo.slot}
          selectedMachines={selectedMachines}
          onCancel={onClose}
        />
      )}
      {step === "newSlotConfigForm" && (
        <NewSlotConfigFormModal
          initialValue={swapInfo}
          onSelect={newSlotConfig => {
            setSwapInfo(newSlotConfig);
            setStep("swapConfirmation");
          }}
          onBack={newSlotConfig => {
            setSwapInfo(newSlotConfig);
            setStep("selectSlot");
          }}
          onClose={onClose}
        />
      )}
      {step === "swapConfirmation" && (
        <SwapConfirmationModal
          formValues={swapInfo}
          onBack={() => {
            setStep("newSlotConfigForm");
          }}
          onClose={onClose}
        />
      )}
    </>
  );
}
