import * as React from "react";
import { useState } from "react";

import Button from "common/components/button/Button";
import Modal from "common/components/modal/Modal";
import Select from "common/components/select/Select";
import Typography from "common/components/typography/Typography";

import { compareSlotNames } from "common/utils/slotUtils";

import { useGetMachinesSlots } from "machines/hooks/useGetMachinesSlots";

import * as styles from "./SelectSlotModal.module.scss";

interface ISelectSlotModal {
  onSelect: (slot: string) => void;
  initialValue: string;
  selectedMachines: Array<string>;
  onCancel: () => void;
}

export function SelectSlotModal({
  onSelect,
  initialValue,
  selectedMachines,
  onCancel
}: ISelectSlotModal): JSX.Element {
  const [selectedSlot, setSelectedSlot] = useState<string>(initialValue);
  const { data, isLoading, isError } = useGetMachinesSlots(selectedMachines);
  return (
    <Modal
      closeButton={true}
      onClose={onCancel}
      closeButtonColor="onSurfaceHigh"
      contentClassName={styles.SelectSlotModal}
    >
      <Typography
        type="headline-6"
        translationKey="label_header_swap_inventory"
      />
      <Typography
        type="body-2"
        translationKey={[
          String(selectedMachines.length),
          " ",
          "label_machines_selected"
        ]}
        className={styles.MachineSelected}
      />
      <Select
        value={selectedSlot}
        label="label_slot"
        options={
          (isLoading || isError)
            ? []
            : Array.from(
                data.reduce((acc: Set<string>, curr) => {
                  curr.slots.forEach(slot => acc.add(slot));
                  return acc;
                }, new Set())
              )
                .sort((a: string, b: string) => compareSlotNames(a, b))
                .map((slot: string) => ({ value: slot, label: slot }))
        }
        onSelect={(value: string) => setSelectedSlot(value)}
      />
      <div className={styles.ButtonContainer}>
        <Button
          type="secondary"
          translationKey="action_cancel"
          onClick={onCancel}
        />
        <Button
          type="primary"
          translationKey="action_continue"
          onClick={() => onSelect(selectedSlot)}
          disabled={selectedSlot === undefined}
        />
      </div>
    </Modal>
  );
}
