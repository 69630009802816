import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import { ISwapOrder, IPatchSwapOrder, patchSwapOrder } from "services/swaporders";

// Toasts
import { swapOrderKeys } from "./swapOrderKeys";
import produce from "immer";
import { toast } from "react-toastify";
import i18n from "common/i18n";

interface IUsePatchSwapOrder {
  onSuccess?: () => void;
}

function usePatchSwapOrder({ onSuccess }: IUsePatchSwapOrder = {}) {
  const queryClient = useQueryClient();
  return useMutation<ISwapOrder, AxiosError, IPatchSwapOrder>(
    swapOrder => patchSwapOrder(swapOrder),
    {
      onSuccess: (swapOrderResponse, { orderNumber }) => {
        onSuccess?.();

        const swapOrders = queryClient.getQueryData<ISwapOrder[]>(
          swapOrderKeys.all()
        );
        const orderIndex = swapOrders.findIndex(
          swapOrder => swapOrder.orderNumber === orderNumber
        );
        queryClient.setQueryData(swapOrderKeys.all(), (prev: ISwapOrder[]) =>
          produce(prev, draft => {
            draft[orderIndex] = swapOrderResponse;
          })
        );
        queryClient.invalidateQueries(swapOrderKeys.all());
        toast.success(i18n.t('toast_request_successful'));
      }
    }
  );
}

export { usePatchSwapOrder };
