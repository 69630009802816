export const SERVICE_ZONE_QUERY_PARAM = 'service_zone_id';

export const routes = {
  index: () => '/maintenance',

  errorBoard: () => '/maintenance/error-board',

  majorTrouble: {
    index: () => routes.errorBoard() + '/major-trouble',
    machineUnsellable: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/machine-unsellable' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    outOfService: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/out-of-service' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    offline: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/offline' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    iceError: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/ice-error' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    coffeeError: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/coffee-error' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    iceRateLow: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/ice-rate-low' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    sodaError: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/soda-error' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    spiralUnsellable: (serviceZoneId?: string) =>
      routes.majorTrouble.index() +
      '/spiral-unsellable' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),
  },

  inventory: {
    index: () => routes.errorBoard() + '/inventory',
    whipper: (serviceZoneId?: string) =>
      routes.inventory.index() +
      '/whipper' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    milkAndSugar: (serviceZoneId?: string) =>
      routes.inventory.index() +
      '/milk-and-sugar' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    coffeeBeans: (serviceZoneId?: string) =>
      routes.inventory.index() +
      '/coffee-beans' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    cups: (serviceZoneId?: string) =>
      routes.inventory.index() +
      '/cups' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),
  },

  transactions: {
    index: () => routes.errorBoard() + '/transactions',
    aaNoTransaction30Mins: (serviceZoneId?: string) =>
      routes.transactions.index() +
      '/aa-no-transaction-30-mins' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    noTransaction2Hours: (serviceZoneId?: string) =>
      routes.transactions.index() +
      '/no-transaction-2-hours' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    noTransactionToday: (serviceZoneId?: string) =>
      routes.transactions.index() +
      '/no-transaction-today' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),

    billAndCoin: (serviceZoneId?: string) =>
      routes.transactions.index() +
      '/bill-and-coin' +
      (serviceZoneId ? `?${SERVICE_ZONE_QUERY_PARAM}=${serviceZoneId}` : ''),
  },
};
