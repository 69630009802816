import * as React from 'react';

// component
import UserSettings from './component/UserSetting/UserSettings';
import Team from './component/Team/Team';
import Organisation from './component/Organisation/Organisation';

import * as styles from './Settings.module.scss';
import { useIsAdmin, useIsAdminContract } from 'utils/user-role';
import { Switch, Route, Redirect } from "react-router";

function Settings(): JSX.Element {
  const isUserAdmin = useIsAdmin() || useIsAdminContract();
  
  return (
    <div className={styles.settings}>
      <Switch>
        <Route path="/settings/usersetting">
          <UserSettings />
        </Route>
        {isUserAdmin && 
        <Route path="/settings/organisation">
          <Organisation />
        </Route>}
        {isUserAdmin && 
        <Route path="/settings/team">
          <Team />
        </Route>} 
        <Redirect to="/settings/usersetting" />    
      </Switch>
    </div>
  );
}

export default Settings;
