import { MapProps } from "containers/machines/views/map/Map";

export function calculateBoundingBox(points: MapProps["points"]): [number, number, number, number] {
  const lats = points.map(point => point.geometry.coordinates[1]);
  const lngs = points.map(point => point.geometry.coordinates[0]);

  const minLat = Math.min(...lats);
  const maxLat = Math.max(...lats);
  const minLng = Math.min(...lngs);
  const maxLng = Math.max(...lngs);

  return [minLng, minLat, maxLng, maxLat];
}