import {
  GetMaintenanceBoardSodaErrorDocument,
  GetMaintenanceBoardSodaErrorQuery,
  GetMaintenanceBoardSodaErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getSodaError({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return await fetcher<
    GetMaintenanceBoardSodaErrorQuery,
    GetMaintenanceBoardSodaErrorQueryVariables
  >(GetMaintenanceBoardSodaErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
