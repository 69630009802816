import * as React from 'react';
import { useMemo } from 'react';
import { useGetDashboard } from 'dashboard/hooks/useGetDashboard';

// Components
import Typography from 'common/components/typography/Typography';
import { DashboardCard } from './organisms/DashboardCard';

// Utils
import { mapDashboardDataToCards } from './utils/mapDashboardDataToCards';

import * as styles from './Dashboard.module.scss';
import { useIsAdmin, useIsAdminContract, useIsClerk, useIsOperator, useIsSuperViewer } from 'utils/user-role';
import NoPermissionComponent from 'containers/noPermissionPage/NoPermission';

function Dashboard(): JSX.Element {
  const canViewContent = useIsAdmin() || useIsAdminContract() || useIsClerk() || useIsSuperViewer() || useIsOperator();

  if (canViewContent === false) {
    return <NoPermissionComponent />;
  }

  const { data = [], isLoading } = useGetDashboard();

  const dashboardCards = useMemo(() => mapDashboardDataToCards(data), [data]);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.Header}>
        <Typography translationKey="workspace_dashboard" type="headline-5" />
      </div>
      {isLoading && (
        <div>
          <Typography translationKey="label_loading" type="subtitle-1" />
        </div>
      )}
      {!isLoading && (
        <div className={styles.CardContainer}>
          {dashboardCards.map((card, index) => (
            <DashboardCard key={index} {...card} className={styles.Card} />
          ))}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
