import { AxiosError } from "axios";
import store from "common/store";
import { addToast } from "common/store/actions/uiActions";
import { useMutation, useQueryClient } from "react-query";
import { Dispatch, AnyAction } from 'redux';
import { IPatchUser } from "userandauth/interfacesUser";
import { userKeys } from './userKeys';
import { patchUser } from "userandauth/servicesUser";


function usePatchUserDetails () {
    const queryClient = useQueryClient();

    return useMutation<string, AxiosError, IUsePatchUser>(
      ({newUserData, userId}) => patchUser(newUserData, userId),
      {
        onSuccess: (_, {userId}) => {
          addToast({
            type: 'success',
            text: 'toast_user_patch_success'
          })(store.dispatch as Dispatch<AnyAction>);
          queryClient.invalidateQueries(userKeys.user(userId));
          queryClient.invalidateQueries(userKeys.all());
          queryClient.invalidateQueries();
        }
      }
    );
  }
  
  export { usePatchUserDetails };

  interface IUsePatchUser {
    userId: string,
    newUserData: IPatchUser
  }