import * as React from "react";

import ReactPDF, { View } from "@react-pdf/renderer";

import { Text } from "pdf/components/primitives/Text";
import { COLORS } from "pdf/constants";

interface IOrderHeader extends ReactPDF.ViewProps {
  orderNumber: string;
  type: "refill" | "swap";
}

export function OrderHeader({
  orderNumber, 
  type, 
  ...rest
}: IOrderHeader): JSX.Element {
  return (
    <View {...rest}>
      <Text
        translate
        style={{
          fontFamily: "Kanit-medium",
          fontSize: 20,
          lineHeight: 24 / 20,
          letterSpacing: 0.15,
          color: COLORS.onSurfaceHigh
        }}
      >
        {type === "swap" && "label_swap_sheet"}
        {type === "refill" && "label_refill_sheet"}
        {' '}
        {orderNumber}
      </Text>
    </View>
  );
}
