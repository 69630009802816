import * as React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { OverviewLayout } from './views/monitor';
import ViewNav, { IViewNavOption } from 'common/components/viewnav/ViewNav';
import { useTranslation } from 'react-i18next';


type NavigationOptionsType = IViewNavOption & {
  label: string;

}

const NavigationOptions: NavigationOptionsType[] = [
  {
    icon: "Map",
    to: "/machines/map",
    label: "Map"
  },
  {
    icon: "MachineCash",
    to: "/machines/cash",
    label: "Cash" // not use yet
  },
  {
    icon: "Speedometer",
    to: "/machines-v2",
    label: "overview_page.title"
  },
  {
    icon: "StandbyMachine",
    to: "/machines/standbymachine",
    label: "Standby Machine" // not use yet
  },
];

export function MachineV2 () {
  const { t } = useTranslation("machine");
  const location = useLocation();
  const { path } = useRouteMatch();

  return <div>
    <div className='flex gap-3 mb-4'>
      <h1 className='flex-1 font-kanit'>{t(NavigationOptions.find((i) => location.pathname.includes(i.to))?.label || '')}</h1>
      <ViewNav
        options={NavigationOptions}
      />
    </div>
    <Switch>
      <Route path={`${path}/monitor`}>
        <OverviewLayout />
      </Route>
      <Redirect to={`${path}/monitor`} />
    </Switch>
  </div>;
}
