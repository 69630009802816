import * as React from "react";
import ReactPDF, { View } from "@react-pdf/renderer";

import { Text } from "pdf/components/primitives/Text";

import { COLORS } from "pdf/constants";

interface SwapInstructionProps extends ReactPDF.ViewProps {
  swapMethod: "replaceOld" | "refillOnTop";
  slot: string;
}

export function SwapInstruction({
  swapMethod, slot, ...rest
}: SwapInstructionProps): JSX.Element {
  return (
    <View {...rest}>
      <Text
        translate
        style={{
          color: "rgb(235,87,87)",
          fontFamily: "Kanit-regular",
          fontSize: 24,
          lineHeight: 24 / 24,
          letterSpacing: 0.18,
          marginBottom: 12
        }}
      >
        {"*"}
        {`"${slot}" `}
        {swapMethod === "replaceOld" && "label_replace_method_remove_old"}
        {swapMethod === "refillOnTop" && "label_replace_method_refill_on_top"}
        {"*"}
      </Text>
      <Text
        translate
        style={{
          color: COLORS.onSurfaceMedium,
          fontFamily: "Kanit-regular",
          fontSize: 10,
          lineHeight: 16 / 10,
          letterSpacing: 0.4
        }}
      >
        {"label_new_material"}
      </Text>
    </View>
  );
}
