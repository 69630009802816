import { useHistory, useLocation } from "react-router-dom";

const ERROR_FREQUENCY_MODAL_OPEN = "error_frequency_modal_opened";
const OPENED_VALUE = "true";

export function getLinkToOpenErrorFrequencyModal() {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  searchParams.set(ERROR_FREQUENCY_MODAL_OPEN, OPENED_VALUE);
  return `${url.pathname}?${searchParams.toString()}`;
}

export function useErrorFrequencyModalSearchParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isOpen = searchParams.get(ERROR_FREQUENCY_MODAL_OPEN) === OPENED_VALUE;

  const history = useHistory();

  function setIsModalOpen(isOpen: Boolean) {
    if (isOpen) {
      searchParams.set(ERROR_FREQUENCY_MODAL_OPEN, OPENED_VALUE);
      history.push({
        search: searchParams.toString(),
      });
    } else {
      searchParams.delete(ERROR_FREQUENCY_MODAL_OPEN);
      history.push({
        search: searchParams.toString(),
      });
    }
  }

  return [isOpen, setIsModalOpen] as const;
}
