import { Table } from "@tanstack/react-table";
import * as React from "react";
import { ColumnFilterInput } from "../inputs";
import { clsx } from "clsx";

type SecondaryFilterProps<T> = {
  table: Table<T>;
  className?: string;
};

export function SecondaryFilter<T>({ table, className }: SecondaryFilterProps<T>) {
  const tableName = table.getState().tableName;
  return (
    <div className={clsx(className, "flex gap-3 justify-end")}>
      {table
        .getAllLeafColumns()
        .filter(
          (column) =>
            column?.columnDef?.enableColumnFilter &&
            (column.columnDef.meta?.columnFilter.location || "secondary") === "secondary"
        )
        .map((column) => {
          return (
            <div key={`${column.id}`}>
              <ColumnFilterInput column={column} location="secondary" tableName={tableName} />
            </div>
          );
        })}
    </div>
  );
}
