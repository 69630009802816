import { Dispatch } from 'redux';

import { IToast } from 'common/components/toast/Toast';

export function addToast (toast: IToast) {
  return function (dispatch: Dispatch): void {
    dispatch({
      type: 'UI/TOAST/ADD',
      payload: {
        ...toast,
        id: Date.now()
      }
    });
  };
}

export function removeToast (id: string) {
  return function (dispatch: Dispatch): void {
    dispatch({
      type: 'UI/TOAST/REMOVE',
      payload: id
    });
  };
}
