// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ChangeZone--3z9MXL1cUT{display:flex;flex-direction:column}.ChangeZone--3z9MXL1cUT .EditZoneContent--1_mKYB2_n4{margin-top:10px}.ChangeZone--3z9MXL1cUT .ButtonContainer--81_ECPunmv{display:flex;margin-top:10px}.ChangeZone--3z9MXL1cUT .ButtonContainer--81_ECPunmv .save--2rIXFb55vS{margin-left:auto}", ""]);
// Exports
exports.locals = {
	"ChangeZone": "ChangeZone--3z9MXL1cUT",
	"EditZoneContent": "EditZoneContent--1_mKYB2_n4",
	"ButtonContainer": "ButtonContainer--81_ECPunmv",
	"save": "save--2rIXFb55vS"
};
module.exports = exports;
