import {
  GetMaintenanceBoardAaNoTransaction30MinsErrorDocument,
  GetMaintenanceBoardAaNoTransaction30MinsErrorQuery,
  GetMaintenanceBoardAaNoTransaction30MinsErrorQueryVariables
} from "gql/generated";
import { fetcher } from "gql/fetcher";


export async function getAaNoTransaction30Mins({
  serviceZoneId, cursor,
}: {
  serviceZoneId?: string;
  cursor?: string;
}) {
  return fetcher<
    GetMaintenanceBoardAaNoTransaction30MinsErrorQuery,
    GetMaintenanceBoardAaNoTransaction30MinsErrorQueryVariables
  >(GetMaintenanceBoardAaNoTransaction30MinsErrorDocument, {
    after: cursor,
    serviceZoneId: serviceZoneId,
  })();
}
