import * as React from 'react';

import Icon from 'common/components/icon/Icon';
import Typography from 'common/components/typography/Typography';

import * as styles from './CardTitle.module.scss';

interface CardTitleProps {
  icon: string;
  iconColor?: string;
  title: string;
  className?: string;
}

function CardTitle ({
  icon,
  iconColor = 'onSurfaceDisabled',
  title,
  className = ''
}: CardTitleProps): JSX.Element {
  return (
    <div className={[ styles.CardTitle, className ].join(' ')}>
      <div className={styles.IconContainer}>
        <Icon className={styles.Icon} name={icon} color={iconColor} />
      </div>
      <Typography className={styles.TextContainer} translationKey={title} type="body-1" />
    </div>
  );
}

export { CardTitle };
